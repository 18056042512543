import { Route, Redirect } from "react-router-dom";
import { useSelector } from 'react-redux';
import { getLoginDestination } from './util';
import { selectUserRole, selectAsAdmin, selectVersion } from '../auth/AuthSlice';
import MainLayout from '../layouts/MainLayout';
import { path_in_version } from "../nav/util";
import { navData } from '../nav/navData'

/* UserRoute behaves like Route if user is logged in, else, redirects user to Login. */
/* */

function UserRoute({ children, ...rest }: any) {
    const userRole = useSelector(selectUserRole);
    const asAdmin = useSelector(selectAsAdmin);
    const version = useSelector(selectVersion);

    return (
        <Route {...rest} render={props => {
            if (userRole === "Admin" && sessionStorage.getItem("user") && asAdmin === true) {
                const from = getLoginDestination(asAdmin, props.location, version);
                return <Redirect to={{ pathname: from.pathname, state: { from: props.location } }} />
            } else if (sessionStorage.getItem('user') && (path_in_version(props.location.pathname.trim(), version, navData) || !version)) {
                return <MainLayout>{children}</MainLayout>;
            } else if (sessionStorage.getItem('user') && !path_in_version(props.location.pathname.trim(), version, navData)) {
                const from = getLoginDestination(asAdmin, props.location, version);
                return <Redirect to={{ pathname: from.pathname, state: { from: props.location } }} />
            } else {
                return <Redirect to={{ pathname: '/partner_login', state: { from: props.location } }} />
            }
        }} />
    );
};
export default UserRoute;