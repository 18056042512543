import { authService } from "./authService";

export const walletService = {
    getWallets,
    getAggregatedWallets,
    deleteWallet,
    createWallet,
    getWalletTypes,
    autocompleteWalletTypes,
    autocompleteBlockchains,
    getWalletsSixMonths,
    uploadWalletStatement,
}

const base = process.env.REACT_APP_API_ENDPOINT;
const baseUrlWallets = `${base}/wallets`

/* Get all transactions */
function getWallets(): any {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
        },
    };

    return fetch(`${baseUrlWallets}/get`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

function getAggregatedWallets(): any {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
        },
    };

    return fetch(`${baseUrlWallets}/aggregated/get`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

function getWalletTypes(): any {
    const requestParams = {
        method: "GET",
        headers: {
            ...authService.authHeader(),
        },
    };

    return fetch(`${baseUrlWallets}/types`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

function autocompleteWalletTypes(input: string): any {
    const requestParams = {
        method: "GET",
        headers: {
            ...authService.authHeader(),
        },
    };

    return fetch(`${baseUrlWallets}/types/autocomplete/${input}`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}


function autocompleteBlockchains(input: string): any {
    const requestParams = {
        method: "GET",
        headers: {
            ...authService.authHeader(),
        },
    };

    return fetch(`${baseUrlWallets}/blockchains/autocomplete/` + input, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}




function deleteWallet(walletId: string): any {
    const requestParams = {
        method: "DELETE",
        headers: {
            ...authService.authHeader(),
        },
    };

    return fetch(`${baseUrlWallets}/${walletId}`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

function createWallet(address: string, blockchain: string, walletType: string, accountHolderId: string, description: string, accountHolder?: string): any {

    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
            "Content-type": "application/json",
        },
        body: JSON.stringify({
            address: address,
            blockchain: blockchain,
            wallet_type: walletType,
            account_holder_id: accountHolderId,
            account_holder: accountHolder,
            description: description
        }),
    };

    return fetch(`${baseUrlWallets}`, requestParams)
}

/* Wallet statements (additional proof) */
function getWalletsSixMonths(walletId: string): any {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
        },
    };

    return fetch(`${baseUrlWallets}/statement/recent/${walletId}/get`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

function uploadWalletStatement(
    walletId: string,
    userId: string,
    month: number,
    year: number,
    file: string
): any {
    const requestParams = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            ...authService.authHeader(),
        },
        body: JSON.stringify({file}),
    };

    return fetch(`${baseUrlWallets}/statement/${userId}/${walletId}/${month}/${year}`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

function handleResponse(response: any) {
    return response.text().then((text: any) => {
        const data = text && JSON.parse(text);
        return data;
    });
}
