import redcross from "../assets/redcross.svg";
import { useMemo, useState } from "react";
import Modal from "../components/Modal";
import { useDispatch, useSelector } from "react-redux";
import { selectVersion } from "../auth/AuthSlice";
import capitalizeFirstLetter from "../util/capitalizeFirstLetter";
import info from "../assets/info.svg";
import getSymbolFromCurrency from "currency-symbol-map";
import { getReprensetation } from "../watchlist/Representations";
import { StyledPageTitle } from "../components/StyledPageTitle";
import { StyledModalItem } from "../components/StyledModalItem";
import { StyledInputField } from "../components/StyledInputField";
import minDate from "../util/minDate";
import { StyledActionButton } from "../components/StyledActionButton";
import { endStandingOrder } from "./DashboardSlice";

interface IListItemProps {
    id: number;
    transactionType: string;
    orderType: string;
    ticker: string;
    assetName: string;
    secondaryTicker: string;
    secondaryAssetName: string;
    tradeType: string;
    currency: string;
    volume: string;
    secondaryCryptoVolume: number;
    amount: number;
    secondaryCryptoAmount: number;
    status: string;
    approvedFrom: string;
    approvedUntil: string;
    complianceUser: string;
    dateCreated: string;
    dateUpdated: string;
    user: string;
    key: number;
    representations: { [key: string]: boolean };
    explanation: string;
    subCategory: string;
    frequency: string;
    start: string;
    end: string;
    complianceNotes: string;
    handleDelete(email: string): void;
}

function formatDate(initDate: string) {
    if (initDate !== null && initDate !== "") {
        const tradeReqDate = initDate.split("T")[0];
        const tradeReqTime = initDate.split("T")[1];
        const [year, month, day] = tradeReqDate.split("-");
        const [hour, minute] = tradeReqTime.split(".")[0].split(":");

        const args = [
            parseInt(year),
            parseInt(month),
            parseInt(day),
            parseInt(hour),
            parseInt(minute),
        ];

        if (args.reduce((prev, curr) => prev && !isNaN(curr), true)) {
            const offset = new Date().getTimezoneOffset();
            const hourOffset = Math.floor(offset / 60);
            const minuteOffset = offset % 60;
            const date = new Date(
                Date.UTC(
                    parseInt(year),
                    parseInt(month) - 1,
                    parseInt(day),
                    parseInt(hour) - hourOffset,
                    parseInt(minute) + minuteOffset
                )
            );
            const dateISO = date.toISOString();
            const dateISODate = dateISO.split("T")[0].split("-");
            const dateISOTime = dateISO.split("T")[1].split(".")[0].split(":");
            return `${dateISODate[1]}/${dateISODate[2]} \n ${dateISOTime[0]}:${dateISOTime[1]}`;
        }
    } else {
        return null;
    }
}

/* Makes up the DashboardList
 * Set up as component to more gracefully support styling
 * and eventually add buttons/functionality to them.
 */
function TradesListItem(props: IListItemProps): JSX.Element {
    const [showRepresentations, setShowRepresentations] = useState(false);

    const company = useSelector((state: any) => state.auth.companyName);
    const version = useSelector(selectVersion);
    const explanation = useMemo(() => {
        if (props.explanation) return props.explanation;
        return <i>No explanation.</i>;
    }, [props]);

    const repsSchema = getReprensetation(company);

    const headerText = useMemo(() => {
        if (props.explanation) return <i>Explanation</i>;
        return "";
    }, [props]);

    const representations = useMemo(() => {
        if (props.representations) {
            return Object.values(props.representations).map((representation) =>
                representation ? "Y" : "N"
            );
        }
        return [];
    }, [props]);

    const [endStandingOrderModal, setEndStandingOrderModal] = useState(false);
    const [end, setEnd] = useState<undefined | string>();
    const dispatch = useDispatch();

    const handleEndStandingOrder = async (event: any) => {
        event.preventDefault();
        if (end !== undefined) {
            var payload = {
                id: props.id,
                end,
            };

            dispatch(endStandingOrder(payload));

            setEndStandingOrderModal(false);
            setEnd(undefined);
        }
    };

    return (
        <>
            <tr>
                {props.tradeType === "all" ? <td>{props.user}</td> : null}
                <td>{capitalizeFirstLetter(props.transactionType)}</td>
                <td>{props.ticker}</td>
                <td>{props.assetName}</td>
                {(version === "crypto_base" || version === "crossover_base") && (
                    <td>
                        {props.volume ? props.volume + " Units" : "-"} <br />
                        {props.amount
                            ? getSymbolFromCurrency(props.currency) + "" + props.amount
                            : "-"}
                    </td>
                )}
                {(version === "crypto_base" || version === "crossover_base") && (
                    <td>{props.secondaryTicker}</td>
                )}
                {(version === "crypto_base" || version === "crossover_base") && (
                    <td>{props.secondaryAssetName}</td>
                )}
                {(version === "crypto_base" || version === "crossover_base") && (
                    <td>
                        {props.secondaryCryptoVolume
                            ? props.secondaryCryptoVolume + " Units"
                            : "-"}{" "}
                        <br />
                        {props.secondaryCryptoAmount
                            ? getSymbolFromCurrency(props.currency) +
                              "" +
                              props.secondaryCryptoAmount
                            : "-"}{" "}
                    </td>
                )}
                {version !== "crypto_base" && version !== "crossover_base" && <td></td>}
                <td>{capitalizeFirstLetter(props.status)}</td>
                {props.tradeType === "cleared" ? (
                    <>
                        <td>{formatDate(props.start)}</td>
                        <td>{formatDate(props.approvedUntil)}</td>
                    </>
                ) : null}

                {props.tradeType === "pending" ? (
                    <td>{formatDate(props.dateCreated)}</td>
                ) : null}

                {props.tradeType === "closed" ? (
                    <td>{formatDate(props.dateUpdated)}</td>
                ) : null}

                {props.tradeType === "all" ? (
                    <>
                        {(version === "crypto_base" ||
                            version === "crossover_base") && (
                            <td>
                                <img
                                    style={{ cursor: "pointer" }}
                                    src={info}
                                    alt={"Info"}
                                    width={20}
                                    height={20}
                                    onClick={() => {
                                        setShowRepresentations(true);
                                    }}
                                />
                            </td>
                        )}

                        <td>{formatDate(props.dateCreated)}</td>
                        {/* <td>{formatDate(props.dateUpdated)}</td> */}
                        <td>
                            {props.complianceUser
                                ? props.complianceUser
                                : props.status !== "PENDING"
                                ? "Auto"
                                : ""}
                        </td>
                        <td>{new Date(props.start).toLocaleDateString()}</td>
                        <td align="center">
                            {props.end ? (
                                new Date(props.end).toLocaleDateString()
                            ) : (
                                <img
                                    style={{ cursor: "pointer" }}
                                    src={redcross}
                                    alt=""
                                    onClick={() => {
                                        setEndStandingOrderModal(true);
                                    }}
                                />
                            )}
                        </td>
                    </>
                ) : null}
                {/* i.e. Delete button goes here */}
            </tr>
            <Modal
                show={showRepresentations}
                onClose={() => {
                    setShowRepresentations(false);
                }}
            >
                <div>
                    <div style={{ height: 200 }}>
                        {headerText}
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: 100,
                            }}
                        >
                            {explanation}
                        </div>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            {props.complianceNotes}
                        </div>
                    </div>
                    <div style={{ textAlign: "left" }}>
                        <strong>Standing Order:</strong>
                        {props.subCategory && props.subCategory === "STANDING_ORDER" && (
                            <div>
                                <strong>Frequency: </strong>{" "}
                                {capitalizeFirstLetter(props.frequency)}
                            </div>
                        )}
                        {props.subCategory && props.subCategory === "STANDING_ORDER" && (
                            <div>
                                <strong>Start: </strong> {props.start.split("T")[0]}
                            </div>
                        )}
                    </div>
                    {(version === "crypto_base" || version === "crossover_base") &&
                        representations.length !== 0 && (
                            <>
                                <div
                                    style={{ height: 1, backgroundColor: "lightgray" }}
                                />
                                <br></br>
                                <p style={{ textAlign: "left" }}>
                                    {repsSchema
                                        .filter((reps) => reps.reviewLabel)
                                        .map((rep, index) => (
                                            <p>
                                                {index + 1}.{" "}
                                                {rep.reviewLabel +
                                                    representations[index]}
                                                <br />
                                            </p>
                                        ))}
                                </p>
                            </>
                        )}
                </div>
            </Modal>
            <Modal
                show={endStandingOrderModal}
                onClose={() => {
                    setEndStandingOrderModal(false);
                }}
            >
                <div
                    style={{ height: "90%", display: "flex", flexDirection: "column" }}
                >
                    <StyledPageTitle style={{ fontSize: "30px" }}>
                        End Standing Order: {props.user ? props.user : ""}
                    </StyledPageTitle>
                    <h3 style={{ textAlign: "center" }}>
                        Details: {capitalizeFirstLetter(props.transactionType)} -{" "}
                        {props.assetName} ({props.ticker}) - {props.volume}{" "}
                        {version === "crypto_base" ? "units" : "shares"}
                    </h3>
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            flex: 1,
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <form
                            onSubmit={handleEndStandingOrder}
                            style={{ width: "100%" }}
                        >
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {props.subCategory === "STANDING_ORDER" && (
                                    <StyledModalItem>
                                        <label style={{ width: "100%" }}>
                                            End Date
                                        </label>
                                        <StyledInputField
                                            style={{ width: "100%" }}
                                            onFocus={(e) => {
                                                if (end === undefined) {
                                                    setEnd("");
                                                }
                                            }}
                                            onBlur={(e) => {
                                                if (end === "") {
                                                    setEnd(undefined);
                                                }
                                            }}
                                            type={end === undefined ? "text" : "date"}
                                            placeholder="Ongoing"
                                            value={end || ""}
                                            onChange={(event) =>
                                                setEnd(event.target.value)
                                            }
                                            min={minDate(
                                                new Date(props.start) > new Date()
                                                    ? new Date(props.start)
                                                    : new Date()
                                            )}
                                        />
                                    </StyledModalItem>
                                )}

                                <br />
                                <StyledActionButton
                                    style={{
                                        display: "block",
                                        margin: "auto",
                                        width: "300px",
                                    }}
                                >
                                    End Standing Order
                                </StyledActionButton>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default TradesListItem;
