import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StyledSectionHeader } from "../../components/StyledSectionHeader";
import useInterval from "../../util/useInterval";
import HistoricHoldingsList from "./HistoricHoldingsList";
import { fetchHistoricHoldings, selectHistoricHoldings } from "./HistoricHoldingsSlice";
import handleDownload from "../../util/downloadCsv";
import TablePageFooter, { DefaultPagination } from "../../components/TablePageFooter";
import { ISorting } from "../../dashboard/DashboardSlice";
import "./Calendar.css";
import Calendar from "react-calendar";
import UserAutocomplete, { UserSuggestion } from "../../components/UserAutoComplete";
import { StyledSearchInput } from "../../components/StyledFilterInput";
import { holdingsService } from "../../services/holdingsService";
import { StyledModalItem } from "../../components/StyledModalItem";
import { StyledSelect } from "../../components/StyledSelect";
import { StyledFilterValue } from "../../components/StyledFilterValue";
import redcross from "../../assets/redcross.svg";
import { StyledActionButton } from "../../components/StyledActionButton";
import { IPagination } from "../../util/pagination";
import { downloadEvent, downloadFromUrl } from "../../util/downloadFromUrl";
import { selectEventStream, selectUserId } from "../../auth/AuthSlice";

function HistoricHoldings(): JSX.Element {
    const dispatch = useDispatch();
    const historicHoldings = useSelector(selectHistoricHoldings);

    const defaultFilters: { [key: string]: string } = {
        user_id: "",
        name: "",
        account: "",
        accountHolder: "",
        ticker: "",
        asset: "",
    };

    const [filterMultiple, setFilterMultiple] = useState(defaultFilters) as any;
    const [filter, setFilter] = useState("user_id");
    const [search, setSearch] = useState("");

    const [pagination, setPagination] = useState<IPagination>(DefaultPagination());
    const nextPage = useSelector((state: any) => state.historicHoldings.nextPage);

    const [sorting, setSorting] = useState<ISorting>({
        field: "name",
        direction: "asc",
    });

    const [date, setDate] = useState(new Date());

    const [user, setUser] = useState({ id: "", name: "", email: "" });
    const [lastDate, setLastDate] = useState<Date | undefined>(undefined);

    const version = useSelector((state: any) => state.auth.version);
    const category = useMemo(() => {
        if (version === "crypto_monitoring" || version === "crypto_base") {
            return [
                "CRYPTO_EXCHANGE",
                "CRYPTO_CHAIN",
                "CRYPTO_TOKEN",
                "CRYPTO_NFT",
                "CRYPTO_MANUAL_UPLOAD",
            ];
        } else if (version === "crossover_base") {
            return [
                "SECURITY",
                "CRYPTO_EXCHANGE",
                "CRYPTO_CHAIN",
                "CRYPTO_TOKEN",
                "CRYPTO_NFT",
                "CRYPTO_MANUAL_UPLOAD",
            ];
        }
        return ["SECURITY"];
    }, [version]);

    useEffect(() => {
        dispatch(
            fetchHistoricHoldings({
                pagination,
                filter: { category, ...filterMultiple },
                sorting: [sorting],
                date,
            })
        );
    }, [dispatch, sorting, date, category, filterMultiple, pagination]);

    useEffect(() => {
        if (lastDate === undefined) {
            holdingsService
                .getLastHistoricHoldingDate(user.id)
                .then((response: any) => {
                    setLastDate(new Date(Date.parse(response.date)));
                });
        }
    }, [user, lastDate]);

    useInterval(() => {
        dispatch(
            fetchHistoricHoldings({
                pagination,
                filter: { ...filterMultiple, category },
                sorting: [sorting],
                date,
            })
        );
    }, 25000);

    const handleDelete = (e: string) => {
        /* No delete. */
        return;
    };

    const selectUser = (user: UserSuggestion | undefined) => {
        if (user !== undefined) {
            filterMultiple[filter] = user.id;
            setFilterMultiple({ ...filterMultiple });
            setUser({ id: user.id, name: user.name, email: user.email });
        } else {
            filterMultiple[filter] = defaultFilters[filter];
            setFilterMultiple({ ...filterMultiple });
            setUser({ id: "", name: "", email: "" });
        }
        setSearch("");
    };

    function removeFilter(txt: any) {
        filterMultiple[txt] = "";
        setFilterMultiple({ ...filterMultiple });
    }

    function handleKeyDown(event: any) {
        if (event.key === "Enter") {
            filterMultiple[filter] = search;
            setFilterMultiple({ ...filterMultiple });
            setSearch("");
        }
    }

    const filterOptions = useMemo(() => {
        return (
            <>
                <option value="user_id">Employee</option>
                {version === "crypto_base" ? (
                    <option value="accountHolder">Account Holder</option>
                ) : (
                    <option value="user">User</option>
                )}
                <option value="account">Account Name</option>
                <option value="holdingType">Holding Type</option>
                <option value="ticker">Ticker</option>
                <option value="asset">Asset</option>
            </>
        );
    }, [version]);

    function handleOnClick() {
        setFilterMultiple(defaultFilters);
    }

    const fields = useMemo(() => {
        return [
            "employee",
            version === "crypto_base" ? "accountHolder" : "user",
            "accountName",
            "description",
            "holdingType",
            "ticker",
            "asset",
            "volume",
            "lastUpdated",
            version === "crypto_integration" ||
                version === "crypto_monitoring" ||
                version === "crossover_base" ||
                version === "crypto_base"
                ? "contractAddress"
                : null,
        ].filter((field) => field !== null) as string[];
    }, []);

    return (
        <div>
            <div style={{ width: "100%", marginBottom: "20px" }}>
                <div style={{ position: "relative" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <StyledModalItem style={{ display: "flex", width: "50%" }}>
                            <StyledSelect
                                id="filterValue"
                                name="filterValue"
                                onChange={(event) => {
                                    setFilter(event.target.value);
                                }}
                            >
                                {filterOptions}
                            </StyledSelect>
                        </StyledModalItem>
                        <div style={{ width: "50%", position: "relative" }}>
                            {filter === "user" || filter === "user_id" ? (
                                <UserAutocomplete
                                    value={search}
                                    onSelect={selectUser}
                                    onEnter={selectUser}
                                    style={{ width: "100%" }}
                                    placeholder={"Filter by name or email"}
                                />
                            ) : (
                                <StyledSearchInput
                                    placeholder="Search Historic Holdings"
                                    type={"text"}
                                    value={search}
                                    onKeyDown={handleKeyDown}
                                    onChange={(event) => setSearch(event.target.value)}
                                    style={{ display: "flex" }}
                                />
                            )}
                        </div>
                        <StyledActionButton
                            style={{ width: "20%", marginLeft: "6%" }}
                            onClick={handleOnClick}
                        >
                            Clear Filters
                        </StyledActionButton>
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    {Object.keys(filterMultiple) &&
                        Object.keys(filterMultiple).map((txt: any, index: any) => (
                            <>
                                {filterMultiple[txt] !== "" ? (
                                    <StyledFilterValue key={index}>
                                        &nbsp;&nbsp;&nbsp;
                                        {txt === "user_id" ? "employee" : txt}:{" "}
                                        {txt === "user_id"
                                            ? user.name
                                            : filterMultiple[txt]}
                                        <img
                                            style={{
                                                marginRight: "4px",
                                                cursor: "pointer",
                                            }}
                                            src={redcross}
                                            alt=""
                                            onClick={() => removeFilter(txt)}
                                        />
                                    </StyledFilterValue>
                                ) : null}
                            </>
                        ))}
                </div>
            </div>
            <Calendar
                onChange={(date: Date) => {
                    setDate(date);
                    setPagination({
                        ...pagination,
                        pageNumber: 1,
                    });
                }}
                value={date}
                tileDisabled={(tileProps) =>
                    tileProps.date > new Date() ||
                    (lastDate !== undefined && tileProps.date < lastDate)
                }
            />

            <StyledSectionHeader>Historic Holdings</StyledSectionHeader>
            <div
                onClick={() => {
                    handleDownload(
                        holdingsService.getHistoricHoldingsDownload(
                            { ...user, ...filterMultiple, category },
                            [sorting],
                            fields,
                            date
                        ),
                        "historic_holdings"
                    );
                }}
                style={{
                    textAlign: "right",
                    fontSize: "15px",
                    textDecoration: "underline",
                    color: "#2c71f0",
                    cursor: "pointer",
                }}
            >
                Download historic holdings
            </div>
            <HistoricHoldingsList
                holdings={historicHoldings}
                handleDelete={handleDelete}
                setSorting={setSorting}
                sorting={sorting}
            />
            <TablePageFooter
                nextPage={nextPage}
                pagination={pagination}
                setPageNumber={(n) => setPagination({ ...pagination, pageNumber: n })}
                setPageSize={(s) => setPagination({ ...pagination, pageSize: s })}
            />
        </div>
    );
}

export default HistoricHoldings;
