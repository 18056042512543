/* Wrapper for calls to the Accounts route */
/* Handles querying User Accounts, Connecting Accounts through Yodlee, */
/* and adding Custom investment accounts and statements. */

import { ISorting } from "../dashboard/DashboardSlice";
import { IPagination, paginationQueryParam } from "../util/pagination";
import { authService } from "./authService";

export const holdingsService = {
    getHistoricHoldings,
    getHistoricHoldingsDownload,
    getHistoricUserHoldingsDownload,
    getLastHistoricHoldingDate,
    getLastHistoricHoldingDateUser,
    getUserHistoricHoldings,
    getUserHistoricHoldingsAttestation,
};

const base = process.env.REACT_APP_API_ENDPOINT;
const baseUrl = `${base}/holdings`;

export interface IFilter {
    user: string;
    action: string;
    ticker: string;
    security: string;
    account: string;
    volume: string;
    permissionStatus: string;
    date: string;
}

function getHistoricHoldings(
    pagination: IPagination,
    filter: {},
    sorting: ISorting[],
    date: Date
): any {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
            "Content-type": "application/json",
        },
        body: JSON.stringify({ filter, sorting, date }),
    };

    return fetch(
        paginationQueryParam(
            `${baseUrl}/historic-holdings/get?date=${date
                .toISOString()
                .replace("Z", "")}`,
            pagination
        ),
        requestParams
    )
        .then(authService.handleErrors)
        .then(handleResponse);
}

function getUserHistoricHoldings(
    pagination: IPagination,
    filter: {},
    sorting: ISorting[],
    date: Date
): any {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
            "Content-type": "application/json",
        },
        body: JSON.stringify({ filter, sorting, date }),
    };

    return fetch(
        paginationQueryParam(
            `${baseUrl}/historic-holdings/get?asUser=true&date=${date
                .toISOString()
                .replace("Z", "")}`,
            pagination
        ),
        requestParams
    )
        .then(authService.handleErrors)
        .then(handleResponse);
}

function getUserHistoricHoldingsAttestation(sorting: any): any {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
            "Content-type": "application/json",
        },
        body: JSON.stringify({ sorting }),
    };

    return fetch(`${baseUrl}/user/attestations/get`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

function getLastHistoricHoldingDate(user: string): any {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
            "Content-type": "application/json",
        },
        body: JSON.stringify({ user }),
    };

    return fetch(`${baseUrl}/historic-holdings/last-date/get`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

function getLastHistoricHoldingDateUser(): any {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
        },
    };

    return fetch(
        `${baseUrl}/historic-holdings/last-date/get?asUser=true`,
        requestParams
    )
        .then(authService.handleErrors)
        .then(handleResponse);
}

function getHistoricHoldingsDownload(
    filter: {},
    sorting: ISorting[],
    fields: string[],
    date: Date
): any {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
            "Content-type": "application/json",
        },
        body: JSON.stringify({ filter, sorting, date, fields }),
    };

    return fetch(
        `${baseUrl}/historic-holdings/download?date=${date
            .toISOString()
            .replace("Z", "")}`,
        requestParams
    )
    //         .then(authService.handleErrors)
    //         .then(handleResponse);
}

function getHistoricUserHoldingsDownload(
    filter: {},
    sorting: ISorting[],
    date: Date,
): any {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
            "Content-type": "application/json",
        },
        body: JSON.stringify({ filter, sorting, date }),
    };

    return fetch(
        `${baseUrl}/historic-holdings/download?asUser=true&date=${date
            .toISOString()
            .replace("Z", "")}`,
        requestParams
    )
    // .then(authService.handleErrors)
    // .then(handleResponse);

}

function handleResponse(response: any) {
    return response.text().then((text: any) => {
        const data = text && JSON.parse(text);
        return data;
    });
}
