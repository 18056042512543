import { useState } from "react";
import capitalizeFirstLetter from "../util/capitalizeFirstLetter";
// import { isRegularExpressionLiteral } from "typescript";
import escapeRegExp from "../util/regexHelpers";
import { StyledActionButton } from "./StyledActionButton";
import { StyledDropdown } from "./StyledDropdown";
import { StyledInputField } from "./StyledInputField";

interface IStaticAutoComplete {
    placeholder: string;
    activeValue: any;
    choices: Array<any>;
    showButton?: boolean;
    style?: any;
    dropdownStyle?: any;
    noMatchSuggestion?: string;
    noMatchAction?(): void;
    onSelect(event: any): void;
    handleKeyDown(event: any): void;
    prioritize?(a: string, b: string): number;
    getDisplayName?(a: any): string;
}

function getDisplayNameDefault(e: any) {
    return e as string;
}

function StaticAutoComplete(props: IStaticAutoComplete): JSX.Element {
    const [suggestions, setSuggestions] = useState([] as any);
    const [cursor, setCursor] = useState(0);
    const {
        placeholder,
        activeValue,
        choices,
        showButton,
        style = {},
        dropdownStyle,
        noMatchSuggestion,
        noMatchAction,
        onSelect,
        handleKeyDown,
        prioritize,
        getDisplayName = getDisplayNameDefault,
    } = props;

    const onChangeText = (event: any) => {
        const value = event.target.value;
        setSuggestions([]);
        setCursor(0);
        let lenChoices = 0;
        if (value.length > 0) {
            const regex = new RegExp(`^.* ?${escapeRegExp(value)}`, "i");
            setSuggestions(
                choices
                    .filter((v) => regex.test(getDisplayName(v)))
                    .sort((a: any, b: any) => {
                        if (prioritize) {
                            if (
                                prioritize(getDisplayName(a), getDisplayName(b)) !== 0
                            ) {
                                return prioritize(getDisplayName(a), getDisplayName(b));
                            } else {
                                return (
                                    getDisplayName(a)
                                        .toLowerCase()
                                        .indexOf(escapeRegExp(value.toLowerCase())) -
                                    getDisplayName(b)
                                        .toLowerCase()
                                        .indexOf(escapeRegExp(value.toLowerCase()))
                                );
                            }
                        } else {
                            return (
                                getDisplayName(a)
                                    .toLowerCase()
                                    .indexOf(escapeRegExp(value.toLowerCase())) -
                                getDisplayName(b)
                                    .toLowerCase()
                                    .indexOf(escapeRegExp(value.toLowerCase()))
                            );
                        }
                    })
                    .splice(0, 10)
            );
            lenChoices = choices.filter((v) => regex.test(v)).length;
        }

        if (lenChoices === 0) {
            if (value !== "" && noMatchSuggestion) {
                setSuggestions([noMatchSuggestion]);
            }
        }

        onSelect(event.target.value);
    };

    function renderSuggestions() {
        if (suggestions.length === 0) {
            return null;
        }

        return (
            <ul>
                {suggestions.map((item: any, index: number) => (
                    <li
                        style={{
                            backgroundColor:
                                cursor - 1 === index ? "rgba(249, 249, 249, 1)" : "",
                        }}
                        key={index}
                        /* Must stop the onBlur event from propagating with the */
                        /* onMouseDown event. It should occur after the onClick event. */
                        /* Otherwise, suggestions list is emptied before the onClick fires. */
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={(e) => {
                            if (!(noMatchSuggestion && item === noMatchSuggestion)) {
                                suggestionSelected(item);
                                if (noMatchAction) {
                                    noMatchAction();
                                }
                            } else if (noMatchAction) {
                                noMatchAction();
                            }
                        }}
                    >
                        {getDisplayName(item)}
                    </li>
                ))}
            </ul>
        );
    }

    function suggestionSelected(value: any) {
        onSelect(value);
        setSuggestions([]);
    }

    const handleKeyPress = (event: any) => {
        if (event.key === "Enter") {
            if (event.target.value !== "") {
                // onSelect("");
                setSuggestions([]);

                setCursor(0);
            }
        } else if (event.key === "ArrowUp" && cursor > 0) {
            setCursor((prevCount) => prevCount - 1);
            onSelect(suggestions[cursor - 2]);
        } else if (event.key === "ArrowDown" && cursor < suggestions.length) {
            setCursor((prevCount) => prevCount + 1);
            onSelect(suggestions[cursor]);
        } else if (event.key === "ArrowUp" && cursor === 0) {
            onSelect("");
            setSuggestions([]);
        }

        // handleKeyDown(event);
    };

    function handleSubmit(event: any) {
        if (activeValue !== "") {
            setSuggestions([]);
            onSelect("");
            setCursor(0);
        }

        event.key = "Enter";
        handleKeyDown(event);
    }

    return (
        <>
            <StyledInputField
                style={style ? style : {}}
                onKeyDown={handleKeyPress}
                placeholder={placeholder}
                value={getDisplayName(activeValue)}
                onChange={onChangeText}
                onBlur={(event) => setSuggestions([])}
            />

            <StyledDropdown
                style={{
                    visibility: suggestions.length > 0 ? "visible" : "hidden",
                    whiteSpace: "pre-wrap",
                    overflowWrap: "break-word",
                    ...dropdownStyle,
                }}
            >
                {renderSuggestions()}
            </StyledDropdown>

            {showButton ? (
                <StyledActionButton onClick={handleSubmit} style={{ width: "25%" }}>
                    Enter
                </StyledActionButton>
            ) : null}
        </>
    );
}

export default StaticAutoComplete;
