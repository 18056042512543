import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    createWallet,
    deleteWallet,
    fetchAggregatedWallets,
    fetchWallets,
    IWallet,
    selectAggregatedWallets,
    selectWallets,
} from "./WalletSlice";
import useInterval from "../util/useInterval";
import WalletTable from "./WalletTable";
import { StyledSectionHeader } from "../components/StyledSectionHeader";
import { StyledInputField } from "../components/StyledInputField";
import { StyledActionButton } from "../components/StyledActionButton";
import WalletTypeAutoComplete from "../components/WalletTypeAutoComplete";
import WalletModal from "./WalletModal";
import DesignatedUserSelect from "../components/DesignatedUserSelect";
import { selectSecondaryUsers } from "../user/UserProfile/UserProfileSlice";
import RequiredField from "../components/RequiredField";
import { toast } from "react-toastify";

export const formatWallet: { [key: string]: string } = {
    BITCOIN: "Bitcoin",
    ETHEREUM: "Ethereum",
    DOGECOIN: "Dogecoin",
    BITCOIN_CASH: "Bitcoin Cash",
    BITCOIN_SV: "Bitcoin SV",
    LITECOIN: "Litecoin",
    DASH: "Dash",
    GROESTLCOIN: "Groestlcoin",
    ZCASH: "ZCash",
    SOLANA: "Solana",
    CARDANO: "Cardano",
    RIPPLE: "Ripple",
    POLKADOT: "Polkadot",
    STELLAR: "Stellar",
    MONERO: "Monero",
    EOS: "EOS",
    KUSAMA: "Kusama",
    ECASH: "eCash",
    MIXING: "Mixin",
    AVALANCHE: "Avalanche",
};

function Wallets(): JSX.Element {
    const designatedUsers = useSelector(selectSecondaryUsers);

    /* State for inputs */
    const [submitAttempted, setSubmitAttempted] = useState(false);
    const [open, setOpen] = useState(false);
    const [walletType, setWalletType] = useState("");
    const [accountHolder, setAccountHolder] = useState("");
    const [description, setDescription] = useState("");
    const [selectedWalletType, setSelectedWalletType] = useState("");
    const [selectedAccountHolderId, setSelectedAccountHolderId] = useState("");

    useEffect(() => {
        if (
            designatedUsers.length > 0 &&
            selectedAccountHolderId === "" &&
            accountHolder === ""
        ) {
            setSelectedAccountHolderId(designatedUsers[0].id);
            setAccountHolder(designatedUsers[0].name);
        } else if (selectedAccountHolderId === "" && accountHolder === "") {
            setSelectedAccountHolderId(userId);
        }
    }, [selectedAccountHolderId]);

    // useEffect(() => {
    //     setDefaultAccountHolder();
    // }, [designatedUsers, designatedUsers.length])

    /* Selector for UserId (add to watchlist for demo) */
    const userId = useSelector((state: any) => state.auth.userId);

    /* Hooks for selectors, store*/
    const wallets: any = useSelector(selectWallets);
    const dispatch = useDispatch();

    const handleSubmit = (blockchain: string, address: string) => {
        dispatch(
            createWallet({
                address,
                blockchain,
                walletType: selectedWalletType,
                accountHolderId: selectedAccountHolderId,
                description: description,
                accountHolder: accountHolder,
            })
        );
    };

    const handleDelete = (walletId: string) => {
        dispatch(deleteWallet(walletId));
    };

    const [aggregatedWallets, setAggregatedWallets] = useState<any>({});

    useEffect(() => {
        const aggregatedWallets: {
            [walletType: string]: { [accountHolderId: string]: IWallet[] };
        } = {};
        wallets.forEach((wallet: IWallet) => {
            if (wallet.walletType) {
                if (!(wallet.walletType in aggregatedWallets)) {
                    aggregatedWallets[wallet.walletType] = {};
                }
                if (wallet.accountHolderId) {
                    if (
                        !(
                            wallet.accountHolderId in
                            aggregatedWallets[wallet.walletType]
                        )
                    ) {
                        aggregatedWallets[wallet.walletType][wallet.accountHolderId] =
                            [];
                    }
                    aggregatedWallets[wallet.walletType][wallet.accountHolderId].push(
                        wallet
                    );
                }
            }
        });
        setAggregatedWallets(aggregatedWallets);
    }, [wallets]);
    useEffect(() => {
        if (userId !== -1) {
            dispatch(fetchWallets());
        }
    }, [dispatch, userId]);

    useInterval(() => {
        if (userId !== -1) {
            dispatch(fetchWallets());
        }
    }, 15000);

    const handleModalOpenFromTable = (
        walletType: string,
        accountHolder: string,
        accountHolderId: string
    ) => {
        setSelectedAccountHolderId(accountHolderId);
        setAccountHolder(accountHolder);
        setSelectedWalletType(walletType);

        setOpen(true);
    };

    const handleModalOpen = () => {
        if (
            walletType
                .replaceAll(" ", "")
                .toLocaleLowerCase()
                .match(/^[0-9a-z]+$/) &&
            walletType.replaceAll(" ", "").length > 0
        ) {
            setSelectedAccountHolderId(selectedAccountHolderId);
            setSelectedWalletType(walletType);
            setOpen(true);
        } else {
            toast.error("Wallet type is invalid.", {
                position: "top-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setWalletType("");
        }
    };

    const company = useSelector((state: any) => state.auth.companyName);

    const setDefaultAccountHolder = () => {
        if (designatedUsers.length > 0) {
            setSelectedAccountHolderId(designatedUsers[0].id);
            setAccountHolder(designatedUsers[0].name);
        } else {
            setSelectedAccountHolderId(userId);
        }
    };

    return (
        <>
            <br />

            <StyledSectionHeader>Add a Wallet</StyledSectionHeader>

            <div style={{ display: "flex" }}>
                <div style={{ width: "67%", display: "flex" }}>
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <RequiredField
                            value={walletType}
                            submitAttempted={submitAttempted}
                            wrapperStyles={{ width: "50%", marginBottom: "10px" }}
                            textStyles={{
                                position: "static",
                                margin: 0,
                                lineHeight: "18px",
                            }}
                        >
                            <div style={{ position: "relative" }}>
                                <WalletTypeAutoComplete
                                    style={{ width: "100%" }}
                                    placeholder="Add Wallet Type"
                                    value={walletType}
                                    onEnter={setWalletType}
                                    onChange={setWalletType}
                                    onSelectText={setWalletType}
                                />
                            </div>
                        </RequiredField>
                    </div>
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <RequiredField
                            value={selectedAccountHolderId}
                            submitAttempted={submitAttempted}
                            wrapperStyles={{ width: "50%" }}
                            textStyles={{
                                position: "static",
                                margin: 0,
                                lineHeight: "18px",
                            }}
                        >
                            <DesignatedUserSelect
                                style={{ width: "100%" }}
                                accountHolderId={selectedAccountHolderId}
                                setAccountHolder={(accountHolderId, accountHolder) => {
                                    setSelectedAccountHolderId(accountHolderId);
                                    setAccountHolder(accountHolder);
                                }}
                            />
                        </RequiredField>
                    </div>

                    {(company === "Nydig" ||
                        company === "Argus" ||
                        company === "Fargus" ||
                        company === "ArgusTest") && (
                        <div style={{ width: "100%" }}>
                            <StyledInputField
                                style={{ width: "100%" }}
                                placeholder="Description"
                                value={description}
                                onChange={(event) => {
                                    setDescription(event.target.value);
                                }}
                            />
                        </div>
                    )}
                </div>
                <StyledActionButton style={{ width: "33%" }} onClick={handleModalOpen}>
                    Add Wallet
                </StyledActionButton>
            </div>

            <WalletTable
                aggregatedWallets={aggregatedWallets}
                handleOpen={handleModalOpenFromTable}
            />

            {/* Modal */}
            {open ? (
                <WalletModal
                    accountHolder={selectedAccountHolderId}
                    walletType={selectedWalletType}
                    aggregatedWallets={aggregatedWallets}
                    onDelete={handleDelete}
                    onSubmit={handleSubmit}
                    onHide={() => {
                        setOpen(false);
                        setWalletType("");
                        setSelectedWalletType("");
                    }}
                />
            ) : null}
        </>
    );
}

export default Wallets;
