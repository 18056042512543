import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StyledSearchInput } from "../components/StyledFilterInput";
import { Version } from "./AdminList";
import { selectVersion } from "../auth/AuthSlice";
import {
    fetchRestrictionGroups,
    fetchRestrictions,
    selectRules,
    selectRestrictionGroups,
} from "./RestrictionSlice";

import TablePageFooter, { DefaultPagination } from "../components/TablePageFooter";
import { ISorting } from "../dashboard/DashboardSlice";
import TradeReqModal from "../watchlist/TradeReqModal";
import UserList from "./UserList";
import { StyledActionButton } from "../components/StyledActionButton";
import { IPagination } from "../util/pagination";

function UserRule(): JSX.Element {
    const rules = useSelector(selectRules);
    const restrictionGroups = useSelector(selectRestrictionGroups);

    const version = useSelector(selectVersion);
    const showSecurityRestrictions = useMemo(
        () => version !== "crypto_base" && version !== "crypto_fullstack",
        [version]
    );
    const showCryptoRestrictions = useMemo(
        () =>
            version === "crypto_base" ||
            version === "crossover_base" ||
            version === "crypto_fullstack",
        [version]
    );
    const [filter, setFilter] = useState({
        name: "",
        activityCategory:
            showCryptoRestrictions && showSecurityRestrictions
                ? "ALL"
                : showSecurityRestrictions
                ? "SECURITY_COMPANY"
                : "CRYPTO",
        restrictionDate: new Date().toISOString(),
        appliedOnly: true,
    });
    const [pagination, setPagination] = useState<IPagination>(DefaultPagination());
    const nextPage = useSelector((state: any) => state.restriction.rule.nextPage);
    const [showModal, setShowModal] = useState(false);
    const company = useSelector((state: any) => state.auth.companyName);

    const requestModalProps = useMemo(() => {
        console.log(showCryptoRestrictions, showSecurityRestrictions);
        type RequestTypeOption = "CRYPTO" | "SECURITY";
        if (showCryptoRestrictions && showSecurityRestrictions) {
            return {
                requestType: "SECURITY" as RequestTypeOption,
                dynamicTypeOptions: ["CRYPTO", "SECURITY"] as RequestTypeOption[],
            };
        } else if (showCryptoRestrictions) {
            return {
                requestType: "CRYPTO" as RequestTypeOption,
            };
        } else {
            return {
                requestType: "SECURITY" as RequestTypeOption,
            };
        }
    }, [showCryptoRestrictions, showSecurityRestrictions]);

    const [sorting, setSorting] = useState<ISorting>({
        field: "ticker",
        direction: "asc",
    });
    const sortingByTicker: ISorting[] = useMemo(() => {
        if (sorting.field === "ticker") return [sorting];
        return [sorting, { field: "ticker", direction: "asc" }];
    }, [sorting]);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            fetchRestrictions({ pagination, filter: filter, sorting: sortingByTicker })
        );
        dispatch(fetchRestrictionGroups());
    }, [
        dispatch,
        pagination,
        nextPage,
        filter,
        rules?.length,
        restrictionGroups?.length,
        sortingByTicker,
    ]);

    const securityInfo = {
        ticker: "",
        name: "",
        sector: "",
        exchange: "",
        securityId: "",
        description: "",
    };

    const cryptoInfo = {
        ticker: "",
        name: "",
        cryptoId: "",
    };

    const handleSearch = (e: any) => {
        if (e.key === "Enter") {
            setFilter({ ...filter, name: e.target.value });
            setPagination({ ...pagination, pageNumber: 1 });
        }
    };

    const onClose = () => {
        setShowModal(false);
    };

    return (
        <>
            <TradeReqModal
                show={showModal}
                onClose={onClose}
                securityInfo={securityInfo}
                cryptoInfo={cryptoInfo}
                {...requestModalProps}
            />

            <StyledActionButton
                style={{ marginTop: "1em", marginBottom: "10px" }}
                onClick={(e) => {
                    setShowModal(true);
                }}
            >
                Request a Trade
            </StyledActionButton>

            {!(
                company === "Nydig" ||
                company === "Paradigm" ||
                company === "OneRiver" ||
                company === ""
            ) && (
                <div>
                    <StyledSearchInput
                        placeholder="Search Restrictions"
                        type="text"
                        onKeyDown={handleSearch}
                    />

                    <UserList
                        entities={rules}
                        handleDelete={() => {}}
                        type={Version.CRYPTO}
                        sorting={sorting}
                        setSorting={setSorting}
                    />
                    <TablePageFooter
                        nextPage={nextPage}
                        pagination={pagination}
                        setPageNumber={(n) =>
                            setPagination({ ...pagination, pageNumber: n })
                        }
                        setPageSize={(s) =>
                            setPagination({ ...pagination, pageSize: s })
                        }
                    />
                </div>
            )}
        </>
    );
}

export default UserRule;
