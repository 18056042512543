import { Route } from "react-router-dom";
import SessionLayout from '../layouts/SessionLayout';

/* UserRoute behaves like Route if user is logged in, else, redirects user to Login. */
/* */

function SessionRoute({ children, ...rest }: any) {
    return (
        <Route {...rest} children={<SessionLayout>{children}</SessionLayout>}/>
    );
};

export default SessionRoute;