import { MutableRefObject, useRef } from "react";

const useBlur = (): [any, () => void] => {
    const htmlElRef: MutableRefObject<any> = useRef(null);
    const setBlur = (): void => {
        htmlElRef?.current?.blur?.();
    };

    return [htmlElRef, setBlur];
};

export default useBlur;
