//The jsxRuntime pragma is necessary to get Emotion and React to work w/ latest Create-React-App version
/** @jsxRuntime classic */
/** @jsx jsx */
import styled from "@emotion/styled";

export const StyledActionButtonSecondary = styled.button`
    width: 25%;
    background: #15357a;
    font-family: Lato, sans-serif;
    color: #ffffff;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 5px;
    height: 35px;
    font-weight: bold;
    font-style: normal;
    line-height: 17px;
    font-size: 15px;
    cursor: pointer;
`;
