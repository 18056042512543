import { useCallback, useEffect, useMemo } from "react";
import brokeragesSiteList from "./brokerages";
import useInterval from "../util/useInterval";
import {
    fetchAccounts,
    IAccount,
    saveNewAccount,
    selectAccounts,
} from "./AccountsSlice";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import StaticAutoComplete from "../components/StaticAutoComplete";
import { StyledInputField } from "../components/StyledInputField";
import NotificationList from "../notifications/NotificationList";
import {
    deleteNotification,
    fetchNotifications,
    selectNotifications,
} from "../notifications/NotificationSlice";
import { StyledSectionHeader } from "../components/StyledSectionHeader";
import DeleteModal from "./DeleteModal";
import RequiredField from "../components/RequiredField";
import { StyledActionButtonSecondary } from "../components/StyledActionButtonSecondary";
import TablePageFooter, { DefaultPagination } from "../components/TablePageFooter";
import useFocus from "../util/useFocus";
import { accountsService } from "../services/accountsService";
import AccountListItem from "./AccountListItem";
import FastLink, { IYodleeToken } from "./Fastlink";
import PDFUploadModal from "./PDFUploadModal";
import { compare_priority } from "./account_priority";
import {
    fetchSecondaryUsers,
    selectSecondaryUsers,
} from "../user/UserProfile/UserProfileSlice";
import DesignatedUserSelect from "../components/DesignatedUserSelect";
import { plaidService } from "../services/plaidService";
import { PlaidLinkOnLoad, PlaidLinkOnSuccess } from "react-plaid-link";
import PlaidLink from "./PlaidLink";
import { StyledSpacing } from "../components/StyledSpacing";
import { IPagination } from "../util/pagination";
import SmartList from "../components/SmartList";
import { ISorting } from "../dashboard/DashboardSlice";

function connectableAccount(account: string, brokeragesList: Array<string>) {
    const connectableAccounts = new Map<string, boolean>();

    brokeragesList.forEach((account) => {
        connectableAccounts.set(account, true);
    });

    return connectableAccounts.has(account);
}

function AccountsStock(): JSX.Element {
    /* Local state for inputs, files */
    const designatedUsers = useSelector(selectSecondaryUsers);

    const [activeAccount, setActiveAccount] = useState("");
    const [activeAccountNumber, setActiveAccountNumber] = useState("");
    const [activeAccountCreationDate, setActiveAccountCreationDate] = useState("");
    const [activeAccountHolder, setActiveAccountHolder] = useState("");
    const [description, setDescription] = useState("");
    const [submitAttempted, setSubmitAttempted] = useState(false);
    const company = useSelector((state: any) => state.auth.companyName);
    const version = useSelector((state: any) => state.auth.version);

    const [plaidToken, setPlaidToken] = useState<string>();
    const [accountId, setAccountId] = useState<string>();
    const [sorting, setSorting] = useState<ISorting>({
        field: "account",
        direction: "asc",
    });

    const sortingByDate: ISorting[] = useMemo(() => {
        if (sorting.field === "account") return [sorting];
        return [sorting];
    }, [sorting]);

    /* Selector for UserId (add to watchlist for demo) */
    const userId = useSelector((state: any) => state.auth.userId);

    /* Hooks for selectors, store*/
    const dispatch = useDispatch();
    const accounts = useSelector(selectAccounts);
    const notifications = useSelector(selectNotifications);

    /* Hooks for pagination. */
    const nextPage = useSelector((state: any) => state.accounts.accounts.nextPage);
    const [pagination, setPagination] = useState<IPagination>(DefaultPagination());

    /* Hooks for Modals */
    const [showModal, setShowModal] = useState(false);
    const [deleteAccount, setDeleteAccout] = useState("");
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [uploadAccountId, setUploadAccountId] = useState("");

    /* Hook for auto setting focus. */
    const [inputRef, setInputFocus] = useFocus();
    const nextPageNotifications = useSelector(
        (state: any) => state.notification.nextPage
    );
    const [paginationNotifications, setPaginationNotifications] = useState<IPagination>(
        DefaultPagination()
    );

    const isBrokerageIntegration = version === "brokerage_integration";
    const isBrokeragePlusIntegration = version === "brokerage_integration_plus";

    const [yodleeToken, setYodleeToken] = useState<IYodleeToken | undefined>(undefined);
    const [fastlinkProvider, setFastlinkProvider] = useState<number>();

    useEffect(() => {
        if (userId !== -1) {
            dispatch(
                fetchAccounts({
                    ...pagination,
                    sorting: sortingByDate,
                })
            );
        }
    }, [dispatch, userId, pagination, sortingByDate]);

    useEffect(() => {
        if (yodleeToken === undefined) {
            accountsService.fetchYodleeToken().then((data) => {
                setYodleeToken(data.access_token);
            });
        }
    }, [yodleeToken]);

    useEffect(() => {
        dispatch(fetchNotifications(paginationNotifications));
    }, [dispatch, paginationNotifications]);

    useEffect(() => {
        dispatch(fetchSecondaryUsers());
    }, [dispatch, accounts.length]);

    const refreshAccounts = () => {
        if (userId !== -1) {
            dispatch(
                fetchAccounts({
                    ...pagination,
                    sorting: sortingByDate,
                })
            );
        }
    };

    const handleFastlinkSuccess = useCallback(() => {
        if (accountId !== undefined) {
            accountsService.yodleeConnect(accountId).then(() => {
                refreshAccounts();
                setAccountId(undefined);
            });
        }
    }, [refreshAccounts, accountId]);

    const hadndleFastlinkHide = useCallback(() => {
        setFastlinkProvider(undefined);
        setAccountId(undefined);
    }, []);

    useInterval(() => {
        // if (userId !== -1) {
        //     dispatch(fetchAccounts(pagination));
        // }
        dispatch(fetchSecondaryUsers());
    }, 15000);

    useInterval(() => {
        if (userId !== -1) {
            accountsService.fetchYodleeToken().then((data) => {
                setYodleeToken(data.access_token);
            });
        }
        dispatch(fetchSecondaryUsers());
    }, 28 * 60000);

    useEffect(() => {
        if (!activeAccountHolder) {
            setDefaultActiveAccountHolder();
        }
    }, [activeAccountHolder]);

    useEffect(() => {
        setDefaultActiveAccountHolder();
    }, [designatedUsers, designatedUsers.length]);

    const onSuccess = useCallback<PlaidLinkOnSuccess>(
        (publicToken: string) => {
            if (accountId !== undefined)
                plaidService
                    .exchangePublicToken(publicToken, accountId)
                    .then(refreshAccounts);
            setPlaidToken(undefined);
            setAccountId(undefined);
        },
        [accountId, refreshAccounts]
    );

    const onExit = useCallback<PlaidLinkOnLoad>(() => {
        setPlaidToken(undefined);
        setAccountId(undefined);
    }, []);

    const handleKeyDown = (event: any) => {
        if (event.key === "Enter") {
            if (
                activeAccount !== "" &&
                activeAccountNumber !== "" &&
                activeAccountHolder !== "" &&
                (!isBrokerageIntegration || activeAccountCreationDate !== "")
            ) {
                const dateOfTodayOneYearAgo = new Date();
                dateOfTodayOneYearAgo.setTime(
                    dateOfTodayOneYearAgo.getTime() - 1000 * 60 * 60 * 24 * 365
                );
                dispatch(
                    saveNewAccount({
                        name: activeAccount,
                        accountNumber: activeAccountNumber,
                        accountCreationDate: isBrokerageIntegration
                            ? activeAccountCreationDate
                            : dateOfTodayOneYearAgo.toISOString().slice(0, 10),
                        accountHolderId: activeAccountHolder,
                        description: description,
                    })
                );
                setSubmitAttempted(false);
                setActiveAccount("");
                setActiveAccountNumber("");
                setActiveAccountCreationDate("");
                setActiveAccountHolder("");
                setDescription("");
            } else {
                setSubmitAttempted(true);
            }
        }
    };

    const removeNotification = (id: string) => {
        dispatch(deleteNotification(id));
        return true;
    };

    const setDefaultActiveAccountHolder = () => {
        if (designatedUsers.length > 0) {
            setActiveAccountHolder(designatedUsers[0].id);
        } else {
            setActiveAccountHolder(userId);
        }
    };

    const generateItem = (account: IAccount, index: number) => {
        const isConnectable =
            account.isLinked ||
            connectableAccount(account.investmentAccount.name, brokeragesSiteList);
        const onUpload = () => {
            setShowUploadModal(true);
            setUploadAccountId(account.id);
        };
        const onDelete = () => {
            setDeleteAccout(account.id);
            setShowModal(true);
        };
        return (
            <AccountListItem
                key={index.toString()}
                index={index}
                account={account}
                setFastlinkProvider={setFastlinkProvider}
                setPlaidToken={setPlaidToken}
                setAccountId={setAccountId}
                isConnectable={isConnectable}
                isBrokerageIntegration={isBrokerageIntegration}
                onUpload={onUpload}
                onDelete={onDelete}
            />
        );
    };

    const headerData = [
        { fieldText: "Account", fieldValue: "account" },
        { fieldText: "Last Updated", fieldValue: "lastPull" },
        { fieldText: "Status", fieldValue: "" },
        { fieldText: "Account Number", fieldValue: "" },
        { fieldText: "", fieldValue: "" },
    ];

    return (
        <>
            {isBrokerageIntegration || isBrokeragePlusIntegration ? (
                <>
                    <StyledSectionHeader>Notifications</StyledSectionHeader>

                    <NotificationList
                        notifications={notifications}
                        activeFilter={[]}
                        filterValue={[]}
                        handleDelete={removeNotification}
                    />

                    <TablePageFooter
                        nextPage={nextPageNotifications}
                        pagination={paginationNotifications}
                        setPageNumber={(n) =>
                            setPaginationNotifications({
                                ...paginationNotifications,
                                pageNumber: n,
                            })
                        }
                        setPageSize={(s) =>
                            setPaginationNotifications({
                                ...paginationNotifications,
                                pageSize: s,
                            })
                        }
                    />

                    <br />
                    <br />
                    <br />
                    <br />
                </>
            ) : null}
            <StyledSectionHeader>Add an Investment Account</StyledSectionHeader>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        width: isBrokerageIntegration ? "20%" : "22%",
                    }}
                >
                    <RequiredField
                        value={activeAccount}
                        submitAttempted={submitAttempted}
                    >
                        <StyledSpacing>
                            <StaticAutoComplete
                                style={{ width: "100%" }}
                                choices={
                                    (company === "Paradigm" ||
                                        company === "Nydig" ||
                                        company === "OneRiver") &&
                                        version === "crypto_base"
                                        ? brokeragesSiteList.filter(
                                            (brokerage) =>
                                                brokerage === "Robinhood" ||
                                                brokerage ===
                                                "Wealthsimple (WASI) (Canada)" ||
                                                brokerage === "SoFi"
                                        )
                                        : brokeragesSiteList
                                }
                                placeholder="Add investment account"
                                noMatchSuggestion={
                                    version !== "crypto_base"
                                        ? "Enter your unsupported account for PDF upload."
                                        : ""
                                }
                                noMatchAction={() => {
                                    version !== "crypto_base" && setInputFocus();
                                }}
                                activeValue={activeAccount}
                                handleKeyDown={handleKeyDown}
                                onSelect={(value) => {
                                    setActiveAccount(value);
                                }}
                                showButton={false}
                                prioritize={compare_priority}
                            />
                        </StyledSpacing>
                    </RequiredField>
                </div>

                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        width: isBrokerageIntegration ? "20%" : "22%",
                    }}
                >
                    <RequiredField
                        submitAttempted={submitAttempted}
                        value={activeAccountHolder}
                    >
                        <DesignatedUserSelect
                            style={{ width: "100%" }}
                            accountHolderId={activeAccountHolder}
                            setAccountHolderId={setActiveAccountHolder}
                        />
                    </RequiredField>
                </div>

                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        width: isBrokerageIntegration ? "20%" : "22%",
                    }}
                >
                    <RequiredField
                        submitAttempted={submitAttempted}
                        value={activeAccountNumber}
                    >
                        <StyledInputField
                            ref={inputRef}
                            style={{ width: "100%" }}
                            placeholder="Your account number"
                            value={activeAccountNumber}
                            onChange={(event) => {
                                setActiveAccountNumber(event.target.value);
                            }}
                        />
                    </RequiredField>
                </div>

                {isBrokerageIntegration ? (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "20%",
                        }}
                    >
                        <RequiredField
                            submitAttempted={submitAttempted}
                            value={activeAccountCreationDate}
                        >
                            <StyledInputField
                                style={{ width: "100%" }}
                                type="text"
                                onFocus={(e) => (e.currentTarget.type = "date")}
                                onBlur={(e) => (e.currentTarget.type = "date")}
                                placeholder="Account creation date"
                                value={activeAccountCreationDate}
                                onChange={(event) => {
                                    setActiveAccountCreationDate(event.target.value);
                                }}
                            />
                        </RequiredField>
                    </div>
                ) : null}

                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        width: isBrokerageIntegration ? "20%" : "22%",
                    }}
                >
                    <StyledInputField
                        style={{ width: "100%" }}
                        placeholder="Description (Optional)"
                        value={description}
                        onChange={(event) => {
                            setDescription(event.target.value);
                        }}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        width: isBrokerageIntegration ? "20%" : "33%",
                    }}
                >
                    <StyledActionButtonSecondary
                        style={{ width: "100%", height: "40px" }}
                        onClick={(event) => {
                            const e = { key: "Enter" };
                            handleKeyDown(e);
                        }}
                    >
                        Add Investment Account
                    </StyledActionButtonSecondary>
                </div>
            </div>
            <br />
            <br />
            {/* <table>
                <thead style={{ background: "#E5EAFF" }}>
                    <tr>
                        <th>Account</th>
                        <th>Last Updated</th>
                        <th>Status</th>
                        <th>Account Number</th>
                        {isBrokerageIntegration ? <th>Account Creation Date</th> : null}
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {accounts.map((account: IAccount, index: number) => {
                        const isConnectable =
                            account.isLinked ||
                            connectableAccount(
                                account.investmentAccount.name,
                                brokeragesSiteList
                            );
                        const onUpload = () => {
                            setShowUploadModal(true);
                            setUploadAccountId(account.id);
                        };
                        const onDelete = () => {
                            setDeleteAccout(account.id);
                            setShowModal(true);
                        };
                        return (
                            <AccountListItem
                                key={index.toString()}
                                index={index}
                                account={account}
                                setFastlinkProvider={setFastlinkProvider}
                                setPlaidToken={setPlaidToken}
                                setAccountId={setAccountId}
                                isConnectable={isConnectable}
                                isBrokerageIntegration={isBrokerageIntegration}
                                onUpload={onUpload}
                                onDelete={onDelete}
                            />
                        );
                    })}
                </tbody>
            </table> */}
            <SmartList
                arr={accounts}
                generateItem={generateItem}
                headerData={headerData}
                setSorting={setSorting}
                sorting={sorting}
                disabled={["status", ""]}
            />

            <TablePageFooter
                nextPage={nextPage}
                pagination={pagination}
                setPageNumber={(n) => setPagination({ ...pagination, pageNumber: n })}
                setPageSize={(s) => setPagination({ ...pagination, pageSize: s })}
            />

            <DeleteModal
                show={showModal}
                onClose={() => {
                    setShowModal(false);
                }}
                account={deleteAccount}
            />
            {showUploadModal ? (
                <PDFUploadModal
                    show={showUploadModal}
                    onClose={() => setShowUploadModal(false)}
                    accountId={uploadAccountId}
                    userId={userId}
                />
            ) : null}
            {fastlinkProvider !== undefined && yodleeToken !== undefined && (
                <FastLink
                    providerId={fastlinkProvider}
                    accessToken={yodleeToken}
                    onHide={hadndleFastlinkHide}
                    onSuccess={handleFastlinkSuccess}
                />
            )}
            {plaidToken !== undefined && (
                <PlaidLink token={plaidToken} onExit={onExit} onSuccess={onSuccess} />
            )}
        </>
    );
}

export default AccountsStock;
