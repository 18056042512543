import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StyledActionButton } from "../components/StyledActionButton";
import { StyledSearchInput } from "../components/StyledFilterInput";
import { StyledModalItem } from "../components/StyledModalItem";
import { StyledSectionHeader } from "../components/StyledSectionHeader";
import { StyledSelect } from "../components/StyledSelect";
import { StyledFilterValue } from "../components/StyledFilterValue";
import useInterval from "../util/useInterval";
import HistoricTradesList from "./HistoricTradesList";
import { fetchTransactions, selectTransactions } from "./HistoricTradesSlice";
import redcross from "../assets/redcross.svg";
import { transactionsService } from "../services/transactionsService";
import TablePageFooter, { DefaultPagination } from "../components/TablePageFooter";
import { ISorting } from "../dashboard/DashboardSlice";
import UserAutocomplete, { UserSuggestion } from "../components/UserAutoComplete";
import { TransactionTypes } from "./utils";
import { IPagination } from "../util/pagination";
import { downloadFromUrl } from "../util/downloadFromUrl";
import handleDownload from "../util/downloadCsv";

const SECURITY_CATEGORY = "SECURITY";

function HistoricTrades(): JSX.Element {
    const dispatch = useDispatch();
    const transactions = useSelector(selectTransactions);

    const defaultFilters: { [key: string]: string } = {
        employee: "",
        user: "",
        accountName: "",
        accountHolder: "",
        action: "",
        ticker: "",
        asset: "",
        permissionStatus: "",
        transactionDate: "",
        transactionDateMax: "",
        transactionDateMin: "",
        lastUpdated: "",
        lastUpdatedMax: "",
        lastUpdatedMin: "",
        volume: "",
        volumeMin: "",
        volumeMax: "",
    };

    const company = useSelector((state: any) => state.auth.companyName);

    const [pagination, setPagination] = useState<IPagination>(DefaultPagination());
    const nextPage = useSelector((state: any) => state.historicTrades.nextPage);
    const [filterMultiple, setFilterMultiple] = useState(defaultFilters) as any;
    const [filter, setFilter] = useState("employee");
    const [search, setSearch] = useState("");
    const [startSearch, setStartSearch] = useState("");
    const [endSearch, setEndSearch] = useState("");
    const [rangeBasedFiltering, setRangeBasedFiltering] = useState(false);

    const [sorting, setSorting] = useState<ISorting>({
        field: "transactionDate",
        direction: "desc",
    });
    const sortingByDate: ISorting[] = useMemo(() => {
        if (sorting.field === "transactionDate") return [sorting];
        return [sorting, { field: "transactionDate", direction: "desc" }];
    }, [sorting]);

    const dateTypes = ["transactionDate", "lastUpdated"];

    const version = useSelector((state: any) => state.auth.version);
    const category = useMemo(() => {
        if (version === "crypto_monitoring" || version === "crypto_base") {
            return [
                "CRYPTO_EXCHANGE",
                "CRYPTO_CHAIN",
                "CRYPTO_TOKEN",
                "CRYPTO_MANUAL_UPLOAD",
                "CRYPTO_NFT",
                "FIAT",
            ];
        } else if (version === "crossover_base") {
            return [
                "CRYPTO_EXCHANGE",
                "CRYPTO_CHAIN",
                "CRYPTO_TOKEN",
                "CRYPTO_MANUAL_UPLOAD",
                "CRYPTO_NFT",
                "SECURITY",
                "FIAT",
            ];
        }
        return ["SECURITY", "FIAT"];
    }, [version]);

    const [transactionTypes, setTransactionTypes] = useState(
        Object.keys(TransactionTypes)
    );

    const toggleFilterStakeRewards = useMemo(() => {
        return () => {
            if (transactionTypes.includes("STAKE_REWARD")) {
                setTransactionTypes(
                    Object.keys(TransactionTypes)
                        .filter((transactionType) => transactionType !== "STAKE_REWARD")
                        .filter((transactionType) => transactionType !== "REWARD")
                );
            } else {
                setTransactionTypes(Object.keys(TransactionTypes));
            }
        };
    }, [transactionTypes]);

    useEffect(() => {
        dispatch(
            fetchTransactions({
                pagination,
                filter: {
                    ...filterMultiple,
                    transactionType: transactionTypes,
                    category,
                },
                sorting: sortingByDate,
            })
        );
    }, [
        dispatch,
        pagination,
        filterMultiple,
        sortingByDate,
        category,
        transactionTypes,
    ]);

    useInterval(() => {
        dispatch(
            fetchTransactions({
                pagination,
                filter: {
                    ...filterMultiple,
                    transactionType: transactionTypes,
                    category,
                },
                sorting: sortingByDate,
            })
        );
    }, 25000);

    const handleDelete = (e: string) => {
        /* No delete. */
        return;
    };

    const handleSubmitNote = (note: string, userActionId: string) => {
        if (note !== "") {
            transactionsService.createTransactionNote(note, userActionId).then((r) => {
                if (r.status === 200) {
                    dispatch(
                        fetchTransactions({
                            pagination,
                            filter: {
                                ...filterMultiple,
                                transactionType: transactionTypes,
                                category,
                            },
                            sorting: sortingByDate,
                        })
                    );
                }
            });
        }
    };

    function handleKeyDown(event: any) {
        if (event.key === "Enter") {
            filterMultiple[filter] = search;
            setFilterMultiple({ ...filterMultiple });
            setSearch("");
        }
    }

    function handleOnClick() {
        setFilterMultiple(defaultFilters);
    }

    function removeFilter(txt: any) {
        filterMultiple[txt] = "";
        setFilterMultiple({ ...filterMultiple });
    }

    function handleRangeSearch() {
        if (startSearch !== "" && endSearch !== "") {
            filterMultiple[filter + "Min"] = startSearch;
            filterMultiple[filter + "Max"] = endSearch;
            setFilterMultiple({ ...filterMultiple });
            setStartSearch("");
            setEndSearch("");
        }
    }

    const filterOptions = useMemo(() => {
        return (
            <>
                <option value="employee">Employee</option>
                {version === "crypto_base" ? (
                    <option value="accountHolder">Account Holder</option>
                ) : (
                    <option value="user">User</option>
                )}
                <option value="accountName">Account Name</option>
                <option value="action">Action</option>
                <option value="ticker">Ticker</option>
                <option value="asset">Asset</option>
                <option value="volume">Volume</option>
                {company !== "Public" && version !== "crypto_monitoring" && (
                    <option value="permissionStatus">Permission Status</option>
                )}
                {company === "Public" && (
                    <option value="lastUpdated">Last Updated</option>
                )}
                <option value="transactionDate">Date</option>
            </>
        );
    }, [company, version]);

    const cryptoVersion =
        version === "crypto_integration" ||
        version === "crypto_monitoring" ||
        version === "crossover_base" ||
        version === "crypto_base";

    const selectUser = (user: UserSuggestion | undefined) => {
        if (user !== undefined) {
            filterMultiple["user"] = user.email;
            setFilterMultiple({ ...filterMultiple });
        } else {
            filterMultiple["user"] = defaultFilters.user;
            setFilterMultiple({ ...filterMultiple });
        }
        setSearch("");
    };

    return (
        <div>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <StyledModalItem style={{ display: "flex", width: "50%" }}>
                    <StyledSelect
                        id="filterValue"
                        name="filterValue"
                        onChange={(event) => {
                            setFilter(event.target.value);
                            if (filter !== "volume" && rangeBasedFiltering) {
                                setRangeBasedFiltering(false);
                            }
                        }}
                        style={{ width: "100%" }}
                    >
                        {filterOptions}
                    </StyledSelect>
                </StyledModalItem>

                <div style={{ width: "50%" }}>
                    {!rangeBasedFiltering ? (
                        <>
                            {filter === "user" || filter === "employee" ? (
                                <div style={{ position: "relative" }}>
                                    <UserAutocomplete
                                        value={search}
                                        onSelect={selectUser}
                                        onEnter={selectUser}
                                        style={{ width: "100%" }}
                                        placeholder={"Filter by name or email"}
                                    />
                                </div>
                            ) : (
                                <StyledSearchInput
                                    placeholder="Search Historic Transactions"
                                    type={dateTypes.includes(filter) ? "date" : "text"}
                                    value={search}
                                    onKeyDown={handleKeyDown}
                                    onChange={(event) => setSearch(event.target.value)}
                                    style={{ display: "flex" }}
                                />
                            )}
                            {dateTypes.includes(filter) || filter === "volume" ? (
                                <div
                                    onClick={() => setRangeBasedFiltering(true)}
                                    style={{
                                        textAlign: "right",
                                        fontSize: "12px",
                                        textDecoration: "underline",
                                        color: "#2c71f0",
                                        cursor: "pointer",
                                    }}
                                >
                                    Add range based filter
                                </div>
                            ) : null}
                        </>
                    ) : (
                        <>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <StyledSearchInput
                                    placeholder="Start Filter"
                                    type={dateTypes.includes(filter) ? "date" : "text"}
                                    value={startSearch}
                                    onChange={(event) =>
                                        setStartSearch(event.target.value)
                                    }
                                    style={{ display: "flex", width: "35%" }}
                                />
                                &nbsp;_&nbsp;
                                <StyledSearchInput
                                    placeholder="End Filter"
                                    type={dateTypes.includes(filter) ? "date" : "text"}
                                    value={endSearch}
                                    onChange={(event) =>
                                        setEndSearch(event.target.value)
                                    }
                                    style={{ display: "flex", width: "35%" }}
                                />
                                &nbsp;&nbsp;
                                <StyledActionButton
                                    onClick={handleRangeSearch}
                                    style={{ width: "20%" }}
                                >
                                    Enter
                                </StyledActionButton>
                            </div>
                            {dateTypes.includes(filter) || filter === "volume_max" ? (
                                <div
                                    onClick={() => setRangeBasedFiltering(false)}
                                    style={{
                                        textAlign: "right",
                                        fontSize: "12px",
                                        textDecoration: "underline",
                                        color: "#2c71f0",
                                        cursor: "pointer",
                                    }}
                                >
                                    Cancel range based filtering
                                </div>
                            ) : null}
                        </>
                    )}
                </div>

                <StyledActionButton
                    style={{ width: "20%", marginLeft: "1rem" }}
                    onClick={handleOnClick}
                >
                    Clear Filters
                </StyledActionButton>
            </div>
            <br />

            <div
                style={{ display: "flex", flexDirection: "row", marginBottom: "1rem" }}
            >
                {Object.keys(filterMultiple).map((txt: any, index: any) => (
                    <>
                        {filterMultiple[txt] !== "" ? (
                            <StyledFilterValue
                                key={index}
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    height: "2.5em",
                                }}
                            >
                                <span
                                    style={{
                                        marginLeft: "0.5em",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}
                                >
                                    <span>{`${txt}: `}</span>
                                    <span>{filterMultiple[txt]}</span>
                                </span>
                                <img
                                    style={{ marginRight: "0.5em", cursor: "pointer" }}
                                    src={redcross}
                                    alt=""
                                    onClick={() => removeFilter(txt)}
                                />
                            </StyledFilterValue>
                        ) : null}
                    </>
                ))}
            </div>

            <StyledSectionHeader>Historic Transactions</StyledSectionHeader>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: "1%",
                    marginBottom: "1%",
                }}
            >
                <div
                    style={{
                        textAlign: "left",
                        fontSize: "15px",
                        textDecoration: "none",
                        color: "#15357a",
                        cursor: "pointer",
                    }}
                >
                    <label htmlFor="exclude-rewards">Exclude rewards</label>
                    <input
                        checked={!transactionTypes.includes("STAKE_REWARD")}
                        onChange={toggleFilterStakeRewards}
                        type="checkbox"
                        id="exclude-rewards"
                    />
                </div>

                <div
                    onClick={() =>
                        handleDownload(
                            transactionsService.getHistoricTradesDownload(
                                {
                                    ...filterMultiple,
                                    transactionType: transactionTypes,
                                    category,
                                },
                                sortingByDate,
                                (
                                    [
                                        "employeeEmail",
                                        "employeeName",
                                        "accountHolder",
                                        "accountName",
                                        "description",
                                        "transactionType",
                                        "ticker",
                                        "asset",
                                        "volume",
                                        company === "Public" ||
                                            version === "crypto_integration"
                                            ? "lastUpdated"
                                            : "permissionStatus",
                                        "transactionDate",
                                        cryptoVersion ? "contractAddress" : null,
                                        cryptoVersion ? "walletAddress" : null,
                                        cryptoVersion ? "counterPartyAddress" : null,
                                        cryptoVersion ? "from" : null,
                                        cryptoVersion ? "to" : null,
                                    ].filter((field) => field !== null) as string[]
                                ).filter(
                                    (field) =>
                                        version === "crypto_base" ||
                                        version === "crossover_base" ||
                                        field !== "accountName"
                                ),
                                SECURITY_CATEGORY
                            ),
                            "historic_transactions"
                        )
                    }
                    style={{
                        textAlign: "right",
                        fontSize: "15px",
                        textDecoration: "underline",
                        color: "#2c71f0",
                        cursor: "pointer",
                    }}
                >
                    Download historic transactions
                </div>
            </div>

            <HistoricTradesList
                handleSubmitNote={handleSubmitNote}
                trades={transactions}
                handleDelete={handleDelete}
                setSorting={setSorting}
                sorting={sorting}
            />
            <TablePageFooter
                nextPage={nextPage}
                pagination={pagination}
                setPageNumber={(n) => setPagination({ ...pagination, pageNumber: n })}
                setPageSize={(s) => setPagination({ ...pagination, pageSize: s })}
            />
        </div>
    );
}

export default HistoricTrades;
