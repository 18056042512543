import Navbar from '../nav/Navbar';
import Footer from '../footer/Footer';
import useMainLayoutDimensions from '../util/useMainLayoutDimensions';
import { ToastContainer } from "react-toastify";
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { navData } from '../nav/navData';
import logo from "../assets/argus_logo.png";
import frameworkLogo from "../assets/framework_logo.jpeg";
import fpLogo from "../assets/fp_logo.png";
import psLogo from "../assets/proSapient_logo.png"
import cvLogo from "../assets/cinven_logo.png";
import publicLogo from "../assets/public_logo.svg";
import jnkLogo from "../assets/jnk_logo.png";
import multicoinLogo from "../assets/multicoin_logo.png";
import nydigLogo from "../assets/nydig_logo.png";
import syncreticLogo from "../assets/syncretic_logo_2.png";
import investcorpLogo from "../assets/investcorp_logo.png";
import globeLogo from "../assets/globe_logo.png";
import variantLogo from "../assets/variant_logo.png";
import paradigmLogo from "../assets/paradigm_logo.png";
import celsiusLogo from "../assets/celsius_logo.svg";
import khelpLogo from "../assets/khelp_logo.png";
import dragonflyLogo from "../assets/dragonfly_logo.jpg";
import oneriverLogo from "../assets/oneriver_logo.png";

import { StyledPageTitle } from '../components/StyledPageTitle';

export default function MainLayout({ children }: any) {
    const spacingColumWidth = 200
    let { width } = useMainLayoutDimensions()
    width -= 100

    const [pageName, setPageName] = useState("");
    const [pageIcon, setPageIcon] = useState("");
    const location = useLocation();
    const [companyInfo, setCompanyInfo] = useState({
        logoSrc: logo,
        alt: 'Argus logo'
    })
    const company = useSelector((state: any) => state.auth.companyName);

    useEffect(() => {
        navData.forEach((nav, index) => {
            if (nav.path === location.pathname) {
                setPageName(nav.title);
                setPageIcon(nav.icon);
            }
        });
    }, [location]);

    useEffect(() => {
        switch (company) {
            case 'Multicoin':
                setCompanyInfo({
                    logoSrc: multicoinLogo,
                    alt: "Multicoin"
                })
                break;
            case 'Framework':
                setCompanyInfo({
                    logoSrc: frameworkLogo,
                    alt: 'Framework'
                })
                break;
            case 'Nydig':
                setCompanyInfo({
                    logoSrc: nydigLogo,
                    alt: 'Nydig'
                })
                break;
            case 'FranciscoPartners':
                setCompanyInfo({
                    logoSrc: fpLogo,
                    alt: 'Francisco Partners'
                })
                break;
            case 'proSapient':
                setCompanyInfo({
                    logoSrc: psLogo,
                    alt: 'proSapient'
                })
                break;
            case 'Cinven':
                setCompanyInfo({
                    logoSrc: cvLogo,
                    alt: 'Cinven'
                })
                break;
            case 'Public':
                setCompanyInfo({
                    logoSrc: publicLogo,
                    alt: 'Public'
                })
                break;
            case 'Jnk':
                setCompanyInfo({
                    logoSrc: jnkLogo,
                    alt: 'Jnk'
                })
                break;
            case 'Syncretic':
                setCompanyInfo({
                    logoSrc: syncreticLogo,
                    alt: 'Syncretic'
                })
                break;
            case 'Investcorp':
                setCompanyInfo({
                    logoSrc: investcorpLogo,
                    alt: 'Investcorp'
                })
                break;
            case 'Globe':
                setCompanyInfo({
                    logoSrc: globeLogo,
                    alt: 'Globe'
                })
                break;
            case 'Variant':
                setCompanyInfo({
                    logoSrc: variantLogo,
                    alt: 'Variant'
                })
                break;
            case 'Paradigm':
                setCompanyInfo({
                    logoSrc: paradigmLogo,
                    alt: 'Paradigm'
                })
                break;
            case 'Celsius':
                setCompanyInfo({
                    logoSrc: celsiusLogo,
                    alt: 'Celsius'
                })
                break;
            case 'Khelp':
                setCompanyInfo({
                    logoSrc: khelpLogo,
                    alt: 'Khelp'
                })
                break;
            case 'Dragonfly':
                setCompanyInfo({
                    logoSrc: dragonflyLogo,
                    alt: 'Dragonfly'
                })
                break;
            case 'OneRiver':
                setCompanyInfo({
                    logoSrc: oneriverLogo,
                    alt: 'OneRiver'
                })
                break;
            case 'ArgusTest':
                setCompanyInfo({
                    logoSrc: logo,
                    alt: 'Argus'
                })
                break;
            default:
                setCompanyInfo({
                    logoSrc: logo,
                    alt: 'Argus'
                })
                break;
        };
    }, [company])


    return (
        <>
            <div style={{ overflow: 'auto', display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                <div style={{ display: 'flex', flexWrap: 'nowrap', height: '100%', flex: '1 0 auto' }}>
                    <div style={{ padding: '0', backgroundColor: '#F9F9F9', width: width * 0.2 }}>
                        <div style={{ backgroundColor: '#F9F9F9', padding: '10px 0' }}>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ width: '100px', height: '80px' }}>
                                    <img
                                        src={companyInfo.logoSrc}
                                        alt={companyInfo.alt}
                                        style={{ objectFit: 'contain', width: '100%', height: '100%' }}
                                    />
                                </div>
                            </div>
                        </div>
                        <Navbar />
                    </div>
                    <ToastContainer />
                    <div id="csv_download" />
                    <div style={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
                        <div style={{ padding: '0 50px', width: Math.min(1400, Math.max(1000, width * 0.8)) }}>
                            <div style={{ width: '100%' }}>
                                <StyledPageTitle style={{ padding: '50px 0 30px 0', margin: '0' }}>
                                    <img src={pageIcon} alt={pageName} /> {pageName}
                                </StyledPageTitle>
                            </div>
                            {children}
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};