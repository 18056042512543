import { StyledSelect } from "../components/StyledSelect";
import {
    fetchSecondaryUsers,
    ISecondaryUser,
    selectSecondaryUsers,
} from "../user/UserProfile/UserProfileSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useInterval from "../util/useInterval";

interface IDesignatedUserSelectProps {
    accountHolderId: string,
    setAccountHolderId?(accountHolderId: string): void;
    setAccountHolder?(accountHolderId: string, accountHolder: string): void;
    style?: React.CSSProperties
}

function DesignatedUserSelect({ accountHolderId, setAccountHolderId, setAccountHolder, style }: IDesignatedUserSelectProps): JSX.Element {

    const userId = useSelector((state: any) => state.auth.userId);
    const designatedUsers = useSelector(selectSecondaryUsers);
    const dispatch = useDispatch();
    const customStyle = { ...style }

    useEffect(() => {
        dispatch(fetchSecondaryUsers());
    }, [dispatch, userId])

    useInterval(() => {
        if (userId !== -1) {
            dispatch(fetchSecondaryUsers());
        }
    }, 15000);

    return (
        <StyledSelect
            style={customStyle}
            value={accountHolderId}
            onChange={(event) => {
                console.log(event.target.value);
                if (setAccountHolderId) setAccountHolderId(event.target.value);
                if (setAccountHolder) {
                    const name = event.target[event.target.selectedIndex].getAttribute('data-name') as string
                    setAccountHolder(event.target.value, name);
                }
            }}
        >
            {designatedUsers.map((user: ISecondaryUser) => (
                <option key={user.id} value={user.id} data-name={user.name}>
                    {user.name}
                </option>
            ))}
        </StyledSelect>
    )
}

export default DesignatedUserSelect;