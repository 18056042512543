//The jsxRuntime pragma is necessary to get Emotion and React to work w/ latest Create-React-App version
/** @jsxRuntime classic */
/** @jsx jsx */
import styled from "@emotion/styled";

export const StyledSpanRedBubble = styled.span`
    background: rgba(240, 44, 56, 0.1);
    border-radius: 101px;
    padding: 4px;
    color: #f02c38;
`;
