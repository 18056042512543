//The jsxRuntime pragma is necessary to get Emotion and React to work w/ latest Create-React-App version
/** @jsxRuntime classic */
/** @jsx jsx */
import styled from "@emotion/styled";

export const StyledBulletList = styled.ul`
    font-family: Lato, sans-serif;
    color: #15357a;
    font-weight: bold;
    font-style: normal;
    line-height: 18px;
    font-size: 15px;
    list-style-position: inside;
    padding-left: 0;

    li {
        margin-bottom: 1%;
    }


    a:link {
        color: #2c71f0;
    }
    a:visited {
        color: #2c71f0;
    }
`;
