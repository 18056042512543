export function getDateDiffInDay(startDate: Date, endDate: Date): number {
    const msInDay = 24 * 60 * 60 * 1000;

    startDate = new Date(startDate);
    startDate.setHours(0, 0, 0, 0);
    endDate = new Date(endDate);
    endDate.setHours(0, 0, 0, 0);

    return Math.round(Math.abs(Number(endDate) - Number(startDate)) / msInDay)
}
