import { useMemo, useState } from "react";
import Modal from "../components/Modal";
import { StyledActionButton } from "../components/StyledActionButton";
import { StyledSelect } from "../components/StyledSelect";
import { StyledTextArea } from "../components/StyledTextArea";
import { useDispatch, useSelector } from "react-redux";
import { saveNewReq } from "../dashboard/DashboardSlice";
import { StyledModalItem } from "../components/StyledModalItem";
import { useEffect } from "react";
import { SecuritySuggestion } from "../components/SecurityAutoComplete";
import ModalLabel from "./ModalLabel";
import Attestations from "./Attestations";
import AssetSearch, { ISecurityInfo, ICryptoInfo } from "./AssetSearch";
import { CryptoSuggestion } from "../components/CryptoAutoComplete";
import Attestation from "../components/Attestation";
import QuantityInput from "./QuantityInput/QuantityInput";
import Representations from "./Representations";
import { StyledInputField } from "../components/StyledInputField";
import { StyledSelectField } from "../components/StyledSelectField";
import capitalizeFirstLetter from "../util/capitalizeFirstLetter";
import { StyledSpacing } from "../components/StyledSpacing";
import CryptoAssetModal from "./CryptoAssetModal";
import minDate from "../util/minDate";

interface IProps {
    show: boolean;
    onClose(): void;
    securityInfo: ISecurityInfo;
    cryptoInfo: ICryptoInfo;
    requestType: "SECURITY" | "CRYPTO" | "ALL";
    dynamicTypeOptions?: ("SECURITY" | "CRYPTO")[];
}

const initErrorMessages = {
    searchedTicker: "",
    searchedSecondTicker: "",
    volume: "",
    amount: "",
    secondVolume: "",
    secondAmount: "",
    frequency: "",
    start: "",
};

function TradeReqModal(props: IProps): JSX.Element {
    const { show, onClose, securityInfo, cryptoInfo, dynamicTypeOptions } = props;

    const [requestType, setRequestType] = useState(props.requestType);

    /* For modal */
    const [volume, setVolume] = useState("");
    const [reason, setReason] = useState("");
    const [buySell, setBuySell] = useState("BUY");
    const [orderType, setOrderType] = useState("Market");
    const [signer, setSigner] = useState("");
    const version = useSelector((state: any) => state.auth.version);
    const company = useSelector((state: any) => state.auth.companyName);

    /* For new crypto asset modal */
    const [showNewCryptoModal, setShowNewCryptoModal] = useState(false);

    /* Assets filter */
    const [restrictedAssets, setRestrictedAssets] = useState(false);

    const [errorMessages, setErrorMessages] = useState(initErrorMessages);
    const [showErrors, setShowErrors] = useState(false);
    const [attestationsComplete, setAttestationsComplete] = useState(false);

    const [representationJson, setRepresentationJson] = useState({} as any);

    const [suggestedSecurity, setSuggestedSecurity] = useState<
        SecuritySuggestion | undefined
    >(undefined);
    const [suggestedCrypto, setSuggestedCrypto] = useState<
        CryptoSuggestion | undefined
    >(undefined);

    /* State for Fiat Input */
    const [currency, setCurrency] = useState("USD");
    const [amount, setAmount] = useState("");

    /* State for Second Crypto Input */
    const [showSecondCrypto, setShowSecondCrypto] = useState(false);
    const [suggestedSecondCrypto, setSuggestedSecondCrypto] = useState<
        CryptoSuggestion | undefined
    >(undefined);
    const [secondVolume, setSecondVolume] = useState("");
    const [secondAmount, setSecondAmount] = useState("");

    const [firstInputMethod, setFirstInputMethod] = useState("VOLUME");
    const [secondInputMethod, setSecondInputMethod] = useState("VOLUME");

    const [isStandingOrder, setIsStandingOrder] = useState(false);
    const [frequency, setFrequency] = useState<undefined | string>();
    const [start, setStart] = useState<undefined | string>(minDate(new Date()));
    const [end, setEnd] = useState<undefined | string>();

    /* Other hooks and selectors */
    const dispatch = useDispatch();
    const userId = useSelector((state: any) => state.auth.userId);

    /* Default behavior for framework is search on restricted list */
    useEffect(() => {
        if (company === "Framework") {
            setRestrictedAssets(true);
        }
    }, [company]);

    // On form submit checks if there are any errors, and if true assigns associated message
    const isFormValid = () => {
        // Reset error messages before we validate data.
        let volumeErrorMessage: string = volumeError(volume);
        let amountErrorMessage: string = amountError(amount);
        let secondVolumeErrorMessage: string = volumeError(secondVolume);
        let secondAmountErrorMessage: string = amountError(secondAmount);
        let searchedTickerErrorMessage: string = searchedTickerError(
            (suggestedSecurity && suggestedSecurity.id) ||
            (suggestedCrypto && suggestedCrypto.id)
        );
        let searchedSecondTickerErrorMessage = "";
        if (showSecondCrypto) {
            searchedSecondTickerErrorMessage = searchedTickerError(
                suggestedSecondCrypto && suggestedSecondCrypto.id
            );
        }
        let frequencyErrorMessage = frequencyError(frequency);
        let startErrorMessage = startError(start);

        setErrorMessages({
            volume: volumeErrorMessage,
            amount: amountErrorMessage,
            secondVolume: secondVolumeErrorMessage,
            secondAmount: secondAmountErrorMessage,
            searchedTicker: searchedTickerErrorMessage,
            searchedSecondTicker: searchedSecondTickerErrorMessage,
            frequency: frequencyErrorMessage,
            start: startErrorMessage,
        });
        let valid =
            searchedTickerErrorMessage === "" &&
            (!showSecondCrypto || searchedSecondTickerErrorMessage === "");
        if (firstInputMethod === "VOLUME") {
            valid = valid && volumeErrorMessage === "";
        } else if (firstInputMethod === "AMOUNT") {
            valid = valid && amountErrorMessage === "";
        } else {
            valid = valid && amountErrorMessage === "" && volumeErrorMessage === "";
        }
        if (showSecondCrypto && secondInputMethod === "VOLUME") {
            valid = valid && secondVolumeErrorMessage === "";
        } else if (showSecondCrypto && secondInputMethod === "AMOUNT") {
            valid = valid && secondAmountErrorMessage === "";
        } else if (showSecondCrypto) {
            valid =
                valid &&
                secondAmountErrorMessage === "" &&
                secondVolumeErrorMessage === "";
        }
        if (isStandingOrder) {
            valid = valid && frequencyErrorMessage === "" && startErrorMessage === "";
        }
        return valid;
    };

    const resetState = () => {
        setBuySell("BUY");
        setVolume("");
        setReason("");
        setSuggestedSecurity(undefined);
        setSuggestedCrypto(undefined);
        setCurrency("USD");
        setAmount("");
        setShowSecondCrypto(false);
        setSecondVolume("");
        setSecondAmount("");
        setSuggestedSecondCrypto(undefined);
        setErrorMessages(initErrorMessages);
        setSigner("");
        setIsStandingOrder(false);
        setFrequency(undefined);
        setStart(undefined);
        setEnd(undefined);
    };

    const handleRequestTrade = async (event: any) => {
        console.log(attestationsComplete);
        if (!isFormValid()) {
            setShowErrors(true);
            return;
        }

        event.preventDefault();
        if (requestTypeSelectorValue === "SECURITY" || attestationsComplete) {
            setShowErrors(false);
        } else {
            setShowErrors(true);
            return;
        }

        if (version === "crypto_base" && signer === "") {
            setShowErrors(true);
            return;
        } else {
            setShowErrors(false);
        }

        var payload = {
            volume: volume,
            buySell: buySell,
            orderType: orderType,
            reason: reason,
            securityId: suggestedSecurity
                ? suggestedSecurity.id
                : securityInfo.securityId,
            cryptoId:
                suggestedCrypto && suggestedCrypto.type == "CRYPTO"
                    ? suggestedCrypto.id
                    : cryptoInfo.cryptoId,
            privateAssetId:
                suggestedCrypto && suggestedCrypto.type == "PRIVATE_ASSET"
                    ? suggestedCrypto.id
                    : "",
            userId: userId,
            amount: amount,
            currency: currency,
            secondaryCryptoId: suggestedSecondCrypto ? suggestedSecondCrypto.id : "",
            secondaryCryptoVolume: secondVolume,
            secondaryCryptoAmount: secondAmount,
            representativeJson: representationJson,
            signer: signer,
            isStandingOrder: isStandingOrder,
            frequency: frequency,
            start: start,
            end: end,
        };

        dispatch(saveNewReq(payload));
        resetState();
        onClose();
    };

    const _onClose = () => {
        resetState();
        onClose();
    };

    const securityAttestationsText = [
        "I certify that I do not have any material nonpublic information about this security.",
        "I certify that I am not a member of, or related to a member of, the Board of Directors or a senior executive at this security.",
        "I certify that I have not had any communications with this security's team in the last 6 months.",
    ];

    const cryptoAttestationsText = [
        "Are you in possession of any material nonpublic information relating to the security or digital asset you propose to purchase, sell or trade, or the issuer thereof (including its affiliates and subsidiaries)? \n \t a. For the purposes of this question, “material nonpublic information” means information regarding the security or other asset that is the subject of your proposed trade, or the issuer thereof (including its affiliates and subsidiaries), which is not generally available to the public and which (i) a reasonable investor would likely consider important in deciding whether to buy, sell or trade such asset or (ii) if made public, would reasonably be expected to impact the price of the asset. [Check if no]",
        "Do you have any other knowledge relating to the security or digital asset you propose to sell, purchase or trade, or the issuer thereof (including its affiliates and subsidiaries), which, if used as a basis for your trade, would, to the best of your knowledge, violate Firm policies or applicable law? [Check if no]",
        "Acknowledge: Your approval for the requested transaction will be valid for the period indicated in the approval notice; provided, however, that you may not rely on such approval if the facts or circumstances have changed such that the above representations are no longer true or accuraterepresent and warrant that your responses to the questions above are true, accurate and complete in all respects; and affirm that, to the best of your knowledge, you are not legally prohibited from executing the proposed transaction for which you seek preclearance.",
    ];

    const attestationsText = securityAttestationsText;

    const startError = (value: string | undefined) => {
        if (isStandingOrder && value === undefined)
            return "Start date needs to be provided.";
        return "";
    };

    const frequencyError = (value: string | undefined) => {
        if (isStandingOrder && value === undefined)
            return "Frequency needs to be provided.";
        return "";
    };

    const volumeError = (value: string) => {
        let msg = "";
        let numberValue = Number(value);
        if (isNaN(numberValue) || numberValue <= 0) {
            msg = "Volume must be 0 or greater";
        }
        return msg;
    };

    const amountError = (value: string) => {
        let msg = "";
        let numberValue = Number(value);
        if (isNaN(numberValue) || numberValue <= 0) {
            msg = "Value must be 0 or greater";
        }
        return msg;
    };
    const searchedTickerError = (suggestedSelect: string | undefined) => {
        let msg = "";
        if (!suggestedSelect) {
            msg = "Please select an asset";
        }
        return msg;
    };

    const handleSuggestedSelect = (suggestedSelect: string | undefined) => {
        let msg = searchedTickerError(suggestedSelect);
        setErrorMessages((prevErrorMessages) => {
            return {
                ...prevErrorMessages,
                searchedTicker: msg,
            };
        });
    };

    const handleSuggestedSecondSelect = (suggestedSelect: string | undefined) => {
        let msg = searchedTickerError(suggestedSelect);
        setErrorMessages((prevErrorMessages) => {
            return {
                ...prevErrorMessages,
                searchedSecondTicker: msg,
            };
        });
    };

    // On every input determines error message
    const handleInputChange = (event: any, order: string = "") => {
        let name = event.target.name;
        let msg: string;
        let value;

        switch (name) {
            case "volume":
                msg = volumeError(event.target.value);
                break;
            case "amount":
                value = event.target.value;
                msg = amountError(value);
                break;
        }
        if (order) {
            name = order + name.charAt(0).toUpperCase() + name.slice(1);
        }
        setErrorMessages((prevErrorMessages) => {
            return {
                ...prevErrorMessages,
                [name]: msg,
            };
        });
    };

    const handleRestrictedAssetsChange = () => {
        if (restrictedAssets) {
            setRestrictedAssets(false);
        } else {
            setRestrictedAssets(true);
        }
    };

    const [requestTypeSelectorValue, setRequestTypeSelectorValue] = useState<
        "CRYPTO" | "SECURITY"
    >(requestType as "CRYPTO" | "SECURITY");

    useEffect(() => {
        console.log(requestType);

        if (!dynamicTypeOptions) return;

        setRequestType(requestTypeSelectorValue);
    }, [requestTypeSelectorValue, dynamicTypeOptions]);

    useEffect(() => {
        setRequestType(props.requestType);
    }, [props.requestType]);

    const noMatchSuggestion = useMemo(() => {
        const suggestions = [];
        if (requestType === "CRYPTO" || requestType === "ALL") {
            suggestions.push("Create new crypto asset");
        }

        return suggestions.length > 0 ? suggestions : undefined;
    }, [company, requestType]);

    const noMatchAction = (suggestion: string) => {
        if (
            (requestType === "CRYPTO" || requestType === "ALL") &&
            suggestion === "Create new crypto asset"
        ) {
            setShowNewCryptoModal(true);
        }
    };

    return (
        <>
            <Modal show={show} onClose={_onClose} title={"Request to Trade"}>
                <div
                    // onSubmit={e => { e.preventDefault(); }} // prevents form from being submitted upon "Enter" press
                    style={{
                        width: "100%",
                        height: "88%",
                        display: "flex",
                        flexWrap: "wrap",
                        flexDirection: "row",
                    }}
                >
                    {dynamicTypeOptions && (
                        <>
                            <StyledModalItem>
                                <ModalLabel>Class:</ModalLabel>
                                <StyledSelectField
                                    value={requestTypeSelectorValue}
                                    onChange={(event) => {
                                        const requestType = event.target.value as
                                            | "CRYPTO"
                                            | "SECURITY";
                                        setRequestTypeSelectorValue(requestType);
                                    }}
                                >
                                    {dynamicTypeOptions.map((v) => (
                                        <option key={v} value={v}>
                                            {capitalizeFirstLetter(v)}
                                        </option>
                                    ))}
                                </StyledSelectField>
                            </StyledModalItem>
                            <StyledSpacing />
                        </>
                    )}
                    {(company === "Framework" || company === "ArgusTest") && (
                        <StyledModalItem>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <ModalLabel>Asset is: </ModalLabel>
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        marginBottom: "1%",
                                    }}
                                >
                                    <input
                                        checked={restrictedAssets}
                                        onChange={(event) =>
                                            handleRestrictedAssetsChange()
                                        }
                                        type="checkbox"
                                        id="restricted-assets"
                                    />
                                    <label
                                        htmlFor="restricted-assets"
                                        style={{
                                            fontSize: "11px",
                                            lineHeight: "15px",
                                            display: "block",
                                        }}
                                    >
                                        on the restricted list
                                    </label>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        marginBottom: "3%",
                                    }}
                                >
                                    <input
                                        checked={!restrictedAssets}
                                        onChange={(event) =>
                                            handleRestrictedAssetsChange()
                                        }
                                        type="checkbox"
                                        id="other-assets"
                                    />
                                    <label
                                        htmlFor="other-assets"
                                        style={{
                                            fontSize: "11px",
                                            lineHeight: "15px",
                                            display: "block",
                                        }}
                                    >
                                        &gt; $500k, a primary or non-public offering, or
                                        from an issuer/team I've spoken to
                                    </label>
                                </div>
                            </div>
                        </StyledModalItem>
                    )}

                    {errorMessages.searchedTicker.length > 0 && (
                        <StyledModalItem>
                            <p style={{ margin: "0", color: "red", width: "50%" }}>
                                {errorMessages.searchedTicker}
                            </p>
                        </StyledModalItem>
                    )}
                    <AssetSearch
                        filterRestricted={restrictedAssets}
                        requestType={requestType}
                        securityInfo={{
                            ticker: "",
                            name: "",
                            description: "",
                            securityId: "",
                            exchange: "",
                            sector: "",
                        }}
                        cryptoInfo={{ ticker: "", name: "", cryptoId: "" }}
                        suggestedSecurity={suggestedSecurity}
                        setSuggestedSecurity={setSuggestedSecurity}
                        suggestedCrypto={suggestedCrypto}
                        setSuggestedCrypto={(crypto: CryptoSuggestion | undefined) => {
                            console.log(crypto)
                            setSuggestedCrypto(crypto)
                        }}
                        onSelect={handleSuggestedSelect}
                        noMatchSuggestions={noMatchSuggestion}
                        noMatchAction={noMatchAction}
                    />

                    <StyledModalItem>
                        <ModalLabel>Select Action:</ModalLabel>
                        <StyledSelect
                            id="buySell"
                            name="buySell"
                            onChange={(event) => {
                                setBuySell(event.target.value);
                            }}
                            style={{ marginBottom: "10px" }}
                        >
                            <option value="BUY">
                                Buy{" "}
                                {showSecondCrypto
                                    ? " " +
                                    (suggestedCrypto ? suggestedCrypto.ticker : "")
                                    : ""}
                            </option>
                            <option value="SELL">
                                Sell{" "}
                                {showSecondCrypto
                                    ? " " +
                                    (suggestedCrypto ? suggestedCrypto.ticker : "")
                                    : ""}
                            </option>
                            <option value="BUY_OPTION">
                                Call{" "}
                                {showSecondCrypto
                                    ? " " +
                                    (suggestedCrypto ? suggestedCrypto.ticker : "")
                                    : ""}
                            </option>
                            <option value="SELL_OPTION">
                                Put{" "}
                                {showSecondCrypto
                                    ? " " +
                                    (suggestedCrypto ? suggestedCrypto.ticker : "")
                                    : ""}
                            </option>

                            {requestType === "CRYPTO" || requestType === "ALL" ? (
                                <>
                                    <option value="STAKE">
                                        Stake{" "}
                                        {showSecondCrypto
                                            ? " " +
                                            (suggestedCrypto
                                                ? suggestedCrypto.ticker
                                                : "")
                                            : ""}
                                    </option>
                                    <option value="UNSTAKE">
                                        Unstake{" "}
                                        {showSecondCrypto
                                            ? " " +
                                            (suggestedCrypto
                                                ? suggestedCrypto.ticker
                                                : "")
                                            : ""}
                                    </option>
                                    <option value="SUPPLY_LIQUIDITY">
                                        Supply Liquidity{" "}
                                        {showSecondCrypto
                                            ? " " +
                                            (suggestedCrypto
                                                ? suggestedCrypto.ticker
                                                : "")
                                            : ""}
                                    </option>
                                    <option value="REMOVE_LIQUIDITY">
                                        Remove Liquidity{" "}
                                        {showSecondCrypto
                                            ? " " +
                                            (suggestedCrypto
                                                ? suggestedCrypto.ticker
                                                : "")
                                            : ""}
                                    </option>
                                    <option value="WITHDRAW_GIFT">
                                        Send Gift{" "}
                                        {showSecondCrypto
                                            ? " " +
                                            (suggestedCrypto
                                                ? suggestedCrypto.ticker
                                                : "")
                                            : ""}
                                    </option>
                                    <option value="DEPOSIT_GIFT">
                                        Receive Gift{" "}
                                        {showSecondCrypto
                                            ? " " +
                                            (suggestedCrypto
                                                ? suggestedCrypto.ticker
                                                : "")
                                            : ""}
                                    </option>
                                    <option value="DEPOSIT_AIRDROP">
                                        Claim Airdrop{" "}
                                        {showSecondCrypto
                                            ? " " +
                                            (suggestedCrypto
                                                ? suggestedCrypto.ticker
                                                : "")
                                            : ""}
                                    </option>
                                </>
                            ) : null}
                        </StyledSelect>
                    </StyledModalItem>

                    <StyledModalItem>
                        <ModalLabel>Order Type:</ModalLabel>

                        <StyledSelect
                            id="orderType"
                            name="orderType"
                            onChange={(event) => {
                                setOrderType(event.target.value);
                            }}
                            style={{ marginBottom: "10px" }}
                        >
                            <option value="Market">Market</option>
                            <option value="Limit">Limit</option>
                            <option value="Stop Market">Stop Market</option>
                            <option value="Stop Limit">Stop Limit</option>
                        </StyledSelect>
                    </StyledModalItem>

                    {/* <StyledModalItem>
                    <span style={{ width: "50%", lineHeight: "18px" }}><i>Ask for more than you will trade so you won’t buy more than is approved</i></span>
                </StyledModalItem> */}

                    {suggestedCrypto !== undefined ? (
                        <QuantityInput
                            version={requestType}
                            showMissing={true}
                            setComplete={(complete: boolean) => { }}
                            setParentInputMethod={setFirstInputMethod}
                            volume={volume}
                            setVolume={setVolume}
                            amount={amount}
                            setAmount={setAmount}
                            currency={currency}
                            setCurrency={setCurrency}
                            pricingProvider={suggestedCrypto.hasPrice}
                            onChange={(event) => {
                                handleInputChange(event);
                            }}
                            volumeError={errorMessages.volume}
                            amountError={errorMessages.amount}
                        />
                    ) : (
                        <QuantityInput
                            version={requestType}
                            showMissing={true}
                            setComplete={(complete: boolean) => { }}
                            setParentInputMethod={setFirstInputMethod}
                            volume={volume}
                            setVolume={setVolume}
                            amount={amount}
                            setAmount={setAmount}
                            currency={currency}
                            setCurrency={setCurrency}
                            onChange={(event) => {
                                handleInputChange(event);
                            }}
                            volumeError={errorMessages.volume}
                            amountError={errorMessages.amount}
                        />
                    )}
                    {requestType === "CRYPTO" && (
                        <StyledModalItem>
                            <Attestation
                                style={{
                                    attestation: { marginTop: "8px" },
                                    label: { fontSize: "13px", fontWeight: "bold" },
                                }}
                                isChecked={showSecondCrypto}
                                name=""
                                onCheck={(event) => {
                                    setShowSecondCrypto(!showSecondCrypto);
                                    setSecondVolume("");
                                    setSecondAmount("");
                                    setSuggestedSecondCrypto(undefined);
                                }}
                            >
                                I am exchanging for/from another crypto asset.
                            </Attestation>
                        </StyledModalItem>
                    )}

                    {showSecondCrypto && (
                        <>
                            <StyledModalItem>
                                <ModalLabel style={{ width: "50%" }}>
                                    Second Crypto Asset:
                                </ModalLabel>
                            </StyledModalItem>
                            <StyledModalItem>
                                {errorMessages.searchedSecondTicker.length > 0 && (
                                    <p
                                        style={{
                                            margin: "0",
                                            color: "red",
                                            width: "50%",
                                        }}
                                    >
                                        {errorMessages.searchedSecondTicker}
                                    </p>
                                )}
                            </StyledModalItem>
                            <StyledModalItem>
                                <AssetSearch
                                    requestType={requestType}
                                    securityInfo={{
                                        ticker: "",
                                        name: "",
                                        description: "",
                                        securityId: "",
                                        exchange: "",
                                        sector: "",
                                    }}
                                    cryptoInfo={{ ticker: "", name: "", cryptoId: "" }}
                                    suggestedSecurity={suggestedSecurity}
                                    setSuggestedSecurity={setSuggestedSecurity}
                                    suggestedCrypto={suggestedSecondCrypto}
                                    setSuggestedCrypto={setSuggestedSecondCrypto}
                                    onSelect={handleSuggestedSecondSelect}
                                />
                            </StyledModalItem>

                            {suggestedSecondCrypto ? (
                                <QuantityInput
                                    version={requestType}
                                    showMissing={true}
                                    setComplete={(complete: boolean) => { }}
                                    setParentInputMethod={setSecondInputMethod}
                                    volume={secondVolume}
                                    setVolume={setSecondVolume}
                                    amount={secondAmount}
                                    setAmount={setSecondAmount}
                                    currency={currency}
                                    setCurrency={setCurrency}
                                    pricingProvider={suggestedSecondCrypto.hasPrice}
                                    onChange={(event) => {
                                        handleInputChange(event, "second");
                                    }}
                                    volumeError={errorMessages.secondVolume}
                                    amountError={errorMessages.secondAmount}
                                />
                            ) : (
                                <QuantityInput
                                    version={requestType}
                                    showMissing={true}
                                    setComplete={(complete: boolean) => { }}
                                    setParentInputMethod={setSecondInputMethod}
                                    volume={secondVolume}
                                    setVolume={setSecondVolume}
                                    amount={secondAmount}
                                    setAmount={setSecondAmount}
                                    currency={currency}
                                    setCurrency={setCurrency}
                                    onChange={(event) => {
                                        handleInputChange(event, "second");
                                    }}
                                    volumeError={errorMessages.secondVolume}
                                    amountError={errorMessages.secondAmount}
                                />
                            )}
                        </>
                    )}
                    {requestType === "CRYPTO" &&
                        (company === "Fargus" ||
                            company === "Argus" ||
                            company === "ArgusTest" ||
                            company === "Paradigm") && (
                            <StyledModalItem>
                                <ModalLabel>Timing:</ModalLabel>

                                <StyledSelect
                                    id="orderType"
                                    name="orderType"
                                    onChange={(event) => {
                                        setIsStandingOrder(
                                            event.target.value === "standing_order"
                                        );
                                    }}
                                    style={{ marginBottom: "10px" }}
                                >
                                    <option value={"immediate"}>Immediate</option>
                                    <option value={"standing_order"}>
                                        Standing Order
                                    </option>
                                </StyledSelect>
                            </StyledModalItem>
                        )}
                    {isStandingOrder && (
                        <StyledModalItem>
                            <ModalLabel>Frequency:</ModalLabel>

                            <StyledSelect
                                id="frequency"
                                name="frequency"
                                onChange={(event) => {
                                    setFrequency(event.target.value);
                                }}
                                style={{ marginBottom: "10px" }}
                                value={frequency}
                            >
                                <option value="" disabled selected>
                                    Select your frequency
                                </option>
                                <option value="DAILY">Daily</option>
                                {/* <option value="BI_WEEKLY">Bi Weekly</option> */}
                                <option value="WEEKLY">Weekly</option>
                                <option value="MONTHLY">Monthly</option>
                                {/* <option value="QUATERLY">Quaterly</option> */}
                            </StyledSelect>
                            {errorMessages && errorMessages.frequency && (
                                <p style={{ margin: "0 0 10px 0", color: "red" }}>
                                    {errorMessages.frequency}
                                </p>
                            )}
                        </StyledModalItem>
                    )}
                    {isStandingOrder && (
                        <>
                            <StyledModalItem>
                                <ModalLabel style={{ width: "50%" }}>
                                    Start Date
                                </ModalLabel>
                            </StyledModalItem>
                            <StyledModalItem>
                                <StyledInputField
                                    onFocus={(e) => {
                                        if (start === undefined) {
                                            setStart("");
                                        }
                                    }}
                                    onBlur={(e) => {
                                        if (start === "") {
                                            setStart(undefined);
                                        }
                                    }}
                                    type={start === undefined ? "text" : "date"}
                                    placeholder="Start Date"
                                    value={start || ""}
                                    onChange={(event) => setStart(event.target.value)}
                                    min={minDate(new Date())}
                                />
                                {errorMessages && errorMessages.start && (
                                    <p style={{ margin: "0 0 10px 0", color: "red" }}>
                                        {errorMessages.start}
                                    </p>
                                )}
                            </StyledModalItem>
                        </>
                    )}
                    {isStandingOrder && (
                        <>
                            <StyledModalItem>
                                <ModalLabel style={{ width: "50%" }}>
                                    End Date (optional)
                                </ModalLabel>
                            </StyledModalItem>
                            <StyledModalItem>
                                <StyledInputField
                                    onFocus={(e) => {
                                        if (end === undefined) {
                                            setEnd("");
                                        }
                                    }}
                                    onBlur={(e) => {
                                        if (end === "") {
                                            setEnd(undefined);
                                        }
                                    }}
                                    type={end === undefined ? "text" : "date"}
                                    placeholder="Ongoing"
                                    value={end || ""}
                                    onChange={(event) => setEnd(event.target.value)}
                                />
                            </StyledModalItem>
                        </>
                    )}

                    <StyledModalItem>
                        <ModalLabel>Notes (Optional):</ModalLabel>
                        <StyledTextArea
                            style={{ height: "50px", marginBottom: "10px" }}
                            placeholder="Please enter reason for trade..."
                            value={reason}
                            name="reason"
                            onChange={(event) => {
                                handleInputChange(event);
                                setReason(event.target.value);
                            }}
                        />
                    </StyledModalItem>

                    <StyledModalItem>
                        {requestType === "SECURITY" &&
                            !(
                                company === "Fargus" ||
                                company === "Public" ||
                                company === "proSapient" ||
                                company === "Syncretic"
                            ) && (
                                <Attestations
                                    attestations={attestationsText}
                                    showErrors={showErrors}
                                    setAllAttestationsComplete={setAttestationsComplete}
                                />
                            )}

                        {((requestType === "CRYPTO" && company !== "Syncretic") ||
                            company === "Fargus" ||
                            company === "Public" ||
                            company === "proSapient") && (
                                <Representations
                                    setRepresentationJson={setRepresentationJson}
                                    showErrors={showErrors}
                                    setComplete={setAttestationsComplete}
                                />
                            )}

                        {company === "Syncretic" && (
                            <Attestations
                                attestations={cryptoAttestationsText}
                                showErrors={showErrors}
                                setAllAttestationsComplete={(complete: boolean) => {
                                    console.log(attestationsComplete);
                                    console.log(complete);
                                    setAttestationsComplete(complete);
                                }}
                            />
                        )}
                    </StyledModalItem>
                    {version === "crypto_base" && (
                        <StyledModalItem>
                            <ModalLabel>Sign</ModalLabel>
                            <StyledInputField
                                placeholder="Please sign with your name"
                                value={signer}
                                onChange={(event) => {
                                    setSigner(event.target.value);
                                    // setShowErrors(false)
                                }}
                            />
                            {showErrors && signer === "" && (
                                <p
                                    style={{
                                        margin: "0",
                                        color: "red",
                                    }}
                                >
                                    Please sign with your name.
                                </p>
                            )}
                        </StyledModalItem>
                    )}

                    <StyledModalItem>
                        <StyledActionButton
                            onClick={(event) => handleRequestTrade(event)}
                            style={{ margin: "10px 0 40px" }}
                        >
                            Send Request{" "}
                        </StyledActionButton>
                    </StyledModalItem>
                </div>
            </Modal>

            {(requestType === "CRYPTO" || requestType === "ALL") && (
                <CryptoAssetModal
                    show={showNewCryptoModal}
                    onClose={() => setShowNewCryptoModal(false)}
                />
            )}
        </>
    );
}

export default TradeReqModal;
