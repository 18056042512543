import { useState } from "react";
import Modal from "./Modal";
import { StyledModalItem } from "./StyledModalItem";
import { StyledActionButton } from "./StyledActionButton";
import { StyledTextArea } from "./StyledTextArea";
import pen from "../assets/pen.svg";
import { StyledInputField } from "./StyledInputField";

function EditNameModal({ style, handleSubmit, id, ...props }: any) {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [name, setName] = useState<string>(props.name);

    return (
        <>
            <div
                style={{
                    ...style,
                    textAlign: "center",
                    fontSize: "15px",
                    textDecoration: "underline",
                    color: "#2c71f0",
                    cursor: "pointer",
                }}
                onClick={() => {
                    setShowModal(true);
                }}
            >
                <img src={pen} height={20} width={20} alt="" />
            </div>
            <Modal
                width="40%"
                height="50%"
                marginTop="10%"
                show={showModal}
                onClose={() => {
                    setShowModal(false);
                    setName(props.name);
                }}
                title={"Edit Name"}
            >
                <StyledModalItem>
                    <StyledInputField
                        style={{ width: "fit-content", marginBottom: "10px" }}
                        placeholder="Please enter your name here..."
                        value={name}
                        name="reason"
                        onChange={(event) => {
                            setName(event.target.value);
                        }}
                    />
                </StyledModalItem>
                <StyledModalItem>
                    <StyledActionButton
                        onClick={(event) => {
                            handleSubmit(id, name);
                            setName(name);
                            setShowModal(false);
                        }}
                        style={{ margin: "10px 0 40px" }}
                    >
                        Edit name
                    </StyledActionButton>
                </StyledModalItem>
            </Modal>
        </>
    );
}

export default EditNameModal;
