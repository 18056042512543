import { StyledPageTitle } from "../components/StyledPageTitle";
import warning from "../assets/warning.svg";

function ErrorPage(): JSX.Element {
    return (
        <StyledPageTitle style={{ fontSize: "18px" }}>
            <img
                src={warning}
                alt=""
                height="35px"
                style={{ verticalAlign: "middle", marginBottom: "10px" }}
            />{" "}
            &nbsp; Page not found.
        </StyledPageTitle>
    );
}

export default ErrorPage;
