import { ISorting, SortingField } from "../dashboard/DashboardSlice";
import SelectableTableHeader from "./SelectableTableHeader";
import { StyledTableContainer } from "./StyledTable";

interface IHeaderData {
    fieldText: string;
    fieldValue: string;
    versions?: Array<string>;
}

/* Type for the SmartList props */
interface IListProps<T> {
    version?: string,
    arr: Array<T>;
    headerData: Array<IHeaderData>;
    generateItem(el: T, index: number): JSX.Element;
    bumper?: string;
    setSorting: (sorting: ISorting) => void
    sorting: ISorting,
    disabled?: string[]
}

function SmartList<T>(props: IListProps<T>): JSX.Element {
    /* Provide default values and replace them with the prop values if there are any. */
    const {
        version,
        arr,
        headerData,
        generateItem,
        bumper,
        sorting,
        setSorting,
        disabled
    } = props;

    //Use the initial values if provided, otherwise default to true and the first header column

    return (
        <StyledTableContainer>
            <table>
                <thead>
                    <tr>
                        {headerData && (headerData.map((header, index) => {
                            if (!header.versions || (version && header.versions.includes(version))) {
                                return (
                                    <SelectableTableHeader
                                        key={index}
                                        targetCompare={header.fieldValue}
                                        activeCompare={sorting.field}
                                        setActiveCompare={(field: SortingField) => setSorting({ ...sorting, field })}
                                        asc={sorting.direction === 'asc'}
                                        setAsc={(asc: boolean) => setSorting({ ...sorting, direction: asc ? 'asc' : 'desc' })}
                                        disabled={disabled}
                                    >
                                        {header.fieldText}
                                    </SelectableTableHeader>
                                );

                            }
                        }))}
                        {/* For trailing columns [optionally labeled] */}
                        {bumper !== undefined ? <th>{bumper}</th> : null}
                    </tr>
                </thead>
                <tbody>
                    {/* Generate the List Items and add them to the ul */}
                    {/* 
                        1. Filter by the activeFilter, show everything if empty, matching names and tickers if not.
                        2. Slice to show only show pageSize number of results (defaults to 100)
                        3. Map the entities array to AdminListItem components
                        */}
                    {arr
                        ? arr
                            .map(generateItem)
                        : null}
                </tbody>
            </table>
        </StyledTableContainer>
    );
}
export default SmartList;
