import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StyledActionButton } from "../components/StyledActionButton";
import { StyledInputField } from "../components/StyledInputField";
import { StyledSelectField } from "../components/StyledSelectField";
import {
    createRestriction,
    fetchDefaultRestrictionGroup,
    fetchRestrictionGroups,
    selectDefaultestrictionGroup,
    selectRestrictionGroups,
} from "./RestrictionSlice";
import { StyledSpacing } from "../components/StyledSpacing";
import { StyledSectionHeader } from "../components/StyledSectionHeader";
import { StyledLabel } from "../components/StyledLabel";
import CryptoAutoComplete from "../components/CryptoAutoComplete";
import { SecurityCompanyAutoComplete, SecurityCompanySuggestion } from "../components/SecurityAutoComplete";
import useBlur from "../util/useBlur";
import { StyledModalItem } from "../components/StyledModalItem";
import ModalLabel from "../watchlist/ModalLabel";
import { infoService } from "../services/infoService";
import CryptoPrivateAssetAutoComplete, {
    CryptoSuggestion,
} from "../components/CryptoPrivatAssetAutoComplete";
import { Version } from "./AdminList";
import { selectVersion } from "../auth/AuthSlice";
import CryptoAssetModal from "../watchlist/CryptoAssetModal";
import Modal from "../components/Modal";
import { IRestrictionGroup } from "../services/restrictionService";
import MultiSelect from "../components/MultiSelect";

const TransactionTypes = {
    SecurityCompany: [
        { type: "ALL", display: "All" },
        { type: "BUY", display: "Buy" },
        { type: "SELL", display: "Sell" },
    ],
    Crypto: [
        { type: "ALL", display: "All" },
        { type: "BUY", display: "Buy" },
        { type: "SELL", display: "Sell" },
        { type: "BUY_OPTION", display: "Call" },
        { type: "SELL_OPTION", display: "Put" },
    ],
};

function RestrictionInput(): JSX.Element {
    const [showPrivatAssetModal, setShowPrivatAssetModal] = useState(false);
    const [showNewCryptoModal, setShowNewCryptoModal] = useState(false);

    const defaultGroup = useSelector(selectDefaultestrictionGroup);
    const restrictionGroups = useSelector(selectRestrictionGroups);
    const version = useSelector(selectVersion);
    const company = useSelector((state: any) => state.auth.companyName);
    const [showRestrictionTypes, setShowRestrictionTypes] = useState(true);
    const [showVisibilityType, setShowVisibilityType] = useState(false);

    const showStockRestrictions = useMemo(() => version !== "crypto_base", [version]);
    const showCryptoRestrictions = useMemo(
        () => version === "crypto_base" || version === "crossover_base",
        [version]
    );
    const showClassSelector = useMemo(() => version === "crossover_base", [version]);

    /* Local state from form */
    const [activeSearch, setActiveSearch] = useState("");
    const [tokenTicker, setTokenTicker] = useState("");
    const [selectedCrypto, setSelectedCrypto] = useState<CryptoSuggestion | undefined>(
        undefined
    );
    const [selectedStock, setSelectedStock] = useState<SecurityCompanySuggestion | undefined>(
        undefined
    );

    const [inputRef, setInputBlur] = useBlur();

    const [activeRestrictedFrom, setActiveRestrictedFrom] = useState<
        string | undefined
    >(new Date().toISOString().split("T")[0]);
    const [activeRestrictedTo, setActiveRestrictedTo] = useState<string>();

    const [activeRestrictionType, setActiveRestrictionType] =
        useState("Restriction Type");
    const [activeVisibilityType, setActiveVisibilityType] = useState("Visibility Type");
    const [activeRestrictionGroups, setActiveRestrictionGroups] = useState<
        IRestrictionGroup[]
    >(defaultGroup ? [defaultGroup] : []);
    const [activeTransactionType, setActiveTransactionType] =
        useState("Transaction Type");

    const [activeRestrictionClass, setRestrictionClass] = useState<
        Version.CRYPTO | Version.SECURITY_COMPANY | undefined
    >(undefined);


    const enablePrelistedAssets = company === "Fargus" || company === "Framework" || company === "ArgusTest" || company === "Paradigm";

    const actualRestrictionClass = useMemo(() => {
        if (showStockRestrictions && showCryptoRestrictions)
            return activeRestrictionClass;
        else if (!showCryptoRestrictions) return Version.SECURITY_COMPANY;
        else return Version.CRYPTO;
    }, [activeRestrictionClass, showStockRestrictions, showCryptoRestrictions]);

    const RestrictionTypeSelectField = useMemo(
        () => (
            <StyledSelectField
                style={
                    activeRestrictionClass === undefined
                        ? { color: "#2C71F0", flex: 1 }
                        : { flex: 1 }
                }
                value={activeRestrictionClass}
                onChange={(event) => {
                    const v = parseInt(event.target.value);
                    if (v === Version.CRYPTO) setRestrictionClass(Version.CRYPTO);
                    else if (v === Version.SECURITY_COMPANY) setRestrictionClass(Version.SECURITY_COMPANY);
                    else setRestrictionClass(undefined);
                }}
            >
                <option key={-1} value={-1} hidden>
                    Class
                </option>
                <option key={Version.CRYPTO} value={Version.CRYPTO}>
                    Crypto
                </option>
                <option key={Version.SECURITY_COMPANY} value={Version.SECURITY_COMPANY}>
                    Security
                </option>
            </StyledSelectField>
        ),

        [activeRestrictionClass]
    );

    const dispatch = useDispatch();

    useEffect(() => {
        setActiveSearch("");
        setActiveRestrictionGroups(defaultGroup ? [defaultGroup] : []);
        setActiveRestrictionType("Restriction Type");
        setActiveTransactionType("Transaction Type");
        setSelectedStock(undefined);
        setSelectedCrypto(undefined);
        setActiveRestrictedFrom(new Date().toISOString().split("T")[0]);
        setActiveRestrictedTo(undefined);
    }, [actualRestrictionClass]);

    useEffect(() => {
        dispatch(fetchRestrictionGroups());
        if (!defaultGroup) {
            dispatch(fetchDefaultRestrictionGroup());
        } else if (
            !activeRestrictionGroups ||
            (defaultGroup && activeRestrictionGroups.length === 0)
        ) {
            setActiveRestrictionGroups(defaultGroup ? [defaultGroup] : []);
        }
    }, [dispatch, restrictionGroups?.length, defaultGroup]);

    // This is in poor style, but enforces that all restrictions are REQUEST_APPROVAL
    // even with the value would be normally reset after submitting.
    useEffect(() => {
        if (
            company === "Fargus" ||
            company === "Framework" ||
            company === "Multicoin" ||
            company === "Nydig" ||
            company === "Khelp" ||
            company === "Celsius"
        ) {
            setActiveRestrictionType("REQUEST_APPROVAL");
        }
        if (!(company === "Jnk" || company === "Cinven" || company === "proSapient")) {
            setActiveVisibilityType("VISIBLE");
        }
    });

    // Determine whether or not to show the restriction types based on the company loaded
    useEffect(() => {
        if (
            company === "Fargus" ||
            company === "Framework" ||
            company === "Multicoin" ||
            company === "Nydig" ||
            company === "Celsius" ||
            company === "Khelp"
        ) {
            setShowRestrictionTypes(false);
        }
        if (company === "Jnk" || company === "Cinven" || company === "proSapient") {
            setShowVisibilityType(true);
        }
    }, [company]);

    useEffect(() => {
        if (version === "crypto_base") {
            setRestrictionClass(Version.CRYPTO);
        } else if (version === "crossover_base") {
            setRestrictionClass(undefined);
        } else {
            setRestrictionClass(Version.SECURITY_COMPANY);
        }
    }, [version]);

    const [showErrors, setShowErrors] = useState(false);

    const errors = useMemo(() => {
        setShowErrors(false);

        return {
            selectedClass:
                activeRestrictionClass === undefined ? "Please select a class." : "",
            selectedAsset:
                activeRestrictionClass === Version.CRYPTO
                    ? selectedCrypto === undefined
                        ? "Please select an asset."
                        : ""
                    : selectedStock === undefined
                        ? "Please select an asset."
                        : "",
            activeRestrictionType:
                activeRestrictionType === "Restriction Type"
                    ? "Please select a restriction type."
                    : "",
            activeVisibilityType:
                activeVisibilityType === "Visibility Type"
                    ? "Please select a visibility type."
                    : "",
            activeRestrictionGroups:
                activeRestrictionGroups?.length === 0
                    ? "Please select a restriction group."
                    : "",
            activeTransactionType:
                activeTransactionType === "Transaction Type"
                    ? "Please select a transaction type."
                    : "",
            activeRestrictedFrom:
                activeRestrictedFrom === undefined
                    ? "Please select a restriction start date."
                    : "",
        };
    }, [
        activeRestrictionClass,
        selectedCrypto,
        selectedStock,
        activeRestrictionType,
        activeVisibilityType,
        activeRestrictionGroups,
        activeTransactionType,
        activeRestrictedFrom,
    ]);

    const addCrypto = async (event: any) => {
        event.preventDefault();
        if (
            selectedCrypto === undefined ||
            activeRestrictionType === "Restriction Type" ||
            activeRestrictionGroups.length === 0 ||
            activeTransactionType === "Transaction Type" ||
            activeRestrictedFrom === undefined
        ) {
            setShowErrors(true);
            return;
        }

        // Don't allow a restriction end date to occur
        // before a restriction begin date.
        if (activeRestrictedTo) {
            if (new Date(activeRestrictedTo) < new Date(activeRestrictedFrom)) {
                return;
            }
        }

        const asset: { [key: string]: string } = {};
        if (selectedCrypto.type === "CRYPTO") {
            asset["cryptoId"] = selectedCrypto.id;
        } else if (selectedCrypto.type === "PRIVATE_ASSET") {
            asset["privateAssetId"] = selectedCrypto.id;
        }

        dispatch(
            createRestriction({
                ...asset,
                restrictionType: activeRestrictionType,
                restrictionGroups: activeRestrictionGroups,
                transactionType: activeTransactionType,
                visibilityType: activeVisibilityType,
                restrictedFrom: activeRestrictedFrom,
                restrictedTo: activeRestrictedTo,
                securityCompanyId: undefined,
            })
        );

        setActiveSearch("");
        setActiveRestrictionGroups(defaultGroup ? [defaultGroup] : []);
        setActiveRestrictionType("Restriction Type");
        setActiveTransactionType("Transaction Type");
        setSelectedCrypto(undefined);
        setActiveRestrictedFrom(new Date().toISOString().split("T")[0]);
        setActiveRestrictedTo(undefined);
    };

    const addStock = async (event: any) => {
        event.preventDefault();

        if (
            selectedStock === undefined ||
            activeRestrictionType === "Restriction Type" ||
            activeRestrictionGroups.length === 0 ||
            activeVisibilityType === "Visibility Type" ||
            activeRestrictedFrom === undefined
        ) {
            setShowErrors(true);
            return;
        }

        // Don't allow a restriction end date to occur
        // before a restriction begin date.
        if (activeRestrictedTo) {
            if (new Date(activeRestrictedTo) < new Date(activeRestrictedFrom)) {
                return;
            }
        }

        dispatch(
            createRestriction({
                securityCompanyId: selectedStock.id,
                restrictionType: activeRestrictionType,
                restrictionGroups: activeRestrictionGroups,
                transactionType: activeTransactionType,
                visibilityType: activeVisibilityType,
                restrictedFrom: activeRestrictedFrom,
                restrictedTo: activeRestrictedTo,
                cryptoId: undefined,
                privateAssetId: undefined,
            })
        );

        setActiveSearch("");
        setActiveRestrictionGroups(defaultGroup ? [defaultGroup] : []);
        setActiveRestrictionType("Restriction Type");
        setActiveTransactionType("Transaction Type");
        setActiveVisibilityType("Visibility Type");
        setSelectedStock(undefined);
        setActiveRestrictedFrom(new Date().toISOString().split("T")[0]);
        setActiveRestrictedTo(undefined);
    };

    const addRule = async (event: any) => {
        if (activeRestrictionClass === Version.CRYPTO) addCrypto(event);
        else addStock(event);
    };

    const onSelectCrypto = useMemo(() => {
        return (suggestedStock: CryptoSuggestion) => {
            if (suggestedStock) {
                setSelectedCrypto(suggestedStock);
                setActiveSearch(
                    suggestedStock.name + " [" + suggestedStock.ticker + "]"
                );
            }
        };
    }, []);

    const onSelectSecurityCompany = useMemo(() => {
        return (suggestedSecurityCompany: SecurityCompanySuggestion) => {
            if (suggestedSecurityCompany) {
                setSelectedStock(suggestedSecurityCompany);
                setActiveSearch(
                    suggestedSecurityCompany.name
                );
            }
        };
    }, []);

    const AutoComplete =
        enablePrelistedAssets
            ? CryptoPrivateAssetAutoComplete
            : CryptoAutoComplete;

    const noMatchSuggestions = useMemo(() => {
        const suggestions = [];
        if (enablePrelistedAssets) {
            suggestions.push("Create unlisted token");
        }
        if (showCryptoRestrictions) {
            suggestions.push("Create new crypto asset");
        }

        return suggestions.length > 0 ? suggestions : undefined;
    }, [company, activeRestrictionClass]);

    const noMatchAction = (suggestion: string) => {
        if (
            (enablePrelistedAssets) &&
            suggestion === "Create unlisted token"
        ) {
            setInputBlur();
            setShowPrivatAssetModal(true);
        } else if (showCryptoRestrictions && suggestion === "Create new crypto asset") {
            setShowNewCryptoModal(true);
        }
    };

    return (
        <>
            <form>
                <div style={{ display: "flex", flexDirection: "row", fontSize: 13 }}>
                    {showClassSelector && RestrictionTypeSelectField}
                    <div style={{ position: "relative", flex: 1 }}>
                        {activeRestrictionClass === Version.CRYPTO ? (
                            <AutoComplete
                                placeholder={"Ticker or name"}
                                value={activeSearch}
                                style={{ width: "100%" }}
                                onSelect={onSelectCrypto}
                                onChange={(value) => {
                                    setActiveSearch(value);
                                }}
                                onEnter={onSelectCrypto}
                                //@ts-ignore
                                ref={inputRef}
                                noMatchSuggestions={noMatchSuggestions}
                                noMatchAction={noMatchAction}
                            />
                        ) : (
                            <SecurityCompanyAutoComplete
                                placeholder={"Name"}
                                value={activeSearch}
                                style={{ width: "100%" }}
                                onSelect={onSelectSecurityCompany}
                                onEnter={onSelectSecurityCompany}
                            />
                        )}
                    </div>

                    {showRestrictionTypes && (
                        <StyledSelectField
                            style={
                                activeRestrictionType === "Restriction Type"
                                    ? { color: "#2C71F0", flex: 1 }
                                    : { flex: 1 }
                            }
                            value={activeRestrictionType}
                            onChange={(event) =>
                                setActiveRestrictionType(event.target.value)
                            }
                        >
                            <option key={1} value="" hidden>
                                Restriction Type
                            </option>
                            <option key={2} value={"REQUEST_APPROVAL"}>
                                Request Approval
                            </option>
                            <option key={3} value={"RESTRICTED"}>
                                Restricted
                            </option>
                        </StyledSelectField>
                    )}

                    <StyledSelectField
                        style={
                            activeTransactionType === "Transaction Type"
                                ? { color: "#2C71F0", flex: 1 }
                                : { flex: 1 }
                        }
                        value={activeTransactionType}
                        onChange={(event) =>
                            setActiveTransactionType(event.target.value)
                        }
                    >
                        <option key={1} value="" hidden>
                            Transaction Type
                        </option>
                        {(actualRestrictionClass === Version.CRYPTO
                            ? TransactionTypes.Crypto
                            : TransactionTypes.SecurityCompany
                        ).map((txType, index) => {
                            return (
                                <option key={index + 2} value={txType.type}>
                                    {txType.display}
                                </option>
                            );
                        })}
                    </StyledSelectField>

                    {showVisibilityType && (
                        <StyledSelectField
                            style={
                                activeVisibilityType === "Visibility Type"
                                    ? { color: "#2C71F0", flex: 1 }
                                    : { flex: 1 }
                            }
                            value={activeVisibilityType}
                            onChange={(event) =>
                                setActiveVisibilityType(event.target.value)
                            }
                        >
                            <option key={1} value="" hidden>
                                Visibility Type
                            </option>
                            <option key={2} value="VISIBLE">
                                Visible
                            </option>
                            <option key={3} value="HIDDEN">
                                Hidden
                            </option>
                        </StyledSelectField>
                    )}
                </div>

                {showErrors && (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            height: 20,
                            fontSize: 13,
                            paddingTop: 5,
                        }}
                    >
                        <>
                            <div style={{ flex: 1 }}>
                                <p style={{ margin: "0", color: "red" }}>
                                    {errors.selectedClass}
                                </p>
                            </div>
                            <div style={{ flex: 1 }}>
                                <p style={{ margin: "0", color: "red" }}>
                                    {errors.selectedAsset}
                                </p>
                            </div>
                            {showRestrictionTypes && (
                                <div style={{ flex: 1 }}>
                                    <p style={{ margin: "0", color: "red" }}>
                                        {errors.activeRestrictionType}
                                    </p>
                                </div>
                            )}
                            <div style={{ flex: 1 }}>
                                <p style={{ margin: "0", color: "red" }}>
                                    {errors.activeTransactionType}
                                </p>
                            </div>
                            {showVisibilityType && (
                                <div style={{ flex: 1 }}>
                                    <p style={{ margin: "0", color: "red" }}>
                                        {errors.activeVisibilityType}
                                    </p>
                                </div>
                            )}
                        </>
                    </div>
                )}

                {(company === "Paradigm" ||
                    company === "Celsius" ||
                    company === "Fargus" ||
                    company === "ArgusTest") && (
                        <StyledSpacing
                            style={{
                                margin: ".5rem 0 3rem",
                                alignItems: "center",
                                gap: "0.1rem",
                                fontSize: 13,
                            }}
                        >
                            <MultiSelect<IRestrictionGroup>
                                onChange={(newValue) => {
                                    console.log(newValue)
                                    setActiveRestrictionGroups(newValue);
                                }}
                                compare={(a, b) => a.id === b.id}
                                labelFormat={(v) => v.name}
                                options={restrictionGroups}
                                values={activeRestrictionGroups}
                                placeholder="Restriction Group"
                            />
                            {showErrors && (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        height: 20,
                                        fontSize: 13,
                                        paddingTop: 5,
                                    }}
                                >
                                    <p style={{ margin: "0", color: "red" }}>
                                        {errors.activeRestrictionGroups}
                                    </p>
                                </div>
                            )}
                        </StyledSpacing>
                    )}

                {/* <input type='number' value={activeRestrictionGroup} onChange={(event) => setActiveRestrictionGroup(event.target.valueAsNumber)} /> */}
                {/* <input type='button' value='Add' onClick={(event) => addRule(event)} />
                    <input type='button' value='Remove' onClick={(event) => handleRemoveStock(event)} /> */}
                <StyledSpacing style={{ marginBottom: "1rem" }}>
                    <StyledSectionHeader>Restriction Period</StyledSectionHeader>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ width: "15%" }}>
                            <StyledLabel> Restriction Start: </StyledLabel>
                        </div>
                        <StyledInputField
                            onFocus={(e) => {
                                if (activeRestrictedFrom === undefined) {
                                    setActiveRestrictedFrom("");
                                }
                            }}
                            onBlur={(e) => {
                                if (activeRestrictedFrom === "") {
                                    setActiveRestrictedFrom(undefined);
                                }
                            }}
                            type={activeRestrictedFrom === undefined ? "text" : "date"}
                            placeholder="Leave blank to create restriction with no expiration date."
                            value={activeRestrictedFrom || ""}
                            onChange={(event) =>
                                setActiveRestrictedFrom(event.target.value)
                            }
                        />
                        {showErrors && (
                            <p
                                style={{
                                    margin: 0,
                                    marginLeft: 10,
                                    color: "red",
                                    fontSize: 13,
                                }}
                            >
                                {errors.activeRestrictedFrom}
                            </p>
                        )}
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ width: "15%" }}>
                            <StyledLabel> Restriction End: </StyledLabel>
                        </div>
                        <StyledInputField
                            onFocus={(e) => {
                                if (activeRestrictedTo === undefined) {
                                    setActiveRestrictedTo("");
                                }
                            }}
                            onBlur={(e) => {
                                if (activeRestrictedTo === "") {
                                    setActiveRestrictedTo(undefined);
                                }
                            }}
                            type={activeRestrictedTo === undefined ? "text" : "date"}
                            placeholder="Leave blank to create restriction with no expiration date."
                            value={activeRestrictedTo || ""}
                            onChange={(event) =>
                                setActiveRestrictedTo(event.target.value)
                            }
                        />
                    </div>
                </StyledSpacing>
                <StyledActionButton style={{ width: "25%" }} onClick={addRule}>
                    Add restriction
                </StyledActionButton>
            </form>

            {showPrivatAssetModal && (
                <Modal
                    show={showPrivatAssetModal}
                    onClose={() => setShowPrivatAssetModal(false)}
                    title={"Create Token"}
                    backgroundStyle={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                    modalStyle={{ height: "auto", maxHeight: "100%" }}
                >
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            flexWrap: "wrap",
                            flexDirection: "row",
                            padding: "3rem 0",
                            overflow: "auto",
                            gap: "1rem",
                        }}
                    >
                        <StyledModalItem>
                            <ModalLabel>Token:</ModalLabel>
                            <StyledInputField
                                style={{ marginBottom: "1.5rem" }}
                                placeholder="Token name"
                                value={activeSearch}
                                onChange={(e: any) => {
                                    setActiveSearch(e.target.value);
                                }}
                            />
                        </StyledModalItem>
                        <StyledModalItem>
                            <ModalLabel>Token Ticker</ModalLabel>
                            <StyledInputField
                                style={{ marginBottom: "1.5rem" }}
                                placeholder="Token ticker"
                                value={tokenTicker}
                                onChange={(e: any) => {
                                    setTokenTicker(e.target.value);
                                }}
                            />
                        </StyledModalItem>

                        <StyledModalItem>
                            <StyledActionButton
                                onClick={(e: any) => {
                                    infoService.createPrivateAsset(
                                        activeSearch,
                                        tokenTicker,
                                        "CRYPTO"
                                    );
                                    setShowPrivatAssetModal(false);
                                }}
                            >
                                Add Token
                            </StyledActionButton>
                        </StyledModalItem>
                    </div>
                </Modal>
            )}

            {showCryptoRestrictions && (
                <CryptoAssetModal
                    show={showNewCryptoModal}
                    onClose={() => setShowNewCryptoModal(false)}
                />
            )}
        </>
    );
}

export default RestrictionInput;
