import {useEffect, useMemo, useState} from "react";
import { infoService } from "../services/infoService";
import DynamicAutoComplete, { IDynamicAutoComplete } from "./DynamicAutoComplete";

export type SecuritySuggestion = {
    id: string;
    ticker: string;
    name: string;
    exchangeName: string;
    isin: string;
};

function SecurityAutoComplete(props: IDynamicAutoComplete<SecuritySuggestion>): JSX.Element {

    const [restricted, setRestricted] = useState(false);

    useEffect(() => {
        if (props.filteredSuggestions !== undefined) {
            if (props.filteredSuggestions) {
                setRestricted(true);
            } else {
                setRestricted(false);
            }
        }
    }, [props])

    const formatAutoComplete = useMemo(() => {
        return (suggestion: SecuritySuggestion) =>
            suggestion.name +
            " [" +
            suggestion.ticker +
            "] - " +
            suggestion.exchangeName
    }, [])

    const autoComplete = useMemo(() => {
        return async (input: string) => {
            const response = await infoService.autocompleteSecurity(input);
            return response.suggestions;
        }
    }, [])

    const autoCompleteRestricted = useMemo(() => {
        return async (input: string) => {
            const response = await infoService.autocompleteRestrictedSecurity(input);
            return response.suggestions;
        }
    }, [])

    return (
        <DynamicAutoComplete<SecuritySuggestion> {...props} formatAutoComplete={formatAutoComplete} autoComplete={
            restricted ? autoCompleteRestricted : autoComplete
        } />
    );
}


export type SecurityCompanySuggestion = {
    id: string;
    name: string;
};

function SecurityCompanyAutoComplete(props: IDynamicAutoComplete<SecurityCompanySuggestion>): JSX.Element {

    const [restricted, setRestricted] = useState(false);

    useEffect(() => {
        if (props.filteredSuggestions !== undefined) {
            if (props.filteredSuggestions) {
                setRestricted(true);
            } else {
                setRestricted(false);
            }
        }
    }, [props])

    const formatAutoComplete = useMemo(() => {
        return (suggestion: SecurityCompanySuggestion) =>
            suggestion.name
    }, [])

    const autoComplete = useMemo(() => {
        return async (input: string) => {
            const response = await infoService.autocompleteSecurityCompany(input);
            return response.suggestions;
        }
    }, [])

    const autoCompleteRestricted = useMemo(() => {
        return async (input: string) => {
            const response = await infoService.autocompleteRestrictedSecurityCompany(input);
            return response.suggestions;
        }
    }, [])

    return (
        <DynamicAutoComplete<SecurityCompanySuggestion> {...props} formatAutoComplete={formatAutoComplete} autoComplete={
            restricted ? autoCompleteRestricted : autoComplete
        } />
    );
}

export { SecurityCompanyAutoComplete, SecurityAutoComplete };
