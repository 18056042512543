import { useEffect, useMemo } from "react";
import { StyledSectionHeader } from "../../components/StyledSectionHeader";
import sanitizeHtml from "sanitize-html";
import { fetchPolicy, selectPolicy } from "../GlobalRestrictionSlice";
import { useDispatch, useSelector } from "react-redux";
import { StyledTextArea } from "../../components/StyledTextArea";
import { TextStylingContainer } from "../../components/TextStylingContainer";
import { selectVersion } from "../../auth/AuthSlice";

const SANITIZED_CONFIG = {
    allowedTags: [
        "p",
        "i",
        "tbody",
        "br",
        "em",
        "td",
        "a",
        "strong",
        "th",
        "strike",
        "tr",
        "div",
        "u",
        "span",
        "s",
        "colgroup",
        "ul",
        "span",
        "col",
        "li",
        "pre",
        "tfoot",
        "ol",
        "table",
        "b",
        "thead",
        "code",
        "sup",
        "h1",
        "h2",
        "h3",
    ],
    allowedAttributes: {
        a: ["href", "target"],
        "*": ["style"],
    },
    allowedStyles: {
        "*": {
            "margin-left": [/^\d+(?:px|em|%)$/],
        },
    },
};

function GlobalRestrictions(): JSX.Element {
    const policy = useSelector(selectPolicy);
    const version = useSelector(selectVersion);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchPolicy());
    }, [dispatch]);

    return (
        <>
            <StyledSectionHeader style={{ fontSize: "30px", lineHeight: "36px" }}>
                Global Policies
            </StyledSectionHeader>

            <TextStylingContainer>
                <div
                    dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(policy, SANITIZED_CONFIG),
                    }}
                />
            </TextStylingContainer>
        </>
    );
}

export default GlobalRestrictions;
