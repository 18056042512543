import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { nanoid } from "nanoid";
import { authLogout, selectUserId, setIsAdmin } from "./AuthSlice";
import { StyledLoginBox } from "../components/StyledLoginBox";
import { StyledLoginPage } from "../components/StyledLoginPage";
import { StyledActionButton } from "../components/StyledActionButton";
import { Link, useLocation } from "react-router-dom";
import { auth0_admin_url, auth0_user_url, get_auth0_url } from "./util";
import { StyledLinkButton } from "../components/StyledLinkButton";


function PasswordLogin(): JSX.Element {

    /* Hooks for libraries, selectors */
    const dispatch = useDispatch();
    const location = useLocation();

    const userId = useSelector(selectUserId);

    const [userUrl, setUserUrl] = useState(get_auth0_url("dev", "user"))
    const [adminUrl, setAdminUrl] = useState(get_auth0_url("dev", "admin"))

    useEffect(() => {
        userId == "-1"
            ? dispatch(authLogout(false)) : dispatch(authLogout(true));
        dispatch(setIsAdmin(false));
    }, [dispatch]);
    

    /*  Read hostname from URL, load appropriate logins. */
    useEffect(() => {
        const hostname = window.location.hostname;
        const parts = hostname.split('.');
        const company_name = parts[0];

        setUserUrl(get_auth0_url(company_name, "user"))
        setAdminUrl(get_auth0_url(company_name, "admin"))
    }, [])

    const [cacheRefreshState, _setCacheRefreshState] = useState(0);
    const cacheRefreshStateRef = useRef(cacheRefreshState)
    const setCacheRefreshState = (data: number) => {
        cacheRefreshStateRef.current = data;
        _setCacheRefreshState(data)
    }

    useEffect(() => {
        function checkCacheResetSequence(e: any) {

            // Cache Clear combo is CTRL + Space
            if (e.keyCode === 32 && cacheRefreshStateRef.current === 17) {
                dispatch(authLogout(true));
            }
            setCacheRefreshState(e.keyCode)
        }
        document.addEventListener('keydown', checkCacheResetSequence);

        // Don't forget to clean up
        return function cleanup() {
            document.removeEventListener('keydown', checkCacheResetSequence);
        }
    }, []);

    const handleConnection = useCallback((auth0Url: string) => {
        const nonce = nanoid();
        const url = `${auth0Url}&nonce=${nonce}`;
        window.localStorage.setItem('nonce', nonce);
        window.location.href = url
    }, []);

    return (
        <StyledLoginPage>
            <StyledLoginBox style={{ minHeight: "35%", paddingBottom: "5px" }}>
                <div
                    style={{
                        height: "15%",
                        display: "flex",
                        justifyContent: "center",
                        boxSizing: "border-box",
                        fontSize: "40px",
                        lineHeight: "40px",
                    }}
                ></div>

                <h2
                    style={{
                        color: "#15357A",
                        textAlign: "center",
                        fontSize: "24px",
                        lineHeight: "28px",
                    }}
                >
                    Login
                </h2>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "3%",
                        color: "#2C71F0",
                        textAlign: "center",
                    }}
                >
                    <StyledActionButton style={{ width: "33%" }} onClick={() => handleConnection(userUrl)}>
                        User Login
                    </StyledActionButton>

                    <StyledActionButton style={{ width: "33%" }} onClick={() => handleConnection(adminUrl)}>
                        Admin Login
                    </StyledActionButton>

                    <Link to={"/partner_login"}>
                        <StyledLinkButton style={{ marginTop: "3%", fontSize: "16px" }}>
                            Log In via SSO
                        </StyledLinkButton>
                    </Link>
                </div>
            </StyledLoginBox>
        </StyledLoginPage>
    );
}

export default PasswordLogin;
