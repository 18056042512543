import { useEffect } from "react";
import queryString from "querystring";
import { getNonce } from "../util/token";
import { StyledLoginPage } from "../components/StyledLoginPage";
import { useDispatch } from "react-redux";
import { authLoginAuth0, authLoginMs, authLoginOkta } from "./AuthSlice";
import { useLocation } from "react-router-dom";
import { history } from "../nav/history";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const isNonceValid = (token: string) => {
    const storedNonce = window.localStorage.getItem("nonce");

    // Remove the nonce
    window.localStorage.removeItem("nonce");

    const nonce = getNonce(token);
    if (!nonce) {
        // No nonce in the provider token so skip check
        return true;
    }

    return storedNonce === nonce;
};

function Redirect(): JSX.Element {
    /* Hooks for libraries, selectors */
    const dispatch = useDispatch();
    const location = useLocation();
    const query = useQuery();

    useEffect(() => {
        const token = queryString.parse(window.location.hash);
        const idToken = token["#id_token"] as string;
        const loginType = query.get("logintype");
        const asAdmin = loginType ? loginType === "admin" : false;
        const provider = query.get("provider");
        const source = query.get("source");

        if (provider && provider === "okta") {
            if (!isNonceValid(idToken)) {
                history.push("password_login");
                return;
            }

            dispatch(
                authLoginOkta({
                    idToken: idToken,
                    location: location,
                    asAdmin: asAdmin,
                })
            );
        } else if (source && source === "auth0") {
            if (!isNonceValid(idToken)) {
                history.push("password_login");
                return;
            }

            dispatch(
                authLoginAuth0({
                    idToken: idToken,
                    location: location,
                    asAdmin: asAdmin,
                })
            );
        } else {
            if (!isNonceValid(idToken)) {
                history.push("password_login");
                return;
            }

            dispatch(
                authLoginMs({
                    idToken: idToken,
                    location: location,
                    asAdmin: asAdmin,
                })
            );
        }
    });

    return <StyledLoginPage />;
}

export default Redirect;
