import home from "../assets/home.svg";
import hands from "../assets/hands.svg";
import user from "../assets/user.svg";
import dashboard from "../assets/dashboard.svg";
import logout from "../assets/logout.svg";
import history from "../assets/history.svg";
import tradeReq from "../assets/trade_requests.svg";

export const navData = [
    {
        path: "/",
        title: "Home",
        icon: home,
        view: "User",
        version: ["base"],
        exclude_companies: ["Public", "Fargus"]
    },
    {
        path: "/homepage",
        title: "Home",
        icon: home,
        view: "User",
        version: ["base"],
        companies: ["Public", "Fargus"]
    },
    {
        path: "/user-restrictions",
        title: "Home",
        icon: home,
        view: "User",
        version: ["crypto_base", "crossover_base"],
    },
    {
        path: "/user-restrictions",
        title: "Home",
        icon: home,
        view: "Proxy",
        version: ["crypto_base", "crossover_base"],
    },
    {
        path: "/accounts",
        title: "Accounts",
        icon: hands,
        view: "User",
        version: [
            "base",
            "brokerage_integration",
            "brokerage_integration_plus",
            "brokerage_integration_plus_v2",
            "crypto_monitoring",
            "crypto_base",
            "crossover_base",
        ],
        exclude_companies: ["Paradigm", "OneRiver"]
    },
    {
        path: "/accounts",
        title: "Accounts",
        icon: hands,
        view: "Proxy",
        version: [
            "base",
            "brokerage_integration",
            "brokerage_integration_plus",
            "brokerage_integration_plus_v2",
            "crypto_monitoring",
            "crypto_base",
            "crossover_base",
        ],
    },
    {
        path: "/profile",
        title: "Profile",
        icon: user,
        view: "User",
        version: [],
    },
    {
        path: "/clearance",
        title: "Dashboard",
        icon: dashboard,
        view: "User",
        version: ["base", "crypto_base", "crossover_base"],
    },
    {
        path: "/user-history",
        title: "Historic Transactions",
        icon: history,
        view: "User",
        version: ["crypto_monitoring", "crypto_base", "crossover_base"],
    },
    {
        path: "/user-historic-holdings",
        title: "Historic Holdings",
        icon: history,
        view: "User",
        version: ["crypto_monitoring", "crypto_base", "crossover_base"],
    },
    {
        path: "/user-attestations",
        title: "Attestations",
        icon: history,
        view: "User",
        version: ["crypto_base", "crossover_base"],
        companies: ["Fargus", "Framework", "Argus"]
        // version: ["crypto_monitoring", "crypto_base", "crossover_base"],
    },
    {
        path: "/user-profile",
        title: "Profile",
        icon: user,
        view: "User",
        version: [
            "base",
            "brokerage_integration",
            "brokerage_integration_plus",
            "brokerage_integration_plus_v2",
            "crypto_base",
            "crossover_base",
        ],
    },
    {
        path: "/admin",
        title: "Home",
        icon: home,
        view: "Admin",
        version: ["base", "crypto_base", "crossover_base"],
    },
    {
        path: "/userPanel",
        title: "Users",
        icon: user,
        view: "Admin",
        version: [
            "base",
            "brokerage_integration",
            "brokerage_integration_plus",
            "brokerage_integration_plus_v2",
            "crypto_monitoring",
            "crypto_base",
            "crossover_base",
        ],
    },
    {
        path: "/insider-management",
        title: "Insider Management",
        icon: home,
        view: "Admin",
        version: ["base", "crypto_base", "crossover_base"],
        companies: ["Celsius", "Fargus", "ArgusTest"]
    },
    {
        path: "/dashboard",
        title: "Dashboard",
        icon: dashboard,
        view: "Admin",
        version: ["base", "crypto_base", "crossover_base"],
    },
    {
        path: "/analytics",
        title: "Analytics",
        icon: dashboard,
        view: "Admin",
        version: ["base", "crypto_base", "crossover_base"],
        companies: ["Celsius", "Fargus", "ArgusTest"]
    },
    {
        path: "/admin-accounts",
        title: "Accounts",
        icon: hands,
        view: "Admin",
        version: ["base", "crypto_base", "crossover_base"],
    },
    {
        path: "/history",
        title: "Historic Transactions",
        icon: history,
        view: "Admin",
        version: [
            "base",
            "brokerage_integration_plus",
            "brokerage_integration_plus_v2",
            "crypto_monitoring",
            "crypto_base",
            "crossover_base",
        ],
    },
    {
        path: "/historic-holdings",
        title: "Historic Holdings",
        icon: history,
        view: "Admin",
        version: [
            "base",
            "brokerage_integration_plus_v2",
            "crypto_base",
            "crossover_base",
        ],
    },
    {
        path: "/historic-infractions",
        title: "Historic Infractions",
        icon: history,
        view: "Admin",
        version: ["base", "crypto_base", "crossover_base"],
    },
    {
        path: "/trade-requests",
        title: "Trade Requests",
        icon: tradeReq,
        view: "Admin",
        version: ["base", "crypto_base", "crossover_base"],
    },
    {
        path: "/attestations",
        title: "Attestations",
        icon: history,
        view: "Admin",
        version: ["crypto_base", "crossover_base"],
        companies: ["Fargus", "Framework", "Argus"]
        // version: ["crypto_monitoring", "crypto_base", "crossover_base"],
    },
    {
        path: "/partner_login",
        title: "Logout",
        icon: logout,
        view: "All",
        version: ["base"],
    },
    {
        path: "/partner_login",
        title: "Logout",
        icon: logout,
        view: "All",
        version: [
            "brokerage_integration",
            "brokerage_integration_plus",
            "brokerage_integration_plus_v2",
            "crypto_monitoring",
            "crypto_base",
            "crossover_base",
        ],
    },
];
