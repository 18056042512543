/* Wrapper for calls to the trade_reqs route */
import { ISorting } from "../dashboard/DashboardSlice";
import { IPagination, paginationQueryParam } from "../util/pagination";
import { authService } from "./authService";

export const watchlistService = {
    getWatchedSecurities,
    getWatchedCrypto,
    saveSecurityWatchlist,
    saveCryptoWatchlist,
    deleteSecurityWatchlist,
    deleteCryptoWatchlist,
    getSearchCrypto,
};

const baseUrl = process.env.REACT_APP_API_ENDPOINT;
const baseUrlSecurity = `${baseUrl}/watchlist`;
const baseUrlCrypto = `${baseUrl}/crypto/watchlist`;
const baseUrlSearchCrypto = `${baseUrl}/crypto/search`;

export interface IFilter {
    name: string;
}

/* Gets all securities on watchlist */
function getWatchedSecurities(
    pagination: IPagination,
    filter: IFilter,
    sorting: ISorting[]
): any {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ filter, sorting }),
    };

    return fetch(
        paginationQueryParam(`${baseUrlSecurity}/get`, pagination),
        requestParams
    )
        .then(authService.handleErrors)
        .then(handleResponse);
}

/* Gets all cryptos on watchlist */
function getWatchedCrypto(userId: number): any {
    const requestParams = {
        method: "GET",
        headers: {
            ...authService.authHeader(),
        },
    };

    return fetch(`${baseUrlCrypto}/` + userId, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

/* Gets ticker search */
/*
function getSearch(ticker: string): any {
    const requestParams = {
        method: "GET",
        headers: {
            ...authService.authHeader(),
            "Content-Type": "application/json",
        },
    };

    return fetch(`${baseUrlSearch}?search=${ticker}`, requestParams)
        .then(authService.handleErrors)    
        .then(handleResponse);
}
*/

/* Gets ticker search Crypto */
function getSearchCrypto(ticker: string, exchange: string): any {
    const requestParams = {
        method: "GET",
        headers: {
            ...authService.authHeader(),
        },
    };

    return fetch(
        `${baseUrlSearchCrypto}?search=${ticker}&exchange=${exchange}`,
        requestParams
    )
        .then(authService.handleErrors)
        .then(handleResponse);
}

/* Save security to watchlist */
function saveSecurityWatchlist(securityId: string): any {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ securityId }),
    };

    return fetch(`${baseUrlSecurity}`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

/* Save crypto to watchlist */
function saveCryptoWatchlist(
    userId: number,
    ticker: string,
    name: string,
    exchange: string,
    unit: string
): any {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            ticker: ticker,
            name: name,
            exchange: exchange,
            unit: unit,
        }),
    };

    return fetch(`${baseUrlCrypto}/` + userId, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

/* Delete security on watchlist by id */
function deleteSecurityWatchlist(id: number): any {
    const requestParams = {
        method: "DELETE",
        headers: {
            ...authService.authHeader(),
        },
    };

    return fetch(`${baseUrlSecurity}/` + id, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

/* Delete crypto on watchlist by id */
function deleteCryptoWatchlist(id: number): any {
    const requestParams = {
        method: "DELETE",
        headers: {
            ...authService.authHeader(),
        },
    };

    return fetch(`${baseUrlCrypto}/` + id, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

function handleResponse(response: any) {
    return response.text().then((text: any) => {
        const data = text && JSON.parse(text);
        return data;
    });
}
