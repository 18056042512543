//The jsxRuntime pragma is necessary to get Emotion and React to work w/ latest Create-React-App version
/** @jsxRuntime classic */
/** @jsx jsx */
import styled from "@emotion/styled";

export const StyledFastlinkBody = styled.div`
    // position: fixed;
    background-color: #fefefe;
    // display: flex;
    // flex-wrap: wrap;
    margin-top: 1%;
    margin-left: 25%;
    width: 50%;
    height: 96%;
    overflow: auto;
    justify-content: center;

    font-family: Lato;
    color: #15357a;
    font-size: 14px;
    line-height: 40px;
    font-style: normal;
    font-weight: normal;

    // border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 5px;

    -webkit-animation-name: slideIn;
    -webkit-animation-duration: 0.4s;
    animation-name: slideIn;
    animation-duration: 0.4s;

    /* Add Animation */
    @-webkit-keyframes slideIn {
        from {
            bottom: -300px;
            opacity: 0;
        }
        to {
            bottom: 7%;
            opacity: 1;
        }
    }

    @keyframes slideIn {
        from {
            bottom: -300px;
            opacity: 0;
        }
        to {
            bottom: 7%;
            opacity: 1;
        }
    }
`;
