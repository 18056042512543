import { useSelector } from "react-redux";
import { selectVersion } from "../auth/AuthSlice";
import SmartList from "../components/SmartList";
import capitalizeFirstLetter from "../util/capitalizeFirstLetter";
import DashboardListItem from "./DashboardListItem";
import { IReq, ISorting } from "./DashboardSlice";

/* Type for the WatchlistList props */
interface IListProps {
    pageSize?: number; //How many reqs to display, mark as optional
    start?: number; //What is our starting offset, mark as optional
    reqs: Array<IReq>;
    activeFilter: string;
    handleDelete(employee: string): void;
    setSorting: (sorting: ISorting) => void;
    sorting: ISorting;
}

function DashboardList(props: IListProps): JSX.Element {
    /* Provide default values and replace them with the prop values if there are any. */
    const { reqs, setSorting, sorting } = props;

    const version = useSelector(selectVersion);

    const headerData = [
        { fieldText: "Employee", fieldValue: "user" },
        { fieldText: "Type", fieldValue: "transactionType" },
        { fieldText: "Order", fieldValue: "orderType" },
        { fieldText: "Ticker", fieldValue: "ticker" },
        { fieldText: "Asset", fieldValue: "asset" },
        { fieldText: "Volume / Value", fieldValue: "volume" },
        {
            fieldText: "Pair Ticker",
            fieldValue: "pairTicker",
            versions: ["crypto_base", "crossover_base"],
        },
        {
            fieldText: "Pair Asset",
            fieldValue: "pairAsset",
            versions: ["crypto_base", "crossover_base"],
        },
        {
            fieldText: "Pair Volume / Value",
            fieldValue: "volume",
            versions: ["crypto_base", "crossover_base"],
        },
        { fieldText: "Explanation", fieldValue: "explanation" },
    ];
    headerData.push({ fieldText: "Date", fieldValue: "dateSubmitted" });

    const disabled = ['pairTicker', 'pairAsset']

    const generateItem = (req: IReq, index: number) => (
        <DashboardListItem
            version={version}
            employee={req.user}
            transactionType={
                req.transactionSubtype
                    ? req.transactionType === "WITHDRAWAL"
                        ? "Send " + capitalizeFirstLetter(req.transactionSubtype)
                        : req.transactionSubtype === "AIRDROP"
                        ? "Claim " + capitalizeFirstLetter(req.transactionSubtype)
                        : "Receive " + capitalizeFirstLetter(req.transactionSubtype)
                    : capitalizeFirstLetter(req.transactionType)
            }
            orderType={req.orderType}
            ticker={
                req.security ? req.security.ticker : req.crypto ? req.crypto.ticker : req.privateAsset ? req.privateAsset.ticker : ""
            }
            assetName={
                req.security
                    ? req.security.name
                    : req.crypto
                    ? req.crypto.name +
                      (req.crypto.cryptocurrencyType !== "CURRENCY" &&
                      req.crypto.platform
                          ? " [" + req.crypto.platform + "]"
                          : "")
                    : req.privateAsset ? req.privateAsset.name : ""
            }
            secondaryTicker={req.secondaryCrypto ? req.secondaryCrypto.ticker : ""}
            secondaryAssetName={req.secondaryCrypto ? req.secondaryCrypto.name : ""}
            volume={req.volume}
            secondaryCryptoVolume={req.secondaryCryptoVolume}
            amount={req.amount}
            secondaryCryptoAmount={req.secondaryCryptoAmount}
            currency={req.currency}
            explanation={req.explanation}
            subCategory={req.subCategory}
            frequency={req.frequency}
            start={req.start}
            representations={req.representations}
            date={req.dateCreated}
            id={req.id}
            handleDelete={props.handleDelete}
            key={index}
            complianceNotes={req.complianceNotes}
        />
    );

    return (
        <SmartList
            version={version}
            headerData={headerData}
            bumper="Approve?"
            arr={reqs}
            generateItem={generateItem}
            setSorting={setSorting}
            sorting={sorting}
            disabled={disabled}
        />
    );
}
export default DashboardList;
