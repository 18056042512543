import redcross from "../../assets/redcross.svg";
import { useSelector } from "react-redux";
import { selectUserId } from "../../auth/AuthSlice";
import ExtensiveInfoModal from "../../components/ExtensiveInfoModal";

interface IListItemProps {
    secondaryUserId: string;
    name: string;
    // associatedAccounts: number;
    handleDelete: (secondaryUserId: string) => void;
}

function HistoricInfractionsListItem(props: IListItemProps): JSX.Element {
    const userId = useSelector(selectUserId);

    return (
        <tr>
            <td>{props.name}</td>
            {/* <td>{props.associatedAccounts}</td> */}
            {/* <td>
                {props.secondaryUserId !== userId ? (
                    props.associatedAccounts > 0 ? (
                        <ExtensiveInfoModal
                            style={{
                                maxWidth: "15%",
                                maxHeight: "15%",
                            }}
                            title={"Info"}
                        >
                            <div>
                                You cannot delete secondary users which have associated
                                accounts.
                            </div>
                        </ExtensiveInfoModal>
                    ) : (
                        <img
                            src={redcross}
                            style={{
                                cursor: "pointer",
                            }}
                            onClick={() => props.handleDelete(props.secondaryUserId)}
                        />
                    )
                ) : (
                    <div>
                        <ExtensiveInfoModal
                            style={{
                                maxWidth: "15%",
                                maxHeight: "15%",
                            }}
                            title={"Info"}
                        >
                            <div> This is the primary account </div>
                        </ExtensiveInfoModal>
                    </div>
                )}
            </td> */}
        </tr>
    );
}

export default HistoricInfractionsListItem;
