import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StyledSectionHeader } from "../../components/StyledSectionHeader";
import useInterval from "../../util/useInterval";
import {
    fetchUserHistoricHoldings,
    selectUserHistoricHoldings,
} from "../../admin/HistoricHoldings/HistoricHoldingsSlice";
import handleDownload from "../../util/downloadCsv";
import TablePageFooter, { DefaultPagination } from "../../components/TablePageFooter";
import { ISorting } from "../../dashboard/DashboardSlice";
import "../../admin/HistoricHoldings/Calendar.css";
import Calendar from "react-calendar";
import HistoricHoldingsList from "../../admin/HistoricHoldings/HistoricHoldingsList";
import { selectUserId } from "../../auth/AuthSlice";
import { holdingsService } from "../../services/holdingsService";
import { IPagination } from "../../util/pagination";
import { downloadFromUrl } from "../../util/downloadFromUrl";

function UserHistoricHoldings(): JSX.Element {
    const dispatch = useDispatch();
    const userHistoricHoldings = useSelector(selectUserHistoricHoldings);
    const userId = useSelector(selectUserId);

    const [pagination, setPagination] = useState<IPagination>(DefaultPagination());
    const nextPage = useSelector((state: any) => state.historicHoldings.nextPage);

    const [sorting, setSorting] = useState<ISorting>({
        field: "name",
        direction: "asc",
    });

    const [date, setDate] = useState(new Date());

    const [user, setUser] = useState({ id: "", name: "", email: "" });
    const [accountHolder, setAccountHolder] = useState("");
    const [lastDate, setLastDate] = useState<Date | undefined>(undefined);

    const version = useSelector((state: any) => state.auth.version);
    const category = useMemo(() => {
        if (version === "crypto_monitoring" || version === "crypto_base") {
            return [
                "CRYPTO_EXCHANGE",
                "CRYPTO_CHAIN",
                "CRYPTO_TOKEN",
                "CRYPTO_NFT",
                "CRYPTO_MANUAL_UPLOAD",
            ];
        } else if (version === "crossover_base") {
            return [
                "SECURITY",
                "CRYPTO_EXCHANGE",
                "CRYPTO_CHAIN",
                "CRYPTO_TOKEN",
                "CRYPTO_NFT",
                "CRYPTO_MANUAL_UPLOAD",
            ];
        }
        return ["SECURITY"];
    }, [version]);

    useEffect(() => {
        dispatch(
            fetchUserHistoricHoldings({
                pagination,
                filter: { ...user, accountHolder, category },
                sorting: [sorting],
                date,
            })
        );
        if (lastDate === undefined) {
            holdingsService.getLastHistoricHoldingDateUser().then((response: any) => {
                setLastDate(new Date(Date.parse(response.date)));
                console.log(lastDate);
            });
        }
    }, [
        dispatch,
        pagination,
        userHistoricHoldings.length,
        user,
        sorting,
        date,
        lastDate,
        category,
        accountHolder,
    ]);

    useInterval(() => {
        dispatch(
            fetchUserHistoricHoldings({
                pagination,
                filter: { ...user, accountHolder, category },
                sorting: [sorting],
                date,
            })
        );
    }, 25000);

    const handleDelete = (e: string) => {
        /* No delete. */
        return;
    };

    return (
        <div>
            <Calendar
                onChange={(date: Date) => {
                    setDate(date);
                    setPagination({
                        ...pagination,
                        pageNumber: 1,
                    });
                }}
                value={date}
                tileDisabled={(tileProps) =>
                    tileProps.date > new Date() ||
                    (lastDate !== undefined && tileProps.date < lastDate)
                }
            />

            <div
                style={{
                    marginTop: "2%",
                }}
            />

            <StyledSectionHeader>Historic Holdings</StyledSectionHeader>

            <div
                onClick={() => {
                    handleDownload(
                        holdingsService.getHistoricUserHoldingsDownload({ ...user, accountHolder, category }, [sorting], date),
                        'historic_holdings'
                    )
                }}
                style={{
                    textAlign: "right",
                    fontSize: "15px",
                    textDecoration: "underline",
                    color: "#2c71f0",
                    cursor: "pointer",
                    marginBottom: "2%",
                }}
            >
                Download historic holdings
            </div>
            <HistoricHoldingsList
                holdings={userHistoricHoldings}
                handleDelete={handleDelete}
                setSorting={setSorting}
                sorting={sorting}
            />
            <TablePageFooter
                nextPage={nextPage}
                pagination={pagination}
                setPageNumber={(n) => setPagination({ ...pagination, pageNumber: n })}
                setPageSize={(s) => setPagination({ ...pagination, pageSize: s })}
            />
        </div>
    );
}

export default UserHistoricHoldings;
