//The jsxRuntime pragma is necessary to get Emotion and React to work w/ latest Create-React-App version
/** @jsxRuntime classic */
/** @jsx jsx */
import styled from "@emotion/styled";

export const StyledPageTitle = styled.p`
    font-family: "Lato", san-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    line-height: 19px;
    color: #15357a;
`;
