import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IRestrictionGroup, IRestrictionGroupMember, restrictionService } from "../../services/restrictionService";
import 'react-toastify/dist/ReactToastify.css';

const initialState: {
    groups: IRestrictionGroup[];
    groupPaging: { hasNext: boolean };
    members: IRestrictionGroupMember[];
    memberPaging: { hasNext: boolean };
    status: string;
    loaded: boolean;
} = {
    groups: [],
    groupPaging: { hasNext: false },
    members: [],
    memberPaging: { hasNext: false },
    status: "idle",
    loaded: false,
};

export const queryRestrictionGroups = createAsyncThunk(
    "restrictionGroups/queryRestrictionGroups",
    async (payload: { filterType?: string, filter?: string, page: number, pageSize: number, editable?: boolean }) => {
        const { filterType, filter, page, pageSize, editable } = payload
        return await restrictionService.getRestrictionGroups(page, pageSize, filter, filterType, editable);
    }
);

export const queryRestrictionGroup = createAsyncThunk(
    "restrictionGroups/queryRestrictionGroup",
    async (payload: { groupId: string }) => {
        const { groupId } = payload
        return await restrictionService.getRestrictionGroup(groupId);
    }
);

export const saveNewRestrictionGroup = createAsyncThunk(
    "restrictionGroups/saveRestrictionGroup",
    async (payload: { name: string }) => {
        const { name } = payload
        return await restrictionService.saveRestrictionGroup(name);
    }
);

export const queryRestrictionGroupMembers = createAsyncThunk(
    "restrictionGroups/queryRestrictionGroupMembers",
    async (payload: { groupId: string, filter?: string, page: number, pageSize: number }) => {
        const { groupId, filter, page, pageSize } = payload
        return await restrictionService.getRestrictionGroupMembers(groupId, page, pageSize, filter)
    }
);

export const saveNewRestrictionGroupMember = createAsyncThunk(
    "restrictionGroups/saveNewRestrictionGroupMember",
    async (payload: { groupId: string, userId: string }, { dispatch }) => {
        const { groupId, userId } = payload
        const res = await restrictionService.saveNewRestrictionGroupMember(groupId, userId)

        dispatch(queryRestrictionGroup({ groupId }))

        return res
    }
);

export const deleteRestrictionGroupMember = createAsyncThunk(
    "restrictionGroups/deleteRestrictionGroupMember",
    async (payload: { groupId: string, userId: string }, { dispatch }) => {
        const { groupId, userId } = payload
        const res = await restrictionService.removeRestrictionGroupMember(groupId, userId);

        dispatch(queryRestrictionGroup({ groupId }))

        return res
    }
);

const restrictionGroupMembersSlice = createSlice({
    name: "restrictionGroups",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(queryRestrictionGroups.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(queryRestrictionGroups.fulfilled, (state, action) => {
                state.status = "idle";
                state.loaded = true;
                state.groups = action.payload.restrictionGroup
                state.groupPaging = action.payload.paging ? action.payload.paging : { hasNext: false }
            })
            .addCase(queryRestrictionGroup.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(queryRestrictionGroup.fulfilled, (state, action) => {
                state.status = "idle";
                state.loaded = true;
                if (!state.groups.some((g) => {
                    if (g.id == action.payload.id) {
                        const t = g as any
                        const s = action.payload as any
                        for (const k in s) {
                            t[k] = s[k]
                        }
                        return true
                    }
                })) {
                    state.groups.splice(0, 0, action.payload)
                }
            })
            .addCase(saveNewRestrictionGroup.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(saveNewRestrictionGroup.fulfilled, (state, action) => {
                state.status = "idle";
                state.groups.splice(0, 0, action.payload.restrictionGroup)
            })
            .addCase(queryRestrictionGroupMembers.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(queryRestrictionGroupMembers.fulfilled, (state, action) => {
                state.status = "idle";
                state.loaded = true;
                state.members = action.payload.data
                state.memberPaging = action.payload.paging
            })
            .addCase(saveNewRestrictionGroupMember.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(saveNewRestrictionGroupMember.fulfilled, (state, action) => {
                state.status = "idle";
                if (!state.members.some((m) => {
                    return m.id == action.payload.id
                })) {
                    state.members.splice(0, 0, action.payload)
                }
            })
            .addCase(deleteRestrictionGroupMember.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(deleteRestrictionGroupMember.fulfilled, (state, action) => {
                state.status = "idle";
                if (action.payload) {
                    state.members = state.members.filter(m => m.id != action.payload)
                }
            })
    },
});

/* Selector for state.entities array */
export const selectRestrictionGroups = (state: any) => {
    return state.insiderManagement.groups as IRestrictionGroup[];
}
export const selectRestrictionGroupPaging = (state: any) => {
    return state.insiderManagement.groupPaging as { hasNext: boolean };
}
export const selectRestrictionGroupMembers = (state: any) => {
    return state.insiderManagement.members as IRestrictionGroupMember[];
}
export const selectRestrictionGroupMemberPaging = (state: any) => {
    return state.insiderManagement.memberPaging as { hasNext: boolean };
}

export default restrictionGroupMembersSlice.reducer;
