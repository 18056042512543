import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StyledSearchInput } from "../components/StyledFilterInput";
import AdminList, { Version } from "./AdminList";
import { StyledSelectField } from "../components/StyledSelectField";
import {
    deleteRestriction,
    fetchRestrictionGroups,
    fetchRestrictions,
    selectRules,
    selectRestrictionGroups,
} from "./RestrictionSlice";
import { StyledSpacing } from "../components/StyledSpacing";
import { StyledLabel } from "../components/StyledLabel";
import TablePageFooter, { DefaultPagination } from "../components/TablePageFooter";
import { ISorting } from "../dashboard/DashboardSlice";
import handleDownload from "../util/downloadCsv";
import { restrictionService } from "../services/restrictionService";
import { StyledSectionHeader } from "../components/StyledSectionHeader";
import RestrictionInput from "./RestrictionInput";
import { StyledActionButton } from "../components/StyledActionButton";
import redcross from "../assets/redcross.svg";
import { StyledFilterValue } from "../components/StyledFilterValue";
import { IPagination } from "../util/pagination";

function AdminRules(): JSX.Element {
    const rules = useSelector(selectRules);
    const restrictionGroups = useSelector(selectRestrictionGroups);

    const defaultFilter = {
        name: "",
        activityCategory: "ALL",
        restrictionDate: new Date(new Date().getTime() + 1000 * 60).toISOString(),
    };
    const [filter, setFilter] = useState(defaultFilter) as any;
    const [dFilter, setDFilter] = useState(defaultFilter) as any;

    const [pagination, setPagination] = useState<IPagination>(DefaultPagination());
    const nextPage = useSelector((state: any) => state.restriction.rule.nextPage);
    const [showPagination, _] = useState(true);
    const [activePaginationType, setActivePaginationType] = useState("Pagination");
    const [startSearch, setStartSearch] = useState("");
    const [endSearch, setEndSearch] = useState("");
    const [rangeBasedFiltering, setRangeBasedFiltering] = useState(false);
    const [activeRestrictedFrom, setActiveRestrictedFrom] = useState<
        string | undefined
    >(undefined);

    const [sorting, setSorting] = useState<ISorting>({
        field: "ticker",
        direction: "asc",
    });
    const sortingByTicker: ISorting[] = useMemo(() => {
        if (sorting.field === "ticker") return [sorting];
        return [sorting, { field: "ticker", direction: "asc" }];
    }, [sorting]);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            fetchRestrictions({
                pagination,
                filter: {
                    ...filter,
                },
                sorting: sortingByTicker,
            })
        );
        
    }, [
        dispatch,
        pagination,
        filter,
        sortingByTicker,
    ]);

    useEffect(() => {
        dispatch(fetchRestrictionGroups());
    }, [
        dispatch,
    ]);

    const removeRule = (id: string) => {
        dispatch(deleteRestriction(id));
        setFilter({ ...filter, restrictionDate: new Date().toISOString() });
        dispatch(
            fetchRestrictions({
                pagination,
                filter: {
                    ...filter,
                    restrictionDate: new Date(
                        new Date().getTime() + 1000 * 60
                    ).toISOString(),
                },
                sorting: sortingByTicker,
            })
        );
        return true;
    };

    const handlePaginationTypeChange = (event: any) => {
        setActivePaginationType(event.target.value);
        if (activePaginationType !== event.target.value) {
            if (event.target.value === "NOT_PAGINATED") {
                setPagination({
                    ...pagination,
                    pageNumber: -1,
                });
            } else if (event.target.value === "PAGINATED") {
                setPagination({
                    ...pagination,
                    pageNumber: 1,
                });
            }
        }
        //
    };

    const handleSearch = (e: any) => {
        if (e.key === "Enter") {
            setFilter({
                name: e.target.value,
                activityCategory: "ALL",
                restrictionDate: new Date().toISOString(),
            });
            setPagination({
                ...pagination,
                pageNumber: 1,
            });
        }
    };

    const resetFilter = () => {
        setDFilter(defaultFilter);
        setStartSearch("");
        setEndSearch("");
        setActiveRestrictedFrom(undefined);
    };

    function handleRangeSearch() {
        if (startSearch !== "") {
            setDFilter({
                activityCategory: "ALL",
                startDate: startSearch,
            });
            setRangeBasedFiltering(false);
        }
        if (startSearch !== "" && endSearch !== "") {
            setDFilter({
                activityCategory: "ALL",
                startDate: startSearch,
                endDate: endSearch,
            });
            setRangeBasedFiltering(false);
        }
    }

    return (
        <>
            <StyledSpacing>
                <StyledSectionHeader>Add a single restriction</StyledSectionHeader>
                <RestrictionInput />
            </StyledSpacing>
            <div>
                <StyledSearchInput
                    placeholder="Search Restrictions"
                    type="text"
                    onKeyDown={handleSearch}
                />
                <div>
                    {showPagination && (
                        <div style={{ display: "contents" }}>
                            <div style={{ width: "15%" }}>
                                <StyledLabel> Pagination </StyledLabel>
                            </div>
                            <StyledSelectField
                                style={
                                    activePaginationType === "Pagination"
                                        ? { color: "#2C71F0", width: "20%" }
                                        : { width: "20%" }
                                }
                                value={activePaginationType}
                                onChange={(event) => handlePaginationTypeChange(event)}
                            >
                                <option key={1} value={"PAGINATED"}>
                                    Paginated
                                </option>
                                <option key={2} value={"NOT_PAGINATED"}>
                                    Show All Records
                                </option>
                            </StyledSelectField>
                        </div>
                    )}

                    <div>
                        <div
                            onClick={() => {
                                handleDownload(
                                    restrictionService.getRestrictionsDownload(
                                        dFilter,
                                        [sorting]
                                    ),
                                    "restrictions"
                                );
                            }}
                            style={{
                                textAlign: "right",
                                fontSize: "15px",
                                textDecoration: "underline",
                                color: "#2c71f0",
                                cursor: "pointer",
                                float: "right",
                                width: "fit-content",
                                marginLeft: "10px",
                                marginTop: "10px",
                            }}
                        >
                            Download restrictions
                        </div>
                        {!rangeBasedFiltering ? (
                            <>
                                <div
                                    onClick={() => setRangeBasedFiltering(true)}
                                    style={{
                                        textAlign: "right",
                                        fontSize: "15px",
                                        textDecoration: "underline",
                                        color: "#2c71f0",
                                        cursor: "pointer",
                                        float: "right",
                                        width: "fit-content",
                                        marginTop: "10px",
                                        marginLeft: "13px",
                                    }}
                                >
                                    Set dates for restriction download
                                </div>
                                <>
                                    {dFilter["startDate"] || dFilter["endDate"] ? (
                                        <StyledFilterValue
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                height: "2.5em",
                                                marginLeft: "auto",
                                                width: "fit-content",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    marginLeft: "0.5em",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                }}
                                            >
                                                <span>{"Date: "}</span>
                                                <span>{`${dFilter["startDate"]}  ${
                                                    dFilter["endDate"]
                                                        ? `- ${dFilter["endDate"]}`
                                                        : " "
                                                } `}</span>
                                            </span>
                                            <img
                                                style={{
                                                    marginRight: "0.5em",
                                                    cursor: "pointer",
                                                    marginLeft: "0.5em",
                                                }}
                                                src={redcross}
                                                alt=""
                                                onClick={() => resetFilter()}
                                            />
                                        </StyledFilterValue>
                                    ) : null}
                                </>
                            </>
                        ) : (
                            <>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        width: "50%",

                                        marginLeft: "auto",
                                        marginTop: "0",
                                    }}
                                >
                                    <StyledSearchInput
                                        placeholder="Start Date"
                                        type={"date"}
                                        value={startSearch}
                                        onChange={(event) =>
                                            setStartSearch(event.target.value)
                                        }
                                        style={{ display: "flex", width: "35%" }}
                                    />
                                    &nbsp;_&nbsp;
                                    <StyledSearchInput
                                        placeholder="End Date (Optional)"
                                        onFocus={() => {
                                            if (activeRestrictedFrom === undefined) {
                                                setActiveRestrictedFrom("");
                                            }
                                        }}
                                        onBlur={() => {
                                            if (activeRestrictedFrom === "") {
                                                setActiveRestrictedFrom(undefined);
                                            }
                                        }}
                                        type={
                                            activeRestrictedFrom === undefined
                                                ? "text"
                                                : "date"
                                        }
                                        value={endSearch}
                                        onChange={(event) =>
                                            setEndSearch(event.target.value)
                                        }
                                        style={{ display: "flex", width: "35%" }}
                                    />
                                    &nbsp;&nbsp;
                                    <StyledActionButton
                                        onClick={handleRangeSearch}
                                        style={{ width: "20%" }}
                                    >
                                        Enter
                                    </StyledActionButton>
                                    <div
                                        onClick={() => setRangeBasedFiltering(false)}
                                        style={{
                                            textAlign: "center",
                                            marginTop: "auto",
                                            fontSize: "12px",
                                            textDecoration: "underline",
                                            color: "#2c71f0",
                                            cursor: "pointer",
                                        }}
                                    >
                                        Cancel Filter
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <AdminList
                    entities={rules}
                    handleDelete={removeRule}
                    type={Version.ALL}
                    sorting={sorting}
                    setSorting={setSorting}
                />
                {pagination.pageNumber >= 1 && (
                    <TablePageFooter
                        nextPage={nextPage}
                        pagination={pagination}
                        setPageNumber={(n) =>
                            setPagination({ ...pagination, pageNumber: n })
                        }
                        setPageSize={(s) =>
                            setPagination({ ...pagination, pageSize: s })
                        }
                    />
                )}
            </div>
        </>
    );
}

export default AdminRules;
