//The jsxRuntime pragma is necessary to get Emotion and React to work w/ latest Create-React-App version
/** @jsxRuntime classic */
/** @jsx jsx */
import styled from "@emotion/styled";

export const StyledBubbleSpan = styled.span`
    border-radius: 101px;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    text-align: center;
    white-space: nowrap;
    color: #ffffff;
`;
