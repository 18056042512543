import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { globalRestrictionService } from "../services/globalRestrictionService";

const initialState: {
    policy: string
    loaded: boolean
} = {
    policy: "",
    loaded: false
};

export const fetchPolicy = createAsyncThunk("globalrestriction/fetchPolicy", async () => {
    return await globalRestrictionService.getPolicy()
})


const globalRestrictionSlice = createSlice({
    name: "globalrestriction",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPolicy.pending, (state) => {
                state.loaded = false
                state.policy = ""
            })
            .addCase(fetchPolicy.fulfilled, (state, action: any) => {
                if (!action.payload) {
                    return;
                }

                state.loaded = true
                state.policy = action.payload
            })
            .addCase(fetchPolicy.rejected, (state, action) => {
                state.loaded = true
                state.policy = "Failed to load global restriction policy"
            })
    },
});

export const selectPolicy = (state: any): string => state.globalrestriction.policy;

export default globalRestrictionSlice.reducer;
