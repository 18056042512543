// import React from 'react';
// import Navbar from '../nav/Navbar';
import { Container, Row } from 'react-bootstrap';

export default function SessionLayout({ children }: any){
    return(
        <>
            <Container fluid={true} style={{ paddingLeft: 0, paddingRight: 0 }}>
                <Row noGutters={true}>
                    {children}  
                </Row>
            </Container>
        </>
    )
};