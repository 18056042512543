import { authService } from "./authService";

export interface IGlobalRestriction { policy: string }

export const globalRestrictionService = {
    getPolicy
};


const baseUrl = process.env.REACT_APP_API_ENDPOINT;
const baseUrlGlobalRestrictions = `${baseUrl}/global-restrictions`;

function getPolicy(): Promise<string> {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
            "Content-Type": "application/json",
        },
        body: "{}",
    };

    return fetch(`${baseUrlGlobalRestrictions}/policy/get`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse)
		.then(data => data.policy)
}

function handleResponse(response: any) {
    return response.text().then((text: any) => {
        const data = text && JSON.parse(text);
        return data;
    });
}

export default handleResponse;
