import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ISorting } from "../dashboard/DashboardSlice";
import { IFilter, transactionsService } from "../services/transactionsService";
import { IPagination } from "../util/pagination";

export interface ITransactionAccount {
    accountName: string;
    accountHolder: string;
}

export interface ITransactionUser {
    id: string;
    name: string;
}

export interface ITransactionSecurity {
    name: string;
    ticker: string;
    platform?: string;
    cryptocurrencyType?: string;
}

export interface IHistoricTrade {
    category: string;
    id: string;
    employee: string;
    user: ITransactionUser;
    security: ITransactionSecurity;
    account: ITransactionAccount;
    transactionType: string;
    transactionSubtype: string;
    brokerage: string;
    volume: string;
    price: number;
    currency: string;
    permissionStatus: string;
    transactionDate: string;
    lastUpdated: string;
    userNotes: JSON;
}

export interface IHistoricTrades {
    transactions: Array<IHistoricTrade>;
    userTransactions: Array<IHistoricTrade>;
    status: string;
    nextPage: boolean;
}

const initialState: IHistoricTrades = {
    transactions: [],
    userTransactions: [],
    nextPage: false,
    status: "idle",
};

const SECURITY_CATEGORY = "SECURITY";

export const fetchTransactions = createAsyncThunk(
    "historicTrades/fetchTransactions",
    async (payload: {
        pagination: IPagination;
        filter: IFilter;
        sorting: ISorting[];
    }) => {
        const { pagination, filter, sorting } = payload;

        const transactions = await transactionsService.getTransactions(
            pagination,
            filter,
            sorting,
            SECURITY_CATEGORY
        );
        /* Handle errors */

        return transactions;
    }
);

export const fetchUserTransactions = createAsyncThunk(
    "historicTrades/fetchUserTransactions",
    async (payload: {
        pagination: IPagination;
        filter: IFilter;
        sorting: ISorting[];
    }) => {
        const { pagination, filter, sorting } = payload;
        const userTransactions = await transactionsService.getUserTransactions(
            pagination,
            filter,
            sorting,
            SECURITY_CATEGORY
        );
        return userTransactions;
    }
);

const historicTradesSlice = createSlice({
    name: "historicTrades",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTransactions.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(fetchTransactions.fulfilled, (state, action) => {
                state.transactions = action.payload.data;
                state.nextPage = action.payload.nextPage;
                state.status = "idle";
            })
            .addCase(fetchUserTransactions.fulfilled, (state, action) => {
                state.userTransactions = action.payload.data;
                state.nextPage = action.payload.nextPage;
                state.status = "idle";
            });
    },
});

/* Selector for state.watched array*/
export const selectTransactions = (state: any) => state.historicTrades.transactions;
export const selectUserTransactions = (state: any) =>
    state.historicTrades.userTransactions;

export default historicTradesSlice.reducer;
