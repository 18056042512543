import { authService } from "./authService";
import { AmlType, InsiderType, IRelativeSorting, ISorting } from "../analytics/AnalyticsSlice";
import { IFilter } from "../analytics/AnalyticsSlice";

export const analyticsService = {
    // abnormal gain
    getInsiderTradings,
    getInsiderTradingRelated,
    flagInsiderTrading,
    reviewInsiderTrading,
    complianceInsiderTrading,

    // aml
    getAmlRecords,
    getAmlRelated,
    flagAmlRecord,
    reviewAmlRecord,
    complianceAmlRecord,

    // suspicious wallet
    getSuspiciousWallets,
    getSuspiciousWalletRelated,
    flagSuspiciousWallet,
    reviewSuspiciousWallet,
    complianceSuspiciousWallet,
    whitelistingSuspiciousWallet,
};

const base = process.env.REACT_APP_API_ENDPOINT;
const baseUrl = `${base}/analytics`;

/*
// frequent trading
// function getFrequentTradings(
//     filter: IFilter,
//     sorting: ISorting[],
//     pageNumber: number,
//     pageSize: number
// ): any {
//     let bodyObj: any = {
//         ...filter,
//         sorting: sorting,
//         pageNumber: pageNumber,
//         pageSize: pageSize,
//     };

//     const requestParams = {
//         method: "POST",
//         headers: {
//             "Content-Type": "application/json",
//             ...authService.authHeader(),
//         },
//         body: JSON.stringify(bodyObj),
//     };

//     return fetch(`${baseUrl}/frequent-tradings`, requestParams)
//         .then(authService.handleErrors)
//         .then(handleResponse);
// }

// function flagFrequentTradings(id: string): any {
//     const requestParams = {
//         method: "PUT",
//         headers: {
//             "Content-Type": "application/json",
//             ...authService.authHeader(),
//         },
//     };

//     return fetch(`${baseUrl}/frequent-tradings/flag/${id}`, requestParams)
//         .then(authService.handleErrors)
//         .then(handleResponse);
// }

// function commentFrequentTradings(id: string, comment: string): any {
//     let bodyObj: any = {
//         comment: comment,
//     };

//     const requestParams = {
//         method: "PUT",
//         headers: {
//             "Content-Type": "application/json",
//             ...authService.authHeader(),
//         },
//         body: JSON.stringify(bodyObj),
//     };

//     console.log(id, comment);

//     return fetch(`${baseUrl}/frequent-tradings/comment/${id}`, requestParams)
//         .then(authService.handleErrors)
//         .then(handleResponse);
// }
*/

// insider tradings
function getInsiderTradings(
    filter: IFilter,
    sorting: ISorting[],
    pageNumber: number,
    pageSize: number
): any {
    let bodyObj: any = {
        ...filter,
        sorting: sorting,
        pageNumber: pageNumber,
        pageSize: pageSize,
    };

    const requestParams = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            ...authService.authHeader(),
        },
        body: JSON.stringify(bodyObj),
    };

    return fetch(`${baseUrl}/insider-tradings`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

function getInsiderTradingRelated(
    type: InsiderType,
    id: string,
    sorting: IRelativeSorting[],
    pageNumber: number,
    pageSize: number
): any {
    let bodyObj: any = {
        type: type,
        sorting: sorting,
        pageNumber: pageNumber,
        pageSize: pageSize,
    };

    const requestParams = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            ...authService.authHeader(),
        },
        body: JSON.stringify(bodyObj),
    };

    return fetch(`${baseUrl}/related_records/insider-tradings/${id}`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

function flagInsiderTrading(id: string, type: InsiderType): any {
    let bodyObj: any = {
        id: id,
        type: type,
    };

    const requestParams = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            ...authService.authHeader(),
        },
        body: JSON.stringify(bodyObj),
    };

    return fetch(`${baseUrl}/insider-tradings/flag`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

function reviewInsiderTrading(id: string, type: InsiderType): any {
    let bodyObj: any = {
        id: id,
        type: type,
    };

    const requestParams = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            ...authService.authHeader(),
        },
        body: JSON.stringify(bodyObj),
    };

    return fetch(`${baseUrl}/insider-tradings/review`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

function complianceInsiderTrading(
    id: string,
    type: InsiderType,
    comment: string
): any {
    let bodyObj: any = {
        id: id,
        type: type,
        comment: comment,
    };

    const requestParams = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            ...authService.authHeader(),
        },
        body: JSON.stringify(bodyObj),
    };

    return fetch(`${baseUrl}/insider-tradings/compliance`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

// AML
function getAmlRecords(
    filter: IFilter,
    sorting: ISorting[],
    pageNumber: number,
    pageSize: number
): any {
    let bodyObj: any = {
        ...filter,
        nonFlag: false,
        sorting: sorting,
        pageNumber: pageNumber,
        pageSize: pageSize,
    };

    const requestParams = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            ...authService.authHeader(),
        },
        body: JSON.stringify(bodyObj),
    };

    return fetch(`${baseUrl}/aml`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

function getAmlRelated(
    type: AmlType,
    id: string,
    sorting: IRelativeSorting[],
    pageNumber: number,
    pageSize: number
): any {
    let bodyObj: any = {
        type: type,
        sorting: sorting,
        pageNumber: pageNumber,
        pageSize: pageSize,
    };

    const requestParams = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            ...authService.authHeader(),
        },
        body: JSON.stringify(bodyObj),
    };

    return fetch(`${baseUrl}/related_records/aml/${id}`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

function flagAmlRecord(id: string, type: AmlType): any {
    let bodyObj: any = {
        id: id,
        type: type,
    };

    const requestParams = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            ...authService.authHeader(),
        },
        body: JSON.stringify(bodyObj),
    };

    return fetch(`${baseUrl}/aml/flag`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

function reviewAmlRecord(id: string, type: AmlType): any {
    let bodyObj: any = {
        id: id,
        type: type,
    };

    const requestParams = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            ...authService.authHeader(),
        },
        body: JSON.stringify(bodyObj),
    };

    return fetch(`${baseUrl}/aml/review`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

function complianceAmlRecord(id: string, type: AmlType, comment: string): any {
    let bodyObj: any = {
        id: id,
        type: type,
        comment: comment,
    };

    const requestParams = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            ...authService.authHeader(),
        },
        body: JSON.stringify(bodyObj),
    };

    return fetch(`${baseUrl}/aml/compliance`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

// Suspicious Wallet
function getSuspiciousWallets(
    filter: IFilter,
    sorting: ISorting[],
    pageNumber: number,
    pageSize: number
): any {
    let bodyObj: any = {
        ...filter,
        sorting: sorting,
        pageNumber: pageNumber,
        pageSize: pageSize,
    };

    const requestParams = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            ...authService.authHeader(),
        },
        body: JSON.stringify(bodyObj),
    };

    return fetch(`${baseUrl}/wallet`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

function getSuspiciousWalletRelated(
    address: string,
    cryptoId: string,
    sorting: IRelativeSorting[],
    pageNumber: number,
    pageSize: number
): any {
    let bodyObj: any = {
        sorting: sorting,
        pageNumber: pageNumber,
        pageSize: pageSize,
    };

    const requestParams = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            ...authService.authHeader(),
        },
        body: JSON.stringify(bodyObj),
    };

    return fetch(
        `${baseUrl}/related_records/wallet/${cryptoId}/${address}`,
        requestParams
    )
        .then(authService.handleErrors)
        .then(handleResponse);
}

function flagSuspiciousWallet(address: string, cryptoId: string): any {
    let bodyObj: any = {
        address: address,
        cryptoId: cryptoId,
    };

    const requestParams = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            ...authService.authHeader(),
        },
        body: JSON.stringify(bodyObj),
    };

    return fetch(`${baseUrl}/wallet/flag`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

function reviewSuspiciousWallet(address: string, cryptoId: string): any {
    let bodyObj: any = {
        address: address,
        cryptoId: cryptoId,
    };

    const requestParams = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            ...authService.authHeader(),
        },
        body: JSON.stringify(bodyObj),
    };

    return fetch(`${baseUrl}/wallet/review`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

function whitelistingSuspiciousWallet(address: string, cryptoId: string): any {
    let bodyObj: any = {
        address: address,
        cryptoId: cryptoId,
    };

    const requestParams = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            ...authService.authHeader(),
        },
        body: JSON.stringify(bodyObj),
    };

    return fetch(`${baseUrl}/wallet/whitelist`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

function complianceSuspiciousWallet(
    address: string,
    cryptoId: string,
    comment: string
): any {
    let bodyObj: any = {
        comment: comment,
        address: address,
        cryptoId: cryptoId,
    };

    const requestParams = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            ...authService.authHeader(),
        },
        body: JSON.stringify(bodyObj),
    };

    return fetch(`${baseUrl}/wallet/compliance`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

function handleResponse(response: any) {
    return response.text().then((text: any) => {
        return text && JSON.parse(text);
    });
}
