import { StyledTableContainer } from "../components/StyledTable";
import { IWatchCrypto } from "./WatchlistSlice";
import { useState } from "react";
import WatchlistCryptoListItem from "./WatchlistCryptoListItem";
import { FieldType, getCompare } from "../util/compareUtil";
import SelectableTableHeader from "../components/SelectableTableHeader";

/* Type for the WatchlistList props */
interface IListProps {
    pageSize?: number; //How many stocks to display, mark as optional
    start?: number; //What is our starting offset, mark as optional
    watchedCrypto: Array<IWatchCrypto>;
    activeFilter: string;
    handleDelete(ticker: string, unit: string): void;
    setShowModal(show: boolean, ticker: string, name: string, securityId: string): void;
}

const fieldMap: any = {
    ticker: FieldType.String,
    exchange: FieldType.String,
    price: FieldType.Float,
    status: FieldType.String,
};

const headerData = [
    { fieldText: "Ticker", fieldValue: "ticker" },
    { fieldText: "Exchange", fieldValue: "exchange" },
    { fieldText: "Price", fieldValue: "price" },
    { fieldText: "Status", fieldValue: "status" },
];

function WatchlistList(props: IListProps): JSX.Element {
    /* Provide default values and replace them with the prop values if there are any. */
    const { pageSize = 100, start = 0, watchedCrypto, activeFilter } = props;

    const [asc, setAsc] = useState(true);
    const [activeCompare, setActiveCompare] = useState("ticker");

    const compare = getCompare<IWatchCrypto>(
        asc,
        activeCompare,
        fieldMap[activeCompare]
    );

    return (
        <StyledTableContainer>
            <table>
                <thead>
                    <tr>
                        {headerData.map((header, index) => {
                            return (
                                <SelectableTableHeader
                                    key={index}
                                    targetCompare={header.fieldValue}
                                    activeCompare={activeCompare}
                                    setActiveCompare={setActiveCompare}
                                    asc={asc}
                                    setAsc={setAsc}
                                >
                                    {header.fieldText}
                                </SelectableTableHeader>
                            );
                        })}
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {/* Generate the AdminListItems and add them to the ul */}
                    {/* 
                        1. Filter by the activeFilter, show everything if empty, matching names and tickers if not.
                        2. Slice to show only show pageSize number of results (defaults to 100)
                        3. Map the entities array to AdminListItem components
                        */}
                    {watchedCrypto
                        .filter((watch: IWatchCrypto) => {
                            return (
                                watch.ticker
                                    .toLowerCase()
                                    .includes(activeFilter.toLowerCase()) ||
                                watch.exchange
                                    .toLowerCase()
                                    .includes(activeFilter.toLowerCase())
                            );
                        })
                        .slice(start, start + pageSize)
                        .sort(compare)
                        .map((watch: IWatchCrypto, index: number) => (
                            <WatchlistCryptoListItem
                                ticker={watch.ticker}
                                name={watch.name}
                                price={watch.price}
                                unit={watch.unit}
                                movement={watch.movement}
                                exchange={watch.exchange}
                                status={watch.status}
                                handleDelete={props.handleDelete}
                                setShowModal={props.setShowModal}
                                key={index}
                            />
                        ))}
                </tbody>
            </table>
        </StyledTableContainer>
    );
}
export default WatchlistList;
