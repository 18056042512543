import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectVersion } from "../auth/AuthSlice";
import AML from "./aml/AML";
import InsiderTradings from "./insider/InsiderTradings";
import SuspiciousWallets from "./wallets/SuspiciousWallets";
import "./styles/table.css";
import { StyledBrokerageDiv } from "../components/StyledBrokerageDiv";
import { StyledSectionHeader } from "../components/StyledSectionHeader";

function Analytics(): JSX.Element {
    const version = useSelector(selectVersion);
    const showCryptoRelated = useMemo(
        () => version === "crypto_base" || version === "crossover_base",
        [version]
    );
    const divider = (
        <div
            style={{
                borderBottom: "dashed 1px #75a4fa",
                margin: 10,
                marginBottom: 20,
            }}
        ></div>
    );

    return (
        <div className="inspect">
            <StyledBrokerageDiv>
                <StyledSectionHeader>Insider Trading</StyledSectionHeader>
                <InsiderTradings />
            </StyledBrokerageDiv>
            {divider}
            <StyledBrokerageDiv>
                <StyledSectionHeader>AML</StyledSectionHeader>
                <AML />
            </StyledBrokerageDiv>
            {showCryptoRelated && (
                <>
                    {divider}
                    <StyledBrokerageDiv>
                        <StyledSectionHeader>Undeclared Wallet</StyledSectionHeader>
                        <SuspiciousWallets />
                    </StyledBrokerageDiv>
                </>
            )}
        </div>
    );
}

export default Analytics;
