import FiatInput from "../../components/FiatInput/FiatInput";
import { StyledInputField } from "../../components/StyledInputField";
import { StyledModalItem } from "../../components/StyledModalItem";
import ModalLabel from "../ModalLabel";
import { Version } from "./QuantityInput";


export interface IVolumeInputProps {
    amount: string,
    setAmount(amount: string): void,
    showErrors: boolean,
    version: "SECURITY" | "CRYPTO" | "ALL",
    setComplete(complete: boolean): void,
    currency: string,
    setCurrency(currency: string): void
    error?: string,
    onChange(e: any): void,
    name: string,
}


function AmountInput({ amount, setAmount, showErrors, version, setComplete, currency, setCurrency, error, onChange, name }: IVolumeInputProps): JSX.Element {


    return (
        <>
            <StyledModalItem>
                <ModalLabel>
                    Enter Value of Trade:
                </ModalLabel>
                <FiatInput
                    style={{ width: "50%" }}
                    placeholder="Enter estimated value of trade"
                    showError={showErrors}
                    value={amount}
                    setValue={setAmount}
                    currency={currency}
                    setCurrency={setCurrency}
                    name={name}
                    onChange={(event) => {
                        onChange(event);
                    }}
                />
                {showErrors && error && (
                    <p style={{ margin: "0 0 10px 0", color: "red" }}>
                        {error}
                    </p>
                )}
            </StyledModalItem>
        </>
    )
}

export default AmountInput;
