import { useMemo, } from "react";
import { walletService } from "../services/walletService";
import DynamicAutoComplete, { IDynamicAutoComplete } from "./DynamicAutoComplete";

function WalletTypeAutoComplete(props: IDynamicAutoComplete<string>): JSX.Element {
    const walletTypes = [
        "Airbitz Wallet",
        "Anchorage",
        "ArcBit",
        "Argent",
        "Armory Wallet",
        "Atomic Wallet",
        "BC Vault",
        "BitFreezer",
        "Block.io Wallet",
        "Blockstack",
        "Blue Wallet App",
        "Brave Wallet",
        "Bread Wallet",
        "Casa",
        "Coinbase Wallet",
        "Coinomi",
        "Coldcard",
        "CryptX Wallet",
        "Crypterium",
        "Crypvestor",
        "Daedalus",
        "Electrum Wallet",
        "Ellipal Titan",
        "Exodus",
        "Guarda Wallet",
        "IndieSquare",
        "Infinito Wallet",
        "Infinity Wallet",
        "Jaxx",
        "Keplr",
        "Ledger Nano X",
        "Ledger Nano S",
        "Lumi Wallet",
        "Metamask",
        "MyEtherWallet",
        "Mist",
        "Muun",
        "Mycelium",
        "Nexus Wallet",
        "OmniWallet",
        "Phantom",
        "Poolin Wallet",
        "Rainbow",
        "SafePal S1",
        "Self Custody Wallet",
        "Terra Station",
        "Trezor Wallet",
        "Trust Wallet",
        "USDX Wallet",
        "Venly Wallet",
        "Wriex Wallet",
        "XETH Ether Wallet",
        "Yoroi",
        "ZenGo",
        "imToken"
    ]

    const formatAutoComplete = useMemo(() => {
        return (suggestion: string) => suggestion
    }, [])

    const autoComplete = useMemo(() => {
        return async (input: string) => {
            const response = await walletService.autocompleteWalletTypes(input)
            return response.data
        }
    }, [])

    return (
        <DynamicAutoComplete<string> populatedSuggestions={walletTypes} {...props} formatAutoComplete={formatAutoComplete} autoComplete={autoComplete} />
    );
}

export default WalletTypeAutoComplete;