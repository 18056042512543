import { BiX } from "react-icons/bi";
import { StyledModalBackground } from "./StyledModalBackground";
import { StyledModalBody } from "./StyledModalBody";

function Modal({
    children,
    show,
    onClose,
    title,
    width = "50%",
    height = "96%",
    marginLeft = "25%",
    marginTop = "1%",
    backgroundStyle = {},
    modalStyle = {
        width,
        height,
        marginLeft,
        marginTop
    },
    ...rest
}: any) {
    return show ? (
        <StyledModalBackground
            style={backgroundStyle}
            onClick={(event) => {
                onClose();
            }}
        >
            <StyledModalBody
                style={modalStyle}
                onClick={(event) => {
                    event.stopPropagation();
                }}
            >
                <div
                    style={{
                        width: "100%",
                        height: "50px",
                        background: "#2C71F0",
                        color: "#FFFFFF",
                        fontWeight: "bold",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        boxSizing: "border-box",
                        borderTopRightRadius: "5px",
                        borderTopLeftRadius: "5px",
                        zIndex: 1,
                    }}
                >
                    {title}
                    <BiX
                        onClick={(event) => {
                            onClose();
                        }}
                        style={{
                            position: "absolute",
                            right: "10px",
                            cursor: "pointer",
                        }}
                    />
                </div>
                {children}
            </StyledModalBody>
        </StyledModalBackground>
    ) : null;
}

export default Modal;