import { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers, selectUsers } from "../../users/UserPanelSlice";
import TablePageFooter, { DefaultPagination } from "../../components/TablePageFooter";
import { ISorting } from "../../dashboard/DashboardSlice";
import AdminAccountsList from "./AdminAccountsList";
import { accountsService } from "../../services/accountsService";
import UserAutocomplete, { UserSuggestion } from "../../components/UserAutoComplete";
import { IPagination } from "../../util/pagination";
import { StyledLabel } from "../../components/StyledLabel";
import handleDownload from "../../util/downloadCsv";
import { authService } from "../../services/authService";

function Accounts(): JSX.Element {
    const [filter, setFilter] = useState({ name: "" });
    const [pagination, setPagination] = useState<IPagination>(DefaultPagination());
    const nextPage = useSelector((state: any) => state.userPanel.nextPage);
    const dispatch = useDispatch();
    const users = useSelector(selectUsers);

    const [statementIds, setStatementIds] = useState();

    const [sorting, setSorting] = useState<ISorting>({
        field: "lastUpdated",
        direction: "desc",
    });
    const [isLinked, setIsLinked] = useState(true);

    const sortingByDate: ISorting[] = useMemo(() => {
        if (sorting.field === "lastUpdated") return [sorting];
        return [sorting, { field: "lastUpdated", direction: "desc" }];
    }, [sorting]);

    useEffect(() => {
        dispatch(
            fetchUsers({
                ...pagination,
                filter: {
                    ...filter,
                    userType: "PRIMARY",
                },
                isLinked: isLinked,
                sorting: sortingByDate,
            })
        );
    }, [dispatch, pagination, filter, sortingByDate, isLinked]);

    useEffect(() => {
        const userIds = users.map((user: any) => user.id);
        const fetchData = async () => {
            const data = await accountsService.getStatementIds(userIds);
            const tempStatementIds = data.statement_ids;
            setStatementIds(tempStatementIds);
        };
        fetchData();
    }, [users]);

    const handleSearch = useCallback(
        (user: UserSuggestion) => {
            setFilter({ name: user.name });
            setPagination({
                ...pagination,
                pageNumber: 1,
            });
        },
        [pagination, setPagination, setFilter]
    );

    return (
        <div>
            <div style={{ position: "relative" }}>
                <UserAutocomplete
                    value={""}
                    onSelect={handleSearch}
                    onEnter={handleSearch}
                    style={{ width: "100%" }}
                    placeholder={"Filter by name or email"}
                />
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginBottom: "1%",
                    }}
                >
                    <input
                        onChange={() => setIsLinked(!isLinked)}
                        type="checkbox"
                        id="deleted-users"
                    />
                    <StyledLabel>Show Manual Upload Accounts</StyledLabel>
                </div>
            </div>
            <div
                onClick={() =>
                    handleDownload(
                        authService.getUsersPanelDownload(
                            filter,
                            sortingByDate,
                            isLinked
                        ),
                        "accounts"
                    )
                }
                style={{
                    textAlign: "right",
                    fontSize: "15px",
                    textDecoration: "underline",
                    color: "#2c71f0",
                    cursor: "pointer",
                }}
            >
                Download accounts
            </div>

            <AdminAccountsList
                users={users}
                sorting={sorting}
                setSorting={setSorting}
                statementIds={statementIds}
            />
            <TablePageFooter
                nextPage={nextPage}
                pagination={pagination}
                setPageNumber={(n) => setPagination({ ...pagination, pageNumber: n })}
                setPageSize={(s) => setPagination({ ...pagination, pageSize: s })}
            />
        </div>
    );
}

export default Accounts;
