import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ISorting } from "../dashboard/DashboardSlice";
import { watchlistService } from "../services/watchlistService";
import { IPagination } from "../util/pagination";

export interface IWatch {
    id: number;
    ticker: string;
    name: string;
    movement: number;
    price: string;
    status: string;
    securityId: string;
}

export interface IWatchCrypto {
    id: number;
    ticker: string;
    name: string;
    movement: number;
    price: string;
    status: string;
    cryptoId: number;
    exchange: string;
    unit: string;
}

export interface IWatched {
    /* Note: The id here refers to the restriction id*/
    watched: Array<IWatch>;
    watchedCrypto: Array<IWatchCrypto>;
    status: string;
    nextPage: boolean;
}

export interface IWatchedCrypto {
    /* Note: The id here refers to the restriction id*/
    watched: Array<IWatchCrypto>;
    status: string;
}

const initialState: IWatched = {
    watched: [],
    watchedCrypto: [],
    nextPage: false,
    status: "idle",
};

export interface IFilter {
    name: string;
}

export const fetchWatchedSecurities = createAsyncThunk(
    "watchlist/fetchWatchedSecurities",
    async (payload: { pagination: IPagination; filter: IFilter, sorting: ISorting[] }) => {
        const { pagination, filter, sorting } = payload;
        const res = await watchlistService.getWatchedSecurities(pagination, filter, sorting);
        res.watch = res.data.map(
            (entry: {
                ticker: string;
                id: number;
                name: string;
                securityId: string;
                status: string;
            }) => {
                return {
                    ticker: entry.ticker,
                    name: entry.name,
                    id: entry.id,
                    price: "",
                    movement: 0,
                    status: entry.status,
                    securityId: entry.securityId,
                };
            }
        );
        return res;
    }
);

export const fetchWatchedCryptos = createAsyncThunk(
    "watchlist/fetchWatchedCryptos",
    async (userId: number) => {
        const res = await watchlistService.getWatchedCrypto(userId);

        const mapped = res.watch.map(
            (entry: {
                ticker: string;
                id: number;
                name: string;
                cryptoId: string;
                status: string;
                exchange: string;
                unit: string;
            }) => {
                return {
                    ticker: entry.ticker,
                    name: entry.name,
                    id: entry.id,
                    price: "",
                    movement: 0,
                    status: entry.status,
                    cryptoId: entry.cryptoId,
                    exchange: entry.exchange,
                    unit: entry.unit,
                };
            }
        );

        return mapped;
    }
);

export interface IReq {
    tickers: Array<string>;
}


export const saveNewWatchedSecurity = createAsyncThunk(
    "watchlist/saveNewWatchedSecurity",
    async (payload: { securityId: string }) => {
        const { securityId } = payload; /* Unpack parameters */
        const res = await watchlistService.saveSecurityWatchlist(securityId);
        return res;
    }
);

export const deleteWatchedSecurity = createAsyncThunk(
    "watchlist/deleteWatchedSecurity",
    async (id: number) => {
        watchlistService.deleteSecurityWatchlist(id);
        return id;
    }
);

export const saveNewWatchedCrypto = createAsyncThunk(
    "watchlist/saveNewWatchedCrypto",
    async (payload: {
        userId: number;
        ticker: string;
        name: string;
        exchange: string;
        unit: string;
    }) => {
        const { userId, ticker, name, exchange, unit } =
            payload; /* Unpack parameters */
        const res = watchlistService.saveCryptoWatchlist(
            userId,
            ticker,
            name,
            exchange,
            unit
        );

        return res;
    }
);

export const deleteWatchedCrypto = createAsyncThunk(
    "watchlist/deleteWatchedCrypto",
    async (id: number) => {
        watchlistService.deleteCryptoWatchlist(id);
        return id;
    }
);

const watchlistSlice = createSlice({
    name: "watchlist",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchWatchedSecurities.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(fetchWatchedCryptos.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(fetchWatchedSecurities.fulfilled, (state, action) => {
                const prices = new Map();
                const movement = new Map();

                /* Log old prices, if there are any. */
                if (state.watched.length > 0) {
                    state.watched.forEach((watch) => {
                        prices.set(watch.ticker, watch.price);
                        movement.set(watch.ticker, watch.movement);
                    });
                }
                /* Set the state to fetched watch items. */
                state.watched = action.payload.watch;
                state.nextPage = action.payload.nextPage;

                /* Copy over the old prices until we fetch the new ones. */
                /* This removes the flicker effect of all of them going to 0. */
                state.watched.forEach((watch) => {
                    if (prices.has(watch.ticker)) {
                        watch.price = prices.get(watch.ticker);
                        watch.movement = movement.get(watch.ticker);
                    }
                });
                state.status = "idle";
            })
            .addCase(fetchWatchedCryptos.fulfilled, (state, action) => {
                const prices = new Map();
                const movement = new Map();

                /* Log old prices, if there are any. */
                if (state.watchedCrypto.length > 0) {
                    state.watchedCrypto.forEach((watch) => {
                        var name = "";
                        if (watch.exchange === "Coinbase") {
                            name = watch.ticker + "-" + watch.unit;
                        } else {
                            name = watch.ticker + watch.unit;
                        }

                        prices.set(name, watch.price);
                        movement.set(name, watch.movement);
                    });
                }
                state.watchedCrypto = action.payload;

                /* Copy over the old prices until we fetch the new ones. */
                /* This removes the flicker effect of all of them going to 0. */
                state.watchedCrypto.forEach((watch) => {
                    var name = "";
                    if (watch.exchange === "Coinbase") {
                        name = watch.ticker + "-" + watch.unit;
                    } else {
                        name = watch.ticker + watch.unit;
                    }
                    if (prices.has(name)) {
                        watch.price = prices.get(name);
                        watch.movement = movement.get(name);
                    }
                });

                state.status = "idle";
            })
            .addCase(saveNewWatchedSecurity.fulfilled, (state, action) => {
                state.watched.push(action.payload);
            })
            .addCase(deleteWatchedSecurity.fulfilled, (state, action) => {
                state.watched = state.watched.filter(
                    (watchedSecurity) => watchedSecurity.id !== action.payload
                );
            })
            .addCase(saveNewWatchedCrypto.fulfilled, (state, action) => {
                state.watchedCrypto.push(action.payload);
            })
            .addCase(deleteWatchedCrypto.fulfilled, (state, action) => {
                state.watchedCrypto = state.watchedCrypto.filter(
                    (watchedCrypto) => watchedCrypto.id !== action.payload
                );
            });
    },
});

/* Selector for state.watched array*/
export const selectWatched = (state: any) => state.watchlist.watched;
export const selectWatchedCryptos = (state: any) => state.watchlist.watchedCrypto;

export default watchlistSlice.reducer;
