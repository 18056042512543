import { useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from "../components/Modal";
import { StyledActionButton } from '../components/StyledActionButton';
import { history } from '../nav/history';
import ReactMarkdown from 'react-markdown'
import { useDispatch, useSelector } from 'react-redux';
import { acceptTos, selectAcceptTos } from "../auth/AuthSlice";


function TOSModal(props: { show: boolean, onClose(): void; }) {
    const { show, onClose } = props;
    const [step, setStep] = useState(1);
    const dispatch = useDispatch();
    const acceptedTos = useSelector(selectAcceptTos);

    const redirectToLogout = () => {
        history.push('/partner_login')
    };

    const acceptTerms = (event: any) => {
        event.preventDefault();

        dispatch(
            acceptTos({
                acceptTos: true
            })
        );
        onClose();
    };

    return (
        <Modal show={show && !acceptedTos} onClose={redirectToLogout} title={"Terms of Service"} >
            <div style={{ margin: '0 auto', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '80%' }}>
                {step === 1
                    ? <>
                        <div style={{ height: '90%', padding: '10px 30px', overflow: 'scroll' }}>
                            {renderPrivacyPolicy()}
                        </div>
                        <div style={{ height: '10%', width: '100%', textAlign: 'center', paddingTop: '20px', borderTop: '2px solid #2C71F0', backgroundColor: '#FAFAFA' }}>
                            <Link to="/partner_login">
                                <StyledActionButton style={{ width: '25%' }}>Decline</StyledActionButton>
                            </Link>
                            <StyledActionButton style={{ width: '25%' }} onClick={() => setStep(2)}>Accept</StyledActionButton>
                        </div>
                    </>
                    : <>
                        <div style={{ height: '90%', padding: '10px 30px', overflow: 'scroll' }}>
                            {renderCookiePolicy()}
                        </div>
                        <div style={{ height: '10%', width: '100%', textAlign: 'center', paddingTop: '20px', borderTop: '2px solid #2C71F0', backgroundColor: '#FAFAFA' }}>
                            <Link to="/partner_login">
                                <StyledActionButton style={{ width: '25%' }}>Decline</StyledActionButton>
                            </Link>
                            <StyledActionButton style={{ width: '25%' }} onClick={(e) => acceptTerms(e)}>Accept</StyledActionButton>
                        </div>
                    </>
                }

            </div>
        </Modal>
    )
};

// Probably worth adding in a MD parser so this can just be pasted. 
const renderPrivacyPolicy = () => {
    return (
        <>
            <ReactMarkdown children={privacyPolicyMD} />
        </>
    )
}

const renderCookiePolicy = () => {
    return (
        <>
            <ReactMarkdown children={cookiePolicyMD} />
        </>
    )
}

export default TOSModal;

const privacyPolicyMD =
    `
## PRIVACY POLICY

Effective Date: September 24, 2021

This Privacy Policy (“Privacy Policy”) describes how Argus, Inc. and our subsidiaries and affiliates (collectively, “Argus,” “us,” “we,” or “our”) collect, use and share your personal information in connection with our website at [www.tryargus.com](www.tryargus.com) and any other websites or online platforms we operate that link to this Privacy Policy (collectively, the “Site”), our social media profiles, our email communications, and our related services and offerings (collectively, the “Platform”), and the choices that you have with respect to your personal information.

This Privacy Policy does not apply to personal information of employees and other personnel that our customers collect through their use of our services on the Platform or that we process on their behalf. For more information about the privacy practices of your organization, please visit their privacy policy or contact them for more information.

We reserve the right, at any time, to modify this Privacy Policy. If we make revisions that change the way we collect, use, or share personal information, we will post those changes in this Privacy Policy. You should review this Privacy Policy periodically so that you keep up to date on our most current policies and practices. We will note the effective date of the latest version of our Privacy Policy at the top of this Privacy Policy. Your continued use of the Platform following posting of changes constitutes your acceptance of such changes.

1. COLLECTION OF PERSONAL INFORMATION

Personal Information You Provide to Us. The personal information that you may provide to us through the Platform or otherwise includes:
- Contact information, such as your first and last name, email address, phone number, and postal address.
- Professional details, such as your title and the organization you work for.
- Profile information, such as your username, password, and settings.
- Communications, such as communications that we exchange, including when you contact us with questions, feedback, or otherwise.
- Transaction information, such as records of your organization’s subscriptions and purchases and order information.
- Marketing preferences, such as your preferences for receiving marketing and promotional communications, and details about how you engage with our communications.
- Other information that we may collect which is not specifically listed here, which we will use as described in this Privacy Policy or as otherwise disclosed at the time of collection.

Unless we specifically request it, we ask that you not provide us with any sensitive personal information (e.g., information related to racial or ethnic origin, political opinions, religion or other beliefs, health, biometrics or genetic characteristics, criminal background or trade union membership).

Personal Information Collected Automatically. We, our service providers, and our business partners may automatically log or collect information about you, your computer or mobile device, and your activity over time on our Platform and other sites and online services, such as:

- Device data, such as your computer or mobile device operating system type and version number, manufacturer and model, browser type, screen resolution, IP address, unique identifiers, the website you visited before browsing to our website, and general location information such as city, state or geographic area.
- Online activity data, such as pages or screens you viewed, how long you spent on a page or screen, navigation paths between pages or screens, information about your activity on a page or screen, access times, and duration of access.

Like many online services, the Platform uses cookies and similar technologies to facilitate automatic data collection, which may include:

- Cookies, which are text files that websites store on a visitor‘s device to uniquely identify the visitor’s browser or to store information or settings in the browser for the purpose of helping you navigate between pages efficiently, remembering your preferences, enabling functionality, helping us understand user activity and patterns, and facilitating online advertising.
- Flash cookies, or locally-stored objects, which are used on websites for purposes similar to cookies but allow storage of a larger amount of data.
- Web beacons, also known as pixel tags or clear GIFs, which are typically used to demonstrate that a webpage or email was accessed or opened, or that certain content was viewed or clicked, typically to compile statistics about usage of websites and the success of marketing campaigns.

For more information, please view our Cookie Policy.

Personal Information from Third Parties. We may obtain personal information about you from third parties, such as the organization you work for, third-party services or platforms that you link or use to sign into the Platform, public sources (such as social media profiles), and our other business partners.

Referrals. You may have the opportunity to refer colleagues or other contacts to us and share their contact information with us. Please do not refer someone to us or share their contact information with us unless you have their permission to do so.

2. USE OF PERSONAL INFORMATION

We may use your personal information for various purposes, including the uses below and as otherwise described in this Privacy Policy or at the time of collection:

To Provide the Platform and Our Services. We use personal information to provide the Platform and our services, including to:

- operate and the deliver the Platform;
- provide you with the content, features, products, and services that you request;
- set up, maintain, and monitor your account on the Platform;
- engage customers, manage billing, and process orders and transactions;
- communicate with you about the Platform (including by sending announcements, updates, security alerts, and support and administrative messages);
- understand your interests, personalize your experience on the Platform, and deliver information about products and services relevant to your interests;
- respond to your inquiries and requests for customer support, including to investigate and address your concerns and monitor and improve our responses; and
- enable security features of the Platform, such as by sending you security codes or remembering devices from which you have previously logged in.

Research and Development. We use personal information to understand and analyze the usage trends and preferences of our users to make our Platform and other offerings better, diagnose technical issues, and develop new features and functionality. As part of these activities, we may create aggregated, de-identified, or other anonymous data from personal information we collect. We make personal information into anonymous data by removing information that makes the data personally identifiable to you. We may use this anonymous data and share it with third parties for our lawful business purposes, including to analyze and improve the Platform and promote our business.

Direct Marketing. We may send you Argus-related or other direct marketing communications as permitted by law. You may opt-out of our marketing communications as described in the “Your Choices” section below.

Interest-Based Advertising. We may work with third-party advertising companies and social media companies to help us advertise our business. These companies may use cookies and similar technologies to collect information about you (including the device data and online activity data described above) over time across our Platform and other sites and services or your interaction with our emails, and use that information to serve ads that they think will interest you. You can learn more about your choices for limiting interest-based advertising in the “Your Choices” section below.

To Comply with Laws and Regulations. We use personal information as we believe necessary or appropriate to comply with applicable laws, lawful requests, and legal process, such as to respond to subpoenas or requests from government authorities.

For Compliance, Fraud Prevention, and Protection. We may use your personal information for compliance, fraud prevention, safety, and protection purposes, including to:

- perform compliance activities related to the Platform;
- maintain the safety, security, and integrity of our Platform, products and services, business, databases, and other technology assets;
- protect our, your or others’ rights, privacy, safety or property (including by making and defending legal claims);
- audit our internal processes for compliance with legal and contractual requirements and internal policies;
- enforce the terms and conditions that govern the Platform; and
- prevent, identify, investigate and deter fraudulent, harmful, unauthorized, unethical or illegal activity, including cyberattacks and identity theft.

With Your Consent. In some cases we may specifically ask you for your consent to collect, use, or share your personal information, such as when required by law.

3. SHARING OF PERSONAL INFORMATION

We may share your personal information with the entities and individuals listed below, and as otherwise described in this Privacy Policy or at the point of collection:

Service Providers. We share personal information with third parties and individuals who perform functions on our behalf and help us run our business. For example, service providers help us perform technical maintenance (including maintenance of network equipment and electronic communications networks), website hosting, maintenance services, database management, web analytics, billing, payment processing, marketing, and other services.

Your Employer or Organization. Your personal information may be shared with your employer or organization as part of the services that we provide.

Related Companies. We may share your personal information with companies that are affiliated with us (that is, that control, are controlled by, or are under common control with us), including affiliates and subsidiaries.

Business Transactions. We may disclose your personal information to third parties in connection with any business transaction (or potential transaction) involving a merger, acquisition, sale of shares or assets, financing, acquisition, consolidation, reorganization, divestiture, or dissolution of all or a portion of our business (including in connection with a bankruptcy or similar proceedings).

Law Enforcement, Government Authorities, and Others. Under certain circumstances, we may be required to disclose your personal information to law enforcement, government authorities, and other parties if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency). We may also disclose your personal information in the good faith belief that such action is necessary to comply with a legal obligation, protect and defend the rights or property of Argus or our customers, prevent or investigate possible wrongdoing in connection with the Platform and our services, protect the personal safety of users of the Platform or the public, protect against legal liability, and for the other compliance and protection purposes described in the section above titled “Use of Personal Information.”

Business and Advertising Partners. We may also share personal information collected about you with third parties who we partner with for advertising campaigns, surveys, special offers, or other events or activities in connection with our Platform. We may also share or permit the collection of your personal information by advertising companies that we work with, such as companies that collect activity on the Platform and other online services to help us advertise our products and service and/or that use hashed customer lists that we share with them to deliver ads to you and to similar users on their platforms.

Professional Advisors. We may disclose your personal information to our professional advisors, such as lawyers, bankers, auditors and insurers, where necessary in the course of the professional services that they render to us.

4. SECURITY OF PERSONAL INFORMATION

No method of transmission over the Internet, or method of electronic storage, is fully secure. While we use reasonable efforts to protect your personal information from the risks presented by unauthorized access or acquisition, we cannot guarantee the security of your personal information.

5. YOUR CHOICES

In this section, we describe the rights and choices available to all users.

Access or Update Your Personal Information. You may review and update certain account information by logging into your account on the Platform.

Opt-out of Marketing Communications. You may opt out of marketing-related emails by following the opt-out or unsubscribe instructions at the bottom of the email, or by contacting us at privacy@tryargus.com. You may continue to receive service-related and other non-marketing emails.

Cookies. Most browsers let you remove and/or stop accepting cookies from the websites you visit. To do this, follow the instructions in your browser’s settings. Many browsers accept cookies by default until you change your settings. If you do not accept cookies, however, you may not be able to use all functionality of the Platform and our Site may not work properly. For more information about cookies, including how to see what cookies have been set on your browser and how to manage and delete them, visit www.allaboutcookies.org. You may also visit our Cookie Policy.

Advertising Choices. You can limit the use of your information for interest-based advertising by blocking third-party cookies in your browser settings, using browser plug-ins/extensions, and/or using your mobile device settings to limit the use of the advertising ID associated with your mobile device. You can also opt out of interest-based ads from companies participating in the following industry opt-out programs by visiting the linked websites below:

- Network Advertising Initiative: [http://www.networkadvertising.org/managing/opt_out.asp](http://www.networkadvertising.org/managing/opt_out.asp)
- Digital Advertising Alliance: [optout.aboutads.info](optout.aboutads.info)
- European Interactive Digital Advertising Alliance: [http://www.youronlinechoices.eu/](http://www.youronlinechoices.eu/)

The opt-out preferences described here must be set on each device and/or browser for which you want them to apply. Please note that we also may work with companies that offer their own opt-out mechanisms or do not participate in the opt-out mechanisms described above, so even after opting-out, you may still receive some cookies and interest-based ads from other companies. If you opt-out of interest-based advertisements, you will still see advertisements online but they may be less relevant to you.

Do Not Track. Some Internet browsers may be configured to send “Do Not Track” signals to the online services that you visit. We currently do not respond to “Do Not Track” or similar signals. To find out more about “Do Not Track,” please visit [http://www.allaboutdnt.com](http://www.allaboutdnt.com).

Declining to Provide Information. We need to collect personal information to provide certain services. If you do not provide the information requested, we may not be able to provide those services.

6. INTERNATIONAL TRANSFERS OF PERSONAL INFORMATION

Argus is headquartered in the United States of America. If you are from a country outside of the United States of America with laws governing data collection, use, and disclosure that may differ from U.S. law and you provide personal information to us, please note that any personal information that you provide to us may be transferred to the United States of America. By providing your personal information, where applicable law permits, you hereby specifically and expressly consent to such transfer and processing and the collection, use, and disclosure set forth herein or in any applicable terms of service.

European users may view the section below titled “Notice to European Users” for additional information about data transfers.

7. OTHER SITES AND SERVICES

Our Platform may contain links to websites, mobile applications or other online services operated by third parties. When you click on a link to any other website, mobile application or online service, you will leave our Platform and go to another site, and another entity may collect personal information or anonymous data from you. In addition, our content may be included on other online services that are not associated with us. We have no control over, do not review, and are not responsible for, these third party online services or for their content or actions. Other online services follow different rules regarding the collection, use and sharing of your personal information. We encourage you to read the privacy policies of other online services that you visit or use. The links to third party online services are for your convenience and do not signify our endorsement of, or affiliation with, any third party or its services.

8. CHILDREN

Our Platform is not intended for use by minors under the age of 18. If we learn that we have collected personal information of a minor under 18 without the consent of the child’s parent or guardian as required by law, we will delete it as soon as possible. If you believe that we might have any personal information collected online from a minor under 18, please contact us at privacy@tryargus.com.

9. YOUR CALIFORNIA PRIVACY RIGHTS

Under California Civil Code sections 1798.83-1798.84, California residents who have an established business relationship with Argus are entitled to ask us once a year for information regarding the personal information we have shared, if any, with third parties for their direct marketing purposes. If you are a California resident and would like to submit such a request, please submit your request in writing to the email address listed in the section titled “CONTACTING US” below with “Shine the Light” in the subject line. The request must include your name, street address, city, state, and ZIP code and an attestation that you are a California resident. We are not responsible for requests that are not labeled or sent properly, or that do not have complete information.

10. NOTICE TO EUROPEAN USERS

The information provided in this section applies only to individuals in the European Union, the European Economic Area, and the United Kingdom (collectively, “Europe”).

Except as otherwise specified, references to “personal information” in this Privacy Policy are equivalent to “personal data” governed by European data protection legislation.

Controller. The controller of your personal information covered by this Privacy Policy for purposes of European data protection legislation is Argus, Inc., 18 East 48th Street, 6th Floor, New York, NY 10017, USA.

Our office in the United Kingdom may be contacted at 210A Ralph Court, Queensway, London, W2 5HT.

Legal Bases for Processing. The legal bases of our processing of your personal information as described in this Privacy Policy will depend on the type of personal information and the specific context in which we process it. However, the legal bases we typically rely on are set out in the table below. We rely on our legitimate interests as our legal basis only where those interests are not overridden by the impact on you (unless we have your consent or our processing is otherwise required or permitted by law). If you have questions about the legal basis of how we process your personal information, contact us at privacy@tryargus.com.

Processing Purpose (as described above in the “Use of Personal Information” section) Legal Basis

To Provide the Platform and Our Services Processing is necessary to perform the contract governing our operation of the Platform or our services, or to take steps that you or your organization request prior to engaging our services. Where we cannot process your personal information as required to provide the Platform and our services on the grounds of contractual necessity, we process your personal information for this purpose based on our legitimate interest in providing you with the products or services you access and request.

Research and Development Processing is based on our legitimate interests in performing research and development as described in this Privacy Policy.

Direct Marketing

Processing is based on your consent where that consent is required by applicable law. Where such consent is not required by applicable law, we process your personal information for these purposes based on our legitimate interests in promoting our business and showing you tailored relevant content.

Interest-Based Advertising Processing is based on your consent where that consent is required by applicable law. Where we rely on your consent you have the right to withdraw it any time in the manner indicated when you consent or via the Platform.

To Comply with Laws and Regulations Processing is necessary to comply with our legal obligations.

For Compliance, Fraud Prevention, and Protection Processing is necessary to comply with our legal obligations or based on our legitimate interests in protecting our or others’ rights, privacy, safety, or property.

With Your Consent Processing is based on your consent. Where we rely on your consent you have the right to withdraw it at any time in the manner indicated when you consent or via the Platform.



Use for New Purposes. We may use your personal information for reasons not described in this Privacy Policy where permitted by law and where the reason is compatible with the purpose for which we collected it. If we need to use your personal information for an unrelated purpose, we will notify you and explain the applicable legal basis.

Your Rights. European data protection laws give you certain rights regarding your personal information. You may ask us to take the following actions in relation to your personal information that we hold:

- Access. Provide you with information about our processing of your personal information and give you access to your personal information.

- Correct. Update or correct inaccuracies in your personal information.

- Delete. Delete your personal information.

- Transfer. Transfer a machine-readable copy of your personal information to you or a third party of your choice.

- Restrict. Restrict the processing of your personal information.

- Object. Object to our reliance on our legitimate interests as the basis of our processing of your personal information that impacts your rights.

You may submit these requests by contacting us at privacy@tryargus.com or contacting us using the other contact details in this Privacy Policy. We may request specific information from you to help us confirm your identity and process your request. Applicable law may require or permit us to decline your request. If we decline your request, we will tell you why, subject to legal restrictions. If you would like to submit a complaint about our use of your personal information or our response to your requests regarding your personal information, you may contact us or submit a complaint to the data protection regulator in your jurisdiction. You can find your data protection regulator here.

Cross-Border Data Transfer. If we transfer your personal information to a country outside of Europe such that we are required to apply additional safeguards to your personal information under European data protection laws, we will do so. Please contact us using the information below for further information about any such transfers or the specific safeguards applied.

11. CONTACTING US

You may contact us via email at privacy@tryargus.com or by writing to us at the address below:

Argus, Inc.

Attn: Legal – Privacy

18 East 48th Street, 6th Floor

New York, NY 10007
`

const cookiePolicyMD = `
## Cookie Policy

This Cookie Policy describes how Argus, inc. and its affiliates and subsidiaries (collectively, “Argus,” “us,” “we,” or “our”) use cookies and similar technologies in connection with the websites and platforms we operate that link to this Cookie Policy (collectively, the “Site”) to provide, improve, promote, and protect the Site and as otherwise described below.

What is a cookie?

A cookie is a small piece of text sent to your browser when you visit the Site. It serves a variety of functions, like enabling us to remember certain information you provide to us as you navigate between pages on the Site. Each cookie expires after a certain period of time depending on what we use it for. Cookies are useful because they help us make your experience on the Site more enjoyable. They allow us to recognize your device (e.g. your laptop or mobile device) so that we can tailor your experience of the Site.

Why do we use cookies?

We may use first party and third party cookies for several reasons, such as letting you navigate between pages efficiently, remembering your preferences, letting us analyze how well our website is performing, improving your experience, and helping us advertise our business. Some cookies may be required for technical reasons in order for our Site to operate. Other cookies may enable us and the advertising partners we work with to track and target the interests of visitors to our Site and across other sites and services. For example, we may use cookies to tailor content and information that we send or display to you and otherwise personalize your experience while interacting with our Site, and to otherwise improve the functionality of the services we provide. Third parties may also serve cookies through our Site for advertising, analytics, and other purposes. This is described in more detail below.

What cookies do we use?

Essential

These cookies are strictly necessary to provide you with the Site and to use some of their features, such as access to secure areas and account authentication purposes. Because these cookies are strictly necessary to deliver the Site, you cannot refuse them without impacting how our Site functions. You may be able to block or delete essential cookies by changing your browser settings.

Performance and Analytics, Personalization, and Security

These cookies help us analyze how the Site is being accessed and used, enable live chat features, and secure the Site. For example, we may use cookies to get insights regarding users and Site performance, such as page speed or to help us customize our Site for you in order to enhance your experience.

Examples of performance and analytics, personalization, and security cookies we may use include the following services:

| Cookies |
| Tawk |

Targeting or Advertising Cookies

These cookies are used to make advertising messages more relevant to you and your interests. We sometimes use cookies delivered by third parties to track the performance of our advertisements. For example, these cookies remember which browsers have visited our Site. This process helps us manage and track the effectiveness of our marketing efforts.

How do I manage cookies?

Most browsers let you remove and/or stop accepting cookies from the websites you visit. To do this, follow the instructions in your browser’s settings. Many browsers accept cookies by default until you change your settings. If you do not accept cookies, however, you may not be able to use all functionality of the Site and it may not work properly. For more information about cookies, including how to see what cookies have been set on your browser and how to manage and delete them, visit [www.allaboutcookies.org](www.allaboutcookies.org).

Please visit our Privacy Policy for more information about your choices in connection with your personal information, including additional instructions for opting-out of interest-based advertising.

Cookie Policy Updates

We may update this Cookie Policy from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal, or regulatory reasons. Please re-visit this Cookie Policy regularly to stay informed about our use of cookies and related technologies. The date at the bottom of this Cookie Policy indicates when it was last updated.

Where can you get further information?

If you have any questions about our use of cookies or other technologies, please email us at privacy@tryargus.com.

Last Revised: September 24, 2021
`