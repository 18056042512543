import { useEffect, useState } from "react";
import { StyledModalItem } from "../../components/StyledModalItem";
import { StyledSelect } from "../../components/StyledSelect";
import ModalLabel from "../ModalLabel";
import AmountInput from "./AmountInput";
import VolumeInput from "./VolumeInput";


export enum Version {
    SECURITY = "SECURITY",
    CRYPTO = "CRYPTO",
    ALL = "ALL"
}


export interface IQuantityInputProps {
    version: "SECURITY" | "CRYPTO" | "ALL",
    showMissing: boolean,
    setComplete(complete: boolean): void,
    volume: string,
    setVolume(volume: string): void,
    amount: string,
    setAmount(amount: string): void,
    currency: string,
    setCurrency(currency: string): void,
    onChange(e: any): void,
    volumeError?: string,
    amountError?: string,
    pricingProvider?: boolean,
    setParentInputMethod(inputMethod: string): void,
}


function isComplete(inputType: string, completion: { volume: boolean, amount: boolean }): boolean {
    if (inputType === "BOTH") {
        return completion.volume && completion.amount;
    } else if (inputType === "VOLUME") {
        return completion.volume;
    } else {
        return completion.amount;
    }
}


function getInputMethod(pricingProvider: boolean | undefined, version: string) {
    if (version === "SECURITY") {
        return "VOLUME";
    } else {
        if (pricingProvider !== undefined && pricingProvider) {
            return "VOLUME";
        } else {
            return "VOLUME";
        }
    }
}

function QuantityInput({ version, showMissing, setComplete, volume, setVolume, amount, setAmount, currency, setCurrency, pricingProvider, onChange, amountError, volumeError, setParentInputMethod }: IQuantityInputProps): JSX.Element {

    const [inputMethod, setInputMethod] = useState(version === "SECURITY" ? "VOLUME" : "AMOUNT")

    const [completion, setCompletion] = useState({ volume: false, amount: false })

    useEffect(() => {
        setComplete(isComplete(inputMethod, completion))
        setInputMethod(getInputMethod(pricingProvider, version))
    }, [completion, pricingProvider, version])

    return (
        <>
            {((version === "ALL" || version === "CRYPTO") && (pricingProvider !== false)) && (
                <StyledModalItem>
                    <ModalLabel>
                        Trade by Value/Volume
                    </ModalLabel>

                    <StyledSelect
                        id="orderType"
                        name="orderType"
                        onChange={(event) => {
                            setInputMethod(event.target.value);
                            setParentInputMethod(event.target.value);
                            onChange(event);
                        }}
                        style={{ marginBottom: "10px" }}
                    >
                        <option value="VOLUME">Volume</option>
                        <option value="AMOUNT">Value</option>
                        <option value="BOTH">Both</option>
                    </StyledSelect>


                </StyledModalItem>)}


            <StyledModalItem>
                <span style={{ width: "50%", lineHeight: "18px" }}><i>Ask for more than you will trade so you won’t buy more than is approved.</i></span>
                {(pricingProvider !== undefined && pricingProvider === false) && (<span style={{ width: "50%", lineHeight: "18px" }}><i>No pricing information is available for this asset, so must request by volume.</i></span>)}
            </StyledModalItem>

            {(inputMethod === "BOTH" || inputMethod === "VOLUME") && (
                <VolumeInput
                    version={version}
                    volume={volume}
                    setVolume={setVolume}
                    showErrors={showMissing}
                    setComplete={(completed: boolean) => { setCompletion({ ...completion, volume: completed }) }}
                    onChange={(e) => { onChange(e) }}
                    error={volumeError}
                />
            )}

            {(inputMethod === "BOTH" || inputMethod === "AMOUNT") && (
                <AmountInput
                    version={version}
                    amount={amount}
                    setAmount={setAmount}
                    showErrors={showMissing}
                    setComplete={(completed: boolean) => { setCompletion({ ...completion, amount: completed }) }}
                    currency={currency}
                    setCurrency={setCurrency}
                    name={"amount"}
                    onChange={(e) => { onChange(e) }}
                    error={amountError}
                />
            )}

        </>
    )
}

export default QuantityInput;
