import Modal from "../components/Modal";
import { StyledPageTitle } from "../components/StyledPageTitle";
import { StyledActionButton } from "../components/StyledActionButton";

interface IProps {
    show: boolean;
    onClose(): void;
    handleDelete(email: string): void;
    email: string;
}

function DeleteUserModal(props: IProps): JSX.Element {
    const { show, onClose, handleDelete, email } = props;

    return (
        <Modal
            width="30%"
            height="30%"
            marginLeft="35%"
            marginTop="10%"
            show={show}
            onClose={onClose}
            title={"Delete"}
        >
            <div
                style={{
                    width: "100%",
                    height: "60%",
                    display: "flex",
                    marginLeft: "2%",
                    marginRight: "2%",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    flexDirection: "row",
                }}
            >
                <StyledPageTitle style={{ width: "100%", textAlign: "center" }}>
                    Are you sure you want to delete?
                </StyledPageTitle>
                <StyledActionButton
                    style={{
                        background: "#22B43A",
                        width: "30%",
                        alignContent: "center",
                        lineHeight: "0px",
                    }}
                    onClick={(e) => {
                        handleDelete(email);
                        onClose();
                    }}
                >
                    <p>Yes</p>
                </StyledActionButton>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <StyledActionButton
                    style={{
                        background: "#F02C38",
                        width: "30%",
                        lineHeight: "0px",
                    }}
                    onClick={() => {
                        onClose();
                    }}
                >
                    <p>No</p>
                </StyledActionButton>
            </div>
        </Modal>
    );
}

export default DeleteUserModal;
