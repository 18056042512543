import { useMemo } from "react"
import { useDimensions } from "../contexts/DimensionsContext"

const useMainLayoutDimensions = () => {
    const dimensions = useDimensions()

    const width = useMemo(() => {
        return Math.min(Math.max(dimensions.width, 800), 1920)
    }, [dimensions.width])

    return {width, height: dimensions.height}
}

export default useMainLayoutDimensions