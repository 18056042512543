import { StyledSpacing } from "../components/StyledSpacing";
import GlobalRestrictions from "./GlobalRestrictions/GlobalRestrictions";
import Restrictions from "./Restrictions";

function Admin(): JSX.Element {

    return (
        <>
            <StyledSpacing>
                <GlobalRestrictions />
            </StyledSpacing>

            <Restrictions />
        </>
    );
}

export default Admin;