import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCompanyId } from "../auth/AuthSlice";
import { StyledActionButton } from "../components/StyledActionButton";
import { StyledInputField } from "../components/StyledInputField";
import { StyledSectionHeader } from "../components/StyledSectionHeader";
import { StyledSelect } from "../components/StyledSelect";
import UserAutocomplete, { UserSuggestion } from "../components/UserAutoComplete";
import { createProxyUser } from "../user/UserProfile/UserProfileSlice";
import { saveNewUser } from "./UserPanelSlice";



function AddUser(): JSX.Element {

    const [mode, setMode] = useState<"DEFAULT" | "PROXY" | "OUTSIDE">("DEFAULT");
    const [email, setEmail] = useState("");
    const [employeeId, setEmployeeId] = useState("");
    const [name, setName] = useState("");

    const dispatch = useDispatch();
    const companyId = useSelector(selectCompanyId);

    const resetDefaults = () => {
        setMode("DEFAULT");
        setEmail("");
        setName("");
        setEmployeeId("");
    }

    const handleNewUser = (event: any) => {
        event.preventDefault();


        if (mode !== "PROXY") {
            dispatch(
                saveNewUser({ name: name, email: email, outsideEmail: mode === "OUTSIDE", companyId: companyId })
            );
            resetDefaults();

        } else {
            dispatch(
                createProxyUser({ name: name, email: email, employeeId: employeeId })
            );
            resetDefaults();

        }
    };

    const handleSearch = (user: UserSuggestion) => {
        setEmployeeId(user.id);
    };

    return (
        <>
            <StyledSectionHeader>Add a new user</StyledSectionHeader>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ display: "flex", flexDirection: "row", width: "75%" }}>
                    <StyledSelect
                        placeholder="Select User Type to Add"
                        onChange={(event) => {
                            setMode(event.target.value as "DEFAULT" | "PROXY" | "OUTSIDE")
                        }}
                    >
                        <option value="DEFAULT">SSO User</option>
                        <option value="PROXY">Proxy User</option>
                        <option value="OUTSIDE">Outside Email User</option>
                    </StyledSelect>

                    <StyledInputField
                        placeholder="Email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                    />
                    <StyledInputField
                        placeholder="Name"
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                    />

                    {mode === "PROXY" && (
                        <div style={{ width: "50%" }}>

                            <div style={{ position: "relative" }}>
                                <UserAutocomplete
                                    value={""}
                                    onSelect={handleSearch}
                                    onEnter={handleSearch}
                                    style={{ width: "100%" }}
                                    placeholder={"Filter by name or email"}
                                />
                            </div>
                        </div>)}
                </div>

                <div style={{ width: "25%" }}>
                    <StyledActionButton
                        style={{ width: "100%" }}
                        onClick={handleNewUser}
                    >
                        Add user
                    </StyledActionButton>
                </div>
            </div>
        </>

    )

}


export default AddUser;
