function escapeRegExp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export const kebabToTitleCase = (str: string) => {
    let newString = "";
    const splitString = str.split("-");
    splitString.forEach(s => {
        newString = newString +" "+ (s.charAt(0).toUpperCase() + s.substr(1).toLowerCase());
    });
    return newString;
};

export default escapeRegExp;
