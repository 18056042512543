import { useState } from "react";
import { BiCaretDown } from "react-icons/bi";
import { StyledTableContainer } from "../components/StyledTable";
import NotificationListItem from "./NotificationListItem";
import { INotification } from "./NotificationSlice";

/* Type for the WatchlistList props */
interface IListProps {
    pageSize?: number; //How many reqs to display, mark as optional
    start?: number; //What is our starting offset, mark as optional
    notifications: Array<INotification>;
    activeFilter: Array<string>;
    filterValue: Array<string>;
    handleDelete(id: string): void;
}

interface IField {
    (notifications: INotification): any;
}

function NotificationList(props: IListProps): JSX.Element {
    /* Provide default values and replace them with the prop values if there are any. */
    const {
        pageSize = 100,
        start = 0,
        notifications = [],
        activeFilter,
        filterValue,
    } = props;

    const [asc] = useState(false);

    /* Define comparison functions for each style of */
    const compareField = (
        firstEl: INotification,
        secondEl: INotification,
        field: IField
    ) => {
        if (asc) {
            return field(firstEl) <= field(secondEl) ? -1 : 1;
        } else {
            return field(firstEl) < field(secondEl) ? 1 : -1;
        }
    };

    const compareDate = (firstEl: INotification, secondEl: INotification) => {
        return compareField(firstEl, secondEl, (el) => el.date.toLowerCase());
    };
    const compareMessage = (firstEl: INotification, secondEl: INotification) => {
        return compareField(firstEl, secondEl, (el) => el.message.toLowerCase());
    };
    const compareSender = (firstEl: INotification, secondEl: INotification) => {
        return compareField(firstEl, secondEl, (el) => el.sender.toLowerCase());
    };

    const [activeCompare, setActiveCompare] = useState("dateSubmitted");

    let compare = compareDate;
    if (activeCompare === "message") {
        compare = compareMessage;
    } else if (activeCompare === "sender") {
        compare = compareSender;
    }

    return (
        <StyledTableContainer>
            <table>
                <thead>
                    <tr>
                        <th
                            style={{
                                backgroundColor:
                                    activeCompare === "user"
                                        ? "rgba(44, 113, 240, 0.2)"
                                        : "#E6EAFF",
                                cursor: "pointer",
                            }}
                            onClick={(e) => {
                                setActiveCompare("date");
                            }}
                        >
                            Date
                            {activeCompare === "action" ? <BiCaretDown /> : ""}
                        </th>
                        <th
                            style={{
                                backgroundColor:
                                    activeCompare === "action"
                                        ? "rgba(44, 113, 240, 0.2)"
                                        : "#E6EAFF",
                                cursor: "pointer",
                            }}
                            onClick={(e) => {
                                setActiveCompare("message");
                            }}
                        >
                            Message
                            {activeCompare === "action" ? <BiCaretDown /> : ""}
                        </th>
                        <th
                            style={{
                                backgroundColor:
                                    activeCompare === "ticker"
                                        ? "rgba(44, 113, 240, 0.2)"
                                        : "#E6EAFF",
                                cursor: "pointer",
                            }}
                            onClick={(e) => {
                                setActiveCompare("sender");
                            }}
                        >
                            Sender
                            {activeCompare === "ticker" ? <BiCaretDown /> : ""}
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {/* Generate the DashboardList and add them to the ul */}
                    {/* 
                        1. Filter by the activeFilter, show everything if empty, matching names and tickers if not.
                        2. Slice to show only show pageSize number of results (defaults to 100)
                        3. Map the entities array to AdminListItem components
                        */}
                    {notifications
                        .filter((notification: INotification) => {
                            var notifications = true;

                            if (filterValue.includes("date"))
                                notifications =
                                    notifications &&
                                    notification.date
                                        .toLowerCase()
                                        .includes(
                                            activeFilter[
                                                filterValue.indexOf("date")
                                            ].toLowerCase()
                                        );
                            if (filterValue.includes("message"))
                                notifications =
                                    notifications &&
                                    notification.message
                                        .toLowerCase()
                                        .includes(
                                            activeFilter[
                                                filterValue.indexOf("message")
                                            ].toLowerCase()
                                        );
                            if (filterValue.includes("sender"))
                                notifications =
                                    notifications &&
                                    notification.sender
                                        .toLowerCase()
                                        .includes(
                                            activeFilter[
                                                filterValue.indexOf("sender")
                                            ].toLowerCase()
                                        );

                            return notifications;
                        })
                        .slice(start, start + pageSize)
                        .sort(compare)
                        .map((notification: INotification, index: number) => (
                            <NotificationListItem
                                date={notification.date}
                                message={notification.message}
                                sender={notification.sender}
                                id={notification.id}
                                handleDelete={props.handleDelete}
                            />
                        ))}
                </tbody>
            </table>
        </StyledTableContainer>
    );
}

export default NotificationList;
