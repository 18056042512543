/* Wrapper for calls to the trade_reqs route */
import { ISorting } from "../dashboard/DashboardSlice";
import { IPagination, paginationQueryParam } from "../util/pagination";
import { authService } from "./authService";

export const tradeRequestService = {
    getReqs,
    createReq,
    deleteReq,
    getClearedTrades,
    getPendingTrades,
    getClosedTrades,
    updateTradeStatus,
    getAllPendingTrades,
    getTradeReqsDownload,
    updateRequest
};

export interface IFilter {
    user: string;
    transactionType: string;
    transactionSubtype: string;
    orderType: string;
    ticker: string;
    asset: string;
    volume: string;
    volumeMin: string;
    volumeMax: string;
    status: string;
    dateSubmitted: "";
    dateSubmittedMin: "";
    dateSubmittedMax: "";
    dateProcessed: "";
    dateProcessedMin: "";
    dateProcessedMax: "";
    approvedFrom: "";
    approvedFromMin: "";
    approvedFromMax: "";
    approvedUntil: "";
    approvedUntilMin: "";
    approvedUntilMax: "";
}
const base = process.env.REACT_APP_API_ENDPOINT;
const baseUrl = `${base}/requests`;

/* Get filtered trade requests */
function getReqs(pagination: IPagination, filter: IFilter, sorting: ISorting[]): any {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
            "Content-type": "application/json",
        },
        body: JSON.stringify({ filter, sorting }),
    };

    return fetch(paginationQueryParam(`${baseUrl}/get`, pagination), requestParams)
        .then(authService.handleErrors)
        .then(handleResponse)
        .then((req) => {
            return req;
        });
}

/* Get filtered trade requests download */
function getTradeReqsDownload(filter: IFilter, sorting: ISorting[]): any {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
            "Content-type": "application/json",
        },
        body: JSON.stringify({ filter, sorting }),
    };

    return fetch(`${baseUrl}/download`, requestParams);
}

/* Get all trade requests */
function getAllPendingTrades(pagination: IPagination, sorting: ISorting[]): any {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
            "Content-type": "application/json",
        },
        body: JSON.stringify({ sorting, tradeRequestStatus: "PENDING" }),
    };

    return fetch(paginationQueryParam(`${baseUrl}/assigned/get`, pagination), requestParams)
        .then(authService.handleErrors)
        .then(handleResponse)
        .then((req) => {
            return req;
        });
}

/* Get all cleared trade requests */
function getClearedTrades(
    userId: string,
    pagination: IPagination,
    sorting: ISorting[]
): any {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
            "Content-type": "application/json",
        },
        body: JSON.stringify({ sorting, tradeRequestStatus: "APPROVED" }),
    };

    return fetch(
        paginationQueryParam(`${baseUrl}/get?asUser=true`, pagination),
        requestParams
    )
        .then(authService.handleErrors)
        .then(handleResponse)
        .then((clearedTrades) => {
            return clearedTrades;
        });
}

/* Get all cleared trade requests */
function getPendingTrades(
    userId: string,
    pagination: IPagination,
    sorting: ISorting[]
): any {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
            "Content-type": "application/json",
        },
        body: JSON.stringify({ sorting, tradeRequestStatus: "PENDING" }),
    };

    return fetch(
        paginationQueryParam(`${baseUrl}/get?asUser=true`, pagination),
        requestParams
    )
        .then(authService.handleErrors)
        .then(handleResponse)
        .then((pendingTrades) => {
            return pendingTrades;
        });
}

/* Get all cleared trade requests */
function getClosedTrades(
    userId: string,
    pagination: IPagination,
    sorting: ISorting[]
): any {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
            "Content-type": "application/json",
        },
        body: JSON.stringify({ sorting, tradeRequestStatus: "CLOSED" }),
    };

    return fetch(
        paginationQueryParam(`${baseUrl}/get?asUser=true`, pagination),
        requestParams
    )
        .then(authService.handleErrors)
        .then(handleResponse)
        .then((closedTrades) => {
            return closedTrades;
        });
}

/* Create and send trade req. */
function createReq(
    volume: string,
    buySell: string,
    orderType: string,
    reason: string,
    securityId: string,
    cryptoId: string,
    privateAssetId: string,
    userId: string,
    amount?: string,
    currency?: string,
    secondaryCryptoId?: string,
    secondaryCryptoVolume?: string,
    secondaryCryptoAmount?: string,
    representativeJson?: any,
    signer?: string,
    isStandingOrder?: boolean,
    frequency?: string,
    start?: string,
    end?: string,
): any {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
            "Content-type": "application/json",
        },
        body: JSON.stringify({
            volume: volume,
            buySell: buySell,
            orderType: orderType,
            reason: reason,
            securityId: securityId,
            cryptoId: cryptoId,
            privateAssetId: privateAssetId,
            userId: userId,
            approvalTypeSought: { approval_types: ["RESTRICTION"] },
            amount: amount,
            currency: currency,
            secondaryCryptoId: secondaryCryptoId,
            secondaryCryptoVolume: secondaryCryptoVolume,
            secondaryCryptoAmount: secondaryCryptoAmount,
            representations: representativeJson,
            signer: signer,
            isStandingOrder,
            frequency,
            start,
            end
        }),
    };
    return fetch(`${baseUrl}`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse)
        .then((req) => {
            return req;
        });
}

function updateTradeStatus(
    id: number,
    duration: string,
    updateStatus: string,
    complianceNotes: string,
    end?: string
): any {
    const requestParams = {
        method: "PUT",
        headers: {
            ...authService.authHeader(),
            "Content-type": "application/json",
        },
        body: JSON.stringify({
            complianceNotes: complianceNotes,
            end,
            approvalTypeReceived:
                updateStatus === "APPROVED"
                    ? { approval_types: ["ALL"] }
                    : { approval_types: [] },
        }),
    };
    return fetch(`${baseUrl}/${id}/${duration}/${updateStatus}`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

function updateRequest(
    id: number,
    end: string
): any {
    const requestParams = {
        method: "PUT",
        headers: {
            ...authService.authHeader(),
            "Content-type": "application/json",
        },
        body: JSON.stringify({
            end,
        }),
    };
    return fetch(`${baseUrl}/${id}`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

function deleteReq(id: number): any {
    const requestParams = {
        method: "DELETE",
        headers: {
            ...authService.authHeader(),
        },
    };

    return fetch(`${baseUrl}/` + id, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse)
        .then((req) => {
            return req;
        });
}

function handleResponse(response: any) {
    return response.text().then((text: any) => {
        const data = text && JSON.parse(text);
        return data;
    });
}
