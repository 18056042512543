import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ISorting } from "../../dashboard/DashboardSlice";
import { holdingsService } from "../../services/holdingsService";
import { IPagination } from "../../util/pagination";


export interface IHistoricHoldingAccount {
    accountName: string;
    accountHolder: string;
}


export interface IHistoricHoldingSecurity {
    name: string;
    ticker: string;
}

export interface IHistoricHolding {
    employee: string;
    user: string;
    account: IHistoricHoldingAccount;
    security: IHistoricHoldingSecurity;
    holdingType: string;
    brokerage: string;
    volume: string;
    holdingDate: string;
    lastUpdated: string;
}

export interface IHistoricHoldings {
    historicHoldings: Array<IHistoricHolding>;
    userHistoricHoldings: Array<IHistoricHolding>;
    status: string;
    nextPage: boolean;
}

const initialState: IHistoricHoldings = {
    historicHoldings: [],
    userHistoricHoldings: [],
    nextPage: false,
    status: "idle",
};

export interface IFilter {
    id: string,
    name: string;
    email: string
    category: string[],
    accountHolder?: string
}

export const fetchHistoricHoldings = createAsyncThunk(
    "historicHoldings/fetchHistoricHoldings",
    async (payload: { pagination: IPagination; filter: IFilter, sorting: ISorting[], date: Date }) => {
        const { pagination, filter, sorting, date } = payload;

        const historicHoldings = await holdingsService.getHistoricHoldings(pagination, filter, sorting, date);
        /* Handle errors */

        return historicHoldings;
    }
);


export const fetchHistoricHoldingsAttestation = createAsyncThunk(
    "historicHoldings/fetchHistoricHoldingsAttestation",
    async (sorting: any) => {
        const historicHoldings = await holdingsService.getUserHistoricHoldingsAttestation(sorting)

        return historicHoldings
    }
)


export const fetchUserHistoricHoldings = createAsyncThunk(
    "historicHoldings/fetchUserHistoricHoldings",
    async (payload: { pagination: IPagination; filter: IFilter, sorting: ISorting[], date: Date }) => {
        const { pagination, filter, sorting, date } = payload;

        const userHistoricHoldings = await holdingsService.getUserHistoricHoldings(pagination, filter, sorting, date);
        /* Handle errors */

        return userHistoricHoldings;
    }
);

const historicHoldingsSlice = createSlice({
    name: "historicHoldings",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchHistoricHoldings.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(fetchHistoricHoldings.fulfilled, (state, action) => {
                state.historicHoldings = action.payload.data;
                state.nextPage = action.payload.nextPage;
                state.status = "idle";
            })
            .addCase(fetchHistoricHoldingsAttestation.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(fetchHistoricHoldingsAttestation.fulfilled, (state, action) => {
                state.userHistoricHoldings = action.payload.data;
                state.nextPage = action.payload.nextPage;
                state.status = "idle";
            })
            .addCase(fetchUserHistoricHoldings.fulfilled, (state, action) => {
                state.userHistoricHoldings = action.payload.data;
                state.nextPage = action.payload.nextPage;
                state.status = "idle";
            });
    },
});

/* Selector for state.watched array*/
export const selectHistoricHoldings = (state: any) => state.historicHoldings.historicHoldings;
export const selectUserHistoricHoldings = (state: any) => state.historicHoldings.userHistoricHoldings;

export default historicHoldingsSlice.reducer;
