import { useState } from "react";
// import { isPropertyAccessOrQualifiedName } from "typescript";
// import { isPropertySignature } from "typescript";
// import { deflateRaw } from "zlib";
import trashcan from "../assets/trashcan.svg";
import EditNameModal from "../components/EditNameModal";
import { kebabToTitleCase } from "../util/regexHelpers";
// import Modal from "../components/Modal";
// import { StyledActionButton } from "../components/StyledActionButton";
// import { StyledPageTitle } from "../components/StyledPageTitle";
import DeleteUserModal from "./DeleteUserModal";

interface IListItemProps {
    id: number;
    email: string;
    name: string;
    role: string;
    group: string;
    status: string;
    account: Array<{ account: string; lastUpdated: string; accountHolder: string }>;
    key: number;
    handleDelete(email: string): void;
    handleEdit(id: number, name: string): void;
}

/* Makes up the UserPanelList
 * Set up as component to more gracefully support styling
 * and eventually add buttons/functionality to them.
 */
function UserPanelListItem(props: IListItemProps): JSX.Element {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <tr>
                <td>{props.email}</td>
                <td>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "10px",
                        }}
                    >
                        {props.name}
                        <EditNameModal
                            name={props.name}
                            handleSubmit={props.handleEdit}
                            id={props.id}
                        />
                    </div>
                </td>
                <td>{kebabToTitleCase(props.status)}</td>
                <td>
                    {props.account
                        ? props.account.map(function (name, index) {
                              return (
                                  <>
                                      <div style={{ lineHeight: "22px" }}>
                                          {name.account} ({name.accountHolder})
                                      </div>
                                  </>
                              );
                          })
                        : null}
                </td>

                <td>
                    {props.account
                        ? props.account.map(function (account, index) {
                              if (account.lastUpdated === "--") {
                                  return (
                                      <>
                                          <div style={{ lineHeight: "22px" }}>
                                              {account.lastUpdated}
                                          </div>
                                      </>
                                  );
                              }

                              return (
                                  <>
                                      <div style={{ lineHeight: "22px" }}>
                                          {new Date(
                                              account.lastUpdated
                                          ).toLocaleDateString("en-US", {
                                              timeZone: "UTC",
                                          })}
                                      </div>
                                  </>
                              );
                          })
                        : null}
                </td>

                <td
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                    }}
                >
                    <button
                        style={{
                            background: "transparent",
                            border: "none",
                            cursor: "pointer",
                        }}
                        onClick={(e) => {
                            setShowModal(true);
                            console.log(showModal);
                        }}
                    >
                        <img src={trashcan} alt="" />
                    </button>
                </td>
                <DeleteUserModal
                    email={props.email}
                    handleDelete={props.handleDelete}
                    show={showModal}
                    onClose={() => setShowModal(false)}
                />
            </tr>
        </>
    );
}

export default UserPanelListItem;
