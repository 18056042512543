import redcross from "../../assets/redcross.svg";
import greencheck from "../../assets/greencheck.svg";
import clipboard from "../../assets/clipboard.svg";
import hands from "../../assets/hands.svg";
import { midEllipsis } from "../../util/formatValues";

interface IListItemProps {
    user: string;
    userId: string;
    asset: string;
    address: string;
    status: string;
    selected: boolean;
    onSelect: () => void;
    onFlag: () => void;
    onReview: () => void;
    onCompliance: () => void;
    onWhitelisting: () => void;
}

function SuspiciousWalletsListItem(props: IListItemProps): JSX.Element {
    return (
        <>
            <tr
                className={props.selected ? "suspect-row active" : "suspect-row"}
                onClick={props.onSelect}
            >
                <td>{props.user}</td>
                <td>
                    {midEllipsis(props.address)} [{props.asset}]
                </td>
                <td>{props.status}</td>
                <td
                    className="action-col"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                >
                    <div className="action-wrapper">
                        <span
                            style={{ fontSize: "1.25em" }}
                            className="action tooltip"
                            onClick={(e) => {
                                props.onReview();
                            }}
                        >
                            <img src={clipboard} alt={"Review"} />
                            <span className="tooltiptext">Review</span>
                        </span>
                        <span
                            className="action tooltip"
                            onClick={(e) => {
                                props.onFlag();
                            }}
                        >
                            <img src={redcross} alt={"Flag"} />
                            <span className="tooltiptext">Flag</span>
                        </span>
                        <span
                            className="action tooltip"
                            onClick={(e) => {
                                props.onCompliance();
                            }}
                        >
                            <img src={greencheck} alt={"Compliance"} />
                            <span className="tooltiptext">Compliance</span>
                        </span>
                        <span
                            className="action tooltip"
                            onClick={(e) => {
                                props.onWhitelisting();
                            }}
                        >
                            <img src={hands} alt={"Whitelist"} />
                            <span className="tooltiptext">Whitelist</span>
                        </span>
                    </div>
                </td>
            </tr>
        </>
    );
}

export default SuspiciousWalletsListItem;
