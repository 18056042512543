import { StyledSearchInput } from "../components/StyledFilterInput";
import { StyledSectionHeader } from "../components/StyledSectionHeader";
import TradeReqModal from "./TradeReqModal";
import { StyledActionButtonSecondary } from "../components/StyledActionButtonSecondary";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useInterval from "../util/useInterval";
import { StyledSpacing } from "../components/StyledSpacing";
import cryptoSiteList from "./cryptos";
import {
    deleteWatchedCrypto,
    fetchWatchedCryptos,
    saveNewWatchedCrypto,
    selectWatchedCryptos,
} from "./WatchlistSlice";
import WatchlistCryptoList from "./WatchlistCryptoList";
import { watchlistService } from "../services/watchlistService";
import StaticAutoComplete from "../components/StaticAutoComplete";

const initInfo = {
    cryptoId: "",
    ticker: "",
    name: "",
};



function WatchlistCrypto(): JSX.Element {
    /* Local state for Watchlist*/
    const [activeFilter, setActiveFilter] = useState("");
    const [activeSearch, setActiveSearch] = useState("");

    /* State for Modal */
    const [cryptoInfo, setCryptoInfo] = useState(initInfo);
    const [showModal, setShowModal] = useState(false);

    /* Hooks for selectors, store */
    const watched = useSelector(selectWatchedCryptos);
    const userId = useSelector((state: any) => state.auth.userId);
    const dispatch = useDispatch();
    const watchedLen = watched.length;

    const cryptoList = cryptoSiteList;

    useEffect(() => {
        if (userId !== -1) {
            dispatch(fetchWatchedCryptos(userId));
            // dispatch(fetchWatchedCryptoPrices(watched))
        }
    }, [dispatch, userId, watchedLen]);

    useInterval(() => {
        if (userId !== -1) {
            dispatch(fetchWatchedCryptos(userId));
            // dispatch(fetchWatchedCryptoPrices(watched));
        }
    }, 15000);

    const handleDelete = (ticker: string, unit: string) => {
        /* Only try and remove if we find a matching security ticker. */
        let found = false;
        let id = 0;
        watched.forEach((entity: any) => {
            if (
                entity.ticker.toLowerCase() === ticker.toLowerCase() &&
                entity.unit.toLowerCase() === unit.toLowerCase()
            ) {
                id = entity.id;
                found = true;
                dispatch(deleteWatchedCrypto(id));
            }
        });
        return found;
    };

    const handleAdd = async (event: any) => {
        /* Validate that this security exists before saving it to the DB*/
        let searched = activeSearch.split(" ");
        let input = searched[0];
        let exchange = searched[1].substring(1, searched[1].length - 1);

        const res = await watchlistService.getSearchCrypto(input, exchange);
        var searchedCrypto = res;

        /* TODO: Handle Error Case */
        dispatch(
            saveNewWatchedCrypto({
                userId: userId,
                ticker: searchedCrypto["ticker"],
                name: searchedCrypto["name"],
                exchange: searchedCrypto["exchange"],
                unit: searchedCrypto["unit"],
            })
        );

        setActiveSearch("");
    };

    const handleKeyDown = (e: any) => {
        if (e.key === "Enter") {
            handleAdd(e);
            // setSearchText('');
        }
    };

    const onClose = () => {
        setShowModal(false);
    };

    return (
        <>
            <TradeReqModal show={showModal} onClose={onClose} securityInfo={{ ticker: "", name: "", securityId: "", exchange: "", sector: "", description: "" }} cryptoInfo={cryptoInfo} requestType="CRYPTO" />

            <div style={{ display: "flex", flexWrap: "nowrap" }}>
                <StyledSpacing>
                    <StyledSectionHeader>
                        Add a crypto to your watchlist
                    </StyledSectionHeader>

                    <StaticAutoComplete
                        choices={cryptoList}
                        placeholder="Add another account"
                        activeValue={activeSearch}
                        handleKeyDown={handleKeyDown}
                        onSelect={(value) => {
                            setActiveSearch(value);
                        }}
                    />
                </StyledSpacing>

                <StyledActionButtonSecondary
                    style={{ marginTop: "1em" }}
                    onClick={(e) => {
                        setShowModal(true);
                    }}
                >
                    Request Approval
                </StyledActionButtonSecondary>
            </div>

            <StyledSearchInput
                placeholder="Search Watchlist"
                type="text"
                value={activeFilter}
                onChange={(event) => setActiveFilter(event.target.value)}
            />

            <WatchlistCryptoList
                watchedCrypto={watched}
                activeFilter={activeFilter}
                handleDelete={handleDelete}
                setShowModal={(show, ticker, name, cryptoId) => {
                    setCryptoInfo({
                        cryptoId,
                        ticker: ticker,
                        name: name,
                    });
                    setShowModal(show);
                }}
            />
        </>
    );
}

export default WatchlistCrypto;
