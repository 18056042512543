import { useEffect, useState } from "react";
import trashcan from "../assets/trashcan.svg";
import { StyledBubbleSpan } from "../components/StyledBubbleSpan";
import capitalizeFirstLetter from "../util/capitalizeFirstLetter";
import { allowedEtfsJson } from "./AllowedEtfs";

let allowedEtfs = new Map<string, boolean>();
allowedEtfsJson.etfs.forEach((etf) => {
    allowedEtfs.set(etf, true);
});

interface IWatchlistItemProps {
    securityId: string;
    ticker: string;
    name: string;
    movement: number;
    price: string;
    status: string;
    handleDelete(ticker: string): void;
    setShowModal(show: boolean, ticker: string, name: string, securityId: string): void;
    key: number;
}

/* Makes up the WatchlistList
 * Set up as component to more gracefully support styling
 * and eventually add buttons/functionality to them.
 */
function WatchlistListItem(props: IWatchlistItemProps): JSX.Element {
    const [statusColor, setStatusColor] = useState("black");

    useEffect(() => {
        if (props.status === "RESTRICTED") {
            setStatusColor("#F02C38");
        } else if (props.status === "REQUEST_APPROVAL") {
            setStatusColor("#F0A22C");
        } else if (props.status === "NOT_RESTRICTED") {
            setStatusColor('#22B43A')
        }
    }, [props.status, props.ticker]);

    return (
        <tr>
            <td>{props.ticker}</td>
            <td>{props.name}</td>
            {/* <td style={props.movement<0? {'color': '#F02C38'} : {'color': '#22B43A'}}>{props.price} ({props.movement>0? '+' + props.movement : props.movement})</td> */}
            <td>
                <StyledBubbleSpan
                    style={{
                        background: statusColor,
                        padding: "5px",
                        paddingLeft: "6px",
                        paddingRight: "6px",
                        cursor: props.status === "REQUEST_APPROVAL" ? "pointer" : "default"
                    }}
                >

                    {props.status === "REQUEST_APPROVAL" &&
                        !allowedEtfs.get(props.ticker) ? (
                        <span
                            onClick={(e) => {
                                props.setShowModal(true, props.ticker, props.name, props.securityId);
                            }}
                        >
                            Request approval
                        </span>
                    ) : (
                        capitalizeFirstLetter(props.status)
                    )}
                    {/* {props.status} */}
                </StyledBubbleSpan>
            </td>
            <td>
                <button
                    style={{
                        background: "transparent",
                        border: "none",
                        cursor: "pointer",
                    }}
                    onClick={(e) => {
                        props.handleDelete(props.ticker);
                    }}
                >
                    <img src={trashcan} alt="" />
                </button>
            </td>
            {/* i.e. Delete button goes here */}
        </tr>
    );
}

export default WatchlistListItem;
