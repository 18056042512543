import { authService } from "./authService";

export const fileUploadService = {
    getFileById,
};
const base = process.env.REACT_APP_API_ENDPOINT;
const baseUrl = `${base}/file_uploads`;

function getFileById(payload: { id: string }): any {
    const { id } = payload;
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
        },
    };

    return fetch(`${baseUrl}/get/${id}`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

function handleResponse(response: any) {
    return response.text().then((text: any) => {
        const data = text && JSON.parse(text);
        return data;
    });
}
