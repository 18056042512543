import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StyledActionButton } from "../../components/StyledActionButton";
import { StyledModalItem } from "../../components/StyledModalItem";
import { StyledSectionHeader } from "../../components/StyledSectionHeader";
import { StyledSelect } from "../../components/StyledSelect";
import TablePageFooter, { DefaultPagination } from "../../components/TablePageFooter";
import SuspiciousWalletsList from "./SuspiciousWalletsList";
import UserAutocomplete, { UserSuggestion } from "../../components/UserAutoComplete";
import CryptoPrivateAssetAutoComplete, {
    CryptoSuggestion,
} from "../../components/CryptoPrivatAssetAutoComplete";
import {
    ISorting,
    ISuspiciousWallet,
    SortingField,
    fetchSuspiciousWallets,
    selectSuspiciousWallet,
    fetchSuspiciousWalletRelated,
    selectSuspiciousWalletRelated,
    flagSuspiciousWallet,
    reviewSuspiciousWallet,
    complianceSuspiciousWallet,
    IFilter,
    whitelistingSuspiciousWallet,
    IRelativeSorting,
    RelativeSortingField,
} from "../AnalyticsSlice";
import { FilterField, filterLabel, IFilterDisplay } from "../AnalyticFilter";
import RelatedWalletTxList from "../RelatedWalletTxList";
import ComplianceModal from "../ComplianceModal";
import { IPagination } from "../../util/pagination";

export interface IWalletFilterDisplay extends Omit<IFilterDisplay, "flag"> {}

function SuspiciousWallets(): JSX.Element {
    const dispatch = useDispatch();
    const records = useSelector(selectSuspiciousWallet);
    const related = useSelector(selectSuspiciousWalletRelated);

    const requestRecords = () => {
        dispatch(
            fetchSuspiciousWallets({
                ...pagination,
                filter: {
                    ...filterMultiple,
                },
                sorting: [sorting],
            })
        );
    };

    const complianceRecord = (r: ISuspiciousWallet, c: string) => {
        const p = new Promise((resolve) => {
            dispatch(
                complianceSuspiciousWallet({
                    record: r,
                    comment: c,
                })
            );
            resolve({});
        });
        p.then(() => {
            requestRecords();
        });
    };

    const flagRecord = (r: ISuspiciousWallet) => {
        const p = new Promise((resolve) => {
            dispatch(
                flagSuspiciousWallet({
                    record: r,
                })
            );
            resolve({});
        });
        p.then(() => {
            requestRecords();
        });
    };

    const reviewRecord = (r: ISuspiciousWallet) => {
        const p = new Promise((resolve) => {
            dispatch(
                reviewSuspiciousWallet({
                    record: r,
                })
            );
            resolve({});
        });
        p.then(() => {
            requestRecords();
        });
    };

    const whitelisting = (r: ISuspiciousWallet) => {
        const p = new Promise((resolve) => {
            dispatch(
                whitelistingSuspiciousWallet({
                    record: r,
                })
            );
            resolve({});
        });
        p.then(() => {
            requestRecords();
        });
    };

    const requestRelated = (r: ISuspiciousWallet) => {
        dispatch(
            fetchSuspiciousWalletRelated({
                address: r.partyAddress,
                cryptoId: r.cryptoId,
                sorting: [relativeSorting],
                ...paginationRelated,
            })
        );
    };

    /**
     * Analytics state
     */

    const defaultFilters: IFilter = {};
    const defaultFilterDisplays: IWalletFilterDisplay = {};

    const company = useSelector((state: any) => state.auth.companyName);

    const [pagination, setPagination] = useState<IPagination>(DefaultPagination());
    const [filterMultiple, setFilterMultiple] = useState<IFilter>(defaultFilters);
    const [filterMultipleDisplay, setFilterMultipleDisplay] =
        useState<IFilterDisplay>(defaultFilterDisplays);
    const [filter, setFilter] = useState<FilterField>("userId");
    const [search, setSearch] = useState("");

    const [paginationRelated, setPaginationRelated] = useState<IPagination>(DefaultPagination());
    const [activeSuspect, setActiveSuspect] = useState<ISuspiciousWallet>();
    const [compliance, setCompliance] = useState<ISuspiciousWallet>();

    const [sorting, setSorting] = useState<ISorting>({
        field: "numTrades",
        direction: "asc",
    });
    const [relativeSorting, setRelativeSorting] = useState<IRelativeSorting>({
        field: "date",
        direction: "desc",
    });

    const version = useSelector((state: any) => state.auth.version);

    const resetFilter = () => {
        setFilterMultiple(defaultFilters);
        setFilterMultipleDisplay(defaultFilterDisplays);
    };

    const filterOptions = useMemo(() => {
        return (
            <>
                <option value="asset">Asset</option>
                <option value="userId">User</option>
            </>
        );
    }, [company, version]);

    const setFilterType = (filterType: FilterField) => {
        setSearch("");
        setFilter(filterType);
    };

    const removeFilter = (filterName: string) => {
        if (filterName in filterMultiple) {
            delete (filterMultiple as any)[filterName];
        }
        if (filterName in filterMultipleDisplay) {
            delete (filterMultipleDisplay as any)[filterName];
        }

        setFilterMultiple({ ...filterMultiple });
        setFilterMultipleDisplay({ ...filterMultipleDisplay });
    };

    const setUserId = (user: UserSuggestion | undefined) => {
        if (user !== undefined) {
            filterMultiple.userId = user.id;
            filterMultipleDisplay.userId = user.name;
        } else {
            delete filterMultiple.userId;
            delete filterMultipleDisplay.userId;
        }
        setFilterMultiple({ ...filterMultiple });
        setFilterMultipleDisplay({ ...filterMultipleDisplay });
    };

    const setAssetCrypto = (crypto: CryptoSuggestion | undefined) => {
        if (crypto !== undefined) {
            filterMultiple.asset = {id: crypto.id, type: "CRYPTO"};
            filterMultipleDisplay.asset = `${crypto.name} - ${crypto.platform} (${crypto.ticker})`;
        } else {
            delete filterMultiple.asset;
            delete filterMultipleDisplay.asset;
        }
        setFilterMultiple({ ...filterMultiple });
        setFilterMultipleDisplay({ ...filterMultipleDisplay });
    };

    useEffect(() => {
        requestRecords();
    }, [dispatch, pagination, sorting]);

    useEffect(() => {
        if (paginationRelated.pageNumber == 1 && activeSuspect) {
            // directly request related
            requestRelated(activeSuspect);
        } else {
            setPaginationRelated({
                ...paginationRelated,
                pageNumber: 1
            });
        }
    }, [dispatch, activeSuspect]);

    useEffect(() => {
        if (activeSuspect) {
            requestRelated(activeSuspect);
        }
    }, [dispatch, paginationRelated, relativeSorting]);

    return (
        <div>
            <StyledSectionHeader>Filters</StyledSectionHeader>

            <div style={{ display: "flex", flexDirection: "row" }}>
                <StyledModalItem style={{ display: "flex", width: "25%" }}>
                    <StyledSelect
                        id="filterValue"
                        name="filterValue"
                        style={{ width: "100%" }}
                        value={filter}
                        onChange={(event) => {
                            setFilterType(event.target.value as FilterField);
                        }}
                    >
                        {filterOptions}
                    </StyledSelect>
                </StyledModalItem>

                <div style={{ flex: 1 }}>
                    {
                        <>
                            {filter === "userId" && (
                                <div style={{ position: "relative" }}>
                                    <UserAutocomplete
                                        value={search}
                                        onSelect={setUserId}
                                        onEnter={setUserId}
                                        style={{ width: "100%" }}
                                        placeholder={"Filter by name or email"}
                                    />
                                </div>
                            )}
                            {filter === "asset" ? (
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <div
                                        style={{ position: "relative", width: "100%" }}
                                    >
                                        <CryptoPrivateAssetAutoComplete
                                            placeholder={"Ticker or name"}
                                            value={search}
                                            style={{ width: "100%" }}
                                            onSelect={setAssetCrypto}
                                            onEnter={setAssetCrypto}
                                        />
                                    </div>
                                </div>
                            ) : null}
                        </>
                    }
                </div>

                <StyledModalItem
                    style={{
                        display: "flex",
                        width: "25%",
                        flexDirection: "row",
                        gap: ".25em",
                        margin: "0 .25em",
                    }}
                >
                    <StyledActionButton style={{ flex: 1 }} onClick={resetFilter}>
                        Clear Filters
                    </StyledActionButton>
                    <StyledActionButton
                        style={{ flex: 1 }}
                        onClick={() => {
                            setPagination({
                                ...pagination,
                                pageNumber: 1,
                            });
                            requestRecords();
                        }}
                    >
                        Apply
                    </StyledActionButton>
                </StyledModalItem>
            </div>

            <div
                style={{ display: "flex", flexDirection: "row", marginBottom: "1rem" }}
            >
                {Object.keys(filterMultiple)
                    .filter((fieldName: string) => fieldName !== "endDate")
                    .map((fieldName: string, index: number) => (
                        <span key={fieldName}>
                            {(filterMultiple as any)[fieldName] !== ""
                                ? filterLabel(
                                      fieldName,
                                      filterMultipleDisplay,
                                      filterMultiple,
                                      removeFilter
                                  )
                                : null}
                        </span>
                    ))}
            </div>

            <div style={{ display: "flex", flexDirection: "row", gap: "0.5rem" }}>
                <div className="inspect-column">
                    <SuspiciousWalletsList
                        records={records.data}
                        selected={activeSuspect}
                        sorting={sorting}
                        setSorting={(s) =>
                            setSorting({
                                field: s.field as SortingField,
                                direction: s.direction,
                            })
                        }
                        onSelect={setActiveSuspect}
                        onFlag={flagRecord}
                        onCompliance={setCompliance}
                        onReview={reviewRecord}
                        onWhitelisting={whitelisting}
                    />
                    <TablePageFooter
                        nextPage={records.nextPage}
                        pagination={pagination}
                        setPageNumber={(n) =>
                            setPagination({ ...pagination, pageNumber: n })
                        }
                        setPageSize={(s) =>
                            setPagination({ ...pagination, pageSize: s })
                        }
                    />
                </div>
                <div className="inspect-column">
                    <RelatedWalletTxList
                        txList={related.data}
                        setSorting={(s) =>
                            setRelativeSorting({
                                field: s.field as RelativeSortingField,
                                direction: s.direction,
                            })
                        }
                        sorting={relativeSorting}
                    />
                    <TablePageFooter
                        nextPage={related.nextPage}
                        pagination={paginationRelated}
                        setPageNumber={(n) =>
                            setPaginationRelated({ ...paginationRelated, pageNumber: n })
                        }
                        setPageSize={(s) =>
                            setPaginationRelated({ ...paginationRelated, pageSize: s })
                        }
                    />
                </div>
            </div>

            <ComplianceModal<ISuspiciousWallet>
                record={compliance}
                onClose={() => setCompliance(undefined)}
                title={`Compliance on wallet ${compliance?.partyAddress} [${compliance?.asset}]`}
                onCompliance={complianceRecord}
            />
        </div>
    );
}

export default SuspiciousWallets;
