import { StyledInputField } from "../StyledInputField";
import { StyledSelect } from "../StyledSelect";
import currencies from "./currencies";

interface IFiatInputProps {
    style?: any;
    placeholder: string;
    showError: boolean;
    currency: string;
    setCurrency(currency: string): void;
    value: string;
    setValue(value: string): void;
    onChange(e: any): void;
    name: string;
}

function FiatInput(props: IFiatInputProps): JSX.Element {
    const {
        style,
        placeholder,
        currency,
        setCurrency,
        value,
        setValue,
        onChange,
        name,
    } = props;

    return (
        <div style={{ display: "flex", ...style }}>
            <StyledInputField
                style={{ width: "75%" }}
                placeholder={placeholder}
                type="number"
                step="any"
                min={0}
                value={value}
                name={name}
                onChange={(event) => {
                    setValue(event.target.value);
                    onChange(event);
                }}
                onWheel={(e) => e.currentTarget.blur()}
            />
            <StyledSelect
                style={{ width: "25%" }}
                value={currency}
                onChange={(event) => {
                    setCurrency(event.target.value);
                }}
            >
                {currencies.map((currency: string) => {
                    return <option value={currency}>{currency}</option>;
                })}
            </StyledSelect>
        </div>
    );
}

export default FiatInput;
