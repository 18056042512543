import { IRelatedTransaction } from "./AnalyticsSlice";
import SmartList from "../components/SmartList";
import { ISorting } from "../dashboard/DashboardSlice";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import RelatedTxListItem from "./RelatedTxListItem";

interface IListProps {
    txList: Array<IRelatedTransaction>;
    sorting: ISorting;
    setSorting: (sorting: ISorting) => void;
}

function RelatedTxList(props: IListProps): JSX.Element {
    const { txList, sorting, setSorting } = props;

    const version = useSelector((state: any) => state.auth.version);
    const headerData = useMemo(() => {
        return [
            { fieldText: "Account", fieldValue: "account" },
            { fieldText: "Asset", fieldValue: "asset" },
            { fieldText: "Tx. Type", fieldValue: "transaction_type" },
            { fieldText: "Value (USD)", fieldValue: "value" },
            { fieldText: "Date", fieldValue: "date" },
        ].filter(
            (header) => version === "crypto_base" || header.fieldText !== "Account Name"
        );
    }, [version]);

    const generateItem = (tx: IRelatedTransaction, idx: number) => (
        <RelatedTxListItem
            key={idx}
            account={tx.accountHolder}
            asset={tx.asset}
            value={tx.volume * tx.price}
            transactionType={tx.transactionType}
            transactionDate={tx.transactionDate}
        />
    );

    return (
        <div className="table-container">
            <SmartList
                headerData={headerData}
                arr={txList}
                generateItem={generateItem}
                sorting={sorting}
                setSorting={setSorting}
                disabled={headerData
                    .filter((c) => !["date", "value"].includes(c.fieldValue))
                    .map((c) => c.fieldValue)}
            />
        </div>
    );
}

export default RelatedTxList;
