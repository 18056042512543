import trashcan from "../assets/trashcan.svg";
import { StyledTableActionButton } from "../components/StyledTableActionButton";
import { StyledSpanRedBubble } from "../components/StyledSpanRedBubble";
import { IAccount } from "./AccountsSlice";

import { plaidService } from "../services/plaidService";
import { StyledSpanYellowBubble } from "../components/StyledSpanYellowBubble";
import { useState } from "react";
import { StyledTooltip } from "../components/StyledTooltip";
import { getDateDiffInDay } from "../util/getDateDiffInDay";

interface IAccountListItem {
    index: number;
    account: IAccount;
    setFastlinkProvider: (fastlinkProvider: number) => void;
    setPlaidToken: (plaidToken: string) => void;
    setAccountId: (accountId: string) => void;
    isConnectable: boolean;
    isBrokerageIntegration: boolean;
    onDelete: () => void;
    onUpload: () => void;
}

const AccountListItem: React.FC<IAccountListItem> = ({
    index,
    account,
    isConnectable,
    isBrokerageIntegration,
    setFastlinkProvider,
    setPlaidToken,
    setAccountId,
    onDelete,
    onUpload,
}) => {
    const showFeeding = account.lastPull && getDateDiffInDay(new Date(account.lastPull), new Date()) < 8;
    const handleClick = (provider?: string, externalAccountId?: string) => {
        if (provider === "YODLEE" && externalAccountId !== undefined) {
            setFastlinkProvider(parseInt(externalAccountId));
        }
        if (provider === "PLAID" && externalAccountId !== undefined) {
            plaidService.getLinkToken(externalAccountId).then((data) => {
                setPlaidToken(data.link_token);
            });
        }
        setAccountId(account.id);
    };
    const [showDescription, setShowDescription] = useState<number | null>(null);

    return (
        <tr
            key={index}
            onMouseEnter={() => setShowDescription(index)}
            onMouseLeave={() => setShowDescription(null)}
        >
            <td>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        position: "relative",
                    }}
                >
                    {showDescription === index && account.description ? (
                        <StyledTooltip>{account.description}</StyledTooltip>
                    ) : null}
                    {account.investmentAccount.name}
                </div>
            </td>

            <td>
                <span>
                    {account.lastPull === null ? (
                        account.isConnected === false ? (
                            <StyledSpanRedBubble>
                                <b>&nbsp;&nbsp;Never&nbsp;&nbsp;</b>
                            </StyledSpanRedBubble>
                        ) : (
                            <StyledSpanYellowBubble>
                                <b>&nbsp;&nbsp;Pending&nbsp;&nbsp;</b>
                            </StyledSpanYellowBubble>
                        )
                    ) : (
                        <>
                            {new Date(account.lastPull).toLocaleDateString("en-US", {
                                timeZone: "UTC",
                            })}{" "}
                        </>
                    )}
                </span>
            </td>
            <td>
                {isConnectable ? (
                    <>
                        {" "}
                        <StyledTableActionButton
                            style={{
                                background: showFeeding
                                    ? "#22B43A"
                                    : account.connectionStatus === "REAUTH_REQUIRED"
                                    ? "#F0A22C"
                                    : "#F02C38",
                            }}
                            onClick={
                                !showFeeding
                                    ? (event) => {
                                          handleClick(
                                              account.investmentAccount.linkedAccount &&
                                                  account.investmentAccount
                                                      .linkedAccount
                                                      .linkedAccountProvider,
                                              account.investmentAccount.linkedAccount &&
                                                  account.investmentAccount
                                                      .linkedAccount.externalAccountId
                                          );
                                      }
                                    : undefined
                            }
                        >
                            {showFeeding ? (
                                <span>Feeding</span>
                            ) : account.connectionStatus === "REAUTH_REQUIRED" ? (
                                <span>Reconnect</span>
                            ) : (
                                <span>Connect</span>
                            )}
                        </StyledTableActionButton>
                    </>
                ) : (
                    <>
                        {" "}
                        <StyledTableActionButton
                            onClick={(event) => {
                                if (
                                    account.investmentAccount.name ===
                                        "Julius Baer CH" ||
                                    account.investmentAccount.name === "Test Bank"
                                ) {
                                    window.open(
                                        "https://platform.flanks.io/link?token=52e0b7b2-20b3-4a81-a939-ce52c748cbad",
                                        "_blank"
                                    );
                                } else {
                                    onUpload();
                                }
                            }}
                        >
                            Update
                        </StyledTableActionButton>
                    </>
                )}
            </td>
            <td>{account.accountNumber}</td>
            {isBrokerageIntegration ? (
                <td>
                    {new Date(account.accountCreationDate).toLocaleDateString("en-US", {
                        timeZone: "UTC",
                    })}
                </td>
            ) : null}
            <td>
                <button
                    style={{
                        background: "transparent",
                        border: "none",
                        cursor: "pointer",
                    }}
                    onClick={onDelete}
                >
                    <img src={trashcan} alt="" />
                </button>
            </td>
        </tr>
    );
};

export default AccountListItem;
