import { useSelector, useDispatch } from "react-redux";
import { StyledSectionHeader } from "../components/StyledSectionHeader";
import { StyledTableContainer } from "../components/StyledTable";
import TradesList from "../dashboard/TradesList";
import {
    fetchClearedTrades,
    fetchClosedTrades,
    fetchPendingTrades,
    ISorting,
    selectClearedTrades,
    selectClosedTrades,
    selectPendingTrades,
} from "../dashboard/DashboardSlice";
import { useEffect, useMemo, useState } from "react";
import { StyledActionButtonSecondary } from "../components/StyledActionButtonSecondary";
// import { StyledSpacing } from "../components/StyledSpacing";
import {
    fetchNotifications,
    selectNotifications,
    deleteNotification
} from "./NotificationSlice";
import NotificationList from "./NotificationList";
import TablePageFooter, { DefaultPagination } from "../components/TablePageFooter";
import { IPagination } from "../util/pagination";

function ClearanceStatus(): JSX.Element {
    const dispatch = useDispatch();
    const clearedTrades = useSelector(selectClearedTrades);
    const pendingTrades = useSelector(selectPendingTrades);
    const closedTrades = useSelector(selectClosedTrades);
    const userId = useSelector((state: any) => state.auth.userId);
    const notifications = useSelector(selectNotifications);
    const [pagination, setPagination] = useState<IPagination>(DefaultPagination());
    const [paginationCleared, setPaginationCleared] = useState<IPagination>(DefaultPagination());
    const [paginationPending, setPaginationPending] = useState<IPagination>(DefaultPagination());
    const [paginationClosed, setPaginationClosed] = useState<IPagination>(DefaultPagination());
    const nextPage = useSelector((state: any) => state.notification.nextPage);
    const nextPageCleared = useSelector(
        (state: any) => state.dashboard.nextPageCleared
    );
    const nextPagePending = useSelector(
        (state: any) => state.dashboard.nextPagePending
    );
    const nextPageClosed = useSelector((state: any) => state.dashboard.nextPageClosed);

    const [showLiveClearedTrades, setShowLiveClearedTrades] = useState(true);
    const [showPendingTrades, setShowPendingTrades] = useState(false);
    const [showClosedTrades, setShowClosedTrades] = useState(false);
    const [sorting, setSorting] = useState<ISorting>({ field: 'dateSubmitted', direction: 'desc' })
    const sortingByDate: ISorting[] = useMemo(() => {
        if (sorting.field === 'dateSubmitted') return [sorting]
        return [
            sorting,
            { field: 'dateSubmitted', direction: 'desc' },
        ]

    }, [sorting])

    const handleDeleteTrade = (employee: string) => {
        return;
    };

    useEffect(() => {
        dispatch(
            fetchClearedTrades({
                userId: userId,
                pagination: paginationCleared,
                sorting: sortingByDate
            })
        );
    }, [dispatch, userId, paginationCleared, sortingByDate]);

    useEffect(() => {
        dispatch(
            fetchPendingTrades({
                userId: userId,
                pagination: paginationPending,
                sorting: sortingByDate
            })
        );
    }, [dispatch, userId, paginationPending, sortingByDate]);

    useEffect(() => {
        dispatch(
            fetchClosedTrades({
                userId: userId,
                pagination: paginationClosed,
                sorting: sortingByDate
            })
        );
    }, [dispatch, userId, paginationClosed, sortingByDate]);

    useEffect(() => {
        dispatch(
            fetchNotifications(pagination)
        );
    }, [dispatch, userId, pagination]);

    function showTrade(tradeType: string) {
        if (tradeType === "live") {
            setShowLiveClearedTrades(true);
            setShowPendingTrades(false);
            setShowClosedTrades(false);
        } else if (tradeType === "pending") {
            setShowLiveClearedTrades(false);
            setShowPendingTrades(true);
            setShowClosedTrades(false);
        } else if (tradeType === "closed") {
            setShowLiveClearedTrades(false);
            setShowPendingTrades(false);
            setShowClosedTrades(true);
        }
    }

    const removeNotification = (id: string) => {
        dispatch(deleteNotification(id));
        return true;
    };

    return (
        <div>
            <StyledSectionHeader>Notifications</StyledSectionHeader>
            <StyledTableContainer>
                <NotificationList
                    notifications={notifications}
                    activeFilter={[]}
                    filterValue={[]}
                    handleDelete={removeNotification}
                />
            </StyledTableContainer>

            <TablePageFooter
                nextPage={nextPage}
                pagination={pagination}
                setPageNumber={(n) =>
                    setPagination({ ...pagination, pageNumber: n })
                }
                setPageSize={(s) =>
                    setPagination({ ...pagination, pageSize: s })
                }
            />

            <StyledSectionHeader>Trade Requests</StyledSectionHeader>

            <StyledActionButtonSecondary
                style={
                    showLiveClearedTrades
                        ? { width: "33.3%", background: "#445d95" }
                        : { width: "33.3%" }
                }
                onClick={() => showTrade("live")}
            >
                Live Cleared Trades
            </StyledActionButtonSecondary>
            <StyledActionButtonSecondary
                style={
                    showPendingTrades
                        ? { width: "33.3%", background: "#445d95" }
                        : { width: "33.3%" }
                }
                onClick={() => showTrade("pending")}
            >
                Trades Pending Clearance
            </StyledActionButtonSecondary>
            <StyledActionButtonSecondary
                style={
                    showClosedTrades
                        ? { width: "33.3%", background: "#445d95" }
                        : { width: "33.3%" }
                }
                onClick={() => showTrade("closed")}
            >
                Trades Closed
            </StyledActionButtonSecondary>

            {showLiveClearedTrades ? (
                <>
                    <StyledTableContainer>
                        <TradesList
                            trades={clearedTrades}
                            handleDelete={handleDeleteTrade}
                            tradeType={"cleared"}
                            setSorting={setSorting}
                            sorting={sorting}
                        />
                    </StyledTableContainer>

                    <TablePageFooter
                        nextPage={nextPageCleared}
                        pagination={paginationCleared}
                        setPageNumber={(n) =>
                            setPaginationCleared({ ...paginationCleared, pageNumber: n })
                        }
                        setPageSize={(s) =>
                            setPaginationCleared({ ...paginationCleared, pageSize: s })
                        }
                    />
                </>
            ) : null}

            {showPendingTrades ? (
                <>
                    <StyledTableContainer>
                        <TradesList
                            trades={pendingTrades}
                            handleDelete={handleDeleteTrade}
                            setSorting={setSorting}
                            sorting={sorting}
                            tradeType={"pending"}
                        />
                    </StyledTableContainer>

                    <TablePageFooter
                        nextPage={nextPagePending}
                        pagination={paginationPending}
                        setPageNumber={(n) =>
                            setPaginationPending({ ...paginationPending, pageNumber: n })
                        }
                        setPageSize={(s) =>
                            setPaginationPending({ ...paginationPending, pageSize: s })
                        }
                    />
                </>
            ) : null
            }

            {
                showClosedTrades ? (
                    <>
                        <StyledTableContainer>
                            <TradesList
                                trades={closedTrades}
                                setSorting={setSorting}
                                sorting={sorting}
                                handleDelete={handleDeleteTrade}
                                tradeType={"closed"}
                            />
                        </StyledTableContainer>
                        <TablePageFooter
                            nextPage={nextPageClosed}
                            pagination={paginationClosed}
                            setPageNumber={(n) =>
                                setPaginationClosed({ ...paginationClosed, pageNumber: n })
                            }
                            setPageSize={(s) =>
                                setPaginationClosed({ ...paginationClosed, pageSize: s })
                            }
                        />
                    </>
                ) : null
            }
        </div >
    );
}

export default ClearanceStatus;
