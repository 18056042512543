//The jsxRuntime pragma is necessary to get Emotion and React to work w/ latest Create-React-App version
/** @jsxRuntime classic */
/** @jsx jsx */
import styled from "@emotion/styled";

export const StyledActionButton = styled.button`
    minWidth: 50%;
    background: #2c71f0;
    font-family: Lato, sans-serif;
    color: #ffffff;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 5px;
    height: 40px;
    font-weight: bold;
    font-style: normal;
    line-height: 19px;
    font-size: 16px;
    cursor: pointer;

    :disabled {
        opacity: 0.5;
        cursor: auto;
    }
`;
