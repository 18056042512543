import { ThemeProvider } from "@emotion/react";
import React, { useEffect } from "react";
import { useState } from "react";
import { BiX } from "react-icons/bi";
import { useSelector } from "react-redux";
import { StyledActionButton } from "../components/StyledActionButton";
import { StyledInputField } from "../components/StyledInputField";
import { StyledModalBackground } from "../components/StyledModalBackground";
import { StyledModalBody } from "../components/StyledModalBody";
import { StyledModalItem } from "../components/StyledModalItem";
import { accountsService } from "../services/accountsService";

interface IProps {
    forwardedRef: any;
    title: string;
    exchangeId: string;
    exchangeName: string;
    supported: boolean;
    docs: string;
    onSubmit(
        ccxt_exchange_id: string,
        exchangeId: string,
        apiKey: string,
        apiSecret: string,
        apiPassphrase?: string
    ): void;

    onSubmitOAuth(exchangeId: string): void;
}

function ConnectCrypto(props: IProps): JSX.Element {
    const {
        exchangeId,
        exchangeName,
        forwardedRef,
        title,
        supported,
        docs,
        onSubmit,
        onSubmitOAuth,
    } = props;
    console.log(props);
    const company = useSelector((state: any) => state.auth.companyName);
    const [open, setOpen] = useState(false);

    const [passwordRequired, setPasswordRequired] = useState(false);
    const [uuidRequired, setUuidRequired] = useState(false);

    const [requiresOAuth, setRequiresOAuth] = useState(false);
    const [requiresApi, setRequiresApi] = useState(false);
    const [selection, setSelection] = useState("");
    const [secretRequired, setSecretRequired] = useState(false);
    const [activeApiKey, setActiveApiKey] = useState("");
    const [activeApiSecret, setActiveApiSecret] = useState(
        exchangeName === "Celsius" || exchangeName === "Anchorage"
            ? "ARGUS|PLACEHOLDER"
            : ""
    );
    const [activeApiPassphrase, setActiveApiPassphrase] = useState("");
    const [, setActiveApiUuid] = useState("");
    let windowHeight = "60%";
    if (passwordRequired || uuidRequired) {
        windowHeight = "70%";
    }
    if (passwordRequired && uuidRequired) {
        windowHeight = "87%";
    }

    useEffect(() => {
        console.log(exchangeName, exchangeId);
        if (exchangeId && supported) {
            accountsService.getOAuthMethod(exchangeId).then(async (res: any) => {
                const data = await res.json();
                data.forEach((item: any) => {
                    if (item.method === "api_key") {
                        setRequiresApi(true);
                        if (item.params.indexOf("secret") > -1) {
                            setSecretRequired(true);
                        }
                        if (item.params.indexOf("passphrase") > -1) {
                            setPasswordRequired(true);
                        }
                    } else if (item.method === "oauth2") {
                        setRequiresOAuth(true);
                    }
                });
            });
        }
    }, [exchangeId, exchangeName, company, supported]);

    const revertSelection = () => {
        if (requiresApi && requiresOAuth) {
            setSelection("both");
        } else if (requiresApi) {
            setSelection("api");
        }
    };
    useEffect(() => {
        revertSelection();
    }, [requiresApi, requiresOAuth]);
    return (
        <StyledModalBackground
            style={{ visibility: open ? "visible" : "hidden" }}
            onClick={(e) => {
                setOpen(false);
                revertSelection();
            }}
        >
            <StyledModalBody
                ref={forwardedRef}
                style={{
                    visibility: open ? "visible" : "hidden",
                    display: "flex",
                    height: "50%",
                }}
                onClick={
                    open
                        ? (e) => {
                              e.stopPropagation();
                          }
                        : requiresApi
                        ? (e) => {
                              e.stopPropagation();
                              if (requiresApi) {
                                  setOpen(true);
                              }
                          }
                        : (e) => {
                              onSubmitOAuth(exchangeId);
                          }
                }
            >
                <div
                    style={{
                        width: "100%",
                        height: "50px",
                        background: "#2C71F0",
                        color: "#FFFFFF",
                        fontWeight: "bold",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        boxSizing: "border-box",
                        borderTopRightRadius: "5px",
                        borderTopLeftRadius: "5px",
                        zIndex: 1,
                    }}
                >
                    {title}
                    <BiX
                        onClick={(event) => {
                            setOpen(false);
                            revertSelection();
                        }}
                        style={{
                            position: "absolute",
                            right: "10px",
                            cursor: "pointer",
                        }}
                    />
                </div>
                {selection === "api" && (
                    <div style={{}}>
                        <a
                            href="https://instructions.tryargus.com"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Get instructions on how to find your keys here
                        </a>

                        <StyledModalItem>
                            <label style={{ paddingBottom: "0%" }}>API Key:</label>
                            <StyledInputField
                                value={activeApiKey}
                                onChange={(event) => {
                                    setActiveApiKey(event.target.value);
                                }}
                            />
                        </StyledModalItem>
                        {secretRequired && (
                            <StyledModalItem>
                                <label style={{ paddingBottom: "0%" }}>
                                    API Secret:
                                </label>
                                <StyledInputField
                                    value={activeApiSecret}
                                    onChange={(event) => {
                                        setActiveApiSecret(event.target.value);
                                    }}
                                />
                            </StyledModalItem>
                        )}
                        {passwordRequired ? (
                            <StyledModalItem>
                                <label style={{ paddingBottom: "0%" }}>
                                    API Password:
                                </label>
                                <StyledInputField
                                    value={activeApiPassphrase}
                                    onChange={(event) => {
                                        setActiveApiPassphrase(event.target.value);
                                    }}
                                />
                            </StyledModalItem>
                        ) : null}
                        {uuidRequired ? (
                            <StyledModalItem>
                                <label style={{ paddingBottom: "0%" }}>API Uuid:</label>
                                <StyledInputField
                                    value={activeApiPassphrase}
                                    onChange={(event) => {
                                        setActiveApiUuid(event.target.value);
                                    }}
                                />
                            </StyledModalItem>
                        ) : null}

                        <StyledModalItem>
                            <StyledActionButton
                                onClick={(event) => {
                                    onSubmit(
                                        "",
                                        exchangeId,
                                        activeApiKey,
                                        activeApiSecret,
                                        activeApiPassphrase
                                    );
                                    setOpen(false);
                                    setActiveApiKey("");
                                    setActiveApiSecret("");
                                    setActiveApiPassphrase("");
                                }}
                            >
                                Connect
                            </StyledActionButton>
                        </StyledModalItem>
                    </div>
                )}
                {selection === "both" && (
                    <div>
                        <a href="#" onClick={(event) => onSubmitOAuth(exchangeId)}>
                            Connect via OAuth
                        </a>

                        <br />

                        <StyledActionButton
                            onClick={(event) => {
                                setSelection("api");
                            }}
                        >
                            Connect Via Api Key
                        </StyledActionButton>
                    </div>
                )}
            </StyledModalBody>
        </StyledModalBackground>
    );
}
export default ConnectCrypto;
