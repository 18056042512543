import { useSelector } from 'react-redux';
import { Route, Redirect } from "react-router-dom";
import { selectAsAdmin, selectUserRole, selectVersion } from '../auth/AuthSlice';
import MainLayout from '../layouts/MainLayout';
import { path_in_version } from '../nav/util';
import { navData } from '../nav/navData';

function AdminRoute({ children, ...rest }: any) {
    const asAdmin = useSelector(selectAsAdmin);
    const userRole = useSelector(selectUserRole);
    const version = useSelector(selectVersion);

    return (
        <Route
            {...rest}
            render={(props) => {

                // Redirects to homepage is user role != "Admin" and account is not logged in as an admin
                if (!asAdmin || userRole !== "Admin") {
                    return <Redirect to={{ pathname: "/", state: { from: props.location } }} />
                } else if (!userRole) {
                    // Redirect to login if userRole isn't found
                    return (
                        <Redirect to={{ pathname: "/partner_login", state: { from: props.location } }} />
                    )
                } else if (path_in_version(props.location.pathname, version, navData) || !version) {
                    return <MainLayout>{children}</MainLayout>
                } else {
                    return (
                        <Redirect to={{ pathname: "/userPanel", state: { from: props.location } }} />
                    )
                }
            }}
        />
    );
}
export default AdminRoute;
