import { useState } from "react";

// function Representation({ children }: { children: any }, {isChecked}: {isChecked: boolean}): JSX.Element {
interface IRepresentation {
    style: { attestation: any, label: any };
    children: any;
    value: "YES" | "NO" | undefined;
    onCheck(value: "YES" | "NO" | undefined): void;
    name: string;
}

function Representation(props: IRepresentation): JSX.Element {
    const { style, children, value, onCheck, name } = props;

    /* 
        Uses flexbox to align checkboxes and labels as desired.

        display: block in the label is needed to force the lines to properly space vertically.
    */

    return (
        <div style={{ width: "50%", display: "flex", marginBottom: "16px", ...style.attestation }}>
            <div style={{ display: "flex" }}>
                <input name={name} checked={value === "YES"} onChange={(event) => { value === "NO" || value === undefined ? onCheck("YES") : onCheck(undefined) }} id={`attest-${children.replaceAll(" ", "-").toLowerCase()}`} type="checkbox" />
                <input name={name} checked={value === "NO"} onChange={(event) => { value === "YES" || value === undefined ? onCheck("NO") : onCheck(undefined) }} id={`attest-${children.replaceAll(" ", "-").toLowerCase()}`} type="checkbox" />
            </div>
            <div>
                <label htmlFor={`attest-${children.replaceAll(" ", "-").toLowerCase()}`}
                    style={{ fontSize: "11px", lineHeight: "15px", whiteSpace:'pre-wrap', display: "block", ...style.label }}>
                    {children}
                </label>
            </div>
        </div>
    );
}

export default Representation;