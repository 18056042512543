import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { notificationService } from "../services/notificationService";
import { IPagination } from "../util/pagination";

export interface INotification {
    id: string;
    date: string;
    message: string;
    sender: string;
}

export interface INotifications {
    notifications: Array<INotification>;
    nextPage: boolean;
    status: string;
}

const initialState: INotifications = {
    notifications: [],
    nextPage: false,
    status: "idle",
};

export const fetchNotifications = createAsyncThunk(
    "notification/fetchNotifications",
    async (payload: IPagination) => {
        const notifications = await notificationService.getNotifications(payload);

        return notifications;
    }
);


export const saveCompanyNotification = createAsyncThunk(
    "notification/saveCompanyNotification",
    async (payload: { message: string }) => {
        const { message } = payload;

        const notification = await notificationService.saveCompanyNotification(
            message
        );

        return notification.notification;
    }
);

export const deleteNotification = createAsyncThunk("notification/deleteNotification", async (id: string) => {
    await notificationService.deleteNotification(id);
    return id;
});

const notificationSlice = createSlice({
    name: "notification",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchNotifications.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(fetchNotifications.fulfilled, (state, action) => {
                state.notifications = action.payload.data;
                state.nextPage = action.payload.nextPage;
                state.status = "idle";
            })
            .addCase(deleteNotification.fulfilled, (state, action) => {
                state.notifications = state.notifications.filter(
                    (notification) => notification.id !== action.payload
                );
            })
            .addCase(saveCompanyNotification.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(saveCompanyNotification.fulfilled, (state, action) => {
                //state.companyNotifications.push(action.payload);
                state.status = "idle";
            });
    },
});

export const selectNotifications = (state: any) =>
    state.notification.notifications;

export default notificationSlice.reducer;
