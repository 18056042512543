// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';

//The jsxRuntime pragma is necessary to get Emotion and React to work w/ latest Create-React-App version
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import AdminListItem from "./AdminListItem";
import { StyledTableContainer } from "../components/StyledTable";
import SelectableTableHeader from "../components/SelectableTableHeader";
import { ISorting, SortingField } from "../dashboard/DashboardSlice";
import { useSelector } from "react-redux";
import { IRestriction } from "./RestrictionSlice";

export enum Version {
    CRYPTO,
    SECURITY_COMPANY,
    ALL
}


/* Type for the AdminList props */
interface IListProps {
    entities: Array<IRestriction>;
    type: Version;
    handleDelete(ticker: string): void;
    setSorting: (sorting: ISorting) => void
    sorting: ISorting
}

const headerData = [
    { fieldText: "Class", fieldValue: "class", type: Version.ALL },
    { fieldText: "Ticker", fieldValue: "ticker", type: Version.ALL },
    { fieldText: "Name", fieldValue: "name", type: Version.ALL },
    { fieldText: "Restriction Type", fieldValue: "restrictionType", type: Version.ALL },
    { fieldText: "Restriction Group", fieldValue: "restrictionGroupName", type: Version.ALL },
    { fieldText: "Transaction Type", fieldValue: "transactionType", type: Version.ALL },
    { fieldText: "Visiblity Type", fieldValue: "visibilityType", type: Version.ALL },
    { fieldText: "Restricted From", fieldValue: "restrictedFrom", type: Version.ALL },
    { fieldText: "Restricted To", fieldValue: "restrictedTo", type: Version.ALL },
];

function AdminList(props: IListProps): JSX.Element {
    /* Provide default values and replace them with the prop values if there are any. */
    const { entities, sorting, setSorting } = props;
    const company = useSelector((state: any) => state.auth.companyName);

    return (
        <StyledTableContainer>
            <table>
                <thead>
                    <tr>
                        {headerData
                            .filter((headerItem) => { return headerItem.type === Version.ALL || headerItem.type === props.type })
                            .filter((headerItem) => {
                                if ((company === "Framework" || company === "Fargus" || company === "Multicoin" || company === "Paradigm" || company === "Celsius") && headerItem.fieldValue === "restrictionType") {
                                    return false;
                                }
                                else if (!(company === "Jnk" || company === "Cinven" || company === "proSapient") && headerItem.fieldValue === "visibilityType") {
                                    return false;
                                }
                                else if (!(company === "Paradigm" || company === "Celsius" || company === "Fargus" || company === "ArgusTest") && headerItem.fieldValue === "restrictionGroupName") {
                                    return false;
                                }
                                else {
                                    return true;
                                }
                            })
                            .map((header, index) => {
                                return (
                                    <SelectableTableHeader
                                        key={index}
                                        targetCompare={header.fieldValue}
                                        activeCompare={sorting.field}
                                        setActiveCompare={(field: SortingField) => setSorting({ ...sorting, field })}
                                        asc={sorting.direction === 'asc'}
                                        setAsc={(asc: boolean) => setSorting({ ...sorting, direction: asc ? 'asc' : 'desc' })}
                                    >
                                        {header.fieldText}
                                    </SelectableTableHeader>
                                );
                            })}
                        <th></th>
                        {/* <th >Restriction Group</th> */}
                    </tr>
                </thead>
                <tbody>
                    {/* Generate the AdminListItems and add them to the ul */}
                    {/*
                        1. Filter by the activeFilter, show everything if empty, matching names and tickers if not.
                        2. Slice to show only show pageSize number of results (defaults to 100)
                        3. Sort by the selected column
                        4. Map the entities array to AdminListItem components
                        */}
                    {entities.map((entity: IRestriction, index: number) => (
                        <AdminListItem
                            id={entity.id}
                            securityType={entity.securityType}
                            security={
                                entity.securityType === 'crypto' ? entity.crypto : entity.privateAsset
                            }
                            securityCompany={entity.securityCompany}
                            restrictionType={entity.restrictionType}
                            restrictedFrom={entity.restrictedFrom}
                            restrictedTo={entity.restrictedTo}
                            restrictionGroupName={entity.restrictionGroup.name}
                            transactionType={entity.transactionType}
                            handleDelete={props.handleDelete}
                            key={entity.id}
                            visibilityType={entity.visibilityType}
                            dateCreated={entity.dateCreated}
                            class={entity.securityCompany ? "Company" : entity.crypto ? "Crypto" : "Crypto [Pre-launch]"}
                            type={props.type}
                        />
                    ))}
                    {entities.length === 0 ? <tr><td colSpan={headerData.length} style={{ textAlign: 'center' }}>No results</td></tr> : null}
                </tbody>
            </table>
        </StyledTableContainer>
    );
}

export default AdminList;
