// import { StyledSpacing } from "../components/StyledSpacing";
import { useState } from "react";
import WatchlistStock from "./WatchlistStock";
import { StyledActionButtonSecondary } from "../components/StyledActionButtonSecondary";
import WatchlistCrypto from "./WatchlistCrypto";

function Watchlist(): JSX.Element {
    /* Toggle between crypto and stock views */
    const [showStockWatchlist, setShowStockWatchlist] = useState(true);
    const [showCrpytoWatchlist, setShowCryptoWatchlist] = useState(false);

    return (
        <div>
            {showCrpytoWatchlist ? (
                <>
                    <StyledActionButtonSecondary
                        onClick={() => {
                            setShowStockWatchlist(true);
                            setShowCryptoWatchlist(false);
                        }}
                    >
                        Show Stock Watchlist
                    </StyledActionButtonSecondary>
                    <WatchlistCrypto />
                </>
            ) : null}

            {showStockWatchlist ? (
                <>
                    {/* <StyledActionButtonSecondary
                        onClick={() => {
                            setShowStockWatchlist(false);
                            setShowCryptoWatchlist(true);
                        }}
                    >
                        Show Crypto Watchlist
                    </StyledActionButtonSecondary> */}
                    <WatchlistStock />
                </>
            ) : null}
        </div>
    );
}

export default Watchlist;
