import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StyledActionButton } from "../components/StyledActionButton";
import { StyledInputField } from "../components/StyledInputField";
import { StyledSectionHeader } from "../components/StyledSectionHeader";
import TablePageFooter, { DefaultPagination } from "../components/TablePageFooter";
import { ISorting } from "../dashboard/DashboardSlice";
import { UserList } from "../transactions/HistoricTradesList";
import {
    fetchUserTransactions,
    selectUserTransactions,
} from "../transactions/HistoricTradesSlice";
import useInterval from "../util/useInterval";
import ModalLabel from "../watchlist/ModalLabel";
import HistoricAttestationsPanel from "./HistoricAttestationsPanel";
import {
    fetchAttestations,
    IAttestation,
    saveNewAttestation,
    selectAttestations,
} from "./AttestationsSlice";
import HistoricHoldingsList from "../admin/HistoricHoldings/HistoricHoldingsList";
import {
    fetchHistoricHoldingsAttestation,
    selectUserHistoricHoldings,
} from "../admin/HistoricHoldings/HistoricHoldingsSlice";
import { toast } from "react-toastify";
import { IPagination } from "../util/pagination";

const defaultFilters: { [key: string]: string } = {
    employee: "",
    user: "",
    accountName: "",
    accountHolder: "",
    action: "",
    ticker: "",
    permissionStatus: "",
    transactionDate: "",
    transactionDateMax: "2022-06-30",
    transactionDateMin: "2022-04-01",
    lastUpdated: "",
    lastUpdatedMax: "",
    lastUpdatedMin: "",
    volume: "",
    volumeMin: "",
    volumeMax: "",
};

function UserAttestations(): JSX.Element {
    const dispatch = useDispatch();
    const transactions = useSelector(selectUserTransactions);
    const userHistoricHoldings = useSelector(selectUserHistoricHoldings);
    const attestations = useSelector(selectAttestations);

    const [pagination, setPagination] = useState<IPagination>(DefaultPagination());
    const nextPage = useSelector((state: any) => state.historicTrades.nextPage);
    const [filterMultiple, setFilterMultiple] = useState(defaultFilters) as any;

    const [signature, setSignature] = useState("");
    const [quarter, setQuarter] = useState("Q2");
    const [year, setYear] = useState(2022);

    const [active, setActive] = useState(true);

    const [sorting, setSorting] = useState<ISorting>({
        field: "transactionDate",
        direction: "desc",
    });

    const sortingByDate: ISorting[] = useMemo(() => {
        if (sorting.field === "transactionDate") return [sorting];
        return [sorting, { field: "transactionDate", direction: "desc" }];
    }, [sorting]);

    const category = useMemo(() => {
        return [
            "CRYPTO_EXCHANGE",
            "CRYPTO_CHAIN",
            "CRYPTO_TOKEN",
            "CRYPTO_MANUAL_UPLOAD",
            "CRYPTO_NFT",
            "SECURITY",
        ];
    }, []);

    useEffect(() => {
        dispatch(
            fetchUserTransactions({
                pagination,
                filter: {
                    ...filterMultiple,
                    category,
                },
                sorting: sortingByDate,
            })
        );
        dispatch(fetchHistoricHoldingsAttestation(sorting));
        dispatch(fetchAttestations());
    }, [
        dispatch,
        pagination,
        transactions.length,
        filterMultiple,
        sortingByDate,
        category,
    ]);

    useInterval(() => {
        dispatch(
            fetchUserTransactions({
                pagination,
                filter: {
                    ...filterMultiple,
                    category,
                },
                sorting: sortingByDate,
            })
        );
        dispatch(fetchHistoricHoldingsAttestation(sorting));
        dispatch(fetchAttestations());
    }, 25000);

    useEffect(() => {
        attestations.forEach((attestation: IAttestation) => {
            if (attestation.year === year && attestation.quarter === quarter) {
                setActive(false);
            }
        });
    }, [attestations]);

    const submitAttestation = (event: any) => {
        if (quarter && year && signature) {
            dispatch(
                saveNewAttestation({
                    quarter: quarter,
                    year: year,
                    signature: signature,
                })
            );
            setSignature("");
        }
    };

    return (
        <>
            <HistoricAttestationsPanel attestations={attestations} />

            <br />

            <div>
                {active && (
                    <>
                        <StyledSectionHeader>
                            By submitting this form, I attest that (i) I have connected
                            all "Accounts" to the Argus Platform and (ii) that,
                            therefore, this report is accurate and complete and that I
                            have reported all of my Digital Asset holdings and
                            transactions electronically through the Argus platform. For
                            the purposes of this attestation:
                            <ol type="i">
                                <li>
                                    “Accounts” means all wallets, addresses or
                                    centralized accounts used for the acquisition,
                                    holding and disposition of Digital Assets; provided,
                                    however, that a blockchain or centralized account
                                    not currently supported by Argus shall not be
                                    included in the definition of “Accounts” if the
                                    existence and assets in such unsupported blockchain
                                    or centralized account has been reported to the
                                    firm’s compliance team and addressed through other
                                    means, including manual addition on Argus; and
                                </li>
                                <br />
                                <li>
                                    “Digital Assets” means all digital currencies,
                                    cryptoassets, cryptocurrencies, decentralized
                                    application tokens and protocol tokens, blockchain
                                    based assets, smart contracts assets, and other
                                    cryptofinance and digital assets that have been held
                                    by you or a reportable person connected to you in
                                    accordance with the firm’s compliance programs.
                                </li>
                            </ol>
                        </StyledSectionHeader>

                        {/* <StyledSectionHeader>
                            Holdings as of June 30
                        </StyledSectionHeader>

                        <HistoricHoldingsList
                            holdings={userHistoricHoldings}
                            handleDelete={() => { }}
                            setSorting={setSorting}
                            sorting={sorting}
                            hideLastUpdated={true}
                        />
                        {
                            <TablePageFooter
                                nextPage={nextPage}
                                pagination={pagination}
                                setPageNumber={(n) =>
                                    setPagination({ ...pagination, pageNumber: n })
                                }
                                setPageSize={(s) =>
                                    setPagination({ ...pagination, pageSize: s })
                                }
                            />
                        } */}

                        <br />
                        <StyledSectionHeader>
                            Transactions from 4/1/2022 through 6/30/2022
                        </StyledSectionHeader>
                        <UserList
                            handleSubmitNote={(id1, id2) => { }}
                            trades={transactions}
                            handleDelete={(id) => { }}
                            setSorting={setSorting}
                            sorting={sorting}
                        />
                        {
                            <TablePageFooter
                                nextPage={nextPage}
                                pagination={pagination}
                                setPageNumber={(n) =>
                                    setPagination({ ...pagination, pageNumber: n })
                                }
                                setPageSize={(s) =>
                                    setPagination({ ...pagination, pageSize: s })
                                }
                            />
                        }

                        <br />
                        {
                            <StyledSectionHeader>
                                Please also confirm above trades are all those that you
                                made during Q2 2022. If you made a trade and do not see
                                it above, please reach out to compliance to update.
                                Thank you.
                            </StyledSectionHeader>
                        }

                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <ModalLabel>Sign</ModalLabel>
                            <StyledInputField
                                placeholder="Please sign with your name"
                                value={signature}
                                onChange={(event) => {
                                    setSignature(event.target.value);
                                    // setShowErrors(false)
                                }}
                            />
                        </div>

                        <StyledActionButton
                            disabled={!active}
                            onClick={(e: any) => {
                                submitAttestation(e);
                                toast.success(
                                    "Attestation successfully submitted. Please refresh the page to view the PDF.",
                                    {
                                        position: "top-center",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                    }
                                );
                            }}
                            style={{ margin: "10px 0 40px" }}
                        >
                            Submit
                        </StyledActionButton>
                    </>
                )}
            </div>
        </>
    );
}

export default UserAttestations;
