//The jsxRuntime pragma is necessary to get Emotion and React to work w/ latest Create-React-App version
/** @jsxRuntime classic */
/** @jsx jsx */
import styled from "@emotion/styled";

export const StyledSpanYellowBubble = styled.span`
  background: rgba(255, 181, 0, 0.13);
  border-radius: 101px;
  padding: 4px;
  color: #d99208;
`;