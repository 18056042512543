import { formatDate } from "../util/formatDate";
import redcross from "../assets/redcross.svg";
import greencheck from "../assets/greencheck.svg";
import clipboard from "../assets/clipboard.svg";
import capitalizeFirstLetter from "../util/capitalizeFirstLetter";
import { WindowType } from "./AnalyticsSlice";

interface IListItemProps {
    user: string;
    inspectDate: string;
    type: string;
    windowType: WindowType;
    ticker: string;
    flagged: boolean;
    status: string;
    selected: boolean;
    onSelect: () => void;
    onFlag: () => void;
    onReview: () => void;
    onCompliance: () => void;
}

function InspectListItem(props: IListItemProps): JSX.Element {
    return (
        <>
            <tr
                className={props.selected ? "suspect-row active" : "suspect-row"}
                onClick={props.onSelect}
            >
                <td>{props.user}</td>
                <td>
                    {formatDate(props.inspectDate)} [
                    {capitalizeFirstLetter(props.windowType)}]
                </td>
                <td>{props.type}</td>
                <td>{props.ticker}</td>
                <td>{props.flagged ? "True" : "False"}</td>
                <td>{props.status}</td>
                <td
                    className="action-col"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                >
                    <div className="action-wrapper">
                        {!props.flagged && (
                            <span
                                className="action tooltip"
                                onClick={(e) => {
                                    props.onReview();
                                }}
                            >
                                <img
                                    style={{ fontSize: "1.25em" }}
                                    src={clipboard}
                                    alt={"Review"}
                                />
                                <span className="tooltiptext">Review</span>
                            </span>
                        )}
                        {!props.flagged && (
                            <span
                                className="action tooltip"
                                onClick={(e) => {
                                    props.onFlag();
                                }}
                            >
                                <img src={redcross} alt={""} />
                                <span className="tooltiptext">Flag</span>
                            </span>
                        )}
                        {!props.flagged && (
                            <span
                                className="action tooltip"
                                onClick={(e) => {
                                    props.onCompliance();
                                }}
                            >
                                <img
                                    style={{ margin: "0 0.5rem" }}
                                    src={greencheck}
                                    alt={"Compliance"}
                                />
                                <span className="tooltiptext">Compliance</span>
                            </span>
                        )}
                    </div>
                </td>
            </tr>
        </>
    );
}

export default InspectListItem;
