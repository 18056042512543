import { useState } from "react";
import Modal from "../components/Modal";
import { StyledActionButton } from "../components/StyledActionButton";
import { useDispatch } from "react-redux";
import { createCryptoAsset } from "../dashboard/DashboardSlice";
import { toast } from "react-toastify";
import { StyledModalItem } from "../components/StyledModalItem";
import ModalLabel from "./ModalLabel";
import { StyledInputField } from "../components/StyledInputField";
import RequiredField from "../components/RequiredField";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import StaticAutoComplete from "../components/StaticAutoComplete";
import { StyledPageTitle } from "../components/StyledPageTitle";

const supported: { [id: string]: string } = {};
supported["ethereum"] = "Ethereum";
supported["avalanche"] = "Avalanche";
supported["solana"] = "Solana";
supported["polygon"] = "Polygon";
supported["binancesmartchain"] = "Binance Smart Chain";
supported["fantom"] = "Fantom";
supported["optimism"] = "Optimism";
supported["arbitrum"] = "Arbitrum";
supported["moonbeam"] = "Moonbeam";

interface IProps {
    show: boolean;
    onClose(): void;
}

function CryptoAssetModal(props: IProps): JSX.Element {
    const { show, onClose } = props;
    /* For modal */
    const [submitAttempted, setSubmitAttempted] = useState(false);
    const [contractAddress, setContractAddress] = useState("");
    const [blockchain, setBlockchain] = useState("");
    const [blockchainDisplayName, setBlockchainDisplayName] = useState("");

    /* Other hooks and selectors */
    const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

    const resetState = () => {
        setContractAddress("");
        setBlockchain("");
        setBlockchainDisplayName("");
    };

    const handleNewAsset = async (event: any) => {
        event.preventDefault();

        if (blockchain.trim() === "" || contractAddress.trim() === "") {
            setSubmitAttempted(true);
            return;
        }

        var payload = {
            blockchain,
            contractAddress,
        };

        dispatch(createCryptoAsset(payload))
            .unwrap()
            .then((a) => {
                toast.success("Asset successfully requested", {
                    position: "top-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                resetState();
                onClose();
            })
            .catch((e) => {
                if (e.message) {
                    toast.error(e.message, {
                        position: "top-center",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            });
    };

    const _onClose = () => {
        resetState();
        onClose();
    };

    const getDisplayName = (e: any) => {
        return supported[e];
    };

    return (
        <Modal
            show={show}
            onClose={_onClose}
            title={"New Crypto Asset"}
            backgroundStyle={{ display: "flex", flexDirection: "row", alignItems: "center" }}
            modalStyle={{ height: "auto", maxHeight: "100%" }}
        >
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "row",
                    padding: "3rem 0",
                    overflow: "auto",
                    gap: "1rem",
                }}
            >
                <StyledModalItem>
                    <StyledPageTitle style={{ width: "50%" }}>
                        Input blockchain type and contract address in order to add a new
                        token to our crypto asset list.
                    </StyledPageTitle>
                </StyledModalItem>
                <StyledModalItem>
                    <ModalLabel>Blockchain:</ModalLabel>
                    <RequiredField
                        value={blockchain}
                        submitAttempted={submitAttempted}
                        wrapperStyles={{ marginBottom: "1.5rem" }}
                        textStyles={{
                            position: "static",
                            margin: 0,
                            lineHeight: "18px",
                        }}
                    >
                        <div style={{ position: "relative", width: "50%" }}>
                            <StaticAutoComplete
                                style={{ width: "100%" }}
                                choices={Object.keys(supported)}
                                placeholder="Search for a blockchain"
                                getDisplayName={getDisplayName}
                                activeValue={blockchainDisplayName}
                                handleKeyDown={() => { }}
                                onSelect={(value) => {
                                    if (value in supported) {
                                        setBlockchain(value);
                                        setBlockchainDisplayName(value);
                                    }
                                }}
                            />
                        </div>
                    </RequiredField>
                </StyledModalItem>

                <StyledModalItem>
                    <ModalLabel>Contract address:</ModalLabel>
                    <RequiredField
                        value={contractAddress}
                        submitAttempted={submitAttempted}
                        wrapperStyles={{ marginBottom: "1.5rem" }}
                        textStyles={{
                            position: "static",
                            margin: 0,
                            lineHeight: "18px",
                        }}
                    >
                        <StyledInputField
                            style={{ marginBottom: ".5rem" }}
                            placeholder="Address"
                            value={contractAddress}
                            onChange={(e: any) => {
                                setContractAddress(e.target.value.trim());
                            }}
                        />
                    </RequiredField>
                </StyledModalItem>

                <StyledModalItem>
                    <StyledActionButton onClick={(event) => handleNewAsset(event)}>
                        Create new asset
                    </StyledActionButton>
                </StyledModalItem>
            </div>
        </Modal>
    );
}

export default CryptoAssetModal;
