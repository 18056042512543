import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { authService } from "../services/authService";
import jwt_decode, { JwtPayload } from "jwt-decode";
import { history } from "../nav/history";
import { getLoginDestination } from "../routes/util";
// import { persistedReducer } from "../store";
import { persistor } from "..";
import EventSource from "eventsource";
import { downloadEvent } from "../util/downloadFromUrl";

const purge = (userInitiated: boolean) => {
    persistor.purge();
    if (userInitiated) {
        if (caches) {
            console.log("got here");
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then(function (names) {
                for (let name of names) caches.delete(name);
            });
        }
        // delete browser cache and hard reload
        window.location.reload();
    }
    console.log("Factory reset performed.");
};

interface tokenInterface {
    id: number;
    email: string;
    role: string;
    companyId: number;
    acceptTos: boolean;
}

interface ICompany {
    id: number;
    name: string;
    version: string;
}

export interface IAuth {
    id: string;
    userId: number;
    name: string;
    companyId: number;
    version: string;
    userEmail: string;
    userRole: string;
    status: string;
    asAdmin: boolean;
    companies: Array<ICompany>;
    companyName: string;
    companyUrl: string;
    acceptTos: boolean;
    eventSource?: EventSource;
    representations: any;
}

const initialState: IAuth = {
    id: "-1",
    userId: -1,
    companyId: -1,
    name: "",
    version: "",
    userEmail: "",
    userRole: "",
    status: "idle",
    asAdmin: false,
    companyName: "",
    companyUrl: "",
    companies: [],
    acceptTos: false,
    eventSource: undefined,
    representations: [],
};

export const authLogin = createAsyncThunk(
    "auth/authLogin",
    async (credentials: {
        username: string;
        password: string;
        location: { pathname: string };
        asAdmin: boolean;
    }): Promise<any> => {
        purge(false);
        const username = credentials.username;
        const password = credentials.password;
        const location = credentials.location;
        const asAdmin = credentials.asAdmin;

        const user = await authService.login(username, password);

        /* Handle invalid login */

        const accessToken = user.access_token;
        const decoded = jwt_decode<JwtPayload>(accessToken);

        /* Cast the decoded token to the tokenInterface */
        const payload = {
            id: (decoded as tokenInterface).id,
            email: (decoded as tokenInterface).email,
            role: (decoded as tokenInterface).role,
            asAdmin: asAdmin,
            companyId: (decoded as tokenInterface).companyId,
            version: "",
            companyName: "",
            companyUrl: "",
            acceptTos: (decoded as tokenInterface).acceptTos,
            representations: [],
        };

        const res = await authService.getCompanyById(payload.companyId);
        payload.version = res.company.version;
        payload.companyName = res.company.name;
        payload.representations = res.company.representations;
        payload.companyUrl = res.company.url;

        const isAdmin = payload.role === "Admin";

        if (asAdmin && isAdmin === false) {
            return alert("User is not Admin.");
        }

        const from = getLoginDestination(asAdmin, location, payload.version);

        history.push(from);

        return payload;
    }
);

export const authLoginMs = createAsyncThunk(
    "auth/authLoginMs",
    async (credentials: {
        idToken: string;
        location: { pathname: string };
        asAdmin: boolean;
    }): Promise<any> => {
        purge(false);
        const idToken = credentials.idToken;
        const location = credentials.location;
        const asAdmin = credentials.asAdmin;

        const user = await authService.login_with_ms_oidc(idToken);
        /* Handle invalid login */

        const accessToken = user.access_token;
        const decoded = jwt_decode<JwtPayload>(accessToken);

        /* Cast the decoded token to the tokenInterface */
        const payload = {
            id: (decoded as tokenInterface).id,
            email: (decoded as tokenInterface).email,
            role: (decoded as tokenInterface).role,
            asAdmin: asAdmin,
            companyId: (decoded as tokenInterface).companyId,
            version: "",
            companyName: "",
            companyUrl: "",
            acceptTos: (decoded as tokenInterface).acceptTos,
            representations: [],
        };

        const res = await authService.getCompanyById(payload.companyId);
        payload.version = res.company.version;
        payload.companyName = res.company.name;
        payload.representations = res.company.representations;
        payload.companyUrl = res.company.url;

        const isAdmin = payload.role === "Admin";

        if (asAdmin && isAdmin === false) {
            history.push("/partner_login");
            return alert("User is not Admin.");
        }

        const from = getLoginDestination(asAdmin, location, payload.version);
        console.log(from);
        history.push(from);

        return payload;
    }
);

export const authLoginOkta = createAsyncThunk(
    "auth/authLoginMs",
    async (credentials: {
        idToken: string;
        location: { pathname: string };
        asAdmin: boolean;
    }): Promise<any> => {
        purge(false);
        const idToken = credentials.idToken;
        const location = credentials.location;
        const asAdmin = credentials.asAdmin;

        const user = await authService.login_with_okta_oidc(idToken);
        /* Handle invalid login */

        const accessToken = user.access_token;
        const decoded = jwt_decode<JwtPayload>(accessToken);

        /* Cast the decoded token to the tokenInterface */
        const payload = {
            id: (decoded as tokenInterface).id,
            email: (decoded as tokenInterface).email,
            role: (decoded as tokenInterface).role,
            asAdmin: asAdmin,
            companyId: (decoded as tokenInterface).companyId,
            version: "",
            companyName: "",
            companyUrl: "",
            acceptTos: (decoded as tokenInterface).acceptTos,
            representations: [],
        };

        const res = await authService.getCompanyById(payload.companyId);
        payload.version = res.company.version;
        payload.companyName = res.company.name;
        payload.representations = res.company.representations;
        payload.companyUrl = res.company.url;

        const isAdmin = payload.role === "Admin";

        if (asAdmin && isAdmin === false) {
            history.push("/partner_login");
            return alert("User is not Admin.");
        }

        const from = getLoginDestination(asAdmin, location, payload.version);
        console.log(from);
        history.push(from);

        return payload;
    }
);

export const authLoginAuth0 = createAsyncThunk(
    "auth/authLoginMs",
    async (credentials: {
        idToken: string;
        location: { pathname: string };
        asAdmin: boolean;
    }): Promise<any> => {
        purge(false);
        const idToken = credentials.idToken;
        const location = credentials.location;
        const asAdmin = credentials.asAdmin;

        const user = await authService.login_with_auth0_oidc(idToken);
        /* Handle invalid login */

        const accessToken = user.access_token;
        const decoded = jwt_decode<JwtPayload>(accessToken);

        /* Cast the decoded token to the tokenInterface */
        const payload = {
            id: (decoded as tokenInterface).id,
            email: (decoded as tokenInterface).email,
            role: (decoded as tokenInterface).role,
            asAdmin: asAdmin,
            companyId: (decoded as tokenInterface).companyId,
            version: "",
            companyName: "",
            companyUrl: "",
            acceptTos: (decoded as tokenInterface).acceptTos,
            representations: [],
        };

        const res = await authService.getCompanyById(payload.companyId);
        payload.version = res.company.version;
        payload.companyName = res.company.name;
        payload.representations = res.company.representations;
        payload.companyUrl = res.company.url;

        const isAdmin = payload.role === "Admin";

        if (asAdmin && isAdmin === false) {
            history.push("/partner_login");
            return alert("User is not Admin.");
        }

        const from = getLoginDestination(asAdmin, location, payload.version);
        console.log(from);
        history.push(from);

        return payload;
    }
);

export const authLoginGoogle = createAsyncThunk(
    "auth/authLoginMs",
    async (credentials: {
        idToken: string;
        location: { pathname: string };
        asAdmin: boolean;
    }): Promise<any> => {
        purge(false);
        const idToken = credentials.idToken;
        const location = credentials.location;
        const asAdmin = credentials.asAdmin;

        const user = await authService.login_with_google_oidc(idToken);
        /* Handle invalid login */

        const accessToken = user.access_token;
        const decoded = jwt_decode<JwtPayload>(accessToken);

        /* Cast the decoded token to the tokenInterface */
        const payload = {
            id: (decoded as tokenInterface).id,
            email: (decoded as tokenInterface).email,
            role: (decoded as tokenInterface).role,
            asAdmin: asAdmin,
            companyId: (decoded as tokenInterface).companyId,
            version: "",
            companyName: "",
            companyUrl: "",
            acceptTos: (decoded as tokenInterface).acceptTos,
            representations: [],
        };

        const res = await authService.getCompanyById(payload.companyId);
        payload.version = res.company.version;
        payload.companyName = res.company.name;

        payload.representations = res.company.representations;
        payload.companyUrl = res.company.url;


        var isAdmin = payload.role === "Admin";

        if (asAdmin && isAdmin === false) {
            history.push("/partner_login");
            return alert("User is not Admin.");
        }

        const from = getLoginDestination(asAdmin, location, payload.version);
        console.log(from);
        history.push(from);

        return payload;
    }
);

export const authLogout = createAsyncThunk(
    "auth/authLogout",
    async (userInitiated: boolean) => {
        setTimeout(() => purge(userInitiated), 200);

        authService.logout();
    }
);

export const fetchCompanies = createAsyncThunk("auth/fetchCompanies", async () => {
    const res = await authService.getCompanies();
    return res.company;
});

export const fetchCompanyById = createAsyncThunk(
    "auth/fetchCompaniesById",
    async (companyId: number) => {
        const res = await authService.getCompanyById(companyId);
        return res.company;
    }
);

export const saveDeleteCompany = createAsyncThunk(
    "auth/fetchCompaniesById",
    async (companyId: number) => {
        await authService.deleteCompany(companyId);
        return companyId;
    }
);

export const createEventStream = createAsyncThunk(
    "auth/createEventStream",
    async (payload: { userId: string }) => {
        const { userId } = payload;
        const eventSource = authService.createEventStream();
        eventSource.addEventListener(userId, downloadEvent);
        return eventSource;
    }
);

export const acceptTos = createAsyncThunk(
    "auth/authLoginMs",
    async (payload: { acceptTos: boolean }, thunkApi): Promise<any> => {
        const { acceptTos } = payload;
        const auth: IAuth = (thunkApi.getState() as { auth: IAuth }).auth;

        console.log(auth);

        const user = await authService.acceptTos(acceptTos);

        const accessToken = user.access_token;
        const decoded = jwt_decode<JwtPayload>(accessToken);

        /* Cast the decoded token to the tokenInterface */
        const tokenPayload = {
            id: (decoded as tokenInterface).id,
            email: (decoded as tokenInterface).email,
            role: (decoded as tokenInterface).role,
            asAdmin: auth.asAdmin,
            companyId: (decoded as tokenInterface).companyId,
            version: "",
            companyName: "",
            companyUrl: "",
            acceptTos: (decoded as tokenInterface).acceptTos,
            representations: [],
        };

        tokenPayload.version = auth.version;
        tokenPayload.companyName = auth.companyName;
        tokenPayload.representations = auth.representations;
        tokenPayload.companyUrl = auth.companyUrl;

        console.log(tokenPayload);

        return tokenPayload;
    }
);

export const saveCompany = createAsyncThunk(
    "accounts/saveCompany",
    async (payload: { name: string; version: string }) => {
        const { version, name } = payload;
        const res = await authService.createCompany(name, version);
        return res;
    }
);

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        toggleIsAdmin(state) {
            state.asAdmin = !state.asAdmin;
        },
        setIsAdmin(state, action) {
            state.asAdmin = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(authLogin.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(authLogin.fulfilled, (state, action: any) => {
                if (!action.payload) {
                    return;
                }

                let active_payload_version = action.payload.version;
                if (action.payload.version === "crypto_fullstack") {
                    active_payload_version = "crypto_base";
                } else if (action.payload.version === "tradfi_fullstack") {
                    active_payload_version = "base";
                } else if (action.payload.version === "crossover_fullstack") {
                    active_payload_version = "crossover_base";
                }

                state.userId = action.payload.id;
                state.userEmail = action.payload.email;
                state.userRole = action.payload.role;
                state.companyId = action.payload.companyId;
                state.status = "success";
                state.asAdmin = action.payload.asAdmin;
                state.version = active_payload_version;
                state.companyName = action.payload.companyName;
                state.companyUrl = action.payload.companyUrl;
                state.acceptTos = action.payload.acceptTos;
                state.representations = action.payload.representations;
            })
            .addCase(authLogin.rejected, (state, action) => {
                state.status = "failed";
            })
            .addCase(authLoginMs.fulfilled, (state, action: any) => {
                if (!action.payload) {
                    return;
                }

                let active_payload_version = action.payload.version;
                if (action.payload.version === "crypto_fullstack") {
                    active_payload_version = "crypto_base";
                } else if (action.payload.version === "tradfi_fullstack") {
                    active_payload_version = "base";
                } else if (action.payload.version === "crossover_fullstack") {
                    active_payload_version = "crossover_base";
                }

                state.userId = action.payload.id;
                state.userEmail = action.payload.email;
                state.userRole = action.payload.role;
                state.companyId = action.payload.companyId;
                state.status = "success";
                state.asAdmin = action.payload.asAdmin;
                state.version = active_payload_version;
                state.companyName = action.payload.companyName;
                state.companyUrl = action.payload.companyUrl;
                state.acceptTos = action.payload.acceptTos;
                state.representations = action.payload.representations;
            })
            .addCase(authLoginMs.rejected, (state, action) => {
                state.status = "failed";
            })
            .addCase(authLogout.fulfilled, (state, action) => {
                state.userId = -1;
                state.companyId = -1;
                state.userEmail = "";
                state.userRole = "";
                state.status = "idle";
                state.asAdmin = false;
                state.version = "";
                state.companyName = "";
                state.companyUrl = "";
                state.acceptTos = false;
                state.representations = [];
            })
            .addCase(fetchCompanies.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(fetchCompanies.fulfilled, (state, action) => {
                state.status = "idle";
                state.companies.push(action.payload);
            })
            .addCase(fetchCompanyById.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(fetchCompanyById.fulfilled, (state, action) => {
                state.status = "idle";
                state.version = action.payload.version;
                state.companyName = action.payload.companyName;
                state.representations = action.payload.representations;
                state.companyUrl = action.payload.companyUrl;
            })
            .addCase(saveCompany.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(saveCompany.fulfilled, (state, action) => {
                state.status = "idle";
                state.companies.push(action.payload);
            })
            .addCase(createEventStream.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(createEventStream.fulfilled, (state, action) => {
                state.status = "idle";
                state.eventSource = action.payload;
            });
    },
});

export const { toggleIsAdmin, setIsAdmin } = authSlice.actions;

export const selectUserId = (state: any): string => state.auth.userId;
export const selectAsAdmin = (state: any): boolean => state.auth.asAdmin;
export const selectUserRole = (state: any): string => state.auth.userRole;
export const selectCompanies = (state: any): string => state.auth.companies;
export const selectCompanyId = (state: any): number => state.auth.companyId;
export const selectAcceptTos = (state: any): boolean => state.auth.acceptTos;
export const selectVersion = (state: any): string => state.auth.version;
export const selectEventStream = (state: any): EventSource => state.auth.eventStream;
export const selectReps = (state: any): any => state.auth.representations;

export default authSlice.reducer;
