import { IWallet } from "./WalletSlice";
import { StyledLinkButton } from "../components/StyledLinkButton";
import { useMemo } from "react";

interface IWalletTableProps {
    aggregatedWallets: {
        [walletType: string]: { [accountHolderId: string]: IWallet[] };
    };
    handleOpen(
        walletType: string,
        accountHolder: string,
        accountHolderId: string
    ): void;
}
// @ts-ignore

function WalletTable(props: IWalletTableProps): JSX.Element {
    const { aggregatedWallets, handleOpen } = props;
    // eslint-disable-next-line react-hooks/exhaustive-deps

    console.log(aggregatedWallets);
    const walletTypes = useMemo(() => {
        const result: {
            accountHolder: string;
            accountHolderId: string;
            walletType: string;
        }[] = [];
        Object.keys(aggregatedWallets).forEach((walletType) => {
            Object.keys(aggregatedWallets[walletType]).forEach((accountHolder) => {
                const wallet: any = aggregatedWallets[walletType][accountHolder][0];
                result.push({
                    accountHolder: wallet.accountHolder.name,
                    accountHolderId: wallet.accountHolderId,
                    walletType,
                });
            });
        });
        return result;
    }, [aggregatedWallets]);

    // console.log(aggregatedWallets);

    return (
        <>
            <table>
                <thead>
                    <tr>
                        <th>Wallet Type</th>
                        <th>Wallet Holder</th>
                    </tr>
                </thead>
                <tbody>
                    {walletTypes.map(
                        ({ walletType, accountHolder, accountHolderId }, index) => {
                            return (
                                <tr key={index.toString()}>
                                    <td>{walletType}</td>
                                    <td>{accountHolder}</td>
                                    <td>
                                        <StyledLinkButton
                                            onClick={(e) => {
                                                const arr =
                                                    props.aggregatedWallets[walletType][
                                                        accountHolderId
                                                    ];
                                                handleOpen(
                                                    walletType,
                                                    arr[index % arr.length]
                                                        .accountHolder.name,
                                                    arr[index % arr.length]
                                                        .accountHolderId
                                                );
                                            }}
                                        >
                                            Details
                                        </StyledLinkButton>
                                    </td>
                                </tr>
                            );
                        }
                    )}
                </tbody>
            </table>
        </>
    );
}

export default WalletTable;
