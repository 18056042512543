// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';

//The jsxRuntime pragma is necessary to get Emotion and React to work w/ latest Create-React-App version
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import HistoricTradeListItem, {
    HistoricUserTradeListItem,
} from "./HistoricTradeListItem";
import { IHistoricTrade } from "./HistoricTradesSlice";
// import { StyledTableContainer } from "../components/StyledTable";
// import { useState } from "react";
// import SelectableTableHeader from "../components/SelectableTableHeader";
import SmartList from "../components/SmartList";
import { ISorting } from "../dashboard/DashboardSlice";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import capitalizeFirstLetter from "../util/capitalizeFirstLetter";

/* Type for the AdminList props */
interface IListProps {
    trades: Array<IHistoricTrade>;
    handleDelete: (employee: string) => void;
    handleSubmitNote: (note: string, userActionId: string) => void;
    setSorting: (sorting: ISorting) => void;
    sorting: ISorting;
}

function AdminList(props: IListProps): JSX.Element {
    /* Provide default values and replace them with the prop values if there are any. */
    const { trades, sorting, setSorting } = props;
    const company = useSelector((state: any) => state.auth.companyName);
    const version = useSelector((state: any) => state.auth.version);
    const headerData = useMemo(() => {
        return [
            { fieldText: "Employee", fieldValue: "employee" },
            version === "crypto_base"
                ? { fieldText: "Account Holder", fieldValue: "accountHolder" }
                : { fieldText: "User", fieldValue: "user" },
            { fieldText: "Account Name", fieldValue: "accountName" },
            { fieldText: "Action", fieldValue: "action" },
            { fieldText: "Ticker", fieldValue: "ticker" },
            //{ fieldText: "Restriction Group", fieldValue: "restrictionGroupName" },
            { fieldText: "Asset", fieldValue: "asset" },
            { fieldText: "Volume", fieldValue: "volume" },
            company === "Public"
                ? { fieldText: "Last Updated", fieldValue: "lastUpdated" }
                : { fieldText: "Permission Status", fieldValue: "permissionStatus" },
            { fieldText: "Date", fieldValue: "transactionDate" },
        ]
            .filter(
                (header) =>
                    header.fieldText !== "Permission Status" ||
                    version !== "crypto_monitoring"
            )
            .filter(
                (header) =>
                    version === "crypto_base" ||
                    version === "crossover_base" ||
                    header.fieldText !== "Account Name"
            );
    }, [company, version]);

    const generateItem = (trade: IHistoricTrade, index: number) => (
        <HistoricTradeListItem
            category={trade.category}
            transactionId={trade.id}
            employee={trade.employee}
            accountName={trade.account ? trade.account.accountName : ""}
            accountHolder={trade.account ? trade.account.accountHolder : ""}
            transactionType={trade.transactionType}
            ticker={trade.security ? trade.security.ticker : ""}
            currency={trade.currency}
            security={
                (trade.security && trade.security.name)
                    ? trade.security.name +
                    (trade.security.cryptocurrencyType !== "CURRENCY" &&
                        trade.security.platform
                        ? " [" + trade.security.platform + "]"
                        : "")
                    : ""
            }
            brokerage={trade.brokerage}
            volume={trade.volume}
            handleDelete={props.handleDelete}
            user={trade.user ? trade.user.name : ""}
            permissionStatus={trade.permissionStatus}
            transactionDate={trade.transactionDate}
            lastUpdated={trade.lastUpdated}
            key={index}
            notes={trade.userNotes}
            handleSubmitNote={props.handleSubmitNote}
        />
    )

    return (
        <SmartList
            headerData={headerData}
            bumper={"Notes"}
            arr={trades}
            generateItem={generateItem}
            sorting={sorting}
            setSorting={setSorting}
        />
    );
}

export function UserList(props: IListProps): JSX.Element {
    const { trades, sorting, setSorting } = props;
    const version = useSelector((state: any) => state.auth.version);
    const headerData = useMemo(() => {
        return [
            version === "crypto_base"
                ? { fieldText: "Account Holder", fieldValue: "accountHolder" }
                : { fieldText: "User", fieldValue: "user" },
            { fieldText: "Account Name", fieldValue: "accountName" },
            { fieldText: "Action", fieldValue: "action" },
            { fieldText: "Ticker", fieldValue: "ticker" },
            //{ fieldText: "Restriction Group", fieldValue: "restrictionGroupName" },
            { fieldText: "Asset", fieldValue: "asset" },
            { fieldText: "Volume", fieldValue: "volume" },
            { fieldText: "Date", fieldValue: "transactionDate" },
        ]
            .filter(
                (header) =>
                    header.fieldText !== "Permission Status" ||
                    version !== "crypto_monitoring"
            )
            .filter(
                (header) =>
                    version === "crypto_base" ||
                    version === "crossover_base" ||
                    header.fieldText !== "Account Name"
            );
    }, [version]);

    const generateItem = (trade: IHistoricTrade, index: number) => (
        <HistoricUserTradeListItem
            category={trade.category}
            transactionId={trade.id}
            employee={trade.employee}
            accountName={trade.account ? trade.account.accountName : ""}
            accountHolder={trade.account ? trade.account.accountHolder : ""}
            transactionType={
                trade.transactionSubtype
                    ? trade.transactionType === "WITHDRAWAL"
                        ? "Send " + capitalizeFirstLetter(trade.transactionSubtype)
                        : "Receive " + capitalizeFirstLetter(trade.transactionSubtype)
                    : capitalizeFirstLetter(trade.transactionType)
            }
            ticker={trade.security ? trade.security.ticker : ""}
            currency={trade.currency}
            security={
                (trade.security && trade.security.name)
                    ? trade.security.name +
                    (trade.security.cryptocurrencyType !== "CURRENCY" &&
                        trade.security.platform
                        ? " [" + trade.security.platform + "]"
                        : "")
                    : ""
            }
            brokerage={trade.brokerage}
            volume={trade.volume}
            handleDelete={props.handleDelete}
            user={trade.user ? trade.user.name : ""}
            permissionStatus={""}
            transactionDate={trade.transactionDate}
            lastUpdated={""}
            key={index}
            notes={trade.userNotes}
            handleSubmitNote={props.handleSubmitNote}
        />
    );

    return (
        <SmartList
            headerData={headerData}
            bumper={"Notes"}
            arr={trades}
            generateItem={generateItem}
            sorting={sorting}
            setSorting={setSorting}
        />
    );
}

export default AdminList;
