export function smartTruncate(x: number): string {
    if (Math.abs(x) < 1) {
        return x.toPrecision(2);
    } else {
        return x.toFixed(2);
    }
}

export function midEllipsis(x: string): string {
    return x.length > 4 ? x.slice(0, 4) + "..." + x.slice(x.length - 4, x.length) : x;
}
