import { useEffect } from "react";
import { StyledLoginPage } from "../components/StyledLoginPage";
import { useLocation, useParams } from "react-router-dom";
import { accountsService } from "../services/accountsService";
import { history } from "../nav/history";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function AccountRedirect(): JSX.Element {
    /* Hooks for libraries, selectors */
    const query = useQuery();
    const { providerName } = useParams<{providerName: string}>()

    useEffect(() => {
        const code = query.get("code");
        const state = query.get("state");
        const redirectUri = query.get("redirectUri") || window.location.origin
        console.log(code, providerName)
        if (!sessionStorage.getItem("user")) {
            const companyUrl = state?.split('-')[1] || '';
            window.open(window.location.href.replace(redirectUri, companyUrl) + `&redirectUri=${redirectUri}`, '_self');
        }
        if (providerName && state && code)
            accountsService
                .exchangeOAuthToken(
                    providerName,
                    state,
                    code,
                    redirectUri +
                        `/oauth/${providerName}`
                )
                .then(() => {
                    history.push('/accounts')
                })
                .catch(() => {
                    history.push('/accounts')
                });
    });

    return <StyledLoginPage />;
}

export default AccountRedirect;
