import { useMemo, } from "react";
import { walletService } from "../services/walletService";
import DynamicAutoComplete, { IDynamicAutoComplete } from "./DynamicAutoComplete";

export type BlockchainSuggestion = {
    id: string;
    name: string;
    displayName: string;
    supported: boolean;
    display: boolean;
};

function BlockchainAutoComplete(props: IDynamicAutoComplete<BlockchainSuggestion>): JSX.Element {

    const formatAutoComplete = useMemo(() => {
        return (suggestion: BlockchainSuggestion) =>
            suggestion.displayName
    }, [])

    const autoComplete = useMemo(() => {
        return async (input: string) => {
            const response = await walletService.autocompleteBlockchains(input)
            return response.data
        }
    }, [])

    return (
        <DynamicAutoComplete<BlockchainSuggestion> {...props} formatAutoComplete={formatAutoComplete} autoComplete={autoComplete} />
    );
}

export default BlockchainAutoComplete;
