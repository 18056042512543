import { StyledSectionHeader } from "../components/StyledSectionHeader";
import { useDispatch, useSelector } from "react-redux";

import {
    fetchAllPendingTrades,
    fetchComplianceIssues,
    IComplianceFilter,
    IFilter,
    ISorting,
    // hideIssue,
    resolveComplianceIssue,
    selectAllPendingTrades,
    selectIssues,
} from "./DashboardSlice";
import { useEffect, useMemo, useRef, useState } from "react";
import DashboardList from "./DashboardList";
import IssuesList from "./IssuesList";
import { StyledActionButton } from "../components/StyledActionButton";
import TablePageFooter, { DefaultPagination } from "../components/TablePageFooter";
import Modal from "../components/Modal";
import { StyledPageTitle } from "../components/StyledPageTitle";
import { StyledActionButtonSecondary } from "../components/StyledActionButtonSecondary";
import { saveCompanyNotification } from "../notifications/NotificationSlice";
import { StyledTextArea } from "../components/StyledTextArea";
import { transactionsService } from "../services/transactionsService";
import { fetchTransactions } from "../transactions/HistoricTradesSlice";
import { dashboardService } from "../services/dashboardService";
import { IPagination } from "../util/pagination";

const defaultComplianceFilter: IComplianceFilter = {
    user: "",
    dateCreated: "",
    dateCreatedMin: "",
    dateCreatedMax: "",
    status: [""],
};

function Dashboard(): JSX.Element {
    /* Hooks for libraries, selectors*/
    const dispatch = useDispatch();
    const reqs = useSelector(selectAllPendingTrades);
    const issues = useSelector(selectIssues);

    console.log(issues);

    const [paginationIssues, setPaginationIssues] = useState<IPagination>(
        DefaultPagination()
    );
    const nextPageIssues = useSelector((state: any) => state.dashboard.nextPageIssues);
    const [paginationPending, setPaginationPending] = useState<IPagination>(
        DefaultPagination()
    );
    const nextPagePending = useSelector(
        (state: any) => state.dashboard.nextPageAllPending
    );

    const [showModal, setShowModal] = useState(false);
    const [notification, setNotification] = useState("");

    const [complianceNote, setComplianceNote] = useState("");
    const [complianceId, setComplianceId] = useState<string>();
    const [isApproving, setIsApproving] = useState<boolean | undefined>();
    const [status, setStatus] = useState<"RESOLVED" | "MARKED">();

    const showComplianceNote = isApproving !== undefined;
    const [complianceNoteTitle, setComplianceNoteTitle] = useState(
        isApproving ? "Resolve Transaction" : "Disprove Transaction"
    );

    const onCloseComplianceNote = useRef(() => {
        setComplianceId(undefined);
        setComplianceNote("");
        setIsApproving(undefined);
        setStatus(undefined);
    }).current;

    const onClickComplianceNote = useMemo(() => {
        return () => {
            if (complianceId !== undefined && status !== undefined) {
                if (isApproving === true) {
                    dispatch(
                        resolveComplianceIssue({
                            issueId: complianceId,
                            complianceNotes: complianceNote,
                            status,
                        })
                    );
                }
                if (isApproving === false) {
                    dispatch(
                        resolveComplianceIssue({
                            issueId: complianceId,
                            complianceNotes: complianceNote,
                            status,
                        })
                    );
                }
                onCloseComplianceNote();
            }
        };
    }, [
        complianceId,
        isApproving,
        onCloseComplianceNote,
        dispatch,
        complianceNote,
        status,
    ]);

    const [reqsSorting, setReqsSorting] = useState<ISorting>({
        field: "dateSubmitted",
        direction: "desc",
    });
    const reqsSortingByDate: ISorting[] = useMemo(() => {
        if (reqsSorting.field === "dateSubmitted") return [reqsSorting];
        return [reqsSorting, { field: "dateSubmitted", direction: "desc" }];
    }, [reqsSorting]);

    const [issuesSorting, setIssuesSorting] = useState<ISorting>({
        field: "dateCreated",
        direction: "desc",
    });
    const issuesSortingByDate: ISorting[] = useMemo(() => {
        if (issuesSorting.field === "dateCreated") return [issuesSorting];
        return [issuesSorting, { field: "dateCreated", direction: "desc" }];
    }, [issuesSorting]);

    /* Local state for filters and views*/
    // const [activeFilter, setActiveFilter] = useState('');

    useEffect(() => {
        dispatch(
            fetchAllPendingTrades({
                pagination: paginationPending,
                sorting: reqsSortingByDate,
            })
        );
    }, [dispatch, paginationPending, reqsSortingByDate]);

    useEffect(() => {
        dispatch(
            fetchComplianceIssues({
                ...paginationIssues,
                filter: {
                    ...defaultComplianceFilter,
                    status: ["PENDING", "IN_REVIEW"],
                },
                sorting: issuesSortingByDate,
            })
        );
    }, [dispatch, paginationIssues, issuesSortingByDate]);

    const handleDelete = (employee: string) => {
        /* Only try and remove if we find a matching stock ticker. */
        return;
    };

    const handleResolveCompliance = (id: string) => {
        setComplianceId(id);
        setIsApproving(true);
        setComplianceNoteTitle("Resolve Infraction as Allowed");
        setStatus("RESOLVED");
    };
    const handleMarked = (id: string) => {
        setComplianceId(id);
        setIsApproving(true);
        setComplianceNoteTitle("Confirm Infraction");
        setStatus("MARKED");
    };

    const handleDeleteCompliance = (id: string) => {
        setComplianceId(id);
        setIsApproving(false);
    };

    function handleOnClick() {
        dispatch(saveCompanyNotification({ message: notification }));

        setNotification("");
        setShowModal(false);
    }

    const handleSubmitNote = (note: string, issueId: string) => {
        if (note !== "") {
            dashboardService.createComplianceIssueNote(note, issueId).then((r) => {
                if (r.status === 200) {
                    dispatch(
                        fetchComplianceIssues({
                            ...paginationIssues,
                            filter: {
                                ...defaultComplianceFilter,
                                status: ["PENDING", "IN_REVIEW"],
                            },
                            sorting: issuesSortingByDate,
                        })
                    );
                }
            });
        }
    };

    const handleReviewStatusUpdate = (id: string) => {
        dashboardService.updateComplianceReviewStatus(id).then((r) => {
            if (r.status === 200) {
                dispatch(
                    fetchComplianceIssues({
                        ...paginationIssues,
                        filter: {
                            ...defaultComplianceFilter,
                            status: ["PENDING", "IN_REVIEW"],
                        },
                        sorting: issuesSortingByDate,
                    })
                );
            }
        });
    };

    return (
        <div>
            <StyledActionButton onClick={() => setShowModal(true)}>
                Create Company Notification +
            </StyledActionButton>
            <br />
            <br />

            <StyledSectionHeader>Outstanding Compliance Issues</StyledSectionHeader>
            <Modal
                show={showComplianceNote}
                onClose={onCloseComplianceNote}
                width="50%"
                height="50%"
                title={complianceNoteTitle}
            >
                <StyledPageTitle>Compliance Note</StyledPageTitle>
                <StyledTextArea
                    placeholder="Enter text here..."
                    value={complianceNote}
                    onChange={(event) => setComplianceNote(event.target.value)}
                    style={{ display: "flex", width: "90%", fontSize: 14 }}
                    //style={{ height: "50px", marginBottom: "10px" }}
                />

                <br />
                <StyledActionButtonSecondary
                    onClick={onClickComplianceNote}
                    style={{ width: "60%" }}
                >
                    {complianceNoteTitle.split(" ")[0]}
                </StyledActionButtonSecondary>
            </Modal>
            <IssuesList
                issues={issues}
                handleResolve={handleResolveCompliance}
                handleDelete={handleDeleteCompliance}
                setSorting={setIssuesSorting}
                sorting={issuesSorting}
                handleSubmitNote={handleSubmitNote}
                handleReviewStatus={handleReviewStatusUpdate}
                handleMarked={handleMarked}
            />
            <TablePageFooter
                nextPage={nextPageIssues}
                pagination={paginationIssues}
                setPageNumber={(n) =>
                    setPaginationIssues({ ...paginationIssues, pageNumber: n })
                }
                setPageSize={(s) =>
                    setPaginationIssues({ ...paginationIssues, pageSize: s })
                }
            />

            <br />
            <StyledSectionHeader>Pending Trade Requests</StyledSectionHeader>
            <DashboardList
                reqs={reqs}
                activeFilter={""}
                handleDelete={handleDelete}
                setSorting={setReqsSorting}
                sorting={reqsSorting}
            />
            <TablePageFooter
                nextPage={nextPagePending}
                pagination={paginationPending}
                setPageNumber={(n) =>
                    setPaginationPending({ ...paginationPending, pageNumber: n })
                }
                setPageSize={(s) =>
                    setPaginationPending({ ...paginationPending, pageSize: s })
                }
            />

            <Modal
                show={showModal}
                onClose={() => setShowModal(false)}
                width="50%"
                height="50%"
                title="Company Notification"
            >
                <StyledPageTitle>Create Company Notification</StyledPageTitle>
                <StyledTextArea
                    placeholder="Enter text here..."
                    value={notification}
                    onChange={(event) => setNotification(event.target.value)}
                    style={{ display: "flex", width: "90%", fontSize: 14 }}
                />

                <br />
                <StyledActionButtonSecondary
                    onClick={handleOnClick}
                    style={{ width: "60%" }}
                >
                    Enter
                </StyledActionButtonSecondary>
            </Modal>
        </div>
    );
}

export default Dashboard;
