//The jsxRuntime pragma is necessary to get Emotion and React to work w/ latest Create-React-App version
/** @jsxRuntime classic */
/** @jsx jsx */
import styled from "@emotion/styled";

export const StyledLoginBox = styled.div`
    min-width: 300px;
    max-width: 40%;
    min-height: 60%;
    margin-left: 30%;
    border-radius: 20px;
    margin-top: 10%;
    max-height: 100%;
    font-family: Lato;

    background: #ffffff;
`;
