import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { nanoid } from "nanoid";
import { authLoginGoogle, authLogout, selectUserId, setIsAdmin } from "./AuthSlice";
import { StyledLoginBox } from "../components/StyledLoginBox";
import { StyledLoginPage } from "../components/StyledLoginPage";
import { StyledActionButton } from "../components/StyledActionButton";
import GoogleButton from "./GoogleSigninButton";
import { Link, useLocation } from "react-router-dom";
import { GoogleAuthProvider } from "../contexts/GoogleAuthContext";
import { get_okta_url } from "./util";
import { StyledLinkButton } from "../components/StyledLinkButton";

function PartnerLogin(): JSX.Element {
    const MS_SSO_ENDPOINT = process.env.REACT_APP_MS_SSO_ENDPOINT;
    const MS_SSO_ENDPOINT_ADMIN = process.env.REACT_APP_MS_SSO_ENDPOINT_ADMIN;

    const [displayType, setDisplayType] = useState("MICROSOFT");
    const [userUrlOverride, setUserUrlOverride] = useState("");
    const [adminUrlOverride, setAdminUrlOverride] = useState("");

    /* Hooks for libraries, selectors */
    const dispatch = useDispatch();
    const location = useLocation();

    const userId = useSelector(selectUserId);

    useEffect(() => {
        userId == "-1"
            ? dispatch(authLogout(false)) : dispatch(authLogout(true));
        dispatch(setIsAdmin(false));
    }, [dispatch]);


    /*  Read hostname from URL, load appropriate logins. */
    useEffect(() => {
        const hostname = window.location.hostname;
        const parts = hostname.split('.');

        console.log(parts);

        // if (parts[0] === "public" || parts[0] == "localhost" || parts[0] == "dev.google.app" || parts[0] == "dev") {
        //     setDisplayType("GOOGLE")
        // }

        const company_name = parts[0];
        if (company_name == "public") {
            setUserUrlOverride(get_okta_url(company_name, "user"));
            setAdminUrlOverride(get_okta_url(company_name, "admin"));
        } else if (company_name == "localhost" || company_name === "argus" || company_name === "globe" || company_name === "dev") {
            setDisplayType("GOOGLE")
        } else if (company_name == "framework") {
            window.location.replace('https://framework.prod.tryargus.com');
        } else if (company_name == "dragonfly") {
            window.location.replace('https://dragonfly.prod.tryargus.com')
        } else if (company_name == "paradigm") {
            window.location.replace('https://paradigm.prod.tryargus.com')
        } else if (company_name == "multicoin") {
            window.location.replace('https://multicoin.prod.tryargus.com')
        } else if (company_name == "variant") {
            window.location.replace('https://variant.prod.tryargus.com')
        } else if (company_name == "khelp") {
            window.location.replace('https://khelp.prod.tryargus.com')
        } else if (company_name == "oneriver") {
            window.location.replace('https://oneriver.prod.tryargus.com')
        } else if (company_name == "jnk") {
            window.location.replace('https://jnk.prod.tryargus.com')
        } else if (company_name == "nydig") {
            window.location.replace('https://nydig.prod.tryargus.com')
        } else if (company_name == "stoneridge") {
            window.location.replace('https://stoneridge.prod.tryargus.com')
        }


    }, [])

    const [cacheRefreshState, _setCacheRefreshState] = useState(0);
    const cacheRefreshStateRef = useRef(cacheRefreshState)
    const setCacheRefreshState = (data: number) => {
        cacheRefreshStateRef.current = data;
        _setCacheRefreshState(data)
    }
    useEffect(() => {
        function checkCacheResetSequence(e: any) {

            // Cache Clear combo is CTRL + Space
            if (e.keyCode === 32 && cacheRefreshStateRef.current === 17) {
                dispatch(authLogout(true));
            }
            setCacheRefreshState(e.keyCode)
        }

        document.addEventListener('keydown', checkCacheResetSequence);

        // Don't forget to clean up
        return function cleanup() {
            document.removeEventListener('keydown', checkCacheResetSequence);
        }
    }, []);

    const handleConnection = useCallback((auth0Url?: string) => {
        if (!auth0Url) {
            return auth0Url
        }

        const nonce = nanoid();
        const url = `${auth0Url}&nonce=${nonce}`;
        window.localStorage.setItem('nonce', nonce);
        window.location.href = url
    }, []);

    return (
        <StyledLoginPage>
            <StyledLoginBox style={{ minHeight: "35%", paddingBottom: "5px" }}>
                <div
                    style={{
                        height: "15%",
                        display: "flex",
                        justifyContent: "center",
                        boxSizing: "border-box",
                        fontSize: "40px",
                        lineHeight: "40px",
                    }}
                ></div>

                <h2
                    style={{
                        color: "#15357A",
                        textAlign: "center",
                        fontSize: "24px",
                        lineHeight: "28px",
                    }}
                >
                    Login
                </h2>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "3%",
                        color: "#2C71F0",
                        textAlign: "center",
                    }}
                >
                    {
                        displayType === "MICROSOFT" ?
                            <>

                                <StyledActionButton
                                    style={{ width: "33%" }}
                                    onClick={() => handleConnection(userUrlOverride === "" ? MS_SSO_ENDPOINT : userUrlOverride)}
                                >
                                    User Login
                                </StyledActionButton>

                                <StyledActionButton
                                    style={{ width: "33%" }}
                                    onClick={() => handleConnection(adminUrlOverride === "" ? MS_SSO_ENDPOINT_ADMIN : adminUrlOverride)}
                                >
                                    Admin Login
                                </StyledActionButton>
                            </> :
                            <>
                                <GoogleAuthProvider>
                                    <div style={{ width: "250px", display: "flex", "alignItems": "left", justifyContent: "left", marginBottom: "0.5%" }}>
                                        <label style={{ marginBottom: "0.5%" }}> User Login: </label>
                                    </div>

                                    <GoogleButton
                                        width={250}
                                        // loginAbsolutePath="https://dev.app.tryargus.com/redirect?logintype=google"
                                        onCredentialResponse={(response: { credential: string }) => {
                                            console.log(response.credential);
                                            dispatch(
                                                authLoginGoogle({
                                                    idToken: response.credential,
                                                    asAdmin: false,
                                                    location: location
                                                }))
                                        }}
                                    />


                                    <div style={{ width: "250px", display: "flex", "alignItems": "left", justifyContent: "left", marginBottom: "0.5%", marginTop: "2.5%" }}>
                                        <label style={{ marginBottom: "0.5%" }}> Admin Login: </label>
                                    </div>

                                    <GoogleButton
                                        width={250}
                                        // loginAbsolutePath="https://dev.app.tryargus.com/redirect?logintype=google"
                                        onCredentialResponse={(response: { credential: string }) => {
                                            console.log(response.credential);
                                            dispatch(
                                                authLoginGoogle({
                                                    idToken: response.credential,
                                                    asAdmin: true,
                                                    location: location
                                                }))
                                        }}
                                    />
                                </GoogleAuthProvider>
                            </>
                    }

                    <Link to={"/password_login"}>
                        <StyledLinkButton
                            style={{ marginTop: "3%", fontSize: "16px" }}
                        >
                            Log In via Password
                        </StyledLinkButton>
                    </Link>
                </div>
            </StyledLoginBox>
        </StyledLoginPage>
    );
}

export default PartnerLogin;
