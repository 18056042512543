import { useState } from "react";
import Modal from "../components/Modal";
import {StyledActionButton} from "../components/StyledActionButton";

function RestrictionDateModal({ children, name, handleSubmit}: any) {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <div
                onClick={() => setShowModal(true)}
                style={{
                    textAlign: "right",
                    fontSize: "14px",
                    textDecoration: "underline",
                    color: "#2c71f0",
                    cursor: "pointer",
                }}
            >
                {name}
            </div>

            <Modal
                width="30%"
                height="35%"
                marginLeft="35%"
                marginTop="10%"
                show={showModal}
                onClose={() => {
                    setShowModal(false);
                }}
                title={"Change restriction date"}
            >
                {children}
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems:"center",
                    justifyContent: "center"
                }}>
                    <StyledActionButton
                        onClick={(event) => {
                            handleSubmit();
                            setShowModal(false);
                        }}
                        style={{ margin: "10px 0 40px" }}
                    >
                        Save changes
                    </StyledActionButton>
                </div>
            </Modal>
        </>
    );
}

export default RestrictionDateModal;
