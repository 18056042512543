const handleDownload = async (method: any, name: string, csvFile='') => {
    if(csvFile === '') {
        const response = await method;
        csvFile = await response.text();
    }
    const blob = new Blob([csvFile], {type: "text/csv;charset=utf-8;"});
    if (navigator.msSaveBlob) {
        // In case of IE 10+
        navigator.msSaveBlob(blob, name);
    } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", name + ".csv");
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
};

export default handleDownload;
