import SmartList from "../../components/SmartList";
import { ISorting } from "../../dashboard/DashboardSlice";
import { IUser } from "../../users/UserPanelSlice";
import AdminAccountsListItem from "./AdminAccountsListItem";

interface IListProps {
    users: Array<IUser>;
    setSorting: (sorting: ISorting) => void;
    sorting: ISorting;
    statementIds: any;
}

export interface IUserSingleAccount {
    id: number;
    email: string;
    name: string;
    role: string;
    group: string;
    status: string;
    account: {
        account: string;
        lastUpdated: string;
        accountHolder: string;
        id: string;
        isConnected?: boolean;
        isLinked?: boolean;
        isExchange?: boolean;
        walletAddress?: string;
    };
}

const headerData = [
    { fieldText: "Name", fieldValue: "name" },
    { fieldText: "Status", fieldValue: "status" },
    { fieldText: "Account Name", fieldValue: "" },
    { fieldText: "Account Holder", fieldValue: "" },
    { fieldText: "Account Status", fieldValue: "" },
    { fieldText: "Last Updated", fieldValue: "lastUpdated" },
    { fieldText: "Documents", fieldValue: "" },
];

const expandUsers = (users: Array<IUser>): Array<IUserSingleAccount> => {
    let expandedUsers: Array<IUserSingleAccount> = [];
    users.forEach((user) => {
        if (user.account.length === 0) {
            expandedUsers.push({
                ...user,
                account: {
                    id: "",
                    account: "",
                    accountHolder: "",
                    lastUpdated: "",
                },
            });
        } else {
            user.account.forEach((account) => {
                expandedUsers.push({
                    ...user,
                    account: {
                        id: account.id,
                        account: account.account,
                        accountHolder: account.accountHolder,
                        lastUpdated: account.lastUpdated,
                        isConnected: account.isConnected,
                        isLinked: account.isLinked,
                        isExchange: account.isExchange,
                        walletAddress: account.walletAddress,
                    },
                });
            });
        }
    });

    return expandedUsers;
};

function AdminAccountsList(props: IListProps): JSX.Element {
    /* Provide default values and replace them with the prop values if there are any. */
    const { users, setSorting, sorting, statementIds } = props;
    const expandedUsers = expandUsers(users);

    const generateItem = (user: IUserSingleAccount, index: number) => (
        <AdminAccountsListItem
            name={user.name}
            status={user.status}
            account={user.account}
            statements={statementIds ? statementIds[user.account.id] : []}
            key={index}
        />
    );

    return (
        <SmartList
            headerData={headerData}
            arr={expandedUsers}
            generateItem={generateItem}
            setSorting={setSorting}
            sorting={sorting}
            disabled={[""]}
        />
    );
}
export default AdminAccountsList;
