//The jsxRuntime pragma is necessary to get Emotion and React to work w/ latest Create-React-App version
/** @jsxRuntime classic */
/** @jsx jsx */
import styled from "@emotion/styled";

export const StyledDropdown = styled.div`
    width: 25%;
    border: 1px solid #2c71f0;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px 1px rgba(0, 0, 0, 0.18);
    box-sizing: border-box;
    background: white;
    color: #15357a;
    border-radius: 5px;
    font-family: Lato, sans-serif;

    z-index: 1; /* Sit on top */
    margin-top: 5x;
    position: absolute;
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    li {
        cursor: pointer;
        padding-bottom: 5px;
        padding-left: 5px;
        line-height: 18px;
    }
    li:hover {
        // This is the same gray as our Nav bar (converted from hex to rgba)
        background-color: rgba(249, 249, 249, 1);
    }
`;
