import { useSelector } from "react-redux";
import IssueNotesModal from "../../components/IssueNotesModal";
import capitalizeFirstLetter from "../../util/capitalizeFirstLetter";
import { formatDate } from "../../util/formatDate";
import { smartTruncate } from "../../util/formatValues";
import handleDownload from "../../util/downloadCsv";
import { dashboardService } from "../../services/dashboardService";
interface IListItemProps {
    id: string;
    employee: string;
    accountHolder: string;
    ticker: string;
    investmentAccount: string;
    userExchangeAccount: string;
    volume: string;
    price?: number;
    securityName: string;
    dateCreated: string;
    transactionDate?: string;
    transactionType?: string;
    status: string;
    key: number;
    notes: JSON;
    handleSubmitNote(note: string, issueId: string): void;
}

const generateStatus = (status: string): string => {
    if (status.includes("PENDING")) {
        return "Open";
    }
    if (status.includes("IN_REVIEW")) {
        return "In review";
    }

    if (status.includes("RESOLVED")) {
        return "Allowed";
    }
    if (status.includes("MARKED")) {
        return "Confirmed";
    }

    return "";
};

const customDate = (date: string): string => {
    const dateObj = new Date(date);
    return `${dateObj.getMonth() + 1}/${dateObj.getDate()}/${dateObj.getFullYear()}`;
};

function HistoricInfractionsListItem(props: IListItemProps): JSX.Element {
    const version = useSelector((state: any) => state.auth.version);

    return (
        <tr>
            <td>{props.employee}</td>
            {(version === "crypto_base" || version === "crossover_base") && (
                <td>{props.accountHolder}</td>
            )}
            <td>
                {props.investmentAccount
                    ? props.investmentAccount
                    : capitalizeFirstLetter(props.userExchangeAccount)}
            </td>
            <td>
                {capitalizeFirstLetter(
                    props.transactionType
                        ? props.transactionType === "UNRECOGNIZED"
                            ? "TRANSACTION"
                            : props.transactionType
                        : ""
                )}
            </td>
            <td>{props.ticker}</td>
            <td>{props.securityName}</td>
            <td>
                {props.volume && props.price
                    ? smartTruncate(parseFloat(props.volume) * props.price)
                    : "--"}
            </td>
            <td>{smartTruncate(parseFloat(props.volume))}</td>
            <td>{formatDate(props.dateCreated)}</td>
            {/* <td>{props.transactionDate ? formatDate(props.transactionDate) : ""}</td> */}
            <td>{generateStatus(props.status)}</td>
            <td>
                <IssueNotesModal
                    handleSubmit={props.handleSubmitNote}
                    issueId={props.id}
                >
                    {props.notes &&
                        Object.entries(props.notes).map((noteEntry) => {
                            return (
                                <p key={noteEntry[0]}>
                                    {noteEntry[1]?.user} (
                                    {customDate(noteEntry[1]?.timestamp)}):{" "}
                                    {noteEntry[1]?.note}
                                </p>
                            );
                        })}
                </IssueNotesModal>
            </td>
        </tr>
    );
}

export default HistoricInfractionsListItem;
