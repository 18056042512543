interface RequiredTextWarningProps {
    customText?: string;
    wrapperStyles?: React.CSSProperties;
    textStyles?: React.CSSProperties;
}

function RequiredTextWarning({
    customText,
    wrapperStyles,
    textStyles
}: RequiredTextWarningProps): JSX.Element {
    let defaultPStyle = { position: "absolute", marginTop: "2.5%", font: "Lato", fontSize: "14px", color: "#f02c38" } as React.CSSProperties
    let pStyle = { ...defaultPStyle, ...textStyles }
    let defaultDivStyle = { position: 'relative' } as React.CSSProperties
    let divStyle = { ...defaultDivStyle, ...wrapperStyles }
    let text = customText ? customText : "This field is required."
    return (
        <div style={{ ...divStyle }}>
            <p style={{ ...pStyle }}>
                {" "}
                {text}{" "}
            </p>
        </div>
    );
}

export default RequiredTextWarning;