import { StyledInputField } from "../../components/StyledInputField";
import { StyledModalItem } from "../../components/StyledModalItem";
import ModalLabel from "../ModalLabel";
import { Version } from "./QuantityInput";

export interface IVolumeInputProps {
    volume: string;
    setVolume(volume: string): void;
    showErrors: boolean;
    version: "SECURITY" | "CRYPTO" | "ALL";
    setComplete(complete: boolean): void;
    onChange(e: any): void;
    error?: string;
}

function VolumeInput({
    volume,
    setVolume,
    showErrors,
    version,
    setComplete,
    onChange,
    error,
}: IVolumeInputProps): JSX.Element {
    return (
        <StyledModalItem>
            <ModalLabel>Enter Volume:</ModalLabel>
            <StyledInputField
                placeholder={
                    version === Version.SECURITY
                        ? "Please enter number of shares..."
                        : "Please enter number of units..."
                }
                type="number"
                min={0}
                name="volume"
                value={volume}
                onChange={(event) => {
                    setVolume(event.target.value);
                    onChange(event);
                }}
                onWheel={(event) => event.currentTarget.blur()}
            />
            {showErrors && error && (
                <p style={{ margin: "0 0 10px 0", color: "red" }}>{error}</p>
            )}
        </StyledModalItem>
    );
}

export default VolumeInput;
