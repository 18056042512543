import { authService } from "./authService";

export const designatedUsersService = {
    createDesignatedUser,
    getSecondaryUsers,
    deleteDesignatedUser,
    createProxyUser
};

/* Based on auth */
const base = process.env.REACT_APP_API_ENDPOINT;
const authBase = `${base}/auth/secondary-user`;


function createProxyUser(name: string, email: string, employeeId: string) {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
            "Content-type": "application/json",
        },
        body: JSON.stringify({ name, email, employeeId }),
    };

    return fetch(`${authBase}/proxy`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}


function createDesignatedUser(name: string) {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
            "Content-type": "application/json",
        },
        body: JSON.stringify({ name }),
    };

    return fetch(`${authBase}`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

function deleteDesignatedUser(secondaryUserId: string) {
    const requestParams = {
        method: "DELETE",
        headers: {
            ...authService.authHeader(),
            "Content-type": "application/json",
        },
        body: JSON.stringify({}),
    };

    return fetch(`${authBase}/` + secondaryUserId, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

function getSecondaryUsers() {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
            "Content-type": "application/json",
        },
        body: JSON.stringify({}),
    };

    return fetch(`${authBase}/get`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

function handleResponse(response: any) {
    return response.text().then((text: any) => {
        const data = text && JSON.parse(text);
        return data;
    });
}
