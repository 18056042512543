interface ILocationState {
    from: { pathname: string };
    from2: { pathname: string };
    from3: { pathname: string };
}

export function getLoginDestination(
    isAdminLocal: boolean,
    location: any,
    version: string
) {
    let { from } = isAdminLocal
        ? { from: { pathname: "admin" } }
        : (location.state as ILocationState) || { from: { pathname: "/" } };

    if (version === "crypto_base" || version === "crossover_base") {
        if (!isAdminLocal) {
            return { pathname: "/user-restrictions" }
        }
    }
    if (version === "brokerage_integration" || version === "brokerage_integration_plus" || version === "brokerage_integration_plus_v2" || version === "crypto_monitoring") {
        if (isAdminLocal) {
            var { from2 } = { from2: { pathname: "/userPanel" } };
            return from2;
        } else {
            var { from3 } = {
                from3: { pathname: "/accounts" },
            };
            return from3;
        }
    }

    return from;
}
