import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StyledSectionHeader } from "../components/StyledSectionHeader";
import useInterval from "../util/useInterval";
import { fetchAdminAttestations, IAttestation, selectAttestations } from "./AttestationsSlice";
import capitalizeFirstLetter from "../util/capitalizeFirstLetter";
import { attestationsService } from "../services/attestationsService";
import UserAutocomplete, { UserSuggestion } from "../components/UserAutoComplete";
import { StyledTableContainer } from "../components/StyledTable";


function AdminAttestations(): JSX.Element {


    const dispatch = useDispatch();
    const attestations = useSelector(selectAttestations);
    const [user, setUser] = useState<UserSuggestion>();


    useEffect(() => {
        if (user !== undefined) {
            dispatch(fetchAdminAttestations(user.id))
        } else {
            dispatch(fetchAdminAttestations())
        }
    }, [
        dispatch,
        user

    ]);

    useInterval(() => {
        if (user !== undefined) {
            dispatch(fetchAdminAttestations(user.id))
        } else {
            dispatch(fetchAdminAttestations())
        }
    }, 25000);


    const handleSearch = (user: UserSuggestion) => {
        setUser(user);
    }

    return (
        <>
            <StyledSectionHeader> Review and Download Users Attestations </StyledSectionHeader>

            <div style={{ position: "relative" }}>
                <UserAutocomplete
                    value={""}
                    onSelect={handleSearch}
                    onEnter={handleSearch}
                    style={{ width: "100%" }}
                    placeholder={"Filter by name or email"}
                />
            </div>

            <StyledTableContainer>
                <table>
                    <thead>
                        <tr>
                            <th>
                                Name
                            </th>
                            <th>
                                Quarter/Year
                            </th>
                            <th>
                                Attestation
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        {[...attestations]
                            .sort((a: IAttestation, b: IAttestation) => { return a.user.name.localeCompare(b.user.name) })
                            .map((attestation: IAttestation) => {
                                return (
                                    <tr>
                                        <td>
                                            {attestation ? attestation.user.name : ""}
                                        </td>
                                        <td>
                                            {attestation.quarter}/{attestation.year}
                                        </td>
                                        <td>
                                            <span
                                                onClick={(event: any) => { attestationsService.downloadAttestation(attestation.quarter, attestation.year, attestation.user.id, attestation.attestationType) }}
                                                style={{ cursor: "pointer" }}>
                                                {capitalizeFirstLetter(attestation.attestationType)}-{attestation.quarter}-{attestation.year}.pdf
                                            </span>
                                        </td>
                                    </tr>)
                            })
                        }
                    </tbody>


                </table>
            </StyledTableContainer>

        </>
    );

}

export default AdminAttestations;


