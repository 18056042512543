import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ISorting } from "../dashboard/DashboardSlice";
import { accountsService } from "../services/accountsService";
import { IPagination } from "../util/pagination";

export interface ILinkedAccount {
    externalAccountId: string;
    linkedAccountProvider: string;
}

export interface IInvestmentAccount {
    id: string;
    name: string;
    linkedAccount?: ILinkedAccount;
}

export interface IAccount {
    investmentAccount: IInvestmentAccount;
    description: string;
    lastUpdated: string;
    lastPull: string;
    lastConnection: string;
    dateCreated: string;
    isLinked: boolean;
    isConnected: boolean;
    id: string;
    accountCreationDate: string;
    accountNumber: string;
    connectionStatus: string;
}

export interface ICryptoAccount {
    accountType: string;
    accountHolder: string;
    description: string;
    lastUpdated: string;
    dateCreated: string;
    cryptoExchange: ICryptoExchange;
    cryptoExchangeId: string;
    isConnected: boolean;
    exchangeName: string;
    id: string;
    supported: boolean;
}

export interface IUnsupportedUserExchangeAccount {
    id: string;
    name: string;
    accountHolder: string;
}

export interface ICryptoExchange {
    exchangeName: string;
    exchangeId: string;
}

export interface IUserExchangeAccount {
    cryptoExchange: ICryptoExchange;
    accountHolder: string;
}

export interface IAccountValue {
    key: string;
    value: Array<IAccountStatement>;
}

export interface IAccountStatement {
    dateCreated: string;
    id: string;
    lastUpdated: string;
    month: number;
    userAccountId: string;
    userId: string;
    year: number;
}

export interface IExchangeAccountStatement {
    dateCreated: string;
    id: string;
    lastUpdated: string;
    month: number;
    userAccountId: string;
    userId: string;
    year: number;
}

export interface IAccountsPanel {
    status: string;
    accounts: {
        data: Array<IAccount>;
        nextPage: boolean;
    };
    cryptoAccounts: {
        data: Array<ICryptoAccount>;
        nextPage: boolean;
    };
    unsupportedUserExchangeAccounts: Array<IUnsupportedUserExchangeAccount>;
    accountStatements: Array<IAccountStatement>;
    exchangeAccountStatements: Array<IExchangeAccountStatement>;
    userDeclare: boolean;
    showUserDeclare: boolean;
}

export interface IAccountsTable {
    account: string;
    lastUpdated: string;
    status: string;
    accountNumber: string;
}

const initialState: IAccountsPanel = {
    status: "idle",
    accounts: {
        data: [],
        nextPage: false,
    },
    cryptoAccounts: {
        data: [],
        nextPage: false,
    },
    unsupportedUserExchangeAccounts: [],
    accountStatements: [],
    exchangeAccountStatements: [],
    userDeclare: false,
    showUserDeclare: false,
};

export const fetchAccounts = createAsyncThunk(
    "accounts/fetchAccounts",
    async (payload: { pageNumber: number; sorting: ISorting[]; pageSize: number }) => {
        return await accountsService.getAccounts(payload);
    }
);

export const saveNewAccount = createAsyncThunk(
    "accounts/createAccount",
    async (
        payload: {
            name: string;
            accountNumber: string;
            accountCreationDate: string;
            accountHolderId: string;
            description: string;
        },
        { dispatch }
    ) => {
        const {
            name,
            accountNumber,
            accountCreationDate,
            accountHolderId,
            description,
        } = payload;
        const res = await accountsService.createAccount(
            name,
            accountNumber,
            accountCreationDate,
            accountHolderId,
            description
        );
        if (res) {
            dispatch(fetchUserDeclare());
        }
        return res;
    }
);

export const saveDeleteAccount = createAsyncThunk(
    "accounts/saveDeleteAccount",
    async (userAccountId: string, { dispatch }) => {
        await accountsService.deleteAccount(userAccountId);
        dispatch(fetchUserDeclare());
        return userAccountId;
    }
);

export const fetchCryptoAccounts = createAsyncThunk(
    "accounts/fetchCryptoAccounts",
    async (payload: { pageNumber: number; sorting: ISorting[]; pageSize: number }) => {
        return await accountsService.getCryptoAccounts(payload);
    }
);

export const fetchUnsupportedCryptoAccounts = createAsyncThunk(
    "accounts/fetchUnsupportedCryptoAccounts",
    async () => {
        const res = await accountsService.getUnsupportedUserExchangeAccounts();
        return res;
    }
);

export const fetchSixMonths = createAsyncThunk(
    "accounts/fetchSixMonths",
    async (accountId: string) => {
        const res = await accountsService.getAccountSixMonths(accountId);
        return res.data;
    }
);

export const fetchSixMonthsExchanges = createAsyncThunk(
    "accounts/fetchSixMonthsExchanges",
    async (exchangeId: string) => {
        const res = await accountsService.getUserExchangeAccountsSixMonths(exchangeId);
        return res.statement;
    }
);

export const saveLastUpdated = createAsyncThunk(
    "accounts/saveLastUpdated",
    async (accountId: string) => {
        const res = await accountsService.updateLastUpdated(accountId);
        return res.statement;
    }
);

export const saveNewCryptoAccount = createAsyncThunk(
    "accounts/createCryptoAccount",
    async (
        payload: { exchangeId: string; accountHolderId: string; description: string },
        { dispatch }
    ) => {
        const { exchangeId, accountHolderId, description } = payload;
        const res = await accountsService.createCryptoAccount(
            exchangeId,
            accountHolderId,
            description
        );
        if (res) {
            dispatch(fetchUserDeclare());
        }
        return res;
    }
);

export const saveNewUnsupportedCryptoAccount = createAsyncThunk(
    "accounts/createUnsupportedCryptoAccount",
    async (payload: { name: string; accountHolder: string }, { dispatch }) => {
        const { name, accountHolder } = payload;
        const res = await accountsService.createUnsupportedUserExchangeAccount(
            name,
            accountHolder
        );
        if (res) {
            dispatch(fetchUserDeclare());
        }
        return res;
    }
);

export const saveDeleteCryptoAccount = createAsyncThunk(
    "accounts/saveDeleteCryptoAccount",
    async (userAccountId: string, { dispatch }) => {
        await accountsService.deleteCryptoAccount(userAccountId);
        dispatch(fetchUserDeclare());
        return userAccountId;
    }
);

export const uploadAccountStatement = createAsyncThunk(
    "accounts/uploadAccountStatement",
    async (payload: {
        accountId: string;
        userId: string;
        month: number;
        year: number;
        file: string;
    }) => {
        const { accountId, userId, file, month, year } = payload;
        return await accountsService.uploadAccountStatement(
            accountId,
            userId,
            month,
            year,
            file
        );
    }
);

export const uploadExchangeStatement = createAsyncThunk(
    "accounts/uploadExchangeStatement",
    async (payload: {
        exchangeId: string;
        userId: string;
        month: number;
        year: number;
        file: string;
    }) => {
        const { exchangeId, userId, file, month, year } = payload;
        return await accountsService.uploadUserExchangeAccountStatement(
            exchangeId,
            userId,
            month,
            year,
            file
        );
    }
);

export const fetchUserDeclare = createAsyncThunk(
    "accounts/fetchUserDeclare",
    async () => {
        return await accountsService.getUserDeclare();
    }
);

export const updateUserDeclare = createAsyncThunk(
    "accounts/updateUserDeclare",
    async (declare: boolean) => {
        return await accountsService.changeUserDeclare(declare);
    }
);

const accountsSlice = createSlice({
    name: "accounts",
    initialState,
    reducers: {
        addBrokerage(state, action) {
            state.accounts.data.push(action.payload);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAccounts.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(fetchAccounts.fulfilled, (state, action) => {
                state.status = "idle";
                state.accounts = {
                    data: action.payload.data as IAccount[],
                    nextPage: action.payload.nextPage,
                };
            })
            .addCase(saveNewAccount.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(saveNewAccount.fulfilled, (state, action) => {
                state.status = "idle";
                state.accounts.data.push(action.payload);
            })
            .addCase(saveDeleteAccount.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(saveDeleteAccount.fulfilled, (state, action) => {
                state.status = "idle";
                state.accounts.data = state.accounts.data.filter(
                    (account) => account.id !== action.payload
                );
            })
            .addCase(fetchCryptoAccounts.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(fetchCryptoAccounts.fulfilled, (state, action) => {
                state.status = "idle";
                state.cryptoAccounts = {
                    data: action.payload.data,
                    nextPage: action.payload.nextPage,
                };
            })
            .addCase(fetchUnsupportedCryptoAccounts.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(fetchUnsupportedCryptoAccounts.fulfilled, (state, action) => {
                state.status = "idle";
                state.unsupportedUserExchangeAccounts = action.payload.data;
            })
            .addCase(saveNewUnsupportedCryptoAccount.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(saveNewUnsupportedCryptoAccount.fulfilled, (state, action) => {
                state.status = "idle";
                state.unsupportedUserExchangeAccounts.push(action.payload);
            })
            .addCase(saveNewCryptoAccount.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(saveNewCryptoAccount.fulfilled, (state, action) => {
                state.status = "idle";
                state.cryptoAccounts.data.push(action.payload);
            })
            .addCase(saveDeleteCryptoAccount.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(saveDeleteCryptoAccount.fulfilled, (state, action) => {
                state.status = "idle";
                state.cryptoAccounts.data = state.cryptoAccounts.data.filter(
                    (account) => account.id !== action.payload
                );
            })
            .addCase(fetchSixMonths.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(fetchSixMonths.fulfilled, (state, action) => {
                state.status = "idle";
                state.accountStatements = action.payload;
            })
            .addCase(fetchSixMonthsExchanges.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(fetchSixMonthsExchanges.fulfilled, (state, action) => {
                state.status = "idle";
                state.exchangeAccountStatements = action.payload;
            })
            .addCase(uploadAccountStatement.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(uploadAccountStatement.rejected, (state, action) => {
                console.log(action.error);
            })
            .addCase(uploadAccountStatement.fulfilled, (state, action) => {
                state.status = "idle";
                let data = action.payload;
                state.accountStatements = state.accountStatements.filter(
                    (statement) => statement.month !== data.month
                );
                state.accountStatements.push(data);
            })
            .addCase(uploadExchangeStatement.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(uploadExchangeStatement.rejected, (state, action) => {
                console.log(action.error);
            })
            .addCase(uploadExchangeStatement.fulfilled, (state, action) => {
                state.status = "idle";
                let data = action.payload;
                state.exchangeAccountStatements =
                    state.exchangeAccountStatements.filter(
                        (statement) => statement.month !== data.month
                    );
                state.exchangeAccountStatements.push(data);
            })
            .addCase(saveLastUpdated.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(saveLastUpdated.fulfilled, (state, action) => {
                state.status = "idle";
            })
            .addCase(fetchUserDeclare.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(fetchUserDeclare.fulfilled, (state, action) => {
                state.status = "idle";
                state.userDeclare = action.payload !== "UNDECLARED";
                state.showUserDeclare = action.payload !== "DECLARED_ACCOUNTS";
            })
            .addCase(updateUserDeclare.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(updateUserDeclare.rejected, (state, action) => {
                console.log(action.error);
            })
            .addCase(updateUserDeclare.fulfilled, (state, action) => {
                state.status = "idle";
                state.userDeclare = action.payload !== "UNDECLARED";
                state.showUserDeclare = action.payload !== "DECLARED_ACCOUNTS";
            });
    },
});

export const {
    addBrokerage,
    /* Delete these two actions after demo*/
} = accountsSlice.actions;

export const selectAccounts = (state: { accounts: IAccountsPanel }) =>
    state.accounts.accounts.data;
export const selectCryptoAccounts = (state: { accounts: IAccountsPanel }) =>
    state.accounts.cryptoAccounts.data;
export const selectAccountStatements = (state: { accounts: IAccountsPanel }) =>
    state.accounts.accountStatements;
export const selectExchangeAccountStatements = (state: {
    accounts: IAccountsPanel;
}) => {
    return state.accounts.exchangeAccountStatements;
};
export const selectUserDeclare = (state: { accounts: IAccountsPanel }) =>
    state.accounts.userDeclare;
export const selectShowUserDeclare = (state: { accounts: IAccountsPanel }) =>
    state.accounts.showUserDeclare;

export default accountsSlice.reducer;
