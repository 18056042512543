import { StyledTableContainer } from "../components/StyledTable";
import ClearanceTradeListItem from "./TradesListItem";
import { ISorting, ITrade, SortingField } from "./DashboardSlice";
import SelectableTableHeader from "../components/SelectableTableHeader";
import { useSelector } from "react-redux";
import { selectVersion } from "../auth/AuthSlice";
import capitalizeFirstLetter from "../util/capitalizeFirstLetter";

/* Type for the WatchlistList props */
interface IListProps {
    trades: Array<ITrade>;
    tradeType: string;
    handleDelete(employee: string): void;
    setSorting: (sorting: ISorting) => void;
    sorting: ISorting;
}

function TradesList(props: IListProps): JSX.Element {
    const headerDataBase = [
        { fieldText: "Type", fieldValue: "transactionType" },
        { fieldText: "Ticker", fieldValue: "ticker" },
        { fieldText: "Asset", fieldValue: "asset" },
        { fieldText: "Volume / Value", fieldValue: "volume" },
    ];

    const prefix = [{ fieldText: "Employee", fieldValue: "user" }];

    const dateBlockAll = [
        { fieldText: "Date Submitted", fieldValue: "dateSubmitted" },
        // { fieldText: "Date Processed", fieldValue: "dateProcessed" },
        { fieldText: "Approved By", fieldValue: "complianceUser" },
        { fieldText: "Approved From", fieldValue: "approvedFrom" },
        { fieldText: "Approved Until", fieldValue: "approvedUntil" },
    ];

    const dateBlockCleared = [
        { fieldText: "Status", fieldValue: "status" },
        { fieldText: "Approved From", fieldValue: "approvedFrom" },
        { fieldText: "Approved Until", fieldValue: "approvedUntil" },
    ];
    const dateBlockPending = [
        { fieldText: "Status", fieldValue: "status" },
        { fieldText: "Date Submitted", fieldValue: "dateSubmitted" },
    ];

    const dateBlockClosed = [
        { fieldText: "Status", fieldValue: "status" },
        { fieldText: "Date of Status Update", fieldValue: "dateUpdated" },
    ];

    const secondAssetBlock = [
        { fieldText: "Pair Ticker", fieldValue: "secondaryCrypto.ticker" },
        { fieldText: "Pair Name", fieldValue: "secondaryCrypto.name" },
        { fieldText: "Volume / Value", fieldValue: "secondaryCryptoVolume" },
    ];
    /* Provide default values and replace them with the prop values if there are any. */
    const { trades, setSorting, sorting } = props;
    const version = useSelector(selectVersion);

    let headerData: Array<{ fieldText: string; fieldValue: string }> = [];
    if (version === "crypto_base" || version === "crossover_base") {
        headerData = headerDataBase.concat(secondAssetBlock);
    } else {
        headerData = headerDataBase;
    }
    if (props.tradeType === "cleared") headerData = headerData.concat(dateBlockCleared);
    if (props.tradeType === "pending") headerData = headerData.concat(dateBlockPending);
    if (props.tradeType === "closed") headerData = headerData.concat(dateBlockClosed);
    if (props.tradeType === "all") {
        headerData.push({ fieldText: "Status", fieldValue: "status" });
        if (true) {
            headerData.push({ fieldText: "Notes", fieldValue: "representations" });
        }
        headerData = prefix.concat(headerData.concat(dateBlockAll));
    }

    return (
        <StyledTableContainer style={{ width: "100%" }}>
            <table>
                <thead>
                    <tr>
                        {headerData.map((header, index) => {
                            return (
                                <SelectableTableHeader
                                    key={index}
                                    targetCompare={header.fieldValue}
                                    activeCompare={sorting.field}
                                    setActiveCompare={(field: SortingField) =>
                                        setSorting({ ...sorting, field })
                                    }
                                    asc={sorting.direction === "asc"}
                                    setAsc={(asc: boolean) =>
                                        setSorting({
                                            ...sorting,
                                            direction: asc ? "asc" : "desc",
                                        })
                                    }
                                    disabled={["secondaryCrypto.ticker", "secondaryCrypto.name"]}
                                >
                                    {header.fieldText}
                                </SelectableTableHeader>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {/* Generate the DashboardList and add them to the ul */}
                    {/*
                        1. Filter by the activeFilter, show everything if empty, matching names and tickers if not.
                        2. Slice to show only show pageSize number of results (defaults to 100)
                        3. Map the entities array to AdminListItem components
                        */}

                    {trades
                        ? trades.map((trade: ITrade, index: number) => (
                            <ClearanceTradeListItem
                                tradeType={props.tradeType}
                                transactionType={
                                    trade.transactionSubtype
                                        ? trade.transactionType === "WITHDRAWAL"
                                            ? "Send " +
                                            capitalizeFirstLetter(
                                                trade.transactionSubtype
                                            )
                                            : trade.transactionSubtype === "AIRDROP"
                                                ? "Claim " +
                                                capitalizeFirstLetter(
                                                    trade.transactionSubtype
                                                )
                                                : "Receive " +
                                                capitalizeFirstLetter(
                                                    trade.transactionSubtype
                                                )
                                        : capitalizeFirstLetter(trade.transactionType)
                                }
                                orderType={trade.orderType}
                                ticker={
                                    trade.security
                                        ? trade.security.ticker
                                        : trade.crypto
                                            ? trade.crypto.ticker
                                            : trade.privateAsset ? trade.privateAsset.ticker : ""
                                }
                                assetName={
                                    trade.security
                                        ? trade.security.name
                                        : trade.crypto
                                            ? trade.crypto.name +
                                            (trade.crypto.cryptocurrencyType !==
                                                "CURRENCY" && trade.crypto.platform
                                                ? " [" + trade.crypto.platform + "]"
                                                : "")
                                            : trade.privateAsset ? trade.privateAsset.name : ""
                                }
                                secondaryTicker={
                                    trade.secondaryCrypto
                                        ? trade.secondaryCrypto.ticker
                                        : ""
                                }
                                secondaryAssetName={
                                    trade.secondaryCrypto
                                        ? trade.secondaryCrypto.name
                                        : ""
                                }
                                currency={trade.currency}
                                volume={trade.volume}
                                secondaryCryptoVolume={trade.secondaryCryptoVolume}
                                amount={trade.amount}
                                secondaryCryptoAmount={trade.secondaryCryptoAmount}
                                status={trade.status}
                                approvedFrom={trade.approvedFrom}
                                approvedUntil={trade.approvedUntil}
                                complianceUser={
                                    trade.complianceUser
                                        ? trade.complianceUser.name
                                        : ""
                                }
                                dateCreated={trade.dateCreated}
                                dateUpdated={trade.dateUpdated}
                                handleDelete={props.handleDelete}
                                user={trade.user ? trade.user.name : ""}
                                representations={trade.representations}
                                explanation={trade.explanation}
                                subCategory={trade.subCategory}
                                frequency={trade.frequency}
                                start={trade.start}
                                complianceNotes={trade.complianceNotes}
                                key={index}
                            />
                        ))
                        : null}
                </tbody>
            </table>
        </StyledTableContainer>
    );
}

TradesList.defaultProps = {
    clearedTrades: false,
    pendingTrades: false,
    closedTrades: false,
};

export default TradesList;