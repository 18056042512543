import SmartList from "../components/SmartList";
import { ISorting } from "../dashboard/DashboardSlice";
import UserPanelListItem from "./UserPanelListItem";
import { IUser } from "./UserPanelSlice";

interface IListProps {
    users: Array<IUser>;
    handleDelete(email: string): void;
    handleEdit(id: number, name: string): void;
    setSorting: (sorting: ISorting) => void;
    sorting: ISorting;
}

const headerData = [
    { fieldText: "Email", fieldValue: "email" },
    { fieldText: "Name", fieldValue: "name" },
    { fieldText: "Status", fieldValue: "status" },
    { fieldText: "Account", fieldValue: "account" },
    { fieldText: "Last Updated", fieldValue: "lastUpdated" },
];

function UserPanelList(props: IListProps): JSX.Element {
    /* Provide default values and replace them with the prop values if there are any. */
    const { users, setSorting, sorting } = props;

    const generateItem = (user: IUser, index: number) => (
        <UserPanelListItem
            id={user.id}
            email={user.email}
            name={user.name}
            role={user.role}
            group={user.group}
            status={user.status}
            account={user.account}
            handleDelete={props.handleDelete}
            handleEdit={props.handleEdit}
            key={index}
        />
    );

    return (
        <SmartList
            headerData={headerData}
            bumper=""
            arr={users}
            generateItem={generateItem}
            setSorting={setSorting}
            sorting={sorting}
            disabled={["account", "lastUpdated"]}
        />
    );
}
export default UserPanelList;
