import { useEffect, useState } from "react";
import trashcan from "../assets/trashcan.svg";
import { StyledBubbleSpan } from "../components/StyledBubbleSpan";
import { allowedEtfsJson } from "./AllowedEtfs";

let allowedEtfs = new Map<string, boolean>();
allowedEtfsJson.etfs.forEach((etf) => {
    allowedEtfs.set(etf, true);
});

interface IWatchlistItemProps {
    ticker: string;
    name: string;
    movement: number;
    price: string;
    unit: string;
    status: string;
    exchange: string;
    handleDelete(ticker: string, unit: string): void;
    setShowModal(show: boolean, ticker: string, name: string, stockId: string): void;
    key: number;
}

/* Makes up the WatchlistList
 * Set up as component to more gracefully support styling
 * and eventually add buttons/functionality to them.
 */
function WatchlistListItem(props: IWatchlistItemProps): JSX.Element {
    const [statusColor, setStatusColor] = useState("black");

    useEffect(() => {
        if (props.status === "RESTRICTED") {
            setStatusColor("#F02C38");
        } else if (props.status === "REQUEST_APPROVAL") {
            setStatusColor("#F0A22C");
        } else if (props.status === "NOT_RESTRICTED") {
            setStatusColor("#22B43A");
            // setStatusColor("#F0A22C");
            if (allowedEtfs.get(props.ticker)) {
                setStatusColor("#22B43A");
            }
        }
    }, [props.status, props.ticker]);

    return (
        <tr>
            <td>{props.ticker}</td>
            <td>{props.unit === "USDT" ? "USD" : props.unit}</td>
            <td>{props.exchange}</td>
            <td
                style={props.movement < 0 ? { color: "#F02C38" } : { color: "#22B43A" }}
            >
                {Number(props.price).toFixed(2)} (
                {props.movement > 0
                    ? "+" + Number(props.movement).toFixed(2)
                    : Number(props.movement).toFixed(2)}
                )
            </td>
            <td>
                <StyledBubbleSpan
                    style={{
                        background: statusColor,
                        padding: "5px",
                        paddingLeft: "6px",
                        paddingRight: "6px",
                    }}
                >
                    {/* {props.status === 'Not Restricted' && !(allowedEtfs.get(props.ticker)) ? <span onClick={(e) => { props.setShowModal(true, props.ticker, props.name) }}>
                        Request approval</span> : props.status} */}
                    {props.status}
                </StyledBubbleSpan>
            </td>
            <td>
                <button
                    style={{
                        background: "transparent",
                        border: "none",
                        cursor: "pointer",
                    }}
                    onClick={(e) => {
                        props.handleDelete(props.ticker, props.unit);
                    }}
                >
                    <img src={trashcan} alt="" />
                </button>
            </td>
            {/* i.e. Delete button goes here */}
        </tr>
    );
}

export default WatchlistListItem;
