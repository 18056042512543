import { useState } from "react";

// function Attestation({ children }: { children: any }, {isChecked}: {isChecked: boolean}): JSX.Element {
interface IAttestation {
    style: { attestation: any, label: any };
    children: any;
    isChecked?: boolean;
    name: string;
    onCheck(event: any): void;
}

function Attestation(props: IAttestation): JSX.Element {
    const { style, children, isChecked, onCheck, name } = props;

    /* 
        Uses flexbox to align checkboxes and labels as desired.

        display: block in the label is needed to force the lines to properly space vertically.
    */

    return (
        <div style={{ width: "50%", display: "flex", marginBottom: "16px", ...style.attestation }}>
            <div style={{ display: "flex" }}>
                <input name={name} checked={isChecked} onChange={(event) => { onCheck(event) }} id={`attest-${children.replaceAll(" ", "-").toLowerCase()}`} type="checkbox" />
            </div>
            <div>
                <label htmlFor={`attest-${children.replaceAll(" ", "-").toLowerCase()}`}
                    style={{ fontSize: "11px", lineHeight: "15px", display: "block", ...style.label }}>
                    {children}
                </label>
            </div>
        </div>
    );
}

export default Attestation;