import { ISuspiciousWallet } from "../AnalyticsSlice";
import SmartList from "../../components/SmartList";
import { ISorting } from "../../dashboard/DashboardSlice";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import SuspiciousWalletsListItem from "./SuspiciousWalletsListItem";

interface IListProps {
    records: Array<ISuspiciousWallet>;
    selected: ISuspiciousWallet | undefined;
    sorting: ISorting;
    setSorting: (sorting: ISorting) => void;
    onSelect: (record: ISuspiciousWallet) => void;
    onFlag: (record: ISuspiciousWallet) => void;
    onReview: (records: ISuspiciousWallet) => void;
    onCompliance: (record: ISuspiciousWallet) => void;
    onWhitelisting: (record: ISuspiciousWallet) => void;
}

function WalletList(props: IListProps): JSX.Element {
    const {
        records,
        sorting,
        selected,
        setSorting,
        onSelect,
        onFlag,
        onCompliance,
        onReview,
        onWhitelisting,
    } = props;

    const version = useSelector((state: any) => state.auth.version);
    const headerData = useMemo(() => {
        return [
            { fieldText: "Employee", fieldValue: "userName" },
            { fieldText: "Undeclared wallet address", fieldValue: "partyAddress" },
            { fieldText: "Status", fieldValue: "status" },
        ].filter(
            (header) => version === "crypto_base" || header.fieldText !== "Account Name"
        );
    }, [version]);

    const generateItem = (r: ISuspiciousWallet) => (
        <SuspiciousWalletsListItem
            key={`${r.cryptoId} ${r.partyAddress} ${r.address}`}
            user={r.username}
            address={r.partyAddress}
            userId={r.id}
            asset={r.asset}
            status={r.reviewing ? "In review" : "Open"}
            selected={
                selected
                    ? r.cryptoId + r.partyAddress + r.address ===
                      selected.cryptoId + selected.partyAddress + selected.address
                    : false
            }
            onSelect={() => onSelect(r)}
            onFlag={() => onFlag(r)}
            onReview={() => onReview(r)}
            onCompliance={() => onCompliance(r)}
            onWhitelisting={() => onWhitelisting(r)}
        />
    );

    return (
        <div className="table-container">
            <SmartList
                headerData={headerData}
                arr={records}
                bumper={"Action"}
                generateItem={generateItem}
                sorting={sorting}
                setSorting={setSorting}
                disabled={["status"]}
            />
        </div>
    );
}

export default WalletList;
