import { useMemo,  } from "react";
import { authService } from "../services/authService";
import DynamicAutoComplete, { IDynamicAutoComplete } from "./DynamicAutoComplete";

export type UserSuggestion = {
    id: string;
    name: string;
    email: string;
};

function UserAutocomplete(props: IDynamicAutoComplete<UserSuggestion>): JSX.Element {

    const formatAutoComplete = useMemo(() => {
        return (suggestion: UserSuggestion) =>
            suggestion.name +
            " - " +
            suggestion.email 
    }, [])

    const autoComplete = useMemo(() => {
        return async (input: string) => {
            const response = await authService.autocompleteUser(input)
            return response.data
        }
    }, [])

    return (
        <DynamicAutoComplete<UserSuggestion> {...props} formatAutoComplete={formatAutoComplete} autoComplete={autoComplete} />
    );
}

export default UserAutocomplete;