import { useSelector } from "react-redux";
import { selectUserId } from "../auth/AuthSlice";
import { StyledBulletList } from "../components/StyledBulletList";
import { StyledSectionHeader } from "../components/StyledSectionHeader";
import { attestationsService } from "../services/attestationsService";
import capitalizeFirstLetter from "../util/capitalizeFirstLetter";
import { IAttestation } from "./AttestationsSlice";

interface IHistoricAttestationsPanelProps {
    attestations: IAttestation[]
}

function HistoricAttestationsPanel({ attestations }: IHistoricAttestationsPanelProps): JSX.Element {

    const userId = useSelector(selectUserId)

    console.log(attestations.length);

    return (
        <>
            {attestations.length > 0 ? (<StyledSectionHeader>Download your attestations for your records: </StyledSectionHeader>) : null}
            <StyledBulletList>
                {
                    attestations.map((attestation: IAttestation) => {
                        return (<li style={{ cursor: "pointer" }}
                            onClick={(event: any) => { attestationsService.downloadAttestation(attestation.quarter, attestation.year, userId, attestation.attestationType) }}
                        >
                            {`${attestation.year}-${attestation.quarter}-${capitalizeFirstLetter(attestation.attestationType)}.pdf`}
                        </li>
                        )
                    })
                }
            </StyledBulletList>

        </>

    )

}

export default HistoricAttestationsPanel;