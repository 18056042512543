import { BiCaretDown, BiCaretUp } from "react-icons/bi";

function SelectableTableHeader({
    children,
    targetCompare,
    activeCompare,
    setActiveCompare,
    asc,
    setAsc,
    disabled = [],
    ...rest
}: any): JSX.Element {
    return (
        <th
            style={{
                backgroundColor:
                    activeCompare === targetCompare
                        ? "rgba(44, 113, 240, 0.2)"
                        : "#E6EAFF",
                cursor: disabled.includes(targetCompare) ? undefined: "pointer",
            }}
            onClick={(e) => {
                if (!disabled.includes(targetCompare)){
                    if (activeCompare === targetCompare) {
                        setAsc(!asc);
                    } else {
                        setActiveCompare(targetCompare);
                    }
                }
            }}
        >
            {children}
            {activeCompare === targetCompare ? (
                asc ? (
                    <BiCaretUp />
                ) : (
                    <BiCaretDown />
                )
            ) : (
                ""
            )}
        </th>
    );
}

export default SelectableTableHeader;
