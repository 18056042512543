import { useState } from "react";
import { StyledInputField } from "../components/StyledInputField";
import { StyledSelect } from "../components/StyledSelect";
import { StyledActionButton } from "../components/StyledActionButton";
import { StyledModalBackground } from "../components/StyledModalBackground";
import { StyledModalBody } from "../components/StyledModalBody";
import { StyledModalItem } from "../components/StyledModalItem";
import { BiX } from "react-icons/bi";
import WalletAddressesTable from "./WalletAddressesTable";
import { IWallet } from "./WalletSlice";
import { formatWallet } from "./Wallets";
import BlockchainAutoComplete from "../components/BlockchainAutoComplete";
import Modal from "../components/Modal";

interface WalletModalProps {
    onHide: () => void;
    onSubmit: (blockchain: string, address: string) => void;
    onDelete: (walletId: string) => void;
    walletType: string;
    accountHolder: string;
    aggregatedWallets: { [walletType: string]: { [accountHolder: string]: IWallet[] } };
}

function WalletModal({
    onHide,
    onSubmit,
    onDelete,
    walletType,
    accountHolder,
    aggregatedWallets,
}: WalletModalProps): JSX.Element {
    /* State for inputs */
    const [address, setAddress] = useState("");
    const [blockchain, setBlockchain] = useState("");
    const [blockchainDisplayName, setBlockchainDisplayName] = useState("");

    const _onClose = () => {
        onHide();
        setBlockchain("");
        setBlockchainDisplayName("");
        setAddress("");
    };

    return (
        <Modal show={true} onClose={_onClose} title={walletType}>
            <div
                style={{
                    width: "100%",
                    height: "88%",
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "row",
                }}
            >
                <div
                    style={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        padding: 10,
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                        }}
                    >
                        <StyledModalItem style={{ width: "50%" }}>
                            <div style={{ width: "100%" }}>
                                <BlockchainAutoComplete
                                    style={{ width: "100%" }}
                                    dropdownStyle={{ width: "20%" }}
                                    placeholder="Add Blockchain Type"
                                    value={blockchainDisplayName}
                                    onSelect={(value) => {
                                        if (value) {
                                            setBlockchain(value.name);
                                            setBlockchainDisplayName(value.displayName);
                                        }
                                    }}
                                />
                            </div>
                        </StyledModalItem>
                        <StyledModalItem style={{ width: "100%" }}>
                            <StyledInputField
                                style={{ width: "100%" }}
                                placeholder="Wallet Address"
                                value={address}
                                onChange={(e: any) => {
                                    setAddress(e.target.value.trim());
                                }}
                            />
                        </StyledModalItem>
                        <StyledModalItem style={{ width: "50%" }}>
                            <StyledActionButton
                                disabled={blockchain === "" || address === ""}
                                style={{ width: "100%" }}
                                onClick={(e: any) => {
                                    if (blockchain !== "" && address !== "") {
                                        onSubmit(blockchain, address);
                                        setAddress("");
                                        setBlockchainDisplayName("");
                                    }
                                    e.preventDefault();
                                }}
                            >
                                Add Wallet
                            </StyledActionButton>
                        </StyledModalItem>
                    </div>
                    <div style={{ textAlign: "center" }}>
                        <a
                            href="https://instructions.tryargus.com"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Get instructions on how to find your address here
                        </a>
                    </div>
                    <div style={{ flex: 1 }}>
                        {walletType !== "" && accountHolder !== "" && (
                            <StyledModalItem>
                                <WalletAddressesTable
                                    walletType={walletType}
                                    accountHolder={accountHolder}
                                    aggregatedWallets={aggregatedWallets}
                                    handleDelete={onDelete}
                                />
                            </StyledModalItem>
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default WalletModal;
