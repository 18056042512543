import { useEffect, useState } from "react";
import CryptoAutoComplete, { CryptoSuggestion } from "../components/CryptoAutoComplete";
import { SecurityAutoComplete, SecuritySuggestion } from "../components/SecurityAutoComplete";
import { StyledModalItem } from "../components/StyledModalItem";
import { StyledPageTitle } from "../components/StyledPageTitle";
import ModalLabel from "./ModalLabel";

export interface ISecurityInfo {
    securityId: string;
    ticker: string;
    name: string;
    description: string;
    exchange: string;
    sector: string;
}

export interface ICryptoInfo {
    cryptoId: string;
    ticker: string;
    name: string;
}

interface IAssetSearchProps {
    requestType: "SECURITY" | "CRYPTO" | "ALL",
    securityInfo?: ISecurityInfo,
    cryptoInfo?: ICryptoInfo,
    suggestedSecurity?: SecuritySuggestion | undefined,
    setSuggestedSecurity?(security: SecuritySuggestion | undefined): void,
    suggestedCrypto?: CryptoSuggestion | undefined,
    setSuggestedCrypto?(crypto: CryptoSuggestion | undefined): void,
    filterRestricted?: boolean,
    onSelect(value: string | undefined): void,
    noMatchSuggestions?: string[];
    noMatchAction?(suggestion: string): void;
}

function AssetSearch({ requestType, securityInfo, cryptoInfo, suggestedSecurity, setSuggestedSecurity, suggestedCrypto, setSuggestedCrypto, filterRestricted, onSelect, noMatchAction, noMatchSuggestions }: IAssetSearchProps): JSX.Element {

    const [isFiltered, setIsFiltered] = useState(false);

    useEffect(() => {
        if (filterRestricted !== undefined) {
            if (filterRestricted) {
                setIsFiltered(true);
            } else {
                setIsFiltered(false);
            }
        }
    }, [filterRestricted]);

    if (requestType === "SECURITY") {
        return (
            <>
                {securityInfo && securityInfo.ticker === "" && securityInfo.name === "" ?
                    <>
                        <StyledModalItem>
                            {/* {errorMessages.searchedTicker.length > 0 && (
                            <p style={{ margin: "0", color: "red" }}>
                                {errorMessages.searchedTicker}
                            </p>
                        )} */}
                            <div
                                style={{
                                    width: "50%",
                                }}
                            >
                                <ModalLabel>Select Asset:</ModalLabel>


                                <SecurityAutoComplete
                                    filteredSuggestions={isFiltered}
                                    style={{ width: "100%" }}
                                    dropdownStyle={{ width: "50%" }}
                                    placeholder="Search..."
                                    onSelect={(suggestedSecurity) => {
                                        if (setSuggestedSecurity) {
                                            setSuggestedSecurity(suggestedSecurity);
                                        }
                                        onSelect(suggestedSecurity && suggestedSecurity.id);
                                    }}
                                />
                            </div>
                        </StyledModalItem>
                        <StyledModalItem>
                            <StyledPageTitle style={{ fontSize: "18px" }}>
                                {suggestedSecurity !== undefined
                                    ? suggestedSecurity.name +
                                    " (" +
                                    suggestedSecurity.ticker +
                                    ")"
                                    : null}
                            </StyledPageTitle>
                        </StyledModalItem>
                    </>
                    :
                    <StyledModalItem>
                        <StyledPageTitle style={{ fontSize: "24px" }}>
                            {securityInfo && securityInfo.name} ({securityInfo && securityInfo.ticker})
                        </StyledPageTitle>
                    </StyledModalItem>
                }
            </>
        )
    } else if (requestType === "CRYPTO") {
        return (
            <>
                {cryptoInfo && cryptoInfo.ticker === "" && cryptoInfo.name === "" ? (
                    <>
                        <StyledModalItem>
                            {/* {errorMessages.searchedTicker.length > 0 && (
                        <p style={{ margin: "0", color: "red" }}>
                            {errorMessages.searchedTicker}
                        </p>
                    )} */}
                            <div
                                style={{
                                    width: "50%",
                                }}
                            >
                                <ModalLabel>Select Asset:</ModalLabel>

                                <CryptoAutoComplete
                                    filteredSuggestions={isFiltered}
                                    style={{ width: "100%" }}
                                    dropdownStyle={{ width: "50%" }}
                                    placeholder="Search..."
                                    onSelect={(suggestedCrypto) => {
                                        if (setSuggestedCrypto) {
                                            setSuggestedCrypto(suggestedCrypto);
                                        }
                                        onSelect(suggestedCrypto && suggestedCrypto.id);
                                    }}
                                    noMatchAction={noMatchAction}
                                    noMatchSuggestions={noMatchSuggestions}
                                />
                            </div>
                        </StyledModalItem>
                        <StyledModalItem>
                            <StyledPageTitle style={{ fontSize: "18px" }}>
                                {suggestedCrypto !== undefined
                                    ? suggestedCrypto.name +
                                    " (" +
                                    suggestedCrypto.ticker +
                                    ")"
                                    : null}
                            </StyledPageTitle>
                        </StyledModalItem>
                    </>
                ) : (
                    <StyledModalItem>
                        <StyledPageTitle style={{ fontSize: "24px" }}>
                            {cryptoInfo && cryptoInfo.name} ({cryptoInfo && cryptoInfo.ticker})
                        </StyledPageTitle>
                    </StyledModalItem>
                )
                }
            </>
        )
    } else {
        return (
            <></>
        )
    }

}

export default AssetSearch;
