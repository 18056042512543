//The jsxRuntime pragma is necessary to get Emotion and React to work w/ latest Create-React-App version
/** @jsxRuntime classic */
/** @jsx jsx */
import styled from "@emotion/styled";

export const StyledAccordianDropdownShow = styled.div`
    height: auto;
    padding-top: 7px;
    max-height: 9999px;
    transition: all 0.5s cubic-bezier(1, 0, 1, 0);
`;
