import { useEffect, useState } from "react";
import Watchlist from "../watchlist/Watchlist";
import TOSModal from "./TOSModal";
import { useSelector } from "react-redux";
import { selectAcceptTos } from "../auth/AuthSlice";
import { Redirect } from "react-router-dom";

function Home(): JSX.Element {
    const [showModal, setShowModal] = useState(false);
    const isAccepted = useSelector(selectAcceptTos);

    const company = useSelector((state: any) => state.auth.companyName);

    const onClose = () => {
        setShowModal(false);
    };

    useEffect(() => {
        if (!isAccepted) {
            setShowModal(true);
        }
    }, [isAccepted]);

    if (company === "Public" || company === "Fargus") {
        return <Redirect to={{ pathname: "/homepage", state: { from: "/" } }} />;
    } else {
        return (
            <div>
                <TOSModal show={showModal} onClose={onClose} />
                <Watchlist />
            </div>
        );
    }
}
export default Home;
