import { useSelector } from "react-redux";
import capitalizeFirstLetter from "../util/capitalizeFirstLetter";
import { formatDate } from "../util/formatDate";
import NotesModal from "../components/NotesModal";
import { smartTruncate } from "../util/formatValues";
import cc from "currency-codes";

interface IListItemProps {
    transactionId: string;
    employee: string;
    user: string;
    accountName: string;
    accountHolder: string;
    currency?: any;
    transactionType: string;
    category: string;
    ticker: string;
    security: string;
    brokerage: string;
    volume: string;
    permissionStatus: string;
    lastUpdated: string;
    transactionDate: string;
    key: number;
    notes: JSON;
    handleDelete: (email: string) => void;
    handleSubmitNote: (note: string, userActionId: string) => void;
}

function isOption(transactionType: string): boolean {
    return transactionType === "BUY_OPTION" || transactionType === "SELL_OPTION";
}

/* Makes up the DashboardList
 * Set up as component to more gracefully support styling
 * and eventually add buttons/functionality to them.
 */
function HistoricTradeListItem(props: IListItemProps): JSX.Element {
    const company = useSelector((state: any) => state.auth.companyName);
    const version = useSelector((state: any) => state.auth.version);

    const formatVolume = (volume: string): string => {
        if (
            isOption(props.transactionType) &&
            (version === "base" ||
                version === "brokerage_integration" ||
                version === "brokerage_integration_plus" ||
                version === "brokerage_integration_plus_v2")
        ) {
            return smartTruncate(parseFloat(props.volume) / 100);
        } else {
            return smartTruncate(parseFloat(props.volume));
        }
    };

    const formatTransactionType = (transactionType: string, volume: string): string => {
        const parsedVolume = parseFloat(volume);
        var result = "";
        if (isOption(transactionType)) {
            if (parsedVolume > 0) {
                result = result + "BUY_";
            } else {
                result = result + "SELL_";
            }

            if (transactionType === "BUY_OPTION") {
                return result + "CALL";
            } else {
                return result + "PUT";
            }
        } else {
            return transactionType;
        }
    };

    return (
        <tr>
            <td>{props.user}</td>
            <td>{version === "crypto_base" ? props.accountHolder : props.user}</td>
            {version === "crypto_base" || version === "crossover_base" ? (
                <td>{props.accountName ? props.accountName : null}</td>
            ) : null}
            <td>
                {capitalizeFirstLetter(
                    props.transactionType.toLowerCase() === "unrecognized"
                        ? "transaction"
                        : formatTransactionType(props.transactionType, props.volume)
                )}
            </td>
            <td>{props.ticker ? props.ticker : ""}</td>
            <td>
                {props.category.toLowerCase() === "fiat"
                    ? (cc.code(props.currency) || { currency: props.currency }).currency
                    : props.security
                    ? props.security
                    : props.currency}
            </td>
            <td>{formatVolume(props.volume)}</td>
            {version !== "crypto_monitoring" && (
                <td>
                    {company === "Public"
                        ? formatDate(props.lastUpdated)
                        : capitalizeFirstLetter(props.permissionStatus)}
                </td>
            )}
            <td>{formatDate(props.transactionDate)}</td>
            <td>
                <NotesModal
                    handleSubmit={props.handleSubmitNote}
                    userActionId={props.transactionId}
                >
                    {props.notes &&
                        Object.entries(props.notes).map((noteEntry) => {
                            return (
                                <p key={noteEntry[0]}>
                                    {noteEntry[1]?.user} (
                                    {formatDate(noteEntry[1]?.timestamp)}):{" "}
                                    {noteEntry[1]?.note}
                                </p>
                            );
                        })}
                </NotesModal>
            </td>
        </tr>
    );
}

export function HistoricUserTradeListItem(props: IListItemProps): JSX.Element {
    const version = useSelector((state: any) => state.auth.version);
    console.log(props);
    return (
        <tr>
            <td>
                {version === "crypto_base"
                    ? props.accountHolder || props.user
                    : props.user}
            </td>
            {version === "crypto_base" || version === "crossover_base" ? (
                <td>{props.accountName}</td>
            ) : null}
            <td>
                {props.transactionType.toLowerCase() === "unrecognized"
                    ? "transaction"
                    : props.transactionType}
            </td>
            <td>{props.ticker ? props.ticker : props.currency}</td>
            <td>
                {props.category.toLowerCase() === "fiat"
                    ? (cc.code(props.currency) || { currency: props.currency }).currency
                    : props.security
                    ? props.security
                    : props.currency}
            </td>
            <td>{smartTruncate(parseFloat(props.volume))}</td>
            <td>{formatDate(props.transactionDate)}</td>
            <td>
                <NotesModal
                    handleSubmit={props.handleSubmitNote}
                    userActionId={props.transactionId}
                >
                    {props.notes &&
                        Object.entries(props.notes).map((noteEntry) => {
                            return (
                                <p key={noteEntry[0]}>
                                    ({noteEntry[1]?.user}{" "}
                                    {formatDate(noteEntry[1]?.timestamp)}){" "}
                                    {noteEntry[1]?.note}
                                </p>
                            );
                        })}
                </NotesModal>
            </td>
        </tr>
    );
}

export default HistoricTradeListItem;
