import { IPagination } from "../util/pagination";
import { StyledActionButton } from "./StyledActionButton";
import { StyledLabel } from "./StyledLabel";
import { StyledSelect } from "./StyledSelect";

export const DefaultPagination = (initinal?: IPagination): IPagination => ({
    pageNumber: 1,
    pageSize: 10,
    ...initinal,
});

function TablePageFooter({
    nextPage,
    pagination,
    setPageNumber,
    setPageSize,
}: {
    nextPage: boolean;
    pagination: IPagination;
    setPageNumber(pageNumber: number): void;
    setPageSize(pageSize: number): void;
}): JSX.Element {
    return (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex", flexDirection: "row", gap: '.5rem' }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <StyledLabel> Page size: </StyledLabel>
                </div>

                <StyledSelect
                    style={{ width: "8rem", textAlign: "center" }}
                    value={pagination.pageSize}
                    onChange={(event) => {
                        setPageSize(parseInt(event.target.value, 10));
                    }}
                >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </StyledSelect>
            </div>
            <div style={{ display: "flex", flex: 3, justifyContent: "flex-end" }}>
                <StyledActionButton
                    style={{
                        minWidth: "15%",
                        cursor: pagination.pageNumber > 1 ? "pointer" : "default",
                    }}
                    disabled={!(pagination.pageNumber > 1)}
                    onClick={() => {
                        setPageNumber(pagination.pageNumber - 1);
                    }}
                >
                    Previous
                </StyledActionButton>
                <StyledActionButton
                    style={{
                        minWidth: "15%",
                        cursor: pagination.pageNumber > 1 ? "pointer" : "default",
                    }}
                    disabled={!nextPage}
                    onClick={() => {
                        setPageNumber(pagination.pageNumber + 1);
                    }}
                >
                    Next
                </StyledActionButton>
            </div>
        </div>
    );
}

export default TablePageFooter;
