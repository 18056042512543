import { useState, Dispatch, SetStateAction, MutableRefObject } from "react";
import { StyledSpanRedBubble } from "../components/StyledSpanRedBubble";
import { StyledSpanYellowBubble } from "../components/StyledSpanYellowBubble";
import { StyledTableActionButton } from "../components/StyledTableActionButton";
import { StyledTooltip } from "../components/StyledTooltip";
import ConnectCrypto from "./ConnectCrypto";
import ExchangesPDFUploadModal from "./PDFUploadModalExchanges";
import exchangeNameToId from "./exchangesMap";
import exchange_info from "./exchange_info";
import { toast } from "react-toastify";
import trashcan from "../assets/trashcan.svg";
import { accountsService } from "../services/accountsService";
import { useDispatch, useSelector } from "react-redux";
import { saveDeleteCryptoAccount } from "./AccountsSlice";

interface AccountCryptoListItemProps {
    exchangeName: string;
    accountHolder: string;
    exchangeId: string;
    description: string;
    lastUpdated: string;
    isConnected: boolean;
    supported: boolean;
    id: string;
    index: number;
    setSelectUnsupportedAccountId: Dispatch<SetStateAction<string>>;
    setSelectUnsupportedAccountName: Dispatch<SetStateAction<string>>;
    userId: any;
    handleClick: any;
    hiddenFileInputs: MutableRefObject<HTMLInputElement[]>;
}

function AccountCryptoListItem(props: AccountCryptoListItemProps): JSX.Element {
    const canDelete = (props.supported && !props.isConnected) || (!props.supported && !props.lastUpdated);
    const {
        setSelectUnsupportedAccountId,
        setSelectUnsupportedAccountName,
        userId,
        handleClick,
        hiddenFileInputs,
        index,
    } = props;
    const dispatch = useDispatch();
    const [showDescription, setShowDescription] = useState<number | null>(null);
    const company = useSelector((state: any) => state.auth.companyName);

    const [showAdditionalProofsModal, setShowAdditionalProofsModal] =
        useState<string>();

    function lookupDocs(exchangeName: string): string {
        let docs = "";

        for (let i = 0; i < exchange_info.length; i++) {
            if (exchange_info[i].name === exchangeName) {
                docs = exchange_info[i].docs;
            }
        }

        return docs;
    }
    function lookupDisplayName(exchangeName: string): string {
        let displayName = "";

        for (let i = 0; i < exchange_info.length; i++) {
            if (exchange_info[i].name === exchangeName) {
                displayName = exchange_info[i].displayName;
            }
        }

        return displayName;
    }
    const onSubmit = async (
        ccxtExchangeId: string,
        cryptoAccountId: string,
        apiKey: string,
        apiSecret: string,
        apiPassphrase?: string
    ) => {
        const response = await accountsService.connectAccount(
            "nonce",
            cryptoAccountId,
            apiKey,
            apiSecret,
            apiPassphrase ? apiPassphrase : ""
        );
        if (response.ok) {
            toast.success("Your exchange account has been successfully added", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            response.json().then((responseData: any) => {
                responseData = JSON.parse(responseData);
                let message =
                    "Your API key and secret are invalid - please resubmit a valid API key and secret";
                if (
                    responseData.description ===
                    "The keys you provided are too powerful."
                ) {
                    message =
                        "Your API key and secret grant write access - please adjust to read access only and resubmit your API key and secret";
                } else if (
                    responseData.description ===
                    "The keys you provided are too restricted."
                ) {
                    message =
                        "Your API key and secret do not grant read access - please adjust to allow read access and resubmit your API key and secret";
                }
                toast.error(message, {
                    position: "top-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
        }
    };

    const onSubmitOAuth = async (
        provider_name: string,
        user_exchange_account_id: string,
        redirectUri: string
    ) => {
        const response = await accountsService.authorizeOAuth(
            provider_name,
            user_exchange_account_id,
            redirectUri
        );
        if (response.ok) {
            const data = await response.json();
            window.open(data.authorizeUrl, "_self");
        } else {
            toast.error("Cant connect at the moment, please try again later", {
                position: "top-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    return (
        <>
            <tr
                key={index}
                onMouseEnter={() => setShowDescription(index)}
                onMouseLeave={() => setShowDescription(null)}
            >
                <td>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            position: "relative",
                        }}
                    >
                        {showDescription === index && props.description ? (
                            <StyledTooltip>{props.description}</StyledTooltip>
                        ) : null}
                        {props.exchangeName}
                    </div>
                </td>
                <td>{props.accountHolder}</td>

                <td>
                    <span>
                        {props.lastUpdated === null && props.isConnected === false ? (
                            <StyledSpanRedBubble>
                                {" "}
                                Last update: <b>Never</b>{" "}
                            </StyledSpanRedBubble>
                        ) : props.lastUpdated === null ? (
                            <StyledSpanYellowBubble> Pending </StyledSpanYellowBubble>
                        ) : (
                            <>
                                {" "}
                                Last Update: <b> {new Date().toLocaleDateString()} </b>
                            </>
                        )}
                    </span>
                </td>
                <td>
                    {
                        <>
                            {!props.supported && company !== "Nydig" && (
                                <StyledTableActionButton
                                    style={{
                                        background: "grey",
                                    }}
                                    onClick={(event) => {
                                        setSelectUnsupportedAccountId(props.id);
                                        setSelectUnsupportedAccountName(
                                            props.exchangeName
                                        );
                                    }}
                                >
                                    <span>Input data</span>
                                </StyledTableActionButton>
                            )}

                            {!props.supported && (
                                <StyledTableActionButton
                                    style={{
                                        background: "grey",
                                    }}
                                    onClick={(event) => {
                                        setShowAdditionalProofsModal(props.id);
                                    }}
                                >
                                    <span>Documents</span>
                                </StyledTableActionButton>
                            )}
                            {showAdditionalProofsModal === props.id && (
                                <ExchangesPDFUploadModal
                                    show={showAdditionalProofsModal === props.id}
                                    onClose={() =>
                                        setShowAdditionalProofsModal(undefined)
                                    }
                                    exchangeId={props.id}
                                    userId={userId}
                                />
                            )}

                            {props.supported && (
                                <StyledTableActionButton
                                    style={{
                                        background: !props.isConnected
                                            ? "#F02C38"
                                            : "#22B43A",
                                    }}
                                    onClick={(event) => {
                                        handleClick(event, index);
                                    }}
                                >
                                    {props.isConnected ? (
                                        <span>Feeding</span>
                                    ) : (
                                        <span>Connect</span>
                                    )}
                                </StyledTableActionButton>
                            )}

                            {!props.isConnected ? (
                                <ConnectCrypto
                                    exchangeId={props.exchangeId}
                                    exchangeName={props.exchangeName}
                                    supported={props.supported}
                                    docs={lookupDocs(props.exchangeName)}
                                    onSubmit={(
                                        ccxt_exchange_id: string,
                                        exchangeId: string,
                                        apiKey: string,
                                        apiSecret: string,
                                        apiPassphrase?: string
                                    ) =>
                                        onSubmit(
                                            exchangeId,
                                            props.id,
                                            apiKey,
                                            apiSecret,
                                            apiPassphrase
                                        )
                                    }
                                    title={
                                        "Connect your " +
                                        lookupDisplayName(props.exchangeName) +
                                        " accounts."
                                    }
                                    onSubmitOAuth={(exchangeId) => {
                                        const environment =
                                            window.location.hostname.split(".")[0];
                                        let redirectUri = "https://app.tryargus.com";
                                        if (environment === "dev") {
                                            redirectUri =
                                                "https://dev.app.tryargus.com";
                                        } else if (environment === "localhost") {
                                            redirectUri = "http://localhost:3000";
                                        }
                                        console.log(exchangeId);
                                        onSubmitOAuth(
                                            exchangeId,
                                            props.id,
                                            redirectUri + `/oauth/${exchangeId}`
                                        );
                                    }}
                                    key={index}
                                    forwardedRef={(el: any) => {
                                        hiddenFileInputs.current[index] = el;
                                    }}
                                />
                            ) : null}
                        </>
                    }
                </td>
                <td>
                    {canDelete && <button
                        style={{
                            background: "transparent",
                            border: "none",
                            cursor: "pointer",
                        }}
                        onClick={(e) => {
                            dispatch(saveDeleteCryptoAccount(props.id));
                        }}
                    >
                        <img src={trashcan} alt="" />
                    </button>}
                </td>
            </tr>
        </>
    );
}

export default AccountCryptoListItem;
