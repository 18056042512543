import { useMemo } from "react";
import Select, { StylesConfig } from "react-select";

export interface IOption<T> {
    label: string;
    value: T;
}

export interface IMultiSelect<T> {
    options: T[];
    values: T[];
    placeholder: string;
    labelFormat(v: T): string;
    compare(a: T, b: T): boolean;
    onChange(newValue: T[]): void;
}

function MultiSelect<T>(props: IMultiSelect<T>): JSX.Element {
    const { options, values, placeholder, compare, labelFormat } = props;

    const wrapped = useMemo(() => {
        const resValues: IOption<T>[] = [];
        const resOptions = options.map((o) => {
            const r = {
                label: labelFormat(o),
                value: o,
            };

            if (values.some((v) => compare(v, o))) {
                resValues.push(r);
            }

            return r;
        });

        return { values: resValues, options: resOptions, compare, labelFormat };
    }, [values, options, labelFormat]);

    const selectStyle: StylesConfig<IOption<T>, true> = {
        menu: (provided, state) => ({
            ...provided,
            margin: "2px 0",
            border: "1px solid #2c71f0",
            borderRadius: "5px",
            boxShadow:
                "0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px 1px rgba(0, 0, 0, 0.18)",
        }),
        menuList: (provided, state) => ({
            ...provided,
            padding: 0,
        }),
        option: (provided, state) => {
            const backgroundColor = state.isFocused
                ? "rgba(249, 249, 249, 1)"
                : provided.backgroundColor;
            const color = state.isFocused ? provided.color : "#15357a";
            return {
                ...provided,
                backgroundColor,
                color,
                cursor: "pointer",
                "&:first-child": {
                    borderTopLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                },
                "&:last-child": {
                    borderBottomLeftRadius: "5px",
                    borderBottomRightRadius: "5px",
                },
            };
        },
        valueContainer: (provided) => ({ ...provided, padding: 1 }),
        control: (provided, state) => {
            const base = {
                // fontFamily: "Lato, sans-serif",
                border: "1px solid #ffffff",
                borderRadius: "5px",
                height: "40px",
            };
            if (state.isDisabled) {
                return { ...provided, ...base };
            }

            return {
                ...provided,
                background: "#f0f2fd",
                color: "#15357a",
                border: 0,
            };
        },
        input: (provided, state) => {
            const base = {
                // fontFamily: "Lato, sans-serif",
                height: "40px",
            };
            return { ...provided, ...base };
        },
        placeholder: (provided, state) => {
            if (state.isDisabled) {
                return provided;
            }

            return {
                ...provided,
                color: "#2c71f0",
            };
        },
        dropdownIndicator: (provided, state) => {
            if (state.isDisabled) {
                return provided;
            }

            return {
                ...provided,
                color: "#2c71f0",
            };
        },
        clearIndicator: (provided) => {
            return {
                ...provided,
                color: "#2c71f0",
            };
        },
        multiValue: (provided) => {
            return {
                ...provided,
                height: "40px",
                lineHeight: "40px",
            };
        },
        multiValueLabel: (provided) => {
            return {
                ...provided,
                fontSize: "1em",
                lineHeight: "40px",
                padding: "0 3px",
            };
        },
        multiValueRemove: (provided) => {
            return {
                ...provided,
                ":hover": {
                    ...provided[":hover"],
                    backgroundColor: undefined,
                    cursor: "pointer",
                },
            };
        },
    };

    return (
        <Select
            value={wrapped.values}
            options={wrapped.options}
            isMulti
            name="colors"
            placeholder={placeholder}
            onChange={(newValues) => {
                props.onChange(newValues.map((v) => v.value));
            }}
            styles={selectStyle}
        />
    );
}

export default MultiSelect;
