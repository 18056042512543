//The jsxRuntime pragma is necessary to get Emotion and React to work w/ latest Create-React-App version
/** @jsxRuntime classic */
/** @jsx jsx */
import styled from "@emotion/styled";

export const StyledFilterValue = styled.div`
    display: flex;
    width: 13%;
    zindex: 1;
    font-size: 14px;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    background: #f0f2fd;
    font-family: Lato, sans-serif;
    color: #2c71f0;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 5px;
    height: 30px;
`;
