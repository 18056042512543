import { StyledSpacing } from "../components/StyledSpacing";
import GlobalRestrictions from "../restrictions/GlobalRestrictions/GlobalRestrictions";
import UserRule from "../restrictions/UserRestrictions";

function HomePage(): JSX.Element {
    return (
        <div>
            <StyledSpacing>
                <GlobalRestrictions />
            </StyledSpacing>

            <UserRule />
        </div>
    );
}
export default HomePage;
