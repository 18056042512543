import { useSelector } from "react-redux";
import capitalizeFirstLetter from "../../util/capitalizeFirstLetter";
import { formatDate } from "../../util/formatDate";
import { smartTruncate } from "../../util/formatValues";
import { IHistoricHolding } from "./HistoricHoldingsSlice";

interface IListItemProps extends IHistoricHolding {
    key: number;
    handleDelete(email: string): void;
    hideLastUpdated: boolean;
}

/* Makes up the DashboardList
 * Set up as component to more gracefully support styling
 * and eventually add buttons/functionality to them.
 */
function HistoricHoldingListItem(props: IListItemProps): JSX.Element {
    const version = useSelector((state: any) => state.auth.version);
    return (
        <tr>
            <td>{props.user}</td>
            <td>
                {version === "crypto_base" && props.account
                    ? props.account.accountHolder
                    : props.user}
            </td>
            {version === "crypto_base" ||
            (version === "crossover_base" && props.account) ? (
                <td>{props.account.accountName}</td>
            ) : null}
            <td>{capitalizeFirstLetter(props.holdingType)}</td>
            <td>{props.security ? props.security.ticker : null}</td>
            <td>{props.security ? props.security.name : null}</td>
            <td>{smartTruncate(parseFloat(props.volume))}</td>
            {!props.hideLastUpdated && <td>{formatDate(props.lastUpdated)}</td>}
        </tr>
    );
}

export default HistoricHoldingListItem;
