export enum FieldType {
    String = "STRING",
    Int = "INT",
    Float = "FLOAT",
    Bool = "BOOL",
    Date = "DATE",
}

export interface IField<T> {
    (field: T): any;
}

export function compareField<T>(
    asc: boolean,
    firstEl: T,
    secondEl: T,
    field: IField<T>
) {
    if (asc) {
        return field(firstEl) <= field(secondEl) ? -1 : 1;
    } else {
        return field(firstEl) < field(secondEl) ? 1 : -1;
    }
}

export function getCompare<T>(asc: boolean, fieldName: string, fieldType: FieldType) {
    switch (fieldType) {
        case FieldType.String:
            return (firstEl: T, secondEl: T) => {
                //Note: Turning off Typescript here is dangerous, but necessary to access an arbitrary field.
                return compareField<T>(asc, firstEl, secondEl, (el: any) => {
                    if (el[fieldName] === null) return el[fieldName];
                    if (Array.isArray(el[fieldName])) return el[fieldName];
                    return el[fieldName].toLowerCase();
                });
            };
        case FieldType.Int:
            return (firstEl: T, secondEl: T) => {
                //Note: Turning off Typescript here is dangerous, but necessary to access an arbitrary field.
                return compareField<T>(asc, firstEl, secondEl, (el: any) => {
                    return parseInt(el[fieldName]);
                });
            };
        case FieldType.Float:
            return (firstEl: T, secondEl: T) => {
                //Note: Turning off Typescript here is dangerous, but necessary to access an arbitrary field.
                return compareField<T>(asc, firstEl, secondEl, (el: any) => {
                    return parseFloat(el[fieldName]);
                });
            };
        case FieldType.Bool:
            return (firstEl: T, secondEl: T) => {
                //Note: Turning off Typescript here is dangerous, but necessary to access an arbitrary field.
                return compareField<T>(asc, firstEl, secondEl, (el: any) => {
                    return el[fieldName];
                });
            };
        case FieldType.Date:
            return (firstEl: T, secondEl: T) => {
                //Note: Turning off Typescript here is dangerous, but necessary to access an arbitrary field.
                return compareField<T>(asc, firstEl, secondEl, (el: any) => {
                    new Date(el[fieldName]);
                });
            };
    }
}
