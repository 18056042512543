/* Wrapper for calls to the notifications route */
import { IPagination, paginationQueryParam } from "../util/pagination";
import { authService } from "./authService";

export const notificationService = {
    getNotifications,
    saveCompanyNotification,
    deleteNotification,
};

const base = process.env.REACT_APP_API_ENDPOINT;
const baseUrl = `${base}/notifications`;
const companyEndpt = `/company`;

/* Get all notifications for user */
function getNotifications(pagination: IPagination): any {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
        },
    };

    return fetch(paginationQueryParam(`${baseUrl}/get`, pagination), requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

/* Save Company Notification */
function saveCompanyNotification(message: string): any {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            message,
        }),
    };

    return fetch(baseUrl + companyEndpt, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

function deleteNotification(id: string): any {
    const requestParams = {
        method: "DELETE",
        headers: {
            ...authService.authHeader(),
        },
    };

    return fetch(`${baseUrl}/` + id, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

function handleResponse(response: any) {
    return response.text().then((text: any) => {
        const data = text && JSON.parse(text);
        return data;
    });
}
