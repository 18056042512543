import HistoricInfractionsListItem from "./HistoricInfractionsListItem";
import { ICompliance, ISorting } from "../../dashboard/DashboardSlice";
import SmartList from "../../components/SmartList";

/* Type for the WatchlistList props */
interface IListProps {
    issues: Array<ICompliance>;
    setSorting: (sorting: ISorting) => void;
    sorting: ISorting;
    handleSubmitNote: (note: string, issueId: string) => void;
}

function IssuesList(props: IListProps): JSX.Element {
    /* Provide default values and replace them with the prop values if there are any. */
    const { issues, setSorting, sorting } = props;
    const headerData = [
        { fieldText: "Employee", fieldValue: "user" },
        { fieldText: "Account Holder", fieldValue: "" },
        { fieldText: "Account", fieldValue: "" },
        { fieldText: "Type", fieldValue: "" },
        { fieldText: "Ticker", fieldValue: "" },
        { fieldText: "Asset", fieldValue: "" },
        { fieldText: "Value", fieldValue: "" },
        { fieldText: "Volume", fieldValue: "" },
        { fieldText: "Infraction Date", fieldValue: "dateCreated" },
        { fieldText: "Status", fieldValue: "status" },
    ];

    const generateItem = (issue: ICompliance, index: number) => {
        return (
            <HistoricInfractionsListItem
                id={issue.id}
                employee={issue.user.name}
                accountHolder={
                    issue.userExchangeAccount
                        ? issue.userExchangeAccount.accountHolder
                        : issue.userWallet
                        ? issue.userWallet.accountHolder
                        : issue.user.name // this was appearing empty for securities so set the user name for default
                }
                ticker={
                    issue.transactions[0].security
                        ? issue.transactions[0].security.ticker
                        : ""
                }
                securityName={
                    issue.transactions[0].security
                        ? issue.transactions[0].security.name
                        : ""
                }
                investmentAccount={
                    issue.transactions[0].account
                        ? issue.transactions[0].account.accountName
                        : ""
                }
                userExchangeAccount={
                    issue.userExchangeAccount
                        ? issue.userExchangeAccount.cryptoExchange.exchangeName ||
                          issue.userExchangeAccount.cryptoExchange.exchangeId
                        : issue.userWallet
                        ? issue.userWallet.walletType
                        : ""
                }
                price={issue.transactions[0] ? issue.transactions[0].price : undefined}
                transactionType={
                    issue.transactions
                        ? issue.transactions[0].transactionType
                        : undefined
                }
                volume={issue.transactions[0].volume}
                dateCreated={issue.dateCreated}
                status={issue.status}
                key={index}
                notes={issue.userNotes}
                handleSubmitNote={props.handleSubmitNote}
            />
        );
    };

    return (
        <SmartList
            headerData={headerData}
            bumper="Notes"
            disabled={[""]}
            arr={issues}
            generateItem={generateItem}
            setSorting={setSorting}
            sorting={sorting}
        />
    );
}
export default IssuesList;
