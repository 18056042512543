//The jsxRuntime pragma is necessary to get Emotion and React to work w/ latest Create-React-App version
/** @jsxRuntime classic */
/** @jsx jsx */
import styled from "@emotion/styled";

export const StyledSectionHeader = styled.p`
    font-family: "Lato", san-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: bold;
    line-height: 18px;
    color: #15357a;
    margin-bottom: 1%;
    margin-top: 0%;
`;
