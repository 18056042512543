/* Wrapper for calls to the Auth route */
/* Handles login, logout, and user CRUD operations for admins */
import EventSource from "eventsource";
import { ISorting } from "../dashboard/DashboardSlice";
import { history } from "../nav/history";

export const authService = {
    authHeader,
    login,
    login_with_ms_oidc,
    login_with_google_oidc,
    login_with_okta_oidc,
    login_with_auth0_oidc,
    changePassword,
    logout,
    getUsers,
    createUser,
    uploadUsers,
    deleteUser,
    editUserName,
    getCurrentUser,
    createEventStream,
    getCompanies,
    getCompanyById,
    deleteCompany,
    createCompany,
    authHeaderRefresh,
    refresh,
    getUsersPanel,
    getUsersPanelDownload,
    getUsersDownload,
    redirectOnNotAuth,
    handleErrors,
    acceptTos,
    autocompleteUser,
    sendFrontendError,
};

const TOKEN_REFRESH_TIME = 285000;

/* TODO move to config file */
// const authUrl = 'http://localhost:1337/auth'
const authBase = process.env.REACT_APP_API_ENDPOINT;
console.log("env value: ", authBase);
const companiesUrl = `${authBase}/companies`;
const authUrl = `${authBase}/auth`;

var interval: ReturnType<typeof setTimeout>;

export interface IFilter {
    name: string;
}

function tokenRefresh() {
    refresh();
}

function authHeader(): { Authorization: string } {
    const data = sessionStorage.getItem("user");

    if (!data) {
        // No user token in local storage
        logout();
    }
    if (data) {
        let storedToken = JSON.parse(data);
        if (storedToken.access_token) {
            return { Authorization: "Bearer " + storedToken.access_token };
        }
    }
    return { Authorization: "Bearer " };
}

function authHeaderRefresh(): { Authorization: string } {
    const data = sessionStorage.getItem("user");

    if (data) {
        let storedToken = JSON.parse(data);
        if (storedToken.refresh_token) {
            return { Authorization: "Bearer " + storedToken.refresh_token };
        }
    }
    return { Authorization: "Bearer " };
}

function login_with_ms_oidc(id_token: string): any {
    const requestParams = {
        method: "GET",
        headers: { Authorization: "Bearer " + id_token },
    };

    return fetch(`${authUrl}/oidc/ms`, requestParams)
        .then(handleErrors)
        .then(handleResponse)
        .then((user) => {
            interval = setInterval(tokenRefresh, TOKEN_REFRESH_TIME);
            sessionStorage.setItem("user", JSON.stringify(user));
            return user;
        });
}

function login_with_okta_oidc(id_token: string): any {
    const requestParams = {
        method: "GET",
        headers: { Authorization: "Bearer " + id_token },
    };

    return fetch(`${authUrl}/oidc/okta`, requestParams)
        .then(handleErrors)
        .then(handleResponse)
        .then((user) => {
            interval = setInterval(tokenRefresh, TOKEN_REFRESH_TIME);
            sessionStorage.setItem("user", JSON.stringify(user));
            return user;
        });
}

function login_with_auth0_oidc(id_token: string): any {
    const requestParams = {
        method: "GET",
        headers: { Authorization: "Bearer " + id_token },
    };

    return fetch(`${authUrl}/oidc/auth0`, requestParams)
        .then(handleErrors)
        .then(handleResponse)
        .then((user) => {
            interval = setInterval(tokenRefresh, TOKEN_REFRESH_TIME);
            sessionStorage.setItem("user", JSON.stringify(user));
            return user;
        });
}

function login_with_google_oidc(id_token: string): any {
    const requestParams = {
        method: "GET",
        headers: { Authorization: "Bearer " + id_token },
    };

    return fetch(`${authUrl}/oidc/google`, requestParams)
        .then(handleErrors)
        .then(handleResponse)
        .then((user) => {
            interval = setInterval(tokenRefresh, TOKEN_REFRESH_TIME);
            sessionStorage.setItem("user", JSON.stringify(user));
            return user;
        });
}

function login(email: string, password: string): any {
    const requestParams = {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify({ email: email, password: password }),
    };

    return fetch(`${authUrl}/login`, requestParams)
        .then(handleErrors)
        .then(handleResponse)
        .then((user) => {
            interval = setInterval(tokenRefresh, TOKEN_REFRESH_TIME);
            sessionStorage.setItem("user", JSON.stringify(user));
            return user;
        });
}

function refresh(): any {
    const requestParams = {
        method: "POST",
        headers: {
            ...authHeaderRefresh(),
            "Content-type": "application/json",
        },
        body: JSON.stringify({}),
    };

    return fetch(`${authUrl}/refresh`, requestParams)
        .then(handleErrors)
        .then(handleResponse)
        .then((user) => {
            const data = sessionStorage.getItem("user");
            if (data) {
                let token = JSON.parse(data);
                if (token.refresh_token) {
                    user["refresh_token"] = token.refresh_token;
                } else {
                    user["refresh_token"] = "none";
                }
            }

            sessionStorage.setItem("user", JSON.stringify(user));
            return user;
        });
}

function changePassword(password: string, newPassword: string): any {
    const requestParams = {
        method: "POST",
        headers: {
            ...authHeader(),
            "Content-type": "application/json",
        },
        body: JSON.stringify({ password: password, newPassword: newPassword }),
    };

    return fetch(`${authUrl}/change_password`, requestParams)
        .then(handleErrors)
        .then(handleResponse)
        .then((user) => {
            sessionStorage.setItem("user", JSON.stringify(user));
            return user;
        });
}

/* Get all Companies */
function getCompanies(): any {
    const requestParams = {
        method: "GET",
        headers: {
            ...authService.authHeader(),
        },
    };

    return fetch(`${companiesUrl}`, requestParams)
        .then(handleErrors)
        .then(handleResponse);
}

/* Get company associated with id */
function getCompanyById(companyId: number): any {
    const requestParams = {
        method: "GET",
        headers: {
            ...authService.authHeader(),
        },
    };

    return fetch(`${companiesUrl}/${companyId}/get`, requestParams)
        .then(handleErrors)
        .then(handleResponse);
}

/* Delete company assoicated with id */
function deleteCompany(companyId: number): any {
    const requestParams = {
        method: "DELETE",
        headers: {
            ...authService.authHeader(),
        },
    };

    return fetch(`${companiesUrl}/` + companyId, requestParams)
        .then(handleErrors)
        .then(handleResponse)
        .then((admin) => {
            return admin.isAdmin;
        });
}

/* Create a Company. */
function createCompany(name: string, version: string): any {
    const requestParams = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            ...authService.authHeader(),
        },
        body: JSON.stringify({ name: name, version: version }),
    };

    return fetch(`${companiesUrl}`, requestParams)
        .then(handleErrors)
        .then(handleResponse);
}

function logout() {
    sessionStorage.removeItem('user')
    clearInterval(interval);
}

function getUsers(): any {
    const authorization = authHeader();

    const requestParams = {
        method: "POST",
        headers: authorization,
    };

    return fetch(`${authUrl}/get`, requestParams)
        .then(handleErrors)
        .then(handleResponse)
        .then((user) => {
            return user;
        });
}

function getUsersDownload(filter: IFilter, sorting: ISorting[]): any {
    const requestParams = {
        method: "POST",
        headers: {
            ...authHeader(),
            "Content-type": "application/json",
        },
        body: JSON.stringify({ filter, sorting }),
    };

    return fetch(`${authUrl}/download`, requestParams);
}

function getUsersPanel(
    pageNumber: number,
    filter: IFilter,
    sorting: ISorting[],
    showDeleted?: boolean,
    isLinked?: boolean,
    pageSize?: number
): any {
    if (pageSize !== undefined) {
        const requestParams = {
            method: "POST",
            headers: {
                ...authHeader(),
                "Content-type": "application/json",
            },
            body: JSON.stringify({ filter, sorting, pageSize, isLinked, showDeleted }),
        };

        return fetch(
            `${authUrl}/user-panel/get?pageNumber=` + pageNumber,
            requestParams
        )
            .then(handleErrors)
            .then(handleResponse)
            .then((user) => {
                return user;
            });
    }

    const requestParams = {
        method: "POST",
        headers: {
            ...authHeader(),
            "Content-type": "application/json",
        },
        body: JSON.stringify({ filter, sorting }),
    };

    return fetch(`${authUrl}/user-panel/get?pageNumber=` + pageNumber, requestParams)
        .then(handleErrors)
        .then(handleResponse)
        .then((user) => {
            return user;
        });
}

function getUsersPanelDownload(
    filter: IFilter,
    sorting: ISorting[],
    isLinked?: boolean,
    showDeleted?: boolean
): any {
    const requestParams = {
        method: "POST",
        headers: {
            ...authHeader(),
            "Content-type": "application/json",
        },
        body: JSON.stringify({ filter, sorting, isLinked, showDeleted }),
    };

    return fetch(`${authUrl}/user-panel/download`, requestParams);
}

function createUser(
    email: string,
    name: string,
    companyId: number,
    outsideUser: boolean
): any {
    const requestParams = {
        method: "POST",
        headers: {
            ...authHeader(),
            "Content-type": "application/json",
        },
        body: JSON.stringify({
            email: email,
            name: name,
            companyId: companyId,
            outsideUser: outsideUser,
        }),
    };
    return fetch(`${authUrl}/create`, requestParams)
        .then(handleErrors)
        .then(handleResponse)
        .then((user) => {
            return user;
        });
}

function uploadUsers(formData: FormData): any {
    const requestParams = {
        method: "POST",
        headers: {
            ...authHeader(),
        },
        body: formData,
    };

    console.log(requestParams);

    return fetch(`${authUrl}/upload`, requestParams)
        .then(handleErrors)
        .then(handleResponse)
        .then((user) => {
            return user;
        });
}

function deleteUser(id: number): any {
    const requestParams = {
        method: "DELETE",
        headers: {
            ...authHeader(),
        },
    };

    return fetch(`${authUrl}/` + id, requestParams)
        .then(handleErrors)
        .then(handleResponse)
        .then((user) => {
            return user;
        });
}

function editUserName(id: number, name: string) {
    const requestParams = {
        method: "PUT",
        headers: {
            ...authHeader(),
            "Content-type": "application/json",
        },
        body: JSON.stringify({ name: name }),
    };

    return fetch(`${authUrl}/${id}`, requestParams);
}

function createEventStream(): EventSource {
    const authorization = authHeader();
    return new EventSource(`${authBase}/events`, {
        headers: {
            ...authorization,
        },
    });
}

function getCurrentUser(): any {
    const authorization = authHeader();

    const requestParams = {
        method: "GET",
        headers: authorization,
    };

    return fetch(`${authUrl}/who_am_i`, requestParams).then((user) => {
        return user;
    });
}

function redirectOnNotAuth(isLogout: boolean) {
    if (!isLogout) {
        history.push("/");
    } else {
        logout();
        history.push("/partner_login");
    }
}

function handleErrors(response: any) {
    if (!response.ok) {
        if (response.status === 400) {
            // Bad request
            handleBadRequest(response);
        }
        if (response.status === 401) {
            // Not authenticated
            redirectOnNotAuth(true);
        } else if (response.status === 403) {
            // Not authorized
            redirectOnNotAuth(false);
        } else if (response.status === 422) {
            // No user token
            redirectOnNotAuth(true);
        }

        const error = "Authentication Error";
        return Promise.reject(error);
    } else {
        return response;
    }
}

function acceptTos(acceptedTos: boolean) {
    const authorization = authHeader();

    const requestParams = {
        method: "POST",
        headers: { ...authorization, "Content-Type": "application/json" },
        body: JSON.stringify({
            tosConsent: acceptedTos,
        }),
    };

    return fetch(`${authUrl}/accept_tos`, requestParams)
        .then(handleResponse)
        .then((user) => {
            sessionStorage.setItem("user", JSON.stringify(user));
            return user;
        });
}

function autocompleteUser(input: string): any {
    const requestParams = {
        method: "GET",
        headers: {
            ...authService.authHeader(),
        },
    };

    return fetch(`${authUrl}/autocomplete/` + input, requestParams).then(
        handleResponse
    );
}

function sendFrontendError(error: {}) {
    const authorization = authHeader();

    const requestParams = {
        method: "POST",
        headers: { ...authorization, "Content-Type": "application/json" },
        body: JSON.stringify(error),
    };

    return fetch(`${authBase}/frontend-alert`, requestParams);
}

function handleResponse(response: any) {
    return response.text().then((text: any) => {
        const data = text && JSON.parse(text);
        return data;
    });
}

function handleBadRequest(response: any) {
    return response.text().then((text: any) => {
        const data = text && JSON.parse(text);
        if (data.hasOwnProperty("message")) {
            alert(data.message);
            return data.message;
        }
    });
}
