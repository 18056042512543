//The jsxRuntime pragma is necessary to get Emotion and React to work w/ latest Create-React-App version
/** @jsxRuntime classic */
/** @jsx jsx */
import styled from "@emotion/styled";

import search from "../assets/search.svg";

export const StyledSearchInput = styled.input`
    width: 100%;
    background: #f0f2fd;
    font-family: Lato, sans-serif;
    color: #15357a;
    border: 2px solid #ffffff;
    box-sizing: border-box;
    border-radius: 5px;
    height: 40px;
    background: url(${search}) no-repeat;
    padding-left: 30px;
    background-color: #f0f2fd;
    background-position: 1% 50%;

    ::placeholder {
        color: #2c71f0;
        opacity: 1;
    }
    :hover {
        border: 2px solid #6499fc;
    }
`;
