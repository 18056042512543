import { authService } from "./authService";

const base = process.env.REACT_APP_API_ENDPOINT;
const baseUrl = `${base}/plaid`;

export const plaidService = {
    getLinkToken,
    exchangePublicToken,
};

function handleResponse(response: any) {
    return response.text().then((text: any) => {
        const data = text && JSON.parse(text);
        return data;
    });
};

export function getLinkToken(institution_id: string): Promise<any> {
    const requestParams = {
        method: "GET",
        headers: {
            ...authService.authHeader(),
        },
    };

    return fetch(`${baseUrl}/link/${institution_id}`, requestParams).then(handleResponse);
}

export function exchangePublicToken(
    publicToken: string, 
    userAccountId: string
): Promise<any>{
    const requestParams = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authService.authHeader(),
        },
        body: JSON.stringify({
            publicToken,
        }),
    };
    return fetch(`${baseUrl}/connect/${userAccountId}`, requestParams).then(handleResponse);
}




