import { authService } from "./authService";


export const infoService = {
    autocompleteCrypto,
    autocompleteCryptoExchange,
    autocompleteRestrictedCrypto,
    autocompleteSecurity,
    autocompleteRestrictedSecurity,
    autocompletePrivatAsset,
    createPrivateAsset,
    createCryptoAsset,
    autocompleteSecurityCompany,
    autocompleteRestrictedSecurityCompany
};

const baseUrl = process.env.REACT_APP_API_ENDPOINT;
const baseUrlCrypto = `${baseUrl}/crypto`;
const baseUrlSecurities = `${baseUrl}/securities`
const baseUrlPrivateAssets = `${baseUrl}/private-assets`

function autocompleteSecurity(input: string): any {
    const requestParams = {
        method: "GET",
        headers: {
            ...authService.authHeader(),
        },
    };

    return fetch(`${baseUrlSecurities}/autocomplete/` + input, requestParams).then(
        handleResponse
    );
}

function autocompleteRestrictedSecurity(input: string): any {
    const requestParams = {
        method: "GET",
        headers: {
            ...authService.authHeader(),
        },
    };

    return fetch(`${baseUrlSecurities}/autocomplete-restricted/` + input, requestParams).then(
        handleResponse
    );
}

function autocompleteSecurityCompany(input: string): any {
    const requestParams = {
        method: "GET",
        headers: {
            ...authService.authHeader(),
        },
    };

    return fetch(`${baseUrlSecurities}/companies/autocomplete/` + input, requestParams).then(
        handleResponse
    );
}

function autocompleteRestrictedSecurityCompany(input: string): any {
    const requestParams = {
        method: "GET",
        headers: {
            ...authService.authHeader(),
        },
    };

    return fetch(`${baseUrlSecurities}/companies/autocomplete-restricted/` + input, requestParams).then(
        handleResponse
    );
}

function autocompleteCrypto(input: string): any {
    const requestParams = {
        method: "GET",
        headers: {
            ...authService.authHeader(),
        },
    };

    return fetch(`${baseUrlCrypto}/autocomplete/` + input, requestParams).then(
        handleResponse
    );
}

function autocompleteCryptoExchange(input: string): any {
    const requestParams = {
        method: "GET",
        headers: {
            ...authService.authHeader(),
        },
    };

    return fetch(`${baseUrlCrypto}/crypto-exchange-autocomplete/` + input, requestParams).then(
        handleResponse
    );
}

function autocompleteRestrictedCrypto(input: string): any {
    const requestParams = {
        method: "GET",
        headers: {
            ...authService.authHeader(),
        },
    };

    return fetch(`${baseUrlCrypto}/autocomplete-restricted/` + input, requestParams).then(
        handleResponse
    );
}

function autocompletePrivatAsset(input: string): any {
    const requestParams = {
        method: "GET",
        headers: {
            ...authService.authHeader(),
        },
    };

    return fetch(`${baseUrlPrivateAssets}/autocomplete/` + input, requestParams).then(
        handleResponse
    );
}

function createPrivateAsset(
    name: string,
    ticker: string,
    assetType: string,
): any {
    const requestParams = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            ...authService.authHeader(),
        },
        body: JSON.stringify({
            name,
            ticker,
            assetType,
        }),
    };

    return fetch(`${baseUrlPrivateAssets}`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

function createCryptoAsset(
    blockchain: string,
    contractAddress: string,
): any {
    const requestParams = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            ...authService.authHeader(),
        },
        body: JSON.stringify({
            blockchain,
            contract_address: contractAddress,
        }),
    };

    return fetch(`${baseUrlCrypto}/add-crypto-asset`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}


function handleResponse(response: any) {
    return response.text().then((text: any) => {
        const data = text && JSON.parse(text);
        return data;
    });
}
