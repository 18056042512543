import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import trashcan from "../assets/trashcan.svg";
import capitalizeFirstLetter from "../util/capitalizeFirstLetter";
import { formatDate } from "../util/formatDate";
import { Version } from "./AdminList";
import { StyledInputField } from "../components/StyledInputField";
import RestrictionDateModal from "./RestrictionDateModal";
import { StyledLabel } from "../components/StyledLabel";
import { updateRestrictionEnd, updateRestrictionStart } from "./RestrictionSlice";
import { ISecurity, ISecurityCompany } from "./RestrictionSlice";

interface IListItemProps {
    id: string;
    class: string;
    security?: ISecurity;
    securityType: string;
    securityCompany?: ISecurityCompany;
    restrictionType: string;
    transactionType: string;
    restrictedFrom: string;
    restrictedTo?: string;
    restrictionGroupName: string;
    visibilityType: string;
    type: Version;
    dateCreated: string;
    handleDelete(ticker: string): void;
}

/* Makes up the AdminList
 * Set up as component to more gracefully support styling
 * and eventually add buttons/functionality to them.
 */
function AdminListItem(props: IListItemProps): JSX.Element {
    const company = useSelector((state: any) => state.auth.companyName);
    const [showRestrictionType, setShowRestrictionType] = useState(true);
    const [showVisibilityType, setShowVisibilityType] = useState(false);
    const [activeRestrictedTo, setActiveRestrictedTo] = useState<string>();
    const [activeRestrictedFrom, setActiveRestrictedFrom] = useState<string>();

    const [resetActive, setResetActive] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const dateCreated = new Date(props.dateCreated);
    const currentDate = new Date();
    const timeDiff = Math.abs(currentDate.getTime() - dateCreated.getTime()) / 36e5;

    const dispatch = useDispatch();

    useEffect(() => {
        if (
            company === "Fargus" ||
            company === "Multicoin" ||
            company === "Framework" ||
            company === "Paradigm" ||
            company === "Celsius"
        ) {
            setShowRestrictionType(false);
        }
        if (company === "Jnk" || company === "Cinven" || company === "proSapient") {
            setShowVisibilityType(true);
        }
    }, [company]);

    const handleRestrictedToUpdate = () => {
        if (resetActive) {
            dispatch(
                updateRestrictionEnd({
                    restrictionId: props.id,
                    restrictedTo: "Active",
                })
            );
        }

        if (activeRestrictedTo) {
            if (
                activeRestrictedFrom &&
                new Date(activeRestrictedTo) < new Date(activeRestrictedFrom)
            ) {
                setError(true);
                return;
            }

            dispatch(
                updateRestrictionEnd({
                    restrictionId: props.id,
                    restrictedTo: activeRestrictedTo,
                })
            );
        }

        setActiveRestrictedTo(undefined);
    };

    const handleRestrictedFromUpdate = () => {
        if (activeRestrictedFrom) {
            dispatch(
                updateRestrictionStart({
                    restrictionId: props.id,
                    restrictedFrom: activeRestrictedFrom,
                })
            );
        }

        setActiveRestrictedFrom(undefined);
    };

    return (
        <tr>
            <td>{props.class}</td>
            {
                <td>{props.security && props.security.ticker}</td>
            }
            {
            props.security &&
                (<td>
                {props.security &&
                    props.security.name +
                    (props.security.cryptocurrencyType !== "CURRENCY" &&
                        props.security.platform
                            ? " [" + props.security.platform + "]"
                            : "")}
                 </td>)
            }
            {
            props.securityCompany &&
                (<td>{props.securityCompany.name}</td>)
            }
            {showRestrictionType && (
                <td>{capitalizeFirstLetter(props.restrictionType)}</td>
            )}
            {(company === "Paradigm" || company === "Fargus" || company === "ArgusTest" || company === "Celsius") && (
                <td>
                    {props.restrictionGroupName}
                </td>
            )}
            <td>{capitalizeFirstLetter(props.transactionType)}</td>
            {props.type !== Version.CRYPTO && showVisibilityType ? (
                <td>{capitalizeFirstLetter(props.visibilityType)}</td>
            ) : null}
            <td>
                {timeDiff <= 72 ? (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <div style={{ width: "5%" }} />
                        <RestrictionDateModal
                            name={formatDate(props.restrictedFrom)}
                            handleSubmit={handleRestrictedFromUpdate}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "row",
                                        width: "100%",
                                        justifyContent: "center",
                                    }}
                                >
                                    <StyledLabel> Restriction Start: </StyledLabel>
                                    <StyledInputField
                                        onFocus={(e) => {
                                            if (activeRestrictedFrom === undefined) {
                                                setActiveRestrictedFrom("");
                                            }
                                        }}
                                        onBlur={(e) => {
                                            if (activeRestrictedFrom === "") {
                                                setActiveRestrictedFrom(undefined);
                                            }
                                        }}
                                        type={
                                            activeRestrictedFrom === undefined
                                                ? "text"
                                                : "date"
                                        }
                                        placeholder="Select new end date for restriction."
                                        value={activeRestrictedFrom || ""}
                                        onChange={(event) => {
                                            setActiveRestrictedFrom(event.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                        </RestrictionDateModal>
                    </div>
                ) : (
                    formatDate(props.restrictedFrom)
                )}
            </td>
            <td>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <div style={{ width: "5%" }} />
                    <RestrictionDateModal
                        name={
                            props.restrictedTo
                                ? formatDate(props.restrictedTo)
                                : "Active"
                        }
                        handleSubmit={handleRestrictedToUpdate}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    width: "100%",
                                    justifyContent: "center",
                                }}
                            >
                                <StyledLabel> Restriction End: </StyledLabel>
                                <StyledInputField
                                    // disabled={resetActive}
                                    onFocus={(e) => {
                                        if (activeRestrictedTo === undefined) {
                                            setActiveRestrictedTo("");
                                        }
                                    }}
                                    onBlur={(e) => {
                                        if (activeRestrictedTo === "") {
                                            setActiveRestrictedTo(undefined);
                                        }
                                    }}
                                    type={
                                        activeRestrictedTo === undefined
                                            ? "text"
                                            : "date"
                                    }
                                    placeholder="Select new end date for restriction."
                                    value={activeRestrictedTo || ""}
                                    onChange={(event) => {
                                        setResetActive(false);
                                        setActiveRestrictedTo(event.target.value);
                                        setError(false);
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    fontSize: "15px",
                                    textDecoration: "none",
                                    color: "#15357a",
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <label htmlFor="reset-date">No end date</label>
                                <input
                                    checked={resetActive}
                                    onChange={(e) => {
                                        setResetActive(!resetActive);
                                        setActiveRestrictedTo(undefined);
                                    }}
                                    type="checkbox"
                                    id="reset-date"
                                    disabled={props.restrictedTo === null}
                                />
                            </div>
                            <div style={{ width: "100%" }}>
                                <p style={{ margin: "0", color: "red" }}>
                                    {error &&
                                        "The new restriction date should be in future."}
                                </p>
                            </div>
                        </div>
                    </RestrictionDateModal>
                </div>
            </td>
            {/* <td>{props.restrictionGroup}</td> */}
            <td>
                <button
                    style={{
                        background: "transparent",
                        border: "none",
                        cursor: "pointer",
                    }}
                    onClick={(e) => {
                        props.handleDelete(props.id);
                    }}
                >
                    <img src={trashcan} alt="" />
                </button>
            </td>
        </tr>
    );
}

export default AdminListItem;
