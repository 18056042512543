//The jsxRuntime pragma is necessary to get Emotion and React to work w/ latest Create-React-App version
/** @jsxRuntime classic */
/** @jsx jsx */
import styled from "@emotion/styled";

export const StyledLinkButton = styled.button`
    background: none !important;
    border: none;
    padding: 0 !important;
    font-family: Lato, sans-serif;
    color: #15357a;

    font-style: normal;
    font-weight: normal;

    text-decoration: underline;
    cursor: pointer;
`;
