import IssuesListItem from "./IssuesListItem";
import { ICompliance, ISorting } from "./DashboardSlice";
import SmartList from "../components/SmartList";
import { useSelector } from "react-redux";

/* Type for the WatchlistList props */
interface IListProps {
    issues: Array<ICompliance>;
    handleDelete(id: string): void;
    handleResolve(id: string): void;
    handleMarked(id: string): void;
    setSorting: (sorting: ISorting) => void;
    sorting: ISorting;
    handleSubmitNote(note: string, issueId: string): void;
    handleReviewStatus(id: string): void;
}

function IssuesList(props: IListProps): JSX.Element {
    /* Provide default values and replace them with the prop values if there are any. */
    const { issues, setSorting, sorting } = props;
    const version = useSelector((state: any) => state.auth.version);

    const headerData =
        version === "crypto_base" || version === "crossover_base"
            ? [
                  { fieldText: "Employee", fieldValue: "user" },
                  { fieldText: "Account Holder", fieldValue: "" },
                  { fieldText: "Account", fieldValue: "" },
                  { fieldText: "Type", fieldValue: "" },
                  { fieldText: "Ticker", fieldValue: "" },
                  { fieldText: "Asset", fieldValue: "" },
                  { fieldText: "Value", fieldValue: "" },
                  { fieldText: "Volume", fieldValue: "" },
                  // { fieldText: "Infraction Date", fieldValue: "dateCreated" },
                  { fieldText: "Date", fieldValue: "dateCreated" },
                  { fieldText: "Status", fieldValue: "status" },
                  { fieldText: "Reviewer", fieldValue: "reviewer" },
                  { fieldText: "Update", fieldValue: "user" },
              ]
            : [
                  { fieldText: "Employee", fieldValue: "user" },
                  // { fieldText: "Account Holder", fieldValue: "accountHolder" },
                  { fieldText: "Account", fieldValue: "" },
                  { fieldText: "Type", fieldValue: "" },
                  { fieldText: "Ticker", fieldValue: "" },
                  { fieldText: "Asset", fieldValue: "" },
                  { fieldText: "Value", fieldValue: "" },
                  { fieldText: "Volume", fieldValue: "" },
                  // { fieldText: "Infraction Date", fieldValue: "dateCreated" },
                  { fieldText: "Date", fieldValue: "dateCreated" },
                  { fieldText: "Status", fieldValue: "status" },
                  { fieldText: "Reviewer", fieldValue: "reviewer" },
                  { fieldText: "Update", fieldValue: "user" },
              ];

    const generateItem = (issue: ICompliance, index: number) => {
        return (
            <IssuesListItem
                handleUpdateReviewStatus={props.handleReviewStatus}
                reviewerName={issue.complianceReviewUserName}
                notes={issue.userNotes}
                id={issue.id}
                employee={issue.user.name}
                accountHolder={
                    issue.userExchangeAccount
                        ? issue.userExchangeAccount.accountHolder
                        : issue.userWallet
                        ? issue.userWallet.accountHolder
                        : issue.user.name // this line was appearing as empty column for security issues so added default to user's name
                }
                status={issue.status}
                ticker={
                    issue.transactions[0] ? issue.transactions[0].security.ticker : ""
                }
                securityName={
                    issue.transactions[0].security
                        ? issue.transactions[0].security.name
                        : ""
                }
                investmentAccount={
                    issue.transactions[0].account
                        ? issue.transactions[0].account.accountName
                        : ""
                }
                userExchangeAccount={
                    issue.userExchangeAccount
                        ? issue.userExchangeAccount.cryptoExchange.exchangeName ||
                          issue.userExchangeAccount.cryptoExchange.exchangeId
                        : issue.userWallet
                        ? issue.userWallet.walletType
                        : ""
                }
                price={issue.transactions[0] ? issue.transactions[0].price : undefined}
                volume={issue.transactions[0].volume}
                dateCreated={issue.dateCreated}
                transactionType={
                    issue.transactions[0]
                        ? issue.transactions[0].transactionType
                        : undefined
                }
                transactionDate={issue.dateCreated ? issue.dateCreated : undefined}
                handleDelete={props.handleDelete}
                handleResolve={props.handleResolve}
                handleMarked={props.handleMarked}
                key={index}
                handleSubmitNote={props.handleSubmitNote}
            />
        );
    };

    return (
        <SmartList
            headerData={headerData}
            bumper="Notes"
            disabled={[""]}
            arr={issues}
            generateItem={generateItem}
            setSorting={setSorting}
            sorting={sorting}
        />
    );
}
export default IssuesList;
