import redcross from "../assets/redcross.svg";
import greencheck from "../assets/greencheck.svg";
import { useEffect, useMemo, useState } from "react";
import Modal from "../components/Modal";
import { StyledPageTitle } from "../components/StyledPageTitle";
import { StyledInputField } from "../components/StyledInputField";
import { StyledActionButton } from "../components/StyledActionButton";
import { useDispatch, useSelector } from "react-redux";
import { updateTradeStatus } from "./DashboardSlice";
import { StyledTextArea } from "../components/StyledTextArea";
import { StyledModalItem } from "../components/StyledModalItem";
import capitalizeFirstLetter from "../util/capitalizeFirstLetter";
import info from "../assets/infoRed.svg";
import { selectVersion } from "../auth/AuthSlice";
import getSymbolFromCurrency from "currency-symbol-map";
import { IUser } from "../users/UserPanelSlice";
import { getReprensetation, representation } from "../watchlist/Representations";
import minDate from "../util/minDate";

interface IListItemProps {
    version: string;
    employee: IUser;
    transactionType: string;
    orderType: string;
    ticker: string;
    assetName: string;
    secondaryTicker: string;
    secondaryAssetName: string;
    volume: number;
    secondaryCryptoVolume: number;
    amount: number;
    secondaryCryptoAmount: number;
    currency: string;
    explanation: string;
    id: number;
    date: string;
    key: number;
    representations: { [key: string]: boolean };
    handleDelete(email: string): void;
    complianceNotes: string;
    subCategory: string;
    frequency: string;
    start: string;
}

function formatDate(initDate: string) {
    if (initDate !== null && initDate !== "") {
        const tradeReqDate = initDate.split("T")[0];
        const tradeReqTime = initDate.split("T")[1];
        const [year, month, day] = tradeReqDate.split("-");
        const [hour, minute] = tradeReqTime.split(".")[0].split(":");

        const args = [
            parseInt(year),
            parseInt(month),
            parseInt(day),
            parseInt(hour),
            parseInt(minute),
        ];

        if (args.reduce((prev, curr) => prev && !isNaN(curr), true)) {
            const offset = new Date().getTimezoneOffset();
            console.log(offset);
            const hourOffset = Math.floor(offset / 60);
            const minuteOffset = offset % 60;
            const date = new Date(
                Date.UTC(
                    parseInt(year),
                    parseInt(month) - 1,
                    parseInt(day),
                    parseInt(hour) - hourOffset,
                    parseInt(minute) + minuteOffset
                )
            );
            const dateISO = date.toISOString();
            const dateISODate = dateISO.split("T")[0].split("-");
            const dateISOTime = dateISO.split("T")[1].split(".")[0].split(":");
            return `${dateISODate[1]}/${dateISODate[2]} \n ${dateISOTime[0]}:${dateISOTime[1]}`;
        }
    } else {
        return null;
    }
}

/* Makes up the DashboardList
 * Set up as component to more gracefully support styling
 * and eventually add buttons/functionality to them.
 */
function DashboardListItem(props: IListItemProps): JSX.Element {
    const dispatch = useDispatch();
    const version = useSelector(selectVersion);

    const company = useSelector((state: any) => state.auth.companyName);

    const [showModalApprove, setShowModalApprove] = useState(false);
    const [showModalReject, setShowModalReject] = useState(false);
    const [showRepresentations, setShowRepresentations] = useState(false);
    const [liveDays, setLiveDays] = useState("3");
    const [end, setEnd] = useState<string>();
    const [contentNotes, setContentNotes] = useState("");

    const handleRejectTrade = async (event: any) => {
        event.preventDefault();

        var payload = {
            id: props.id,
            duration: "0",
            updateStatus: "REJECTED",
            contentNotes: contentNotes,
        };

        dispatch(updateTradeStatus(payload));

        setShowModalReject(false);
        setLiveDays("");
    };

    const handleApproveTrade = async (event: any) => {
        event.preventDefault();

        var payload = {
            id: props.id,
            duration: liveDays,
            updateStatus: "APPROVED",
            contentNotes: contentNotes,
            end,
        };

        dispatch(updateTradeStatus(payload));

        setShowModalApprove(false);
        setLiveDays("");
    };

    useEffect(() => {
        if (company === "Nydig" || company === "Fargus") {
            setLiveDays("2");
        } else if (company === "Paradigm") {
            setLiveDays("7");
        }
    }, [company]);

    const explanation = useMemo(() => {
        if (props.explanation) return props.explanation;
        return <i>No explanation.</i>;
    }, [props]);

    const repsSchema = getReprensetation(company);

    const headerText = useMemo(() => {
        if (props.explanation) return <i>Explanation</i>;
        return "";
    }, [props]);

    const representations = useMemo(() => {
        if (props.representations) {
            return Object.values(props.representations).map((representation) =>
                representation ? "Y" : "N"
            );
        }
        return [];
    }, [props]);

    return (
        <>
            <tr>
                <td>{props.employee ? props.employee.name : ""}</td>
                <td>{capitalizeFirstLetter(props.transactionType)}</td>
                <td>{props.orderType}</td>
                <td>{props.ticker}</td>
                <td>{props.assetName}</td>
                <td>
                    {props.volume ? props.volume + " Units" : "-"}
                    <br />
                    {props.amount
                        ? getSymbolFromCurrency(props.currency) + "" + props.amount
                        : "-"}
                </td>
                {(props.version === "crypto_base" ||
                    props.version === "crossover_base") && (
                        <td>{props.secondaryTicker}</td>
                    )}
                {(props.version === "crypto_base" ||
                    props.version === "crossover_base") && (
                        <td>{props.secondaryAssetName}</td>
                    )}
                {(props.version === "crypto_base" ||
                    props.version === "crossover_base") && (
                        <td>
                            {props.secondaryCryptoVolume
                                ? props.secondaryCryptoVolume + " Units"
                                : "-"}
                            <br />
                            {props.secondaryCryptoAmount
                                ? getSymbolFromCurrency(props.currency) +
                                "" +
                                props.secondaryCryptoAmount
                                : "-"}
                        </td>
                    )}
                <td>
                    <img
                        style={{ cursor: "pointer" }}
                        src={info}
                        alt={"Info"}
                        width={20}
                        height={20}
                        onClick={() => {
                            setShowRepresentations(true);
                        }}
                    />
                </td>
                <td>{formatDate(props.date)}</td>
                <td>
                    <img
                        style={{ marginRight: "20px", cursor: "pointer" }}
                        src={redcross}
                        alt=""
                        onClick={() => setShowModalReject(true)}
                    />
                    <img
                        style={{ cursor: "pointer" }}
                        src={greencheck}
                        alt=""
                        onClick={() => {
                            if (
                                props.orderType === "Stop Market" ||
                                props.orderType === "Stop Limit" ||
                                props.orderType === "Limit"
                            ) {
                                setLiveDays("5");
                            }
                            setShowModalApprove(true);
                        }}
                    />
                </td>
                {/* i.e. Delete button goes here */}
            </tr>
            <Modal
                show={showRepresentations}
                onClose={() => {
                    setShowRepresentations(false);
                }}
            >
                <div>
                    <div style={{ height: 200 }}>
                        {headerText}
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: 100,
                            }}
                        >
                            {explanation}
                        </div>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            {props.complianceNotes}
                        </div>
                    </div>
                    {(company === "Fargus" ||
                        company === "Argus" ||
                        company === "ArgusTest" ||
                        company === "Paradigm") && (
                            <td>
                                <div style={{ textAlign: "left" }}>
                                    <strong>Standing Order:</strong>{" "}
                                    {props.subCategory &&
                                        props.subCategory === "STANDING_ORDER"
                                        ? "Yes"
                                        : "No"}
                                    {props.subCategory &&
                                        props.subCategory === "STANDING_ORDER" && (
                                            <div>
                                                <strong>Frequency: </strong>{" "}
                                                {capitalizeFirstLetter(props.frequency)}
                                            </div>
                                        )}
                                    {props.subCategory &&
                                        props.subCategory === "STANDING_ORDER" && (
                                            <div>
                                                <strong>Start: </strong>{" "}
                                                {props.start.split("T")[0]}
                                            </div>
                                        )}
                                </div>
                            </td>
                        )}
                    {
                        representations.length !== 0 && (
                            <>
                                <div
                                    style={{ height: 1, backgroundColor: "lightgray" }}
                                />
                                <br></br>
                                <p style={{ textAlign: "left" }}>
                                    {repsSchema
                                        .filter((rep) => rep.reviewLabel)
                                        .map((rep, index) => (
                                            <p>
                                                {index + 1}.{" "}
                                                {rep.reviewLabel +
                                                    representations[index]}
                                                <br />
                                            </p>
                                        ))}
                                </p>
                            </>
                        )}
                </div>
            </Modal>

            <Modal
                show={showModalReject}
                onClose={() => {
                    setShowModalReject(false);
                }}
            >
                <div
                    style={{ height: "90%", display: "flex", flexDirection: "column" }}
                >
                    <StyledPageTitle style={{ fontSize: "30px" }}>
                        Trade Request for {props.employee ? props.employee.name : ""}
                    </StyledPageTitle>
                    <h3 style={{ textAlign: "center" }}>
                        Details: {capitalizeFirstLetter(props.transactionType)} -{" "}
                        {props.assetName} ({props.ticker}) - {props.volume}{" "}
                        {props.version === "crypto_base" ? "units" : "shares"}
                    </h3>
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            flex: 1,
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <form onSubmit={handleRejectTrade} style={{ width: "100%" }}>
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <StyledModalItem style={{ width: "100%" }}>
                                    <label style={{ paddingBottom: "0%" }}>
                                        Additional Notes:
                                    </label>
                                    <StyledTextArea
                                        style={{ width: "100%" }}
                                        placeholder="Can only be seen by compliance team."
                                        value={contentNotes}
                                        onChange={(event) => {
                                            setContentNotes(event.target.value);
                                        }}
                                    />
                                </StyledModalItem>

                                <h3>Reject Trade Request?</h3>
                                <StyledActionButton
                                    style={{
                                        display: "block",
                                        margin: "auto",
                                        width: "150px",
                                    }}
                                >
                                    Reject Request
                                </StyledActionButton>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>

            <Modal
                show={showModalApprove}
                onClose={() => {
                    setShowModalApprove(false);
                }}
            >
                <div
                    style={{ height: "90%", display: "flex", flexDirection: "column" }}
                >
                    <StyledPageTitle style={{ fontSize: "30px" }}>
                        Trade Request for {props.employee ? props.employee.name : ""}
                    </StyledPageTitle>
                    <h3 style={{ textAlign: "center" }}>
                        Details: {capitalizeFirstLetter(props.transactionType)} -{" "}
                        {props.assetName} ({props.ticker}) - {props.volume}{" "}
                        {props.version === "crypto_base" ? "units" : "shares"}
                    </h3>
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            flex: 1,
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <form onSubmit={handleApproveTrade} style={{ width: "100%" }}>
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {props.subCategory === "STANDING_ORDER" && (
                                    <StyledModalItem>
                                        <label style={{ width: "100%" }}>
                                            End Date
                                        </label>
                                        <StyledInputField
                                            style={{ width: "100%" }}
                                            onFocus={(e) => {
                                                if (end === undefined) {
                                                    setEnd("");
                                                }
                                            }}
                                            onBlur={(e) => {
                                                if (end === "") {
                                                    setEnd(undefined);
                                                }
                                            }}
                                            type={end === undefined ? "text" : "date"}
                                            placeholder="Ongoing"
                                            value={end || ""}
                                            onChange={(event) =>
                                                setEnd(event.target.value)
                                            }
                                            min={minDate(new Date())}
                                        />
                                    </StyledModalItem>
                                )}
                                {props.subCategory !== "STANDING_ORDER" && (
                                    <StyledModalItem>
                                        <label style={{ width: "100%" }}>
                                            Number of Days Trade Live For:
                                        </label>
                                        <StyledInputField
                                            style={{ width: "100%" }}
                                            placeholder="Please enter number of days..."
                                            type="text"
                                            value={liveDays}
                                            onChange={(event) => {
                                                setLiveDays(event.target.value);
                                            }}
                                        />
                                    </StyledModalItem>
                                )}

                                <StyledModalItem style={{ width: "100%" }}>
                                    <label style={{ paddingBottom: "0%" }}>
                                        Additional Notes:
                                    </label>
                                    <StyledTextArea
                                        style={{ width: "100%" }}
                                        placeholder="Can only be seen by compliance team"
                                        value={contentNotes}
                                        onChange={(event) => {
                                            setContentNotes(event.target.value);
                                        }}
                                    />
                                </StyledModalItem>
                                <br />
                                <StyledActionButton
                                    style={{
                                        display: "block",
                                        margin: "auto",
                                        width: "150px",
                                    }}
                                >
                                    Approve Request
                                </StyledActionButton>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default DashboardListItem;
