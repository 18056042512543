import { IAml, IInsiderTrading } from "./AnalyticsSlice";
import SmartList from "../components/SmartList";
import { ISorting } from "../dashboard/DashboardSlice";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import AMLListItem from "./InspectListItem";

function isAml(object: any): object is IAml {
    return "amlTradeType" in object;
}

interface IListProps<T extends IAml | IInsiderTrading> {
    records: Array<T>;
    selected: IAml | IInsiderTrading | undefined;
    sorting: ISorting;
    setSorting: (sorting: ISorting) => void;
    onSelect: (record: T) => void;
    onFlag: (record: T) => void;
    onReview: (records: T) => void;
    onCompliance: (record: T) => void;
}

function InspectList<T extends IAml | IInsiderTrading>(props: IListProps<T>): JSX.Element {
    const { records, selected, sorting, setSorting, onSelect, onFlag, onCompliance, onReview } =
        props;

    const version = useSelector((state: any) => state.auth.version);
    const headerData = useMemo(() => {
        return [
            { fieldText: "Employee", fieldValue: "username" },
            { fieldText: "Inspect Date", fieldValue: "inspectDate" },
            { fieldText: "Type", fieldValue: "type" },
            { fieldText: "Ticker", fieldValue: "ticker" },
            { fieldText: "Flag", fieldValue: "flag" },
            { fieldText: "Status", fieldValue: "status" },
        ].filter(
            (header) => version === "crypto_base" || header.fieldText !== "Account Name"
        );
    }, [version]);

    const generateItem = (r: T) => {
        const type = isAml(r) ? r.amlTradeType : r.insiderTradeType;
        return (
            <AMLListItem
                key={r.id + type}
                user={r.username}
                inspectDate={r.anchorDate}
                type={type}
                windowType={r.windowType}
                ticker={r.ticker}
                flagged={!!r.flagUserId}
                status={r.reviewing ? "In review" : "Open"}
                selected={selected ? (r.id + type) === (selected.id + type) : false}
                onSelect={() => onSelect(r)}
                onFlag={() => onFlag(r)}
                onReview={() => onReview(r)}
                onCompliance={() => onCompliance(r)}
            />
        );
    };

    return (
        <div className="table-container">
            <SmartList
                headerData={headerData}
                arr={records}
                bumper={"Action"}
                generateItem={generateItem}
                sorting={sorting}
                setSorting={setSorting}
                disabled={["status", "flag"]}
            />
        </div>
    );
}

export default InspectList;
