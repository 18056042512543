/* Wrapper for calls to the Accounts route */
/* Handles querying User Accounts, Connecting Accounts through Yodlee, */
/* and adding Custom investment accounts and statements. */

import { ISorting } from "../dashboard/DashboardSlice";
import capitalizeFirstLetter from "../util/capitalizeFirstLetter";
import { IPagination, paginationQueryParam } from "../util/pagination";
import { authService } from "./authService";

export const accountsService = {
    getAccounts,
    createAccount,
    deleteAccount,
    getCryptoAccounts,
    createCryptoAccount,
    deleteCryptoAccount,
    connectAccount,
    getExchangeInfo,
    uploadAccountStatement,
    sendCode,
    getAccountSixMonths,
    updateLastUpdated,
    fetchYodleeToken,
    yodleeConnect,
    getOAuthMethod,
    authorizeOAuth,
    exchangeOAuthToken,
    createUnsupportedUserExchangeAccount,
    getUnsupportedUserExchangeAccounts,
    getUserExchangeAccountsSixMonths,
    uploadUserExchangeAccountStatement,
    getUserDeclare,
    changeUserDeclare,
    getStatementIds,
    downloadStatement,
};

const base = process.env.REACT_APP_API_ENDPOINT;
const baseUrl = `${base}/accounts`;
const baseUrlCrypto = `${base}/crypto/accounts`;
const baseOauthUrl = `${base}/oauth`;
export interface IFilter {
    user: string;
    action: string;
    ticker: string;
    security: string;
    account: string;
    volume: string;
    permissionStatus: string;
    date: string;
}

/* Get all User Accounts associated with the given user's ID */
function getAccounts(pagination: {
    pageNumber: number;
    sorting: ISorting[];
    pageSize: number;
}): any {
    const { pageNumber, pageSize, sorting } = pagination;
    const requestParams = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            ...authService.authHeader(),
        },
        body: JSON.stringify({ sorting, pageSize }),
    };

    return fetch(
        paginationQueryParam(`${baseUrl}/user/get`, {
            pageNumber,
            pageSize,
        }),
        requestParams
    )
        .then(authService.handleErrors)
        .then(handleResponse);
}

/* Create a User Account for the given Investment Account by the userId and the name of the account. */
function createAccount(
    accountName: string,
    accountNumber: string,
    accountCreationDate: string,
    accountHolderId: string,
    description: string
): any {
    const requestParams = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            ...authService.authHeader(),
        },
        body: JSON.stringify({
            name: accountName,
            accountNumber: accountNumber,
            accountCreationDate: accountCreationDate,
            accountHolderId: accountHolderId,
            description: description,
        }),
    };

    return fetch(`${baseUrl}`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

/* Upload an Account Statement */
function uploadAccountStatement(
    accountId: string,
    userId: string,
    month: number,
    year: number,
    file: string
): any {
    const requestParams = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            ...authService.authHeader(),
        },
        body: JSON.stringify({
            file,
        }),
    };

    return fetch(
        `${baseUrl}/statement/${userId}/${accountId}/${month}/${year}`,
        requestParams
    )
        .then(authService.handleErrors)
        .then(handleResponse);
}

// Changes last updated value for account
function updateLastUpdated(accountId: string): any {
    const requestParams = {
        method: "PUT",
        headers: {
            ...authService.authHeader(),
        },
    };

    return fetch(`${baseUrl}/last-updated/` + accountId, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

// Gets last 6 months of account statement upload history
function getAccountSixMonths(accountId: string): any {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
        },
    };

    return fetch(`${baseUrl}/statement/recent/` + accountId, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

function deleteAccount(userAccountId: string): any {
    const requestParams = {
        method: "DELETE",
        headers: {
            ...authService.authHeader(),
        },
    };

    return fetch(`${baseUrl}/` + userAccountId, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

/* Get all Crypto Accounts associated with the given user's ID */
function getCryptoAccounts(pagination: {
    pageNumber: number;
    sorting: ISorting[];
    pageSize: number;
}): any {
    const { sorting, pageNumber, pageSize } = pagination;
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ sorting, pageSize }),
    };

    return fetch(
        paginationQueryParam(`${baseUrlCrypto}/user/get`, {
            pageNumber,
            pageSize,
        }),
        requestParams
    )
        .then(authService.handleErrors)
        .then(handleResponse);
}

/* Create a Crypto Account for the name of the account and the given API-Key and API-Secret. */

function createCryptoAccount(
    exchangeId: string,
    accountHolderId: string,
    description: string
): any {
    const requestParams = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            ...authService.authHeader(),
        },
        body: JSON.stringify({ exchangeId, accountHolderId, description }),
    };

    return fetch(`${baseUrlCrypto}`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

/* Sends code to backend to recieve credentials token via Flanks */
function sendCode(code: string, userId: string): any {
    const requestParams = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            ...authService.authHeader(),
        },
        body: JSON.stringify({ code: code, userId: userId }),
    };

    return fetch(`${baseUrl}/flanksCode`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

/* Sends code to backend to recieve credentials token via Flanks */
function fetchYodleeToken(): Promise<any> {
    const requestParams = {
        method: "GET",
        headers: {
            ...authService.authHeader(),
        },
    };

    return fetch(`${baseUrl}/yodlee_token`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

function deleteCryptoAccount(cryptoAccountId: string): any {
    const requestParams = {
        method: "DELETE",
        headers: {
            ...authService.authHeader(),
        },
    };

    return fetch(`${baseUrlCrypto}/` + cryptoAccountId, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

function connectAccount(
    exchangeId: string,
    cryptoAccountId: string,
    apiKey: string,
    apiSecret: string,
    apiPassphrase?: string,
    uuid?: string
): any {
    const requestParams = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            ...authService.authHeader(),
        },
        body: JSON.stringify({
            ccxt_exchange_id: exchangeId,
            api_key: apiKey,
            secret: apiSecret,
            password: apiPassphrase ? apiPassphrase : "",
            uuid: uuid ? uuid : "",
        }),
    };

    return fetch(`${baseUrlCrypto}/connect/` + cryptoAccountId, requestParams);
}

function authorizeOAuth(
    provider_name: string,
    user_exchange_account_id: string,
    redirect_uri: string
): any {
    const requestParams = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            ...authService.authHeader(),
        },
        body: JSON.stringify({
            provider_name,
            user_exchange_account_id,
            redirect_uri,
        }),
    };
    return fetch(`${baseOauthUrl}/authorize-url/get`, requestParams);
}

function getOAuthMethod(provider_name: string): any {
    const requestParams = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            ...authService.authHeader(),
        },
        body: JSON.stringify({
            provider_name,
        }),
    };
    return fetch(`${baseOauthUrl}/auth-method/get`, requestParams);
}

function exchangeOAuthToken(
    providerName: string,
    state: string,
    code: string,
    redirectUri: string
): any {
    const requestParams = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            ...authService.authHeader(),
        },
        body: JSON.stringify({
            provider_name: providerName,
            state,
            code,
            redirect_uri: redirectUri,
        }),
    };
    return fetch(`${baseOauthUrl}/exchange-authorization-code`, requestParams);
}
function getExchangeInfo(exchangeId: string) {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
        },
    };

    return fetch(`${baseUrlCrypto}/exchange-info/${exchangeId}/get`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

function yodleeConnect(accountId: string) {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
        },
    };

    return fetch(`${baseUrl}/yodlee-connect/${accountId}`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

function createUnsupportedUserExchangeAccount(
    name: string,
    accountHolder: string
): any {
    const requestParams = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            ...authService.authHeader(),
        },
        body: JSON.stringify({ name: name, account_holder: accountHolder }),
    };

    return fetch(`${baseUrlCrypto}/unsupported-exchanges/create`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

function getUnsupportedUserExchangeAccounts(): any {
    const requestParams = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            ...authService.authHeader(),
        },
        body: JSON.stringify({}),
    };

    return fetch(`${baseUrlCrypto}/unsupported-exchanges/get`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

/* Exchanges statements (additional proof) */
function getUserExchangeAccountsSixMonths(exchangeId: string): any {
    const requestParams = {
        method: "GET",
        headers: {
            ...authService.authHeader(),
        },
    };

    return fetch(`${baseUrlCrypto}/statement/recent/` + exchangeId, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

function uploadUserExchangeAccountStatement(
    exchangeId: string,
    userId: string,
    month: number,
    year: number,
    file: string
): any {
    const requestParams = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            ...authService.authHeader(),
        },
        body: JSON.stringify({ file }),
    };

    return fetch(
        `${baseUrlCrypto}/statement/${userId}/${exchangeId}/${month}/${year}`,
        requestParams
    )
        .then(authService.handleErrors)
        .then(handleResponse);
}

function getUserDeclare(): any {
    const requestParams = {
        method: "GET",
        headers: {
            ...authService.authHeader(),
        },
    };

    return fetch(`${baseUrl}/declare`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse)
        .then((user) => {
            return user;
        });
}

function changeUserDeclare(declare: boolean): any {
    const requestParams = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            ...authService.authHeader(),
        },
        body: JSON.stringify({ declare: declare }),
    };

    return fetch(`${baseUrl}/declare`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse)
        .then((user) => {
            return user;
        });
}

function getStatementIds(exchangeIds: Array<string>): any {
    const requestParams = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            ...authService.authHeader(),
        },
        body: JSON.stringify({ user_ids: exchangeIds }),
    };

    return fetch(`${baseUrl}/statement/bulk/get`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

function downloadStatement(id: string, filename: string): any {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
        },
    };

    return fetch(`${baseUrl}/statement/download/${id}`, requestParams)
        .then(authService.handleErrors)
        .then((response) => response.blob())
        .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            if (link.parentNode) {
                link.parentNode.removeChild(link);
            }
        });
}

function handleResponse(response: any) {
    return response.text().then((text: any) => {
        const data = text && JSON.parse(text);
        return data;
    });
}
