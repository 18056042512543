import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { walletService } from "../services/walletService";
import { toast } from "react-toastify";
import { fetchUserDeclare } from "../accounts/AccountsSlice";
import { IUser } from "../users/UserPanelSlice";

export interface IWallet {
    id: string;
    blockchain: IBlockchain;
    address: string;
    walletType: string;
    lastUpdated: string;
    lastPull: string;
    dateCreated: string;
    transactionCount: number;
    accountHolderId: string;
    accountHolder: IUser;
}

export interface IBlockchain {
    id: string;
    name: string;
    displayName: string;
    supported: boolean;
    display: boolean;
}

export interface IWalletSliceState {
    status: string;
    wallets: Array<IWallet>;
    aggregatedWallets: { [walletType: string]: { [accountHolder: string]: IWallet[] } };
    nextPage: boolean;
    walletStatements: Array<IWalletStatement>;
}

const initialState: IWalletSliceState = {
    status: "idle",
    wallets: [],
    aggregatedWallets: {},
    nextPage: false,
    walletStatements: [],
};

export interface IWalletStatement {
    dateCreated: string;
    id: string;
    lastUpdated: string;
    month: number;
    userWalletId: string;
    userId: string;
    year: number;
}

export const createWallet = createAsyncThunk(
    "wallets/createWallet",
    async (
        payload: {
            address: string;
            blockchain: string;
            walletType: string;
            accountHolderId: string;
            description: string;
            accountHolder?: string;
        },
        { dispatch }
    ) => {
        const {
            address,
            blockchain,
            walletType,
            accountHolderId,
            accountHolder,
            description,
        } = payload;
        const res = await walletService.createWallet(
            address,
            blockchain,
            walletType,
            accountHolderId,
            description,
            accountHolder
        );
        const text = await res.text();
        if (res.ok === false) {
            toast.error(text.replaceAll('"', ""), {
                position: "top-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            dispatch(fetchAggregatedWallets());
            dispatch(fetchUserDeclare());
            return text && JSON.parse(text);
        }
    }
);

export const deleteWallet = createAsyncThunk(
    "wallets/deleteWallet",
    async (walletId: string, { dispatch }) => {
        await walletService.deleteWallet(walletId);
        dispatch(fetchAggregatedWallets());
        dispatch(fetchUserDeclare());
        return walletId;
    }
);

export const fetchWallets = createAsyncThunk("wallets/fetchWallet", async () => {
    const res = await walletService.getWallets();
    return res;
});

export const fetchAggregatedWallets = createAsyncThunk(
    "wallets/fetchAggregatedWallet",
    async () => {
        const res = await walletService.getAggregatedWallets();
        return res;
    }
);

export const fetchSixMonths = createAsyncThunk(
    "wallets/fetchSixMonths",
    async (walletId: string) => {
        const res = await walletService.getWalletsSixMonths(walletId);
        return res.data;
    }
);

export const uploadWalletStatement = createAsyncThunk(
    "wallets/uploadWalletStatement",
    async (payload: {
        walletId: string;
        userId: string;
        month: number;
        year: number;
        file: string;
    }) => {
        const { walletId, userId, file, month, year } = payload;
        const res = await walletService.uploadWalletStatement(
            walletId,
            userId,
            month,
            year,
            file
        );
        return res;
    }
);

const walletSlice = createSlice({
    name: "accounts",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchWallets.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(fetchWallets.fulfilled, (state, action) => {
                state.status = "idle";
                state.wallets = action.payload.data;
                // state.nextPage = action.payload.nextPage;
            })
            .addCase(fetchAggregatedWallets.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(fetchAggregatedWallets.fulfilled, (state, action) => {
                state.status = "idle";
                state.aggregatedWallets = action.payload.data;
                // state.nextPage = action.payload.nextPage;
            })
            .addCase(deleteWallet.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(deleteWallet.fulfilled, (state, action) => {
                state.status = "idle";
                state.wallets = state.wallets.filter(
                    (wallet) => wallet && wallet.id !== action.payload
                );
            })
            .addCase(createWallet.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(createWallet.fulfilled, (state, action) => {
                state.status = "idle";
                state.wallets.push(action.payload);
            })
            .addCase(fetchSixMonths.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(fetchSixMonths.fulfilled, (state, action) => {
                state.status = "idle";
                state.walletStatements = action.payload;
            })
            .addCase(uploadWalletStatement.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(uploadWalletStatement.rejected, (state, action) => {
                console.log(action.error);
            })
            .addCase(uploadWalletStatement.fulfilled, (state, action) => {
                state.status = "idle";
                let data = action.payload;
                console.log(state.walletStatements);
                state.walletStatements = state.walletStatements.filter(
                    (statement) => statement.month !== data.month
                );
                state.walletStatements.push(data);
            });
    },
});

export const selectWallets = (state: any) => state.wallets.wallets;
export const selectAggregatedWallets = (state: any) => state.wallets.aggregatedWallets;
export const selectWalletStatements = (state: any) => state.wallets.walletStatements;

export default walletSlice.reducer;
