/* Wrapper for calls to the trade_reqs route */
import { IComplianceFilter, ISorting } from "../dashboard/DashboardSlice";
import { authService } from "./authService";

export const dashboardService = {
    getComplianceIssues,
    resolveComplianceIssue,
    markedComplianceIssue,
    getComplianceIssuesDownload,
    createComplianceIssueNote,
    updateComplianceReviewStatus,
    getIssueNotesDownload,
};

export interface IFilter {
    user: string;
    transactionType: string;
    orderType: string;
    ticker: string;
    asset: string;
    account: string;
    volume: string;
    volumeMin: string;
    volumeMax: string;
    status: string;
    dateSubmitted: "";
    dateSubmittedMin: "";
    dateSubmittedMax: "";
    dateProcessed: "";
    dateProcessedMin: "";
    dateProcessedMax: "";
    approvedFrom: "";
    approvedFromMin: "";
    approvedFromMax: "";
    approvedUntil: "";
    approvedUntilMin: "";
    approvedUntilMax: "";
}

const base = process.env.REACT_APP_API_ENDPOINT;
const complianceBaseUrl = `${base}/compliance`;

/* Create issue note */
function createComplianceIssueNote(note: string, issueId: string) {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
            "Content-type": "application/json",
        },
        body: JSON.stringify({
            note: note,
            issueId: issueId,
        }),
    };

    return fetch(`${complianceBaseUrl}/note`, requestParams);
}

function updateComplianceReviewStatus(issueId: string) {
    const requestParams = {
        method: "PUT",
        headers: {
            ...authService.authHeader(),
            "Content-type": "application/json",
        },
        body: JSON.stringify({}),
    };

    return fetch(`${complianceBaseUrl}/review/` + issueId, requestParams);
}

function getComplianceIssues(
    pageNumber: number,
    filter: IComplianceFilter,
    sorting: ISorting[],
    pageSize?: number
): any {
    if (pageSize !== undefined) {
        const requestParams = {
            method: "POST",
            headers: {
                ...authService.authHeader(),
                "Content-type": "application/json",
            },
            body: JSON.stringify({ filter, sorting, pageSize }),
        };

        return fetch(`${complianceBaseUrl}/get?pageNumber=` + pageNumber, requestParams)
            .then(authService.handleErrors)
            .then(handleResponse);
    } else {
        const requestParams = {
            method: "POST",
            headers: {
                ...authService.authHeader(),
                "Content-type": "application/json",
            },
            body: JSON.stringify({ filter, sorting }),
        };

        return fetch(`${complianceBaseUrl}/get?pageNumber=` + pageNumber, requestParams)
            .then(authService.handleErrors)
            .then(handleResponse);
    }
}

function getComplianceIssuesDownload(
    filter: IComplianceFilter,
    sorting: ISorting[],
    fields?: string[]
): any {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
            "Content-type": "application/json",
        },
        body: JSON.stringify({ filter, sorting, fields }),
    };

    return fetch(`${complianceBaseUrl}/download`, requestParams)
    // .then(authService.handleErrors)
    // .then(handleResponse);
}

function resolveComplianceIssue(
    issueId: string,
    status: "MARKED" | "RESOLVED",
    complianceNotes?: string
): any {
    const requestParams = {
        method: "PUT",
        headers: {
            ...authService.authHeader(),
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ complianceNotes: complianceNotes, status }),
    };

    return fetch(`${complianceBaseUrl}/${issueId}`, requestParams).then(handleResponse);
}

function markedComplianceIssue(issueId: string, complianceNotes?: string): any {
    const requestParams = {
        method: "PUT",
        headers: {
            ...authService.authHeader(),
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ complianceNotes: complianceNotes, status: "MARKED" }),
    };

    return fetch(`${complianceBaseUrl}/${issueId}`, requestParams).then(handleResponse);
}
function getIssueNotesDownload(issueId: string): any {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
            "Content-type": "application/json",
        },
        body: JSON.stringify({}),
    };

    return fetch(`${complianceBaseUrl}/notes/download/` + issueId, requestParams);
}

function handleResponse(response: any) {
    return response.text().then((text: any) => {
        const data = text && JSON.parse(text);
        return data;
    });
}
