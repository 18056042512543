import { StyledFilterValue } from "../components/StyledFilterValue";
import { IFilter } from "./AnalyticsSlice";
import redcross from "../assets/redcross.svg";

export type FilterField = "userId" | "asset" | "windowType" | "flagged" | "type";

export interface IFilterValue extends Omit<IFilter, "endDate"> {}

export interface IFilterDisplay
    extends Omit<IFilter, "flagged" | "startDate" | "endDate" | "asset"> {
    asset?: string;
    flagged?: string;
    range?: string;
}

export const filterLabel = (
    fieldName: string,
    displayFields: IFilterDisplay,
    valueFields: IFilterValue,
    removeFilter: (fieldName: string) => void
) => {
    const name = fieldName === "startDate" ? "range" : fieldName;
    const value = (displayFields as any)[
        fieldName === "startDate" ? "range" : fieldName
    ];
    return (valueFields as any)[fieldName] !== "" ? (
        <StyledFilterValue
            key={fieldName}
            style={{
                display: "flex",
                flexDirection: "row",
                gap: "0.5rem",
                padding: "0.5rem",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                width: "auto",
            }}
        >
            <>
                <span>
                    {name}: {value}
                </span>
                <img
                    style={{
                        marginRight: "4px",
                        cursor: "pointer",
                    }}
                    src={redcross}
                    alt=""
                    onClick={() => {
                        fieldName === "startDate" && removeFilter("endDate");
                        removeFilter(fieldName);
                    }}
                />
            </>
        </StyledFilterValue>
    ) : null;
};
