import { useEffect, useRef, useState } from "react";
import trashcan from "../../assets/trashcan.svg";
import { useDispatch, useSelector } from "react-redux";
import { StyledActionButton } from "../../components/StyledActionButton";
import { StyledSearchInput } from "../../components/StyledFilterInput";
import { StyledLinkButton } from "../../components/StyledLinkButton";
import {
    IRestrictionGroup,
    IRestrictionGroupMember,
} from "../../services/restrictionService";
import {
    queryRestrictionGroups,
    selectRestrictionGroups,
    selectRestrictionGroupPaging,
    saveNewRestrictionGroup,
    selectRestrictionGroupMembers,
    selectRestrictionGroupMemberPaging,
    queryRestrictionGroupMembers,
    saveNewRestrictionGroupMember,
    deleteRestrictionGroupMember,
} from "./InsiderManagementSlice";
import UserAutocomplete, { UserSuggestion } from "../../components/UserAutoComplete";
import { StyledTableContainer } from "../../components/StyledTable";
import TablePageFooter, { DefaultPagination } from "../../components/TablePageFooter";

import "./InsiderManagement.scss";
import { StyledSelectField } from "../../components/StyledSelectField";
import { formatDatetime } from "../../util/formatDatetime";
import { IPagination } from "../../util/pagination";

const isGroup = (
    item: IRestrictionGroup | IRestrictionGroupMember
): item is IRestrictionGroup => {
    return item && item.hasOwnProperty("companyId") && !item.hasOwnProperty("email");
};

function InsiderManagement(): JSX.Element {
    /* Local state for group filters and views*/
    const [groupFilterType, setGroupFilterType] = useState<string>("group");
    const [groupFilter, setGroupFilter] = useState<string>("");
    const groups = useSelector(selectRestrictionGroups);
    const groupPaging = useSelector(selectRestrictionGroupPaging);
    const [pagination, setPagination] = useState<IPagination>(DefaultPagination());
    const [editingGroup, setEditingGroup] = useState<IRestrictionGroup | undefined>();
    const [newGroupName, setNewGroupName] = useState<string>("");

    const pageSize = 7;

    /* Local state for group filters and views*/
    const [memberFilter, setMemberFilter] = useState<string>("");
    const members = useSelector(selectRestrictionGroupMembers);
    const memberPaging = useSelector(selectRestrictionGroupMemberPaging);
    const [paginationMember, setPaginationMember] = useState<IPagination>(
        DefaultPagination()
    );

    /* User search*/
    const [user, setUser] = useState<
        { name: string; email: string; id: string } | undefined
    >(undefined);
    const clearUserSearch = useRef<() => void>();
    const setClearUserSearch = (callback: () => void) => {
        clearUserSearch.current = callback;
    };

    const selectUser = (user: UserSuggestion | undefined) => {
        if (user !== undefined) {
            setUser({ id: user.id, name: user.name, email: user.email });
        } else {
            setUser(undefined);
        }
    };

    /* Hooks for libraries, selectors*/
    const dispatch = useDispatch();

    useEffect(() => {
        filterGroup(0, pagination.pageSize);
    }, [dispatch]);

    useEffect(() => {
        if (editingGroup) {
            dispatch(
                queryRestrictionGroupMembers({
                    groupId: editingGroup.id,
                    page: 0,
                    pageSize: pageSize,
                })
            );
        }
    }, [editingGroup]);

    const filterGroup = (pageNumber: number, pageSize: number) => {
        new Promise((resolve) => {
            dispatch(
                queryRestrictionGroups({
                    filterType: groupFilterType,
                    filter: groupFilter,
                    page: (pageNumber = pageNumber >= 1 ? pageNumber : 1),
                    pageSize: pageSize,
                    editable: true,
                })
            );
            resolve({});
        }).then(() => setPagination({ pageNumber, pageSize }));
    };

    const createNewGroup = () => {
        if (newGroupName) {
            dispatch(saveNewRestrictionGroup({ name: newGroupName }));
            setNewGroupName("");
        }
    };

    const filterMember = (pageNumber: number, pageSize: number) => {
        if (editingGroup) {
            new Promise((resolve) => {
                dispatch(
                    queryRestrictionGroupMembers({
                        groupId: editingGroup.id,
                        filter: memberFilter,
                        page: (pageNumber = pageNumber >= 0 ? pageNumber : 0),
                        pageSize: pageSize,
                    })
                );
                resolve({});
            }).then(() =>
                setPaginationMember({ pageNumber, pageSize })
            );
        }
    };

    const createMember = (userId: string) => {
        if (editingGroup && userId) {
            dispatch(
                saveNewRestrictionGroupMember({
                    groupId: editingGroup.id,
                    userId: userId,
                })
            );
        }
        if (clearUserSearch.current) clearUserSearch.current();
        setUser(undefined);
    };

    const removeMember = (userId: string) => {
        if (editingGroup && userId) {
            new Promise((resolve) => {
                const res = dispatch(
                    deleteRestrictionGroupMember({
                        groupId: editingGroup.id,
                        userId: userId,
                    })
                );
                resolve(res);
            }).then(() => filterMember(paginationMember.pageNumber, paginationMember.pageSize));
        }
    };

    const generateItem = (item: IRestrictionGroup | IRestrictionGroupMember) => {
        return isGroup(item) ? (
            <tr
                key={item.id}
                className={editingGroup?.id === item.id ? "active" : ""}
                onClick={(e) => {
                    e.preventDefault();
                    setEditingGroup(item);
                }}
            >
                <td>{item.name}</td>
                <td>{formatDatetime(item.lastUpdated)}</td>
            </tr>
        ) : (
            <tr key={item.id}>
                <td>{item.name}</td>
                <td>{item.email}</td>
                <td>
                    <StyledLinkButton
                        onClick={(e) => {
                            e.preventDefault();
                            removeMember(item.id);
                        }}
                    >
                        <img src={trashcan} alt="" />
                    </StyledLinkButton>
                </td>
            </tr>
        );
    };

    return (
        <div className="InsiderManagement">
            {/* Add Group */}
            <div className="editor-field">
                <p className="editor-label">Add Insider Group</p>
                <div className="editor-input-wrapper">
                    <input
                        type="text"
                        placeholder="Ex. North America"
                        value={newGroupName}
                        style={{ width: "100%" }}
                        onChange={(e) => setNewGroupName(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                createNewGroup();
                            }
                        }}
                    />
                    <StyledActionButton
                        disabled={!newGroupName}
                        onClick={(e) => {
                            e.preventDefault();
                            createNewGroup();
                        }}
                    >
                        Add Group
                    </StyledActionButton>
                </div>
            </div>

            {/* Add member */}
            <div className="editor-field">
                <p className="editor-label">Add Member</p>
                <div className="editor-input-wrapper">
                    <input
                        className="input-group-text"
                        disabled
                        type="text"
                        placeholder="Ex. North America"
                        value={editingGroup ? editingGroup.name : "--"}
                    />
                    <div className="autoComplete">
                        <UserAutocomplete
                            onSelect={selectUser}
                            onEnter={selectUser}
                            placeholder={"Filter by name or email"}
                            clearActionRegister={setClearUserSearch}
                        />
                    </div>
                    <StyledActionButton
                        disabled={!user || !editingGroup}
                        onClick={(e: any) => {
                            e.preventDefault();
                            if (user) {
                                createMember(user.id);
                            }
                        }}
                    >
                        Add Member
                    </StyledActionButton>
                </div>
            </div>

            <div className="table-panel-container">
                <div className="table-panel-section">
                    {/* Search bar */}
                    <div style={{ display: "flex" }}>
                        <StyledSelectField
                            style={{
                                float: "left",
                                width: "auto",
                            }}
                            value={groupFilterType}
                            onChange={(event) => setGroupFilterType(event.target.value)}
                        >
                            <option key={"group"} value="group">
                                By Group
                            </option>
                            <option key={"user"} value="user">
                                By User
                            </option>
                        </StyledSelectField>
                        <StyledSearchInput
                            placeholder={"Search Insider Group"}
                            type="text"
                            value={groupFilter}
                            onChange={(e) => setGroupFilter(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    filterGroup(0, pagination.pageSize);
                                }
                            }}
                        />
                    </div>
                    {/* Context List */}
                    <StyledTableContainer className="table-panel-context">
                        <table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Last Updated</th>
                                </tr>
                            </thead>
                            <tbody>{groups ? groups.map(generateItem) : null}</tbody>
                        </table>
                    </StyledTableContainer>

                    {/* Zero base paging */}
                    <TablePageFooter
                        nextPage={groupPaging.hasNext}
                        pagination={pagination}
                        setPageNumber={(n) =>
                            filterGroup(n, pagination.pageSize)
                        }
                        setPageSize={(s) =>
                            filterGroup(pagination.pageNumber, s)
                        }
                    />
                </div>
                <div className="table-panel-section">
                    {/* Search bar */}
                    <StyledSearchInput
                        disabled={!editingGroup}
                        placeholder={"Search Member"}
                        type="text"
                        value={memberFilter}
                        onChange={(e) => setMemberFilter(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                filterMember(1, paginationMember.pageSize);
                            }
                        }}
                    />
                    {/* Context List */}
                    <StyledTableContainer className="table-panel-context">
                        <table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Remove</th>
                                </tr>
                            </thead>
                            <tbody>
                                {editingGroup ? members.map(generateItem) : null}
                            </tbody>
                        </table>
                    </StyledTableContainer>

                    {/* Zero base paging */}
                    <TablePageFooter
                        nextPage={memberPaging.hasNext}
                        pagination={paginationMember}
                        setPageNumber={(n) =>
                            filterMember(n, paginationMember.pageSize)
                        }
                        setPageSize={(s) =>
                            filterMember(paginationMember.pageNumber, s)
                        }
                    />
                </div>
            </div>
        </div>
    );
}

export default InsiderManagement;
