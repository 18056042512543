import React, { useState, useContext } from "react";
import { Helmet, HelmetProvider, HelmetTags } from 'react-helmet-async';
const googleUrl = 'https://accounts.google.com/gsi/client';

type GoogleIsLoaded = {
    isLoaded: boolean
};

const GoogleAuthContext = React.createContext<GoogleIsLoaded | undefined>(undefined);

export const useGoogleLoaded = () => {
    const context = useContext(GoogleAuthContext);

    if (context === undefined) {
        throw new Error("useGoogleLoaded must be used within a GoogleAuthProvider");
    }

    return context;
};

export const GoogleAuthProvider = ({ children }: { children: React.ReactNode }) => {
    const [scriptLoaded, setScriptLoaded] = useState<GoogleIsLoaded>({
        isLoaded: typeof window !== 'undefined' && typeof (window as any).google !== 'undefined'
    });


    // Helmet does not support the onLoad property of the script tag, so we have to manually add it like so
    const handleChangeClientState = (newState: any, addedTags: HelmetTags) => {
        if (addedTags && addedTags.scriptTags) {
            const foundScript = addedTags.scriptTags.find(
                ({ src }) => src === googleUrl
            );
            if (foundScript) {
                foundScript.addEventListener('load', () => setScriptLoaded({ isLoaded: true }), {
                    once: true,
                });
            }
        }
    };

    return (
        <>
            <HelmetProvider>
                <Helmet onChangeClientState={handleChangeClientState}>
                    <script src={googleUrl} async defer />
                </Helmet>
            </HelmetProvider>
            <GoogleAuthContext.Provider value={scriptLoaded}>{children}</GoogleAuthContext.Provider>
        </>
    );
};