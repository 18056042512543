import "bootstrap/dist/css/bootstrap-grid.min.css";
import { history } from "./nav/history";
import { Router, Switch, Route } from "react-router-dom";

//Routes
import UserRoute from "./routes/UserRoute";
import AdminRoute from "./routes/AdminRoute";
import Redirect from "./auth/Redirect";
import FlanksRedirect from "./auth/FlanksRedirect";
import SessionRoute from "./routes/SessionRoute";

//Public Components
import Login from "./auth/Login";
import PartnerLogin from "./auth/PartnerLogin";
import ErrorPage from "./home/ErrorPage";

//User Components
import Home from "./home/Home";
import Accounts from "./accounts/Accounts";
import Profile from "./home/Profile";
import ClearanceStatus from "./notifications/ClearanceStatus";
import HistoricTrades from "./transactions/HistoricTrades";

//Admin Components
import Admin from "./restrictions/Admin";
import UserPanel from "./users/UserPanel";
import TradeRequests from "./dashboard/TradeRequests";
import Dashboard from "./dashboard/Dashboard";
import { useEffect } from "react";
import HistoricHoldings from "./admin/HistoricHoldings";
import UserHistoricTrades from "./transactions/UserHistoricTrades";
import UserHistoricHoldings from "./user/UserHistoricHoldings/UserHistoricHoldings";
import User from "./restrictions/User";
import HistoricInfractions from "./admin/HistoricInfractions/HistoricInfractions";
import UserProfile from "./user/UserProfile/UserProfile";
import Analytics from "./analytics/Analytics";
import InsiderManagement from "./restrictions/InsiderManagement/InsiderManagement";
import AdminAccounts from "./admin/Accounts/AdminAccounts";
import UserAttestations from "./attestations/UserAttestations";
import AdminAttestations from "./attestations/AdminAttestations";
import PasswordLogin from "./auth/PasswordLogin";
import HomePage from "./home/HomePage";
import AccountRedirect from "./accounts/Redirect";
// import { useDispatch, useSelector } from "react-redux";
// import { createEventStream, selectUserId } from "./auth/AuthSlice";

function App() {
    // const userId = useSelector(selectUserId);
    // const dispatch = useDispatch();
    useEffect(() => {
        console.log(window.location.hostname);
        console.log(window.location.host);
    }, []);
    return (
        <Router history={history}>
            <Switch>
                {/* PUBLIC ROUTES */}
                <SessionRoute exact path="/login" children={<Login />} />
                <SessionRoute exact path="/partner_login" children={<PartnerLogin />} />
                <SessionRoute
                    exact
                    path="/password_login"
                    children={<PasswordLogin />}
                />
                <SessionRoute
                    path="/oauth/:providerName"
                    children={<AccountRedirect />}
                />
                <Route path="/redirect" children={<Redirect />} />
                <Route exact path="/flanks_redirect" children={<FlanksRedirect />} />

                {/* USER ROUTES */}
                <UserRoute exact path="/" children={<Home />} />
                <UserRoute exact path="/homepage" children={<HomePage />} />
                <UserRoute exact path="/accounts" children={<Accounts />} />
                <UserRoute exact path="/profile" children={<Profile />} />
                <UserRoute exact path="/clearance" children={<ClearanceStatus />} />
                <UserRoute exact path="/user-restrictions" children={<User />} />
                <UserRoute
                    exact
                    path="/user-attestations"
                    children={<UserAttestations />}
                />
                <UserRoute
                    exact
                    path="/user-history"
                    children={<UserHistoricTrades />}
                />
                <UserRoute
                    exact
                    path="/user-historic-holdings"
                    children={<UserHistoricHoldings />}
                />
                <UserRoute exact path="/user-profile" children={<UserProfile />} />

                {/* ADMIN ROUTES */}
                <AdminRoute exact path="/admin" children={<Admin />} />
                <AdminRoute exact path="/userPanel" children={<UserPanel />} />
                <AdminRoute
                    exact
                    path="/insider-management"
                    children={<InsiderManagement />}
                />
                <AdminRoute exact path="/dashboard" children={<Dashboard />} />
                <AdminRoute exact path="/trade-requests" children={<TradeRequests />} />
                <AdminRoute exact path="/history" children={<HistoricTrades />} />
                <AdminRoute exact path="/admin-accounts" children={<AdminAccounts />} />
                <AdminRoute
                    exact
                    path="/historic-holdings"
                    children={<HistoricHoldings />}
                />
                <AdminRoute
                    exact
                    path="/historic-infractions"
                    children={<HistoricInfractions />}
                />
                <AdminRoute exact path="/analytics" children={<Analytics />} />
                <AdminRoute
                    exact
                    path="/attestations"
                    children={<AdminAttestations />}
                />

                {/* CATCH ALL ROUTE, MUST BE LAST */}
                <Route children={<ErrorPage />} />
            </Switch>
        </Router>
    );
}

export default App;
