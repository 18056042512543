export const exchange_info = [
    { displayName: 'AAX', docs: 'https://www.aax.com/apidoc/index.html#api-key', name: 'aax' },
    { displayName: 'AscendEX', docs: 'https://ascendex.com/en/support/articles/36231-how-to-create-an-api', name: 'ascendex' },
    { displayName: 'Bequant', docs: 'To be created', name: 'bequant' },
    { displayName: 'Bibox', docs: 'https://helpdesk.bitsgap.com/en/articles/3786782-how-to-create-and-set-up-bibox-api-key', name: 'bibox' },
    { displayName: 'BigONE', docs: 'https://big.one/settings/api-keys', name: 'bigone' },
    { displayName: 'Binance', docs: 'https://www.binance.com/en/support/faq/360002502072', name: 'binance' },
    { displayName: 'Binance US', docs: 'https://www.binance.com/en/support/faq/360002502072', name: 'binanceus' },
    { displayName: 'Bit.com', docs: 'https://www.bit.com/propertyCenter/myApi', name: 'bit.com' },
    { displayName: 'Bitbns', docs: 'https://bitbns.com/trade/#/api-trading/', name: 'bitbns' },
    { displayName: 'Bitcoin.com', docs: 'https://fmfw.io/settings/api-keys', name: 'bitcoin.com' },
    { displayName: 'Bitcoin.de', docs: 'exampledocs', name: 'bitcoinde' },
    { displayName: 'Bitfinex', docs: 'https://support.bitfinex.com/hc/en-us/articles/115003363429-How-to-create-and-revoke-a-Bitfinex-API-Key', name: 'bitfinex' },
    { displayName: 'bitFlyer', docs: 'https://support.coinigy.com/hc/en-us/articles/360020668494-How-do-I-find-my-API-key-on-bitFlyer-com-', name: 'bitflyer' },
    { displayName: 'Bitfront', docs: 'https://github.com/lyingdragon/bitfront-api-docs/blob/master/1_Overview.md#prerequisites', name: 'bitfront' },
    { displayName: 'Bitget', docs: 'https://www.bitget.com/en/account/newapi', name: 'bitget' },
    { displayName: 'Bithumb', docs: 'https://en.bithumb.com/customer_support/info_guide?seq=2001&categorySeq=305', name: 'bithumb' },
    { displayName: 'BitMart', docs: 'https://faq.lukka.tech/en/articles/4785306-how-to-generate-api-keys-for-bitmart', name: 'bitmart' },
    { displayName: 'BitOasis', docs: 'https://api.bitoasis.net/doc/#tag/Authentication', name: 'bitoasis' },
    { displayName: 'Bitpanda', docs: 'https://support.bitpanda.com/hc/en-us/articles/360008139799-Where-can-I-find-documentation-and-troubleshooting-for-the-Bitpanda-Pro-API-', name: 'bitpanda' },
    { displayName: 'Bitrue', docs: 'https://www.bitrue.com/account/list_api', name: 'bitrue' },
    { displayName: 'Bitso', docs: 'https://help.bitso.com/en/support/solutions/articles/11000110568-how-do-i-set-up-my-api-keys-', name: 'bitso' },
    { displayName: 'Bitstamp', docs: 'https://help.kubera.com/article/63-how-do-i-create-bitstamp-api-key', name: 'bitstamp' },
    { displayName: 'Bittrex', docs: 'https://bittrex.zendesk.com/hc/en-us/articles/360031921872-How-to-create-an-API-key-', name: 'bittrex' },
    { displayName: 'Bitvavo', docs: 'https://support.bitvavo.com/hc/en-us/articles/4405059841809-What-are-API-keys-and-how-do-I-create-them-', name: 'bitvavo' },
    { displayName: 'BKEX', docs: 'https://bkexapi.github.io/docs/api_en.htm?shell#useQuick-1', name: 'bkex' },
    { displayName: 'BL3P', docs: 'https://bl3p.eu/api', name: 'bl3p' },
    { displayName: 'BTCBox', docs: 'https://support.btcbox.co.jp/hc/en-us/articles/360001889534-Get-an-API-Key', name: 'btcbox' },
    { displayName: 'BTCTurk', docs: 'https://docs.btcturk.com/authentication/authentication-v1', name: 'btcturk' },
    { displayName: 'Buda', docs: 'https://soporte.buda.com/es/articles/2805986-que-es-una-api-y-api-key', name: 'buda' },
    // { displayName: 'BW', docs: 'https://github.com/bw-exchange/api_docs_en', name: 'bw' },
    { displayName: 'Bybit', docs: 'https://help.bybit.com/hc/en-us/articles/360039749613-How-to-create-a-new-API-key-', name: 'bybit' },
    // { displayName: 'CDAX', docs: 'https://cdax.io/about/api/', name: 'cdax' },
    { displayName: 'Celsius', docs: 'https://tryargus.com', name: 'celsius' },
    { displayName: 'CEX.io', docs: 'https://support.cex.io/en/articles/4383493-api-setup-guide', name: 'cex.io' },
    { displayName: 'Changelly PRO', docs: 'https://pro.changelly.com/settings/api-keys', name: 'changellypro' },
    { displayName: 'Coinbase', docs: 'https://help.coinbase.com/en/exchange/managing-my-account/how-to-create-an-api-key', name: 'coinbase' },
    { displayName: 'Coinbase Pro', docs: 'https://help.coinbase.com/en/pro/other-topics/api/how-do-i-create-an-api-key-for-coinbase-pro', name: 'coinbasepro' },
    { displayName: 'CoinDCX', docs: 'https://support.coindcx.com/hc/en-gb/articles/360001804395-API-Trading-On-CoinDCX', name: 'coindcx' },
    { displayName: 'CoinEx', docs: 'https://support.coinex.com/hc/en-us/articles/900004316323-What-is-API-and-How-to-Set-it-Up-', name: 'coinex' },
    { displayName: 'CoinFalcon', docs: 'https://coinfalcon.com/settings/api-access', name: 'coinfalcon' },
    { displayName: 'CoinFLEX', docs: 'https://coinflex.com/support/5-1-1-creating-an-api/', name: 'coinflex' },
    { displayName: 'Coinmate', docs: 'https://coinmate.io/blog/en/using-the-coinmate-io-api/', name: 'coinmate' },
    { displayName: 'CoinMetro', docs: 'https://coinmate.docs.apiary.io/#introduction/security', name: 'coinmetro' },
    { displayName: 'Coinone', docs: 'https://coinone.co.kr/developer/app/', name: 'coinone' },
    // { displayName: 'Coinsbit', docs: 'https://coinsbit.io/user/api', name: 'coinsbit' },
    { displayName: 'CoinSpot', docs: 'https://www.coinspot.com.au/v2/api#:~:text=You%20can%20generate%20an%20API,minute%2C%20you%20can%20register%20here.', name: 'coinspot' },
    // { displayName: 'CoinW', docs: 'https://www.coinw.com/user/api.html', name: 'coinw' },
    { displayName: 'CREX24', docs: 'https://crex24.com/settings/tokens', name: 'crex24' },
    { displayName: 'Crypto.com', docs: 'https://help.crypto.com/en/articles/3511424-api', name: 'cryptocom' },
    { displayName: 'Currency.com', docs: 'https://currency.com/api-get-started#:~:text=To%20create%20your%20live%20API%20key%3A&text=Go%20to%20Settings%20%3E%20API%20integrations,Click%20on%20Generate%20Key%20button.', name: 'currency.com' },
    { displayName: 'DigiFinex', docs: 'https://digifinex.zendesk.com/hc/en-us/articles/900002055906--API-How-to-Establish-your-DigiFinex-API-Address-', name: 'digifinex' },
    { displayName: 'EQONEX', docs: 'https://help.eqonex.com/en/articles/3819423-api-access', name: 'eqonex' },
    { displayName: 'EXMO', docs: 'https://info.exmo.com/en/api/how-to-generate-an-api-key-pair/', name: 'exmo' },
    { displayName: 'FMFW', docs: 'https://fmfw.io/settings/api-keys', name: 'fmfw' },
    { displayName: 'FTX', docs: 'https://ftx.com/profile', name: 'ftx' },
    { displayName: 'FTX US', docs: 'https://ftx.com/profile', name: 'ftxus' },
    { displayName: 'Gate.io', docs: 'https://support.gate.io/hc/en-us/articles/900000114363-What-are-APIKey-and-APIV4keys-for-', name: 'gate.io' },
    { displayName: 'Gemini', docs: 'https://support.gemini.com/hc/en-us/articles/360031080191-How-do-I-create-an-API-key-#:~:text=API%20keys%20can%20be%20created,the%20role%20for%20the%20key.', name: 'gemini' },
    { displayName: 'Globe', docs: 'https://support.globedx.com/hc/en-gb/articles/360017039399-How-to-create-an-API-key', name: 'globe' },
    { displayName: 'HitBTC', docs: 'https://support.hitbtc.com/en/support/solutions/articles/63000225027-hitbtc-api-keys', name: 'hitbtc' },
    { displayName: 'HollaEx', docs: 'https://pro.hollaex.com/security', name: 'hollaex' },
    { displayName: 'Huobi Global', docs: 'https://www.hbg.com/zh-cn/apikey/', name: 'huobiglobal' },
    { displayName: 'Huobi Japan', docs: 'https://www.hbg.com/zh-cn/apikey/', name: 'huobijapan' },
    { displayName: 'IDEX', docs: 'https://exchange.idex.io/user/manage', name: 'idex' },
    { displayName: 'Independent Reserve', docs: 'https://www.independentreserve.com/products/api#authentication', name: 'independentreserve' },
    { displayName: 'Indodax', docs: 'https://indodax.com/trade_api', name: 'indodax' },
    { displayName: 'IndoEx', docs: 'https://international.indoex.io/api-management', name: 'indoex' },
    { displayName: 'itBit', docs: 'https://help.paxos.com/hc/en-us/articles/360042321451-What-types-of-capabilities-do-you-support-via-APIs-', name: 'itbit' },
    { displayName: 'Kraken', docs: 'https://support.kraken.com/hc/en-us/articles/360000919966-How-to-generate-an-API-key-pair-', name: 'kraken' },
    { displayName: 'KuCoin', docs: 'https://support.kucoin.plus/hc/en-us/articles/360015102174-How-to-Create-an-API-', name: 'kucoin' },
    { displayName: 'LBank', docs: 'https://docs.lbkex.net/en/#process', name: 'lbank' },
    { displayName: 'Liquid', docs: 'https://help.liquid.com/en/articles/2285018-how-to-create-api-tokens', name: 'liquid' },
    { displayName: 'Luno', docs: 'https://www.luno.com/en/developers/api#tag/Authentication', name: 'luno' },
    { displayName: 'Lykke', docs: 'https://support.lykke.com/hc/en-us/articles/360000552605-How-do-I-generate-an-API-KEY-Wallet-', name: 'lykke' },
    { displayName: 'Mandala', docs: 'https://docs.cryptohero.ai/connecting-exchanges/mandala', name: 'mandala' },
    { displayName: 'Matrix', docs: 'https://cdn.matrix.co/docs/index.html?_gl=1*1xj3bw2*_ga*MTgyMTQwMzM5LjE2NDMxOTMzNTM.*_ga_QN41R1KGMH*MTY0MzY0ODY1My4yLjAuMTY0MzY0ODY2MC4w#/', name: 'matrix' },
    { displayName: 'Mercado Bitcoin', docs: 'https://www.mercadobitcoin.com.br/api-doc/', name: 'mercadobitcoin' },
    { displayName: 'MEXC Global', docs: 'https://support.mexc.com/hc/en-001/articles/360055933652-MEXC-API-Opening-Tutorial#:~:text=Step%201%3A%20Enter%20MEXC%20official%20website.&text=Step%202%3A%20Choose%20%5BAPI%5D,Key%20after%20completing%20security%20validation.', name: 'mexcglobal' },
    { displayName: 'MidChains', docs: 'https://midchains.atlassian.net/servicedesk/customer/portal/3/article/47645498', name: 'midchains' },
    { displayName: 'NovaDAX', docs: 'https://doc.novadax.com/en-US/#welcome-to-the-novadax-api', name: 'novadax' },
    { displayName: 'Okcoin', docs: 'https://help.kubera.com/article/60-how-do-i-create-okcoin-api-key', name: 'okcoin' },
    { displayName: 'OKEx', docs: 'https://help.3commas.io/en/articles/3109057-okx-how-to-create-api-keys', name: 'okex' },
    // { displayName: 'P2PB2B', docs: 'https://p2pb2b.io/settings/api', name: 'p2pb2b' },
    { displayName: 'Phemex', docs: 'https://phemex.com/user-guides/how-do-i-create-an-api-key', name: 'phemex' },
    { displayName: 'Poloniex', docs: 'https://support.poloniex.com/hc/en-us/articles/360060622793-How-to-Create-an-API-Secret-Key-Set', name: 'poloniex' },
    { displayName: 'ProBit', docs: 'https://docs-en.probit.com/docs/managing-withdrawal-api', name: 'probit' },
    { displayName: 'STEX', docs: 'https://app.stex.com/en/profile/api/s2s', name: 'stex' },
    { displayName: 'Swyftx', docs: 'https://help.swyftx.com/en/articles/3825168-how-to-create-an-api-key', name: 'swyftx' },
    // { displayName: 'TideBit', docs: 'https://tidebit.zendesk.com/hc/en-us/articles/360004416594--App-How-to-login-logout-TideBit-mobile-App-', name: 'tidebit' },
    { displayName: 'Tidex', docs: 'https://tidex.com/exchange/public-api', name: 'tidex' },
    { displayName: 'TimeX', docs: 'https://docs.timex.io/my-profile.html', name: 'timex' },
    { displayName: 'Upbit', docs: 'https://docs.upbit.com/docs/faq', name: 'upbit' },
    { displayName: 'VCC Exchange', docs: 'https://vcc.exchange/account/createApiKey', name: 'vccexchange' },
    { displayName: 'Waves.Exchange', docs: 'https://docs.waves.exchange/en/waves-matcher/matcher-api', name: 'waves.exchange' },
    { displayName: 'WazirX', docs: 'https://wazirx.com/blog/create-wazirx-api-key/', name: 'wazirx' },
    { displayName: 'WOO Network', docs: 'https://kronosresearch.github.io/wootrade-documents/#authentication', name: 'woonetwork' },
    { displayName: 'Xena', docs: 'https://support.xena.exchange/support/solutions/articles/44001795004--managing-api-keys', name: 'xena' },
    { displayName: 'YoBit', docs: 'https://yobit.net/en/api/keys/', name: 'yobit' },
    { displayName: 'Zaif', docs: 'https://koinly.io/integrations/zaif/', name: 'zaif' },
    { displayName: 'ZB.com', docs: 'https://www.zb.com/api#FAQ', name: 'zb.com' },
    { displayName: 'Zipmex', docs: 'Nothing', name: 'zipmex' },
    { displayName: 'ZT', docs: 'https://zt-global.github.io/ztapi/#introduction', name: 'zt' },
];

export default exchange_info;