import HistoricHoldingListItem from "./HistoricHoldingListItem";
import { IHistoricHolding } from "./HistoricHoldingsSlice";
import SmartList from "../../components/SmartList";
import { ISorting } from "../../dashboard/DashboardSlice";
import { useMemo } from "react";
import { useSelector } from "react-redux";

/* Type for the AdminList props */
interface IListProps {
    holdings: Array<IHistoricHolding>;
    handleDelete(employee: string): void;
    setSorting: (sorting: ISorting) => void;
    sorting: ISorting;
    hideLastUpdated?: boolean;
}

function HistoricHoldingsList(props: IListProps): JSX.Element {
    /* Provide default values and replace them with the prop values if there are any. */
    const { holdings, sorting, setSorting, hideLastUpdated = false } = props;
    const version = useSelector((state: any) => state.auth.version);
    const headerData = useMemo(() => {
        let temp = [
            { fieldText: "Employee", fieldValue: "employee" },
            version === "crypto_base"
                ? { fieldText: "Account Holder", fieldValue: "accountHolder" }
                : { fieldText: "User", fieldValue: "user" },
        ];
        if (version === "crypto_base" || version === "crossover_base") {
            temp.push({ fieldText: "Account", fieldValue: "account" });
        }
        temp = temp.concat([
            { fieldText: "Holding Type", fieldValue: "holdingType" },
            { fieldText: "Ticker", fieldValue: "ticker" },
            //{ fieldText: "Restriction Group", fieldValue: "restrictionGroupName" },
            { fieldText: "Asset", fieldValue: "asset" },
            { fieldText: "Volume", fieldValue: "volume" },
        ]);

        if (!hideLastUpdated) {
            temp.push({ fieldText: "Last Updated", fieldValue: "lastUpdated" });
        }

        return temp;
    }, [version]);

    const generateItem = (trade: IHistoricHolding, index: number) => (
        <HistoricHoldingListItem
            employee={trade.employee}
            account={trade.account}
            security={trade.security}
            brokerage={trade.brokerage}
            holdingType={trade.holdingType}
            volume={trade.volume}
            user={trade.user}
            holdingDate={trade.holdingDate}
            lastUpdated={trade.lastUpdated}
            key={index}
            handleDelete={props.handleDelete}
            hideLastUpdated={hideLastUpdated}
        />
    );

    return (
        <SmartList
            headerData={headerData}
            arr={holdings}
            generateItem={generateItem}
            sorting={sorting}
            setSorting={setSorting}
        />
    );
}

export default HistoricHoldingsList;
