import { useEffect, useState } from "react";
import Attestation from "../components/Attestation";

interface IAttestationsProps {
    attestations: Array<string>;
    showErrors: boolean;
    setAllAttestationsComplete(complete: boolean): void
}

function Attestations({ attestations, showErrors, setAllAttestationsComplete }: IAttestationsProps): JSX.Element {

    /* Track which attestations have been certified */
    const [checked, setChecked] = useState<Array<boolean>>([]);

    /* Mark an attestation as certified, set all attestations complete/not complete if applicable*/
    const onCheck = (checkedAttestation: number) => {

        /* Toggle attestation checked/unchecked*/
        checked[checkedAttestation] = !checked[checkedAttestation];
        setChecked([...checked]);

        /* Test if all attestations complete, if any not, set allComplete false */
        for (let i = 0; i < attestations.length; i++) {
            if (checked[i] === false) {
                console.log("set complete to false")
                setAllAttestationsComplete(false);
                return
            }
        }

        console.log("reached")
        /* If all were complete, set to true*/
        setAllAttestationsComplete(true);
    }

    /* Initialize array of attestation states on load. */
    useEffect(() => {
        setChecked(attestations.map((attestation: string) => {
            return false;
        }))
    }, attestations)

    // useEffect(() => {
    //     setAllAttestationsComplete(true);
    // })


    return (
        <>
            {
                attestations.map((attestation: string, index: number) => {
                    return (
                        <>
                            {(showErrors && !checked[index]) && (
                                <p style={{ margin: "0", color: "red" }}>
                                    Please certify the following
                                </p>
                            )}
                            <Attestation
                                style={{ label: {}, attestation: {} }}
                                isChecked={checked[index]}
                                name=""
                                onCheck={(event) => {
                                    onCheck(index);
                                }}
                            >
                                {attestation}
                            </Attestation>
                        </>
                    )
                })
            }
        </>

    );
}

export default Attestations;