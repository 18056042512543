import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { nanoid } from "nanoid";
import { authLogin, authLogout, setIsAdmin } from "./AuthSlice";
import { StyledLoginBox } from "../components/StyledLoginBox";
import { StyledLoginPage } from "../components/StyledLoginPage";
import { StyledInputField } from "../components/StyledInputField";
import { StyledActionButton } from "../components/StyledActionButton";
import { StyledLinkButton } from "../components/StyledLinkButton";

function Login(): JSX.Element {
    const MS_SSO_ENDPOINT = process.env.REACT_APP_MS_SSO_ENDPOINT;
    const MS_SSO_ENDPOINT_ADMIN = process.env.REACT_APP_MS_SSO_ENDPOINT_ADMIN;

    /* Local state of input components */
    const [userText, setUserText] = useState("");
    const [passText, setPassText] = useState("");

    /* Local state for logging in as User vs Admin */
    const [isAdminLocal, setIsAdminLocal] = useState(false);

    /* Hooks for libraries, selectors */
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        dispatch(authLogout(false));
        dispatch(setIsAdmin(false));
    }, [dispatch]);

    const handleLogin = async (event: any) => {
        event.preventDefault();

        dispatch(
            authLogin({
                username: userText,
                password: passText,
                location: location,
                asAdmin: isAdminLocal,
            })
        );
    };

    const handleConnection = useCallback((auth0Url?: string) => {
        if (!auth0Url) {
            return auth0Url
        }

        const nonce = nanoid();
        const url = `${auth0Url}&nonce=${nonce}`;
        window.localStorage.setItem('nonce', nonce);
        window.location.href = url
    }, []);

    return (
        <StyledLoginPage>
            <StyledLoginBox>
                <div
                    style={{
                        height: "25%",
                        display: "flex",
                        justifyContent: "center",
                        borderBottom: "1px solid #F0F2FD",
                        boxSizing: "border-box",
                        fontSize: "40px",
                        lineHeight: "40px",
                    }}
                >
                    <p style={{ color: "#2C71F0", textAlign: "center" }}>Argus</p>
                </div>

                <h2
                    style={{
                        color: "#15357A",
                        textAlign: "center",
                        fontSize: "24px",
                        lineHeight: "28px",
                    }}
                >
                    Login
                </h2>
                <form onSubmit={handleLogin}>
                    <div>
                        <StyledInputField
                            style={{
                                display: "block",
                                margin: "auto",
                                marginBottom: "1%",
                            }}
                            placeholder="Email"
                            autoComplete="username"
                            onChange={(event) => {
                                setUserText(event.target.value);
                            }}
                        />
                        <StyledInputField
                            style={{
                                display: "block",
                                margin: "auto",
                                marginBottom: "2%",
                            }}
                            placeholder="Password"
                            autoComplete="current-password"
                            type="password"
                            onChange={(event) => {
                                setPassText(event.target.value);
                            }}
                        />
                        <StyledActionButton
                            style={{ display: "block", margin: "auto" }}
                        >
                            {" "}
                            Login{" "}
                        </StyledActionButton>
                    </div>
                </form>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "3%",
                        color: "#2C71F0",
                    }}
                >
                    <StyledLinkButton
                        style={{ marginBottom: "1.5%", fontSize: "16px" }}
                        onClick={(e) => {
                            e.preventDefault();
                            setIsAdminLocal(!isAdminLocal);
                        }}
                    >
                        {isAdminLocal ? "Login as User" : "Login as Admin"}
                    </StyledLinkButton>
                    <StyledActionButton 
                        style={{ width: "33%" }} 
                        onClick={() => handleConnection(!isAdminLocal ? MS_SSO_ENDPOINT : MS_SSO_ENDPOINT_ADMIN)}
                    >
                        Login with your SSO provider
                    </StyledActionButton>
                </div>
            </StyledLoginBox>
        </StyledLoginPage>
    );
}

export default Login;
