import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IInvestmentAccount } from "../accounts/AccountsSlice";
import { IUserExchangeAccount } from "../accounts/AccountsSlice";
import { IHistoricTrade } from "../transactions/HistoricTradesSlice";
import { dashboardService } from "../services/dashboardService";
import { tradeRequestService } from "../services/tradeRequestService";
import { ISecurity } from "../restrictions/RestrictionSlice";
import { IUser } from "../users/UserPanelSlice";
import { infoService } from "../services/infoService";
import { IPagination } from "../util/pagination";
import minDate from "../util/minDate";

export type Crypto = {
    id: string;
    ticker: string;
    name: string;
    platform: string;
    cryptocurrencyType: string;
};

export type PrivateAsset = {
    id: string;
    ticker: string;
    name: string;
    assetType: string;
    cryptoType: string;
};

export interface IReq {
    id: number;
    security?: ISecurity;
    crypto?: Crypto;
    privateAsset?: PrivateAsset;
    secondaryCrypto?: Crypto;
    transactionType: string;
    transactionSubtype: string;
    user: IUser;
    employee: string;
    volume: number;
    secondaryCryptoVolume: number;
    amount: number;
    secondaryCryptoAmount: number;
    currency: string;
    buySell: string;
    explanation: string;
    dateCreated: string;
    orderType: string;
    attestation: string;
    role: string;
    representations: { [key: string]: boolean };
    status: string;
    complianceNotes: string;
    subCategory: string;
    frequency: string;
    start: string;
}

interface SourceEvent {
    type: string;
}
export interface ICompliance {
    id: string;
    user: IUser;
    sourceEvent: SourceEvent;
    security?: ISecurity;
    newSecurity?: ISecurity;
    crypto?: Crypto;
    volume: string;
    investmentAccount: IInvestmentAccount;
    userExchangeAccount: IUserExchangeAccount;
    userWallet?: {
        walletType: string;
        accountHolder: string;
    };
    dateCreated: string;
    transactions: IHistoricTrade[];
    status: string;
    userNotes: JSON;
    complianceReviewUserName: string;
}

export interface IComplianceFilter {
    user: string;
    dateCreated: string;
    dateCreatedMin: string;
    dateCreatedMax: string;
    status: Array<string>;
}

export interface ITrade {
    id: number;
    transactionType: string;
    transactionSubtype: string;
    orderType: string;
    security?: ISecurity;
    crypto?: Crypto;
    privateAsset?: PrivateAsset;
    secondaryCrypto?: Crypto;
    currency: string;
    volume: string;
    secondaryCryptoVolume: number;
    amount: number;
    secondaryCryptoAmount: number;
    user: { name: string; id: string };
    status: string;
    approvedFrom: string;
    approvedUntil: string;
    complianceUser: { name: string; id: string };
    dateCreated: string;
    dateUpdated: string;
    representations: { [key: string]: boolean };
    complianceNotes: string;
    explanation: string;
    subCategory: string;
    frequency: string;
    start: string;
    end: string;
}

export interface IDashboard {
    reqs: Array<IReq>;
    clearedTrades: Array<ITrade>;
    allPendingTrades: Array<IReq>;
    pendingTrades: Array<ITrade>;
    closedTrades: Array<ITrade>;
    issues: Array<ICompliance>;
    nextPageClosed: boolean;
    nextPagePending: boolean;
    nextPageCleared: boolean;
    nextPageIssues: boolean;
    nextPageAllPending: boolean;
    nextPageTradeRequests: boolean;
    status: string;
}

export interface IFilter {
    user: string;
    transactionType: string;
    transactionSubtype: string;
    orderType: string;
    ticker: string;
    asset: string;
    account: string;
    volume: string;
    volumeMin: string;
    volumeMax: string;
    status: string;
    dateSubmitted: "";
    dateSubmittedMin: "";
    dateSubmittedMax: "";
    dateProcessed: "";
    dateProcessedMin: "";
    dateProcessedMax: "";
    approvedFrom: "";
    approvedFromMin: "";
    approvedFromMax: "";
    approvedUntil: "";
    approvedUntilMin: "";
    approvedUntilMax: "";
}

export type SortingField = string;
export type SortingDirection = "asc" | "desc";

export interface ISorting {
    field: SortingField;
    direction: SortingDirection;
}

const initialState: IDashboard = {
    reqs: [],
    clearedTrades: [],
    pendingTrades: [],
    allPendingTrades: [],
    closedTrades: [],
    nextPageCleared: false,
    nextPageClosed: false,
    nextPagePending: false,
    nextPageIssues: false,
    nextPageAllPending: false,
    nextPageTradeRequests: false,
    issues: [],
    status: "idle",
};

export const fetchComplianceIssues = createAsyncThunk(
    "dashboard/fetchComplianceIssues",
    async (payload: {
        pageNumber: number;
        filter: IComplianceFilter;
        sorting: ISorting[];
        pageSize?: number;
    }) => {
        const { pageNumber, sorting, filter, pageSize } = payload;
        return await dashboardService.getComplianceIssues(
            pageNumber,
            filter,
            sorting,
            pageSize
        );
    }
);

export const resolveComplianceIssue = createAsyncThunk(
    "dashboard/resolveComplianceIssue",
    async (payload: {
        issueId: string;
        complianceNotes: string;
        status: "MARKED" | "RESOLVED";
    }) => {
        const { issueId, complianceNotes, status } = payload;
        const issue = await dashboardService.resolveComplianceIssue(
            issueId,
            status,
            complianceNotes
        );

        return issue;
    }
);

export const fetchRequests = createAsyncThunk(
    "dashboard/fetchReqs",
    async (payload: {
        pagination: IPagination;
        filter: IFilter;
        sorting: ISorting[];
    }) => {
        const { pagination, filter, sorting } = payload;

        const reqs = await tradeRequestService.getReqs(pagination, filter, sorting);

        /* Handle errors */

        return reqs;
    }
);

export const fetchAllPendingTrades = createAsyncThunk(
    "dashboard/fetchAllPendingTrades",
    async (payload: { pagination: IPagination; sorting: ISorting[] }) => {
        const { pagination, sorting } = payload;
        const reqs = await tradeRequestService.getAllPendingTrades(pagination, sorting);
        /* Handle errors */

        return reqs;
    }
);

export const fetchClearedTrades = createAsyncThunk(
    "trade_reqs/fetchClearedTrades",
    async (payload: {
        userId: string;
        pagination: IPagination;
        sorting: ISorting[];
    }) => {
        const { userId, pagination, sorting } = payload;
        const clearedTrades = await tradeRequestService.getClearedTrades(
            userId,
            pagination,
            sorting
        );
        /* Handle errors */

        return clearedTrades;
    }
);

export const fetchPendingTrades = createAsyncThunk(
    "trade_reqs/fetchPendingTrades",
    async (payload: {
        userId: string;
        pagination: IPagination;
        sorting: ISorting[];
    }) => {
        const { userId, pagination, sorting } = payload;
        const pendingTrades = await tradeRequestService.getPendingTrades(
            userId,
            pagination,
            sorting
        );
        /* Handle errors */

        return pendingTrades;
    }
);

export const fetchClosedTrades = createAsyncThunk(
    "trade_reqs/fetchClosedTrades",
    async (payload: {
        userId: string;
        pagination: IPagination;
        sorting: ISorting[];
    }) => {
        const { userId, pagination, sorting } = payload;
        const closedTrades = await tradeRequestService.getClosedTrades(
            userId,
            pagination,
            sorting
        );
        /* Handle errors */

        return closedTrades;
    }
);

export const saveNewReq = createAsyncThunk(
    "trade_reqs/saveNewReq",
    async (payload: {
        volume: string;
        buySell: string;
        reason: string;
        securityId: string;
        cryptoId: string;
        privateAssetId: string;
        userId: string;
        orderType: string;
        amount?: string;
        currency?: string;
        secondaryCryptoId?: string;
        secondaryCryptoVolume: string;
        secondaryCryptoAmount: string;
        representativeJson: any;
        signer: string;
        isStandingOrder: boolean;
        frequency?: string;
        start?: string;
        end?: string;
    }) => {
        const {
            volume,
            buySell,
            orderType,
            reason,
            securityId,
            cryptoId,
            privateAssetId,
            userId,
            amount,
            currency,
            secondaryCryptoId,
            secondaryCryptoVolume,
            secondaryCryptoAmount,
            representativeJson,
            signer,
            isStandingOrder,
            frequency,
            start,
            end,
        } = payload; /* Unpack parameters */

        const data = await tradeRequestService.createReq(
            volume,
            buySell,
            orderType,
            reason,
            securityId,
            cryptoId,
            privateAssetId,
            userId,
            amount,
            currency,
            secondaryCryptoId,
            secondaryCryptoVolume,
            secondaryCryptoAmount,
            representativeJson,
            signer,
            isStandingOrder,
            frequency,
            start,
            end
        );

        return data;
    }
);

export const updateTradeStatus = createAsyncThunk(
    "trade_reqs/updateTradeStatus",
    async (payload: {
        id: number;
        duration: string;
        updateStatus: string;
        contentNotes: string;
        end?: string;
    }) => {
        const { id, duration, updateStatus, contentNotes, end } =
            payload; /* Unpack parameters */
        await tradeRequestService.updateTradeStatus(
            id,
            duration,
            updateStatus,
            contentNotes,
            end
        );

        /* Handle errors */
        return id;
    }
);

export const endStandingOrder = createAsyncThunk(
    "trade_reqs/endStandingOrder",
    async (payload: { id: number; end: string }) => {
        const { id, end } = payload; /* Unpack parameters */
        const response = await tradeRequestService.updateRequest(id, end);

        /* Handle errors */
        return { id, end: response.end };
    }
);

export const deleteReq = createAsyncThunk(
    "trade_reqs/deleteReq",
    async (id: number) => {
        await tradeRequestService.deleteReq(id);

        /* Handle errors */

        return id;
    }
);

export const createCryptoAsset = createAsyncThunk(
    "dashboard/saveNewCryptoAsset",
    async (
        payload: { blockchain: string; contractAddress: string },
        { rejectWithValue }
    ) => {
        const { blockchain, contractAddress } = payload; /* Unpack parameters */

        try {
            await infoService.createCryptoAsset(blockchain, contractAddress);
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder

            .addCase(fetchRequests.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(fetchRequests.fulfilled, (state, action) => {
                state.reqs = action.payload.data;
                state.nextPageTradeRequests = action.payload.nextPage;
                state.status = "idle";
            })
            .addCase(fetchAllPendingTrades.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(fetchAllPendingTrades.fulfilled, (state, action) => {
                state.allPendingTrades = action.payload.data;
                state.nextPageAllPending = action.payload.nextPage;
                state.status = "idle";
            })
            .addCase(fetchClearedTrades.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(fetchClearedTrades.fulfilled, (state, action) => {
                state.clearedTrades = action.payload.data;
                state.nextPageCleared = action.payload.nextPage;
                state.status = "idle";
            })
            .addCase(fetchPendingTrades.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(fetchPendingTrades.fulfilled, (state, action) => {
                state.pendingTrades = action.payload.data;
                state.nextPagePending = action.payload.nextPage;
                state.status = "idle";
            })
            .addCase(fetchClosedTrades.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(fetchClosedTrades.fulfilled, (state, action) => {
                state.closedTrades = action.payload.data;
                state.nextPageClosed = action.payload.nextPage;
                state.status = "idle";
            })
            .addCase(saveNewReq.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(saveNewReq.fulfilled, (state, action) => {
                state.reqs.push(action.payload);
            })
            .addCase(deleteReq.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(deleteReq.fulfilled, (state, action) => {
                state.status = "idle";
                state.reqs = state.reqs.filter((req) => req.id !== action.payload);
            })
            .addCase(updateTradeStatus.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(updateTradeStatus.fulfilled, (state, action) => {
                state.status = "idle";
                state.allPendingTrades = state.allPendingTrades.filter(
                    (req) => req.id !== action.payload
                );
            })
            .addCase(endStandingOrder.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(endStandingOrder.fulfilled, (state, action) => {
                state.status = "idle";
                state.reqs = state.reqs.map((req) => {
                    if (req.id === action.payload.id) {
                        return { ...req, end: new Date(action.payload.end) };
                    }
                    return req;
                });
            })
            .addCase(fetchComplianceIssues.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(fetchComplianceIssues.fulfilled, (state, action) => {
                state.status = "idle";
                state.issues = initialState.issues.concat(action.payload.data);
                state.nextPageIssues = action.payload.nextPage;
            })
            .addCase(resolveComplianceIssue.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(resolveComplianceIssue.fulfilled, (state, action) => {
                state.status = "idle";
                state.issues = state.issues.filter((issue) => {
                    return action.payload.id !== issue.id;
                });
            });
    },
});

/* Selector for state.watched array*/
export const selectReqs = (state: any) => state.dashboard.reqs;
export const selectAllPendingTrades = (state: any) => state.dashboard.allPendingTrades;
export const selectClearedTrades = (state: any) => state.dashboard.clearedTrades;
export const selectPendingTrades = (state: any) => state.dashboard.pendingTrades;
export const selectClosedTrades = (state: any) => state.dashboard.closedTrades;
export const selectIssues = (state: any) => state.dashboard.issues;

export default dashboardSlice.reducer;
