//The jsxRuntime pragma is necessary to get Emotion and React to work w/ latest Create-React-App version
/** @jsxRuntime classic */
/** @jsx jsx */
import styled from "@emotion/styled";

export const StyledAccordianTitle = styled.div`
    display: flex;
    color: white;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    background: #2c71f0;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding-left: 15px;
    padding-right: 15px;
    height: 47px;

    h2 {
        font-family: Lato, sans-serif;
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        line-height: 19px;
    }
`;
