//The jsxRuntime pragma is necessary to get Emotion and React to work w/ latest Create-React-App version
/** @jsxRuntime classic */
/** @jsx jsx */
import styled from "@emotion/styled";

export const StyledBrokerageDiv = styled.div`
    table {
        font-family: "Lato", sans-serif;
        width: 100%;
        background: #f0f2fd;
        font-size: 14px;
        text-align: center;
        color: #15357a;
        line-height: 17px;
        font-style: normal;
        font-weight: normal;
        margin-top: 1%;
        margin-bottom: 4%;
        border-collapse: collapse;
        border-radius: 5px;
        border-style: hidden; /* hide standard table (collapsed) border */
        box-shadow: 0 0 0 1px #e1ecff;
    }

    td,
    th {
        padding: 8px;
    }

    tr {
        height: 48px;
    }

    tr:nth-of-type(even) {
        background-color: #fafafa;
    }
`;
