import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { IAuth } from "../auth/AuthSlice";
import { attestationsService } from "../services/attestationsService";

export interface IAttestation {
    user: IAuth,
    attestationType: string,
    quarter: string,
    year: number,
}


const initialState: {
    entities: IAttestation[];
    status: string;
    loaded: boolean;
} = {
    entities: [],
    status: "idle",
    loaded: false,
};

export const fetchAttestations = createAsyncThunk(
    "attestations/fetchAttestations",
    async () => {
        return await attestationsService.getAttestations();
    }
);

export const fetchAdminAttestations = createAsyncThunk(
    "attestations/fetchAdminAttestations",
    async (id?: string) => {
        return await attestationsService.getAdminAttestations(id);
    }
);


export const saveNewAttestation = createAsyncThunk(
    "attestations/createAttestation",
    async (payload: {
        quarter: string,
        year: number,
        signature: string
    }) => {
        const { quarter, year, signature } = payload;
        const res = await attestationsService.createAttestation(
            quarter,
            year,
            signature
        );

        return res;
    }
);

const attestationsSlice = createSlice({
    name: "attestions",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAttestations.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(fetchAttestations.fulfilled, (state, action) => {
                state.entities = action.payload.data;
                state.status = "idle";
                state.loaded = true;
            })
            .addCase(fetchAdminAttestations.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(fetchAdminAttestations.fulfilled, (state, action) => {
                state.entities = action.payload.data;
                state.status = "idle";
                state.loaded = true;
            })
            .addCase(saveNewAttestation.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(saveNewAttestation.fulfilled, (state, action) => {
                state.entities.push(action.payload.data);
                state.status = "idle";
                state.loaded = true;
            })
    },
});

/* Selector for state.entities array */
export const selectAttestations = (state: any) => state.attestations.entities;

export default attestationsSlice.reducer;