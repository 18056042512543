import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StyledActionButton } from "../components/StyledActionButton";
import { StyledSearchInput } from "../components/StyledFilterInput";
import { StyledFilterValue } from "../components/StyledFilterValue";
import { StyledModalItem } from "../components/StyledModalItem";
import { StyledSectionHeader } from "../components/StyledSectionHeader";
import { StyledSelect } from "../components/StyledSelect";
import TradesList from "./TradesList";
import StandingOrderList from "./StandingOrderList";
import { fetchRequests, ISorting, selectReqs } from "./DashboardSlice";
import redcross from "../assets/redcross.svg";
import handleDownload from "../util/downloadCsv";
import TablePageFooter, { DefaultPagination } from "../components/TablePageFooter";
import { tradeRequestService } from "../services/tradeRequestService";
import { IPagination } from "../util/pagination";

function TradeRequests(): JSX.Element {
    const dispatch = useDispatch();
    const trades = useSelector(selectReqs);

    const defaultFilters = {
        user: "",
        transactionType: "",
        transactionSubtype: "",
        orderType: "",
        ticker: "",
        asset: "",
        volume: "",
        volumeMin: "",
        volumeMax: "",
        status: "",
        dateSubmitted: "",
        dateSubmittedMin: "",
        dateSubmittedMax: "",
        dateProcessed: "",
        dateProcessedMin: "",
        dateProcessedMax: "",
        approvedFrom: "",
        approvedFromMin: "",
        approvedFromMax: "",
        approvedUntil: "",
        approvedUntilMin: "",
        approvedUntilMax: "",
    };
    const [pagination, setPagination] = useState<IPagination>(DefaultPagination());
    const nextPage = useSelector((state: any) => state.dashboard.nextPageTradeRequests);
    const [filterMultiple, setFilterMultiple] = useState(defaultFilters) as any;
    const [filter, setFilter] = useState("user");
    const [search, setSearch] = useState("");
    const [startSearch, setStartSearch] = useState("");
    const [endSearch, setEndSearch] = useState("");
    const [rangeBasedFiltering, setRangeBasedFiltering] = useState(false);
    const [sorting, setSorting] = useState<ISorting>({
        field: "dateSubmitted",
        direction: "desc",
    });

    const company = useSelector((state: any) => state.auth.companyName);
    const [standingOrderToggle, setStandingOrderToggle] = useState(false)

    const sortingByDate: ISorting[] = useMemo(() => {
        if (sorting.field === "dateSubmitted") return [sorting];
        return [sorting, { field: "dateSubmitted", direction: "desc" }];
    }, [sorting]);

    const dateTypes = [
        "dateSubmitted",
        "dateProcessed",
        "approvedFrom",
        "approvedUntil",
    ];

    useEffect(() => {
        dispatch(
            fetchRequests({
                pagination,
                filter: {...filterMultiple, subCategory: standingOrderToggle ? "STANDING_ORDER" : null},
                sorting: sortingByDate,
            })
        );
    }, [dispatch, pagination, filterMultiple, sortingByDate, standingOrderToggle]);

    function handleKeyDown(event: any) {
        if (event.key === "Enter") {
            filterMultiple[filter] = search;
            setFilterMultiple({ ...filterMultiple });
            setSearch("");
        }
    }

    function handleOnClick() {
        setFilterMultiple(defaultFilters);
    }

    function removeFilter(txt: string) {
        filterMultiple[txt] = "";
        setFilterMultiple({ ...filterMultiple });
    }

    function handleRangeSearch() {
        if (startSearch !== "" && endSearch !== "") {
            filterMultiple[filter + "Min"] = startSearch;
            filterMultiple[filter + "Max"] = endSearch;
            setFilterMultiple({ ...filterMultiple });
            setStartSearch("");
            setEndSearch("");
        }
    }

    const handleDeleteTrade = (employee: string) => {
        return;
    };

    return (
        <div>
            {(company === "Fargus" || company === "Argus" || company === "ArgusTest" || company === "Paradigm") &&
            <StyledActionButton
                style={{ width: "20%", marginBottom: 20 }}
                onClick={() => {
                    setFilterMultiple(defaultFilters)
                    setPagination({...pagination, pageNumber: 1})
                    setStandingOrderToggle(!standingOrderToggle)
                }}
            >
                {standingOrderToggle ? "Show Trade Requests" : "Show Standing Orders"}
            </StyledActionButton>}
            <div style={{ display: "flex", flexDirection: "row" }}>
                <StyledModalItem style={{ display: "flex", width: "50%" }}>
                    <StyledSelect
                        id="filterValue"
                        name="filterValue"
                        onChange={(event) => {
                            setFilter(event.target.value);
                            setRangeBasedFiltering(false);
                        }}
                    >
                        <option value="user">Employee</option>
                        <option value="transactionType">Transaction Type</option>
                        <option value="orderType">Order Type</option>
                        <option value="ticker">Ticker</option>
                        <option value="asset">Asset</option>
                        <option value="account">Account</option>
                        <option value="volume">Volume</option>
                        <option value="status">Status</option>
                        <option value="dateSubmitted">Date Submitted</option>
                        <option value="dateProcessed">Date Processed</option>
                        <option value="approvedFrom">Approved From</option>
                        <option value="approvedUntil">Approved Until</option>
                    </StyledSelect>
                </StyledModalItem>

                <div style={{ width: "50%" }}>
                    {!rangeBasedFiltering ? (
                        <>
                            <StyledSearchInput
                                placeholder="Search Trade Requests"
                                type={dateTypes.includes(filter) ? "date" : "text"}
                                value={search}
                                onKeyDown={handleKeyDown}
                                onChange={(event) => setSearch(event.target.value)}
                                style={{ display: "flex" }}
                            />
                            {dateTypes.includes(filter) || filter === "volume" ? (
                                <div
                                    onClick={() => setRangeBasedFiltering(true)}
                                    style={{
                                        textAlign: "right",
                                        fontSize: "12px",
                                        textDecoration: "underline",
                                        color: "#2c71f0",
                                        cursor: "pointer",
                                    }}
                                >
                                    Add range based filter
                                </div>
                            ) : null}
                        </>
                    ) : (
                        <>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <StyledSearchInput
                                    placeholder="Start Filter"
                                    type={dateTypes.includes(filter) ? "date" : "text"}
                                    value={startSearch}
                                    onChange={(event) =>
                                        setStartSearch(event.target.value)
                                    }
                                    style={{ display: "flex", width: "35%" }}
                                />
                                &nbsp;_&nbsp;
                                <StyledSearchInput
                                    placeholder="End Filter"
                                    type={dateTypes.includes(filter) ? "date" : "text"}
                                    value={endSearch}
                                    onChange={(event) =>
                                        setEndSearch(event.target.value)
                                    }
                                    style={{ display: "flex", width: "35%" }}
                                />
                                &nbsp;&nbsp;
                                <StyledActionButton
                                    onClick={handleRangeSearch}
                                    style={{ width: "20%" }}
                                >
                                    Enter
                                </StyledActionButton>
                            </div>
                            {dateTypes.includes(filter) || filter === "volume_max" ? (
                                <div
                                    onClick={() => setRangeBasedFiltering(false)}
                                    style={{
                                        textAlign: "right",
                                        fontSize: "12px",
                                        textDecoration: "underline",
                                        color: "#2c71f0",
                                        cursor: "pointer",
                                    }}
                                >
                                    Cancel range based filtering
                                </div>
                            ) : null}
                        </>
                    )}
                </div>

                <StyledActionButton
                    style={{ width: "20%", marginLeft: "6%" }}
                    onClick={handleOnClick}
                >
                    Clear Filters
                </StyledActionButton>
            </div>

            <div style={{ display: "flex", flexDirection: "row" }}>
                {Object.keys(filterMultiple).map((txt: any, index: any) => (
                    <>
                        {filterMultiple[txt] !== "" ? (
                            <StyledFilterValue key={index}>
                                &nbsp;&nbsp;&nbsp;{txt}: {filterMultiple[txt]}
                                <img
                                    style={{ marginRight: "4px", cursor: "pointer" }}
                                    src={redcross}
                                    alt=""
                                    onClick={() => removeFilter(txt)}
                                />
                            </StyledFilterValue>
                        ) : null}
                    </>
                ))}
            </div>

            <StyledSectionHeader>All {standingOrderToggle ? "Standing Orders" : "Trade Requests"}</StyledSectionHeader>
            <div
                onClick={() =>
                    handleDownload(
                        tradeRequestService.getTradeReqsDownload(
                            filterMultiple,
                            sortingByDate
                        ),
                        "trade_reqs"
                    )
                }
                style={{
                    textAlign: "right",
                    fontSize: "15px",
                    textDecoration: "underline",
                    color: "#2c71f0",
                    cursor: "pointer",
                }}
            >
                Download trade requests
            </div>
            <div>
                {standingOrderToggle && <StandingOrderList
                    trades={trades}
                    setSorting={setSorting}
                    sorting={sorting}
                    handleDelete={handleDeleteTrade}
                    tradeType={"all"}
                />}
                {!standingOrderToggle && <TradesList
                    trades={trades}
                    setSorting={setSorting}
                    sorting={sorting}
                    handleDelete={handleDeleteTrade}
                    tradeType={"all"}
                />}
            </div>
            <TablePageFooter
                nextPage={nextPage}
                pagination={pagination}
                setPageNumber={(n) =>
                    setPagination({ ...pagination, pageNumber: n })
                }
                setPageSize={(s) =>
                    setPagination({ ...pagination, pageSize: s })
                }
            />
        </div>
    );
}

export default TradeRequests;
