//The jsxRuntime pragma is necessary to get Emotion and React to work w/ latest Create-React-App version
/** @jsxRuntime classic */
/** @jsx jsx */
import styled from "@emotion/styled";

export const StyledListNav = styled.nav`
    ul {
        font-family: "Lato", sans-serif;
        font-weight: normal;
        font-style: normal;
        color: #15357a;
        list-style-type: none;
        margin: none;
        padding: 0;
        a {
            text-decoration: none;
        }
    }
`;
