import { useEffect, useMemo, useState } from "react";
import { infoService } from "../services/infoService";
import DynamicAutoComplete, { IDynamicAutoComplete } from "./DynamicAutoComplete";

export type CryptoSuggestion = {
    id: string;
    ticker: string;
    name: string;
    type: "CRYPTO" | "PRIVATE_ASSET";
    cryptocurrencyType: string;
    pricingProvider: string;
    hasPrice: boolean;
    platform: string;
};

function CryptoAutoComplete(
    props: IDynamicAutoComplete<CryptoSuggestion>
): JSX.Element {
    const [restricted, setRestricted] = useState(false);

    useEffect(() => {
        if (props.filteredSuggestions !== undefined) {
            if (props.filteredSuggestions) {
                setRestricted(true);
            } else {
                setRestricted(false);
            }
        }
    }, [props]);

    const formatAutoComplete = useMemo(() => {
        return (suggestion: CryptoSuggestion) => {
            const showType =
                suggestion.cryptocurrencyType &&
                suggestion.cryptocurrencyType === "NFT";
            const showPlatform =
                suggestion.platform  &&
                suggestion.cryptocurrencyType !== "CURRENCY";
            const showPrivate = suggestion.type === "PRIVATE_ASSET"
            const showTypeBox = showType || showPlatform || showPrivate;

            return (
                suggestion.name +
                " - " +
                suggestion.ticker +
                (showTypeBox ? " [" : "") +
                (showType
                    ? suggestion.cryptocurrencyType + (showPlatform ? " - " : "")
                    : "") +
                (showPlatform ? suggestion.platform + (showPrivate ? " - " : ""): "") +
                (showPrivate ? "PRIVATE" : "") +
                (showTypeBox ? "]" : "")
            );
        };
    }, []);

    const autoComplete = useMemo(() => {
        return async (input: string) => {
            const response = await infoService.autocompleteCrypto(input);
            return response.data
        };
    }, []);

    const autoCompleteRestricted = useMemo(() => {
        return async (input: string) => {
            const response = await infoService.autocompleteRestrictedCrypto(input);
            return response.data
        };
    }, []);

    return (
        <DynamicAutoComplete<CryptoSuggestion>
            {...props}
            formatAutoComplete={formatAutoComplete}
            autoComplete={restricted ? autoCompleteRestricted : autoComplete}
        />
    );
}

export default CryptoAutoComplete;
