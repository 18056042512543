//The jsxRuntime pragma is necessary to get Emotion and React to work w/ latest Create-React-App version
/** @jsxRuntime classic */
/** @jsx jsx */
import styled from "@emotion/styled";

export const StyledModalBackground = styled.div`
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

    // -webkit-animation-name: fadeIn; /* Fade in the background */
    // -webkit-animation-duration: 0.4s;
    // animation-name: fadeIn;
    // animation-duration: 0.4s;

    // /* Add Animation */
    // @-webkit-keyframes slideIn {
    //     from {
    //         bottom: -300px;
    //         opacity: 0;
    //     }
    //     to {
    //         bottom: 0;
    //         opacity: 1;
    //     }
    // }

    // @keyframes slideIn {
    //     from {
    //         bottom: -300px;
    //         opacity: 0;
    //     }
    //     to {
    //         bottom: 0;
    //         opacity: 1;
    //     }
    // }

    // @-webkit-keyframes fadeIn {
    //     from {
    //         opacity: 0;
    //     }
    //     to {
    //         opacity: 1;
    //     }
    // }

    // @keyframes fadeIn {
    //     from {
    //         opacity: 0;
    //     }
    //     to {
    //         opacity: 1;
    //     }
    // }
`;
