import { useEffect, useMemo, useRef, useState } from "react";
import { StyledSectionHeader } from "../components/StyledSectionHeader";
import { StyledInputField } from "../components/StyledInputField";
import { StyledActionButton } from "../components/StyledActionButton";
import CryptoAutoComplete from "../components/CryptoAutoComplete";
import { StyledLabel } from "../components/StyledLabel";
import { StyledSelectField } from "../components/StyledSelectField";
import { transactionsService } from "../services/transactionsService";
import { StyledModalBackground } from "../components/StyledModalBackground";
import { StyledModalBody } from "../components/StyledModalBody";
import { BiX } from "react-icons/bi";
import capitalizeFirstLetter from "../util/capitalizeFirstLetter";
import trashcan from "../assets/trashcan.svg";
import { toast } from "react-toastify";


interface ManualTransactionUploadModalProps {
    userExchangeAccountId?: string,
    userExchangeAccountName?: string,
    userWalletId?: string,
    userWalletName?: string,
    onHide: () => void,

}

const ManualTransactionUploadModal: React.FC<ManualTransactionUploadModalProps> = ({
    userExchangeAccountId,
    userExchangeAccountName,
    userWalletId,
    userWalletName,
    onHide
}) => {

    const [transactionType, setTransactionType] = useState("BUY")
    const [cryptoId, setCryptoId] = useState<string>("")
    const [searchCrypto, setSearchCrypto] = useState<string>("")
    const [volume, setVolume] = useState(0)
    const [price, setPrice] = useState(0)
    const [transactionDate, setTransactionDate] = useState<string>("")
    const [transactionTime, setTransactionTime] = useState<string>("")
    const [transactions, setTransactions] = useState<any[]>([])

    useEffect(() => {
        transactionsService.getManualTransaction(userExchangeAccountId, userWalletId).then((response: any) => {
            setTransactions(response.data)
        })
    }, [userExchangeAccountId, userWalletId])

    const date = useMemo(() => {
        if (transactionDate !== "" && transactionTime !== "") {
            const [year, month, day] = transactionDate.split('-')
            const [hour, minute] = transactionTime.split(':')

            const args = [parseInt(year), parseInt(month), parseInt(day), parseInt(hour), parseInt(minute)]
            if (args.reduce((prev, curr) => prev && typeof (curr) === 'number' && !isNaN(curr), true)) {
                const offset = new Date().getTimezoneOffset();
                const hourOffset = Math.floor((offset / 60))
                const minuteOffset = offset % 60
                const date = new Date(Date.UTC(parseInt(year), parseInt(month) - 1, parseInt(day), parseInt(hour) + hourOffset, parseInt(minute) + minuteOffset))
                return date.toISOString()
            }
        }
        return ''
    }, [transactionDate, transactionTime])


    const errorMessage = useMemo(() => {
        return {
            transactionType: transactionType === '',
            cryptoId: cryptoId === '',
            transactionDate: transactionDate === "",
            transactionTime: transactionTime === "",
        }

    }, [transactionType, cryptoId, transactionDate, transactionTime])

    const [showError, setShowError] = useState(false)

    const onSubmit = useMemo(() => {
        return () => {
            let ableToSubmit = true
            for (let error of Object.values(errorMessage)) {
                if (error === true) {
                    setShowError(true)
                    ableToSubmit = false
                }
            }
            if (ableToSubmit) {
                transactionsService.uploadManualTransaction(
                    "CRYPTO_MANUAL_UPLOAD",
                    cryptoId,
                    volume,
                    price,
                    date.replace("Z", ''),
                    transactionType,
                    userExchangeAccountId,
                    userWalletId,
                ).then((transaction: any) => {
                    console.log(transaction)
                    setTransactions([...transactions, transaction].sort((a, b) => (new Date(b.transactionDate)).getTime() - (new Date(a.transactionDate)).getTime()))
                })
                setCryptoId("")
                setSearchCrypto("")
                setTransactionDate("")
                setTransactionTime("")
                setVolume(0)
                setPrice(0)
            }
        }
    }, [errorMessage, cryptoId, volume, date, transactionType, userExchangeAccountId, transactions])

    const hiddenFileInput = useRef<HTMLInputElement>(null);

    const handleClick = (event: any) => {
        if (hiddenFileInput && hiddenFileInput.current) {
            hiddenFileInput.current.click();
        }
    };
    const handleChange = (event: any) => {
        const formData = new FormData();
        formData.append("data", event.target.files[0]);
        let fileName = event.target.files[0].name;

        if (fileName.substring(fileName.length - 4) === ".csv" && userExchangeAccountId) {
            transactionsService.uploadCSVExchangeStatement(
                userExchangeAccountId,
                formData
            ).then((response: any) => {
                toast.success("CSV successfully uploaded. You'll see data populate within 24 hours.", {
                    position: "top-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

            })
        } else if (fileName.substring(fileName.length - 4) === ".csv" && userWalletId) {
            transactionsService.uploadCSVWalletStatement(
                userWalletId,
                formData
            ).then((response: any) => {
                toast.success("CSV successfully uploaded. You'll see data populate within 24 hours.", {
                    position: "top-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

            })
        } else {
            toast.error("Please upload a valid csv.", {
                position: "top-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

        }

    };


    return (
        <StyledModalBackground
            style={{ visibility: "visible" }}
        >
            <StyledModalBody
                style={{
                    visibility: "visible",
                    height: "90%",
                    width: "90%",
                    marginTop: "2.5%",
                    marginLeft: "5%",
                }}
                onClick={(e) => { }}
            >
                <div style={{ width: '100%' }}>
                    <div
                        style={{
                            width: "100%",
                            height: "50px",
                            background: "#2C71F0",
                            color: "#FFFFFF",
                            fontWeight: "bold",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            boxSizing: "border-box",
                            borderTopRightRadius: "5px",
                            borderTopLeftRadius: "5px",
                            margin: 0,
                            padding: 0,
                            zIndex: 1,
                        }}
                    >
                        {userExchangeAccountName ? userExchangeAccountName : userWalletName}
                        <BiX
                            onClick={() => {
                                onHide()
                            }}
                            style={{
                                position: "absolute",
                                right: "10px",
                                cursor: "pointer",
                            }}
                        />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 50 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <StyledSectionHeader>Upload CSV</StyledSectionHeader>
                            <p style={{ textAlign: 'center' }}>
                                <strong>Please upload CSV in the following format:</strong><br />
                                ticker, asset, transaction type (buy, sell, call, put, stake, unstake, supply liquidity, remove liquidity), volume, value, date (MM/DD/YYYY), time (HH:MM XM)<br />
                                Please note: do not include negative numbers, and do include fees in withdrawals but not in deposits.
                            </p>
                            <div style={{
                                borderColor: "#75a4fa",
                                borderWidth: 2,
                                borderStyle: 'dashed',
                                borderRadius: 10,
                                width: 300,
                                height: 100,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginTop: 10
                            }}>

                                <StyledActionButton style={{}} onClick={handleClick}>
                                    Select file
                                </StyledActionButton>
                                <input
                                    type="file"
                                    ref={hiddenFileInput}
                                    onChange={handleChange}
                                    style={{ display: 'none' }}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ padding: "5%" }}>
                        <StyledSectionHeader>Add unsupported Transaction</StyledSectionHeader>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ width: '15%' }}>
                                <StyledLabel>Crypto</StyledLabel>
                                <CryptoAutoComplete
                                    style={{ width: "100%" }}
                                    dropdownStyle={{ width: '20%' }}
                                    placeholder="Select Crypto ID"
                                    onChange={setSearchCrypto}
                                    value={searchCrypto}
                                    onSelect={(value) => {
                                        if (value) {
                                            setCryptoId(value.id)
                                            setShowError(false)
                                        }
                                    }}
                                />
                                {showError && errorMessage["cryptoId"] && <StyledLabel style={{ color: 'red' }}>Select Crypto</StyledLabel>}
                            </div>
                            <div style={{ width: "15%" }}>
                                <StyledLabel>Transaction Type</StyledLabel>
                                <StyledSelectField
                                    style={{ width: "100%" }}
                                    placeholder="Select Transaction Type"
                                    value={transactionType}
                                    onChange={(event) => {
                                        setTransactionType(event.target.value)
                                        setShowError(false)
                                    }}
                                >
                                    <option value="BUY">
                                        Buy
                                    </option>
                                    <option value="SELL">
                                        Sell
                                    </option>
                                    <option value="BUY_OPTION">
                                        Call
                                    </option>
                                    <option value="SELL_OPTION">
                                        Put
                                    </option>
                                    <option value="STAKE">Stake</option>
                                    <option value="UNSTAKE">Unstake</option>
                                    <option value="STAKE_REWARD">Stake Reward</option>
                                    <option value="SUPPLY_LIQUIDITY">Supply Liquidity</option>
                                    <option value="REMOVE_LIQUIDITY">Remove Liquidity</option>
                                    <option value="REWARD">Reward</option>
                                </StyledSelectField>
                                {showError && errorMessage["transactionType"] && <StyledLabel style={{ color: 'red' }}>Select Transaction Type</StyledLabel>}
                            </div>
                            <div style={{ width: "10%" }}>
                                <StyledLabel>Volume</StyledLabel>
                                <StyledInputField
                                    placeholder="0"
                                    type="number"
                                    value={volume}
                                    step={"any"}
                                    onChange={(event) => {
                                        setVolume(parseFloat(event.target.value))
                                    }}
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div style={{ width: "10%" }}>
                                <StyledLabel>Value ($)</StyledLabel>
                                <StyledInputField
                                    placeholder="0"
                                    type="number"
                                    value={price}
                                    step={"any"}
                                    onChange={(event) => {
                                        setPrice(parseFloat(event.target.value))
                                    }}
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div style={{ width: "15%" }}>
                                <StyledLabel>Date</StyledLabel>
                                <StyledInputField
                                    type={'date'}
                                    value={transactionDate}
                                    onChange={(event) => {
                                        setTransactionDate(event.target.value)
                                        setShowError(false)
                                    }}
                                    style={{ width: '100%' }}
                                />
                                {showError && errorMessage["transactionDate"] && <StyledLabel style={{ color: 'red' }}>Select Transaction Date</StyledLabel>}
                            </div>
                            <div style={{ width: "10%" }}>
                                <StyledLabel>Time</StyledLabel>
                                <StyledInputField
                                    type={'time'}
                                    value={transactionTime}
                                    onChange={(event) => {
                                        setShowError(false)
                                        setTransactionTime(event.target.value)
                                    }}
                                    style={{ width: '100%' }}
                                />
                                {showError && errorMessage["transactionTime"] && <StyledLabel style={{ color: 'red' }}>Select Transaction Time</StyledLabel>}
                            </div>

                            <StyledActionButton style={{ flexWrap: 'nowrap', whiteSpace: 'nowrap', boxSizing: "border-box", marginTop: "40px", flex: 1 }} onClick={onSubmit}>
                                Add Transaction
                            </StyledActionButton>
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        Ticker
                                    </th>
                                    <th>
                                        Asset
                                    </th>
                                    <th>
                                        Transaction Type
                                    </th>
                                    <th>
                                        Volume
                                    </th>
                                    <th>
                                        Transaction Date
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {transactions.map((transaction: any) => (
                                    <tr key={transaction.id}>
                                        <td>{transaction.security.ticker}</td>
                                        <td>{transaction.security.name}</td>
                                        <td>{capitalizeFirstLetter(transaction.transactionType)}</td>
                                        <td>{transaction.volume}</td>
                                        <td>{new Date(transaction.transactionDate).toLocaleDateString()}</td>
                                        <td>
                                            <button
                                                style={{
                                                    background: "transparent",
                                                    border: "none",
                                                    cursor: "pointer",
                                                }}
                                                onClick={(e) => {
                                                    transactionsService.deleteManualTransaction(transaction.id)
                                                    setTransactions(transactions.filter(t => t.id !== transaction.id))
                                                }}
                                            >
                                                <img src={trashcan} alt="" />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </StyledModalBody>
        </StyledModalBackground>
    );
}

export default ManualTransactionUploadModal;