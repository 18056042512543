import { useEffect, useState } from "react";
import { StyledModalBackground } from "../components/StyledModalBackground";
import { StyledFastlinkBody } from "../components/StyledFastlinkBody";
import { accountsService } from "../services/accountsService";
import { authService } from "../services/authService";

declare let window: any;

export interface IYodleeToken {
    accessToken: string;
    expiresIn: number;
    issuedAt: string;
}

interface IFastLinkProps {
    accessToken: IYodleeToken;
    providerId: number;
    onHide: () => void
    onSuccess: () => void
}


function FastLink(props: IFastLinkProps) {
    const { accessToken, providerId, onHide, onSuccess } = props;

    const [open] = useState(false)

    useEffect(() => {
        window.fastlink.open(
            {
                fastLinkURL: process.env.REACT_APP_FASTLINK_URL,
                accessToken: "Bearer " + accessToken.accessToken,
                params: {
                    providerId: providerId,
                    flow: "add",
                    configName: "Aggregation",
                },
                onSuccess,
                onError: function (data: any) {
                    // will be called on error. For list of possible message, refer to onError(data) Method.
                    if (data.message === 'Provider not supported') {
                        authService.sendFrontendError({ error: data, providerId: providerId })
                    }
                    console.log(data);
                },
                onClose: function (data: any) {
                    console.log(data);
                    window.fastlink.close();
                    onHide()
                },
                onEvent: function (data: any) {
                    // will be called on intermittent status update.
                    console.log(data);
                },
            },
            "container-fastlink"
        );
    }, [accessToken, onHide, providerId, open, onSuccess]);

    return (
        <StyledModalBackground
            onClick={(e) => {
                window.fastlink.close();
                onHide()
            }}
        >
            <StyledFastlinkBody
    id={"container-fastlink"}
    />
        </StyledModalBackground>
    );
}

export default FastLink;
