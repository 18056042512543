import { SortingDirection, SortingField } from "../../dashboard/DashboardSlice";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { designatedUsersService } from "../../services/designatedUsersService";

export interface ISecondaryUser {
    id: string;
    email: string;
    name: string;
    role: string;
    group: string;
    status: string;
    account: Array<{ account: string; lastUpdated: string; accountHolder: string }>;
}

export interface IUserProfile {
    secondaryUsers: Array<ISecondaryUser>;
    nextPage: boolean;
    status: string;
}

const initialState: IUserProfile = {
    secondaryUsers: [],
    nextPage: false,
    status: "idle",
};

export interface ISorting {
    field: SortingField;
    direction: SortingDirection;
}

export const deleteSecondaryUser = createAsyncThunk(
    "userProfile/deleteSecondaryUser",
    async (secondaryUserId: string) => {
        return await designatedUsersService.deleteDesignatedUser(secondaryUserId);
    }
);

export const createSecondaryUser = createAsyncThunk(
    "userProfile/createSecondaryUser",
    async (name: string) => {
        return await designatedUsersService.createDesignatedUser(name);
    }
);

export const createProxyUser = createAsyncThunk(
    "userProfile/createProxyUser",
    async (payload: { name: string, email: string, employeeId: string }) => {
        return await designatedUsersService.createProxyUser(payload.name, payload.email, payload.employeeId);
    }
);

export const fetchSecondaryUsers = createAsyncThunk(
    "userProfile/fetchSecondaryUsers",
    async () => {
        return await designatedUsersService.getSecondaryUsers();
    }
);

const userProfileSlice = createSlice({
    name: "userProfile",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSecondaryUsers.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(fetchSecondaryUsers.fulfilled, (state, action) => {
                state.secondaryUsers = action.payload.data;
                state.nextPage = action.payload.nextPage;
                state.status = "idle";
            });
    },
});

/* User profile selectors */
export const selectSecondaryUsers = (state: any) => state.userProfile.secondaryUsers;

export default userProfileSlice.reducer;
