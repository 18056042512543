//The jsxRuntime pragma is necessary to get Emotion and React to work w/ latest Create-React-App version
/** @jsxRuntime classic */
/** @jsx jsx */
import styled from "@emotion/styled";

export const StyledSelectField = styled.select`
    width: 50%;
    background: #f0f2fd;
    font-family: Lato, sans-serif;
    color: #15357a;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 5px;
    height: 40px;
    ::placeholder {
        color: #2c71f0;
        opacity: 1;
    }
    :hover {
        border: 2px solid #6499fc;
    }
`;
