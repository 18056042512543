import trashcan from "../assets/trashcan.svg";
interface IListItemProps {
    id: string;
    date: string;
    message: string;
    sender: string;
    handleDelete(id: string): void;
}

/* Makes up the DashboardList
 * Set up as component to more gracefully support styling
 * and eventually add buttons/functionality to them.
 */
function NotificationListItem(props: IListItemProps): JSX.Element {
    return (
        <tr>
            <td>{new Date(props.date).toLocaleDateString()}</td>
            <td>{props.message}</td>
            <td>{props.sender}</td>
            <td>
                <button
                    style={{
                        background: "transparent",
                        border: "none",
                        cursor: "pointer",
                    }}
                    onClick={(e) => {
                        props.handleDelete(props.id);
                    }}
                >
                    <img src={trashcan} alt="" />
                </button>
            </td>
        </tr>
    );
}

export default NotificationListItem;
