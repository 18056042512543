import ellipsis from "../assets/ellipsis.svg";
import { Row, Col } from 'react-bootstrap';
import { BiBookContent } from "react-icons/bi";
import { navData } from "./navData";

import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { selectAsAdmin } from "../auth/AuthSlice";
import { StyledListNav } from "../components/StyledListNav";

function Navbar(): JSX.Element | null {
    /* Hooks for selectors, store*/
    const asAdmin = useSelector(selectAsAdmin);
    const isProxy = useSelector((state: any) => state.auth.userRole === "PROXY")
    // const userRole = useSelector(selectUserRole);
    // const dispatch = useDispatch();
    const version = useSelector((state: any) => state.auth.version);
    const company = useSelector((state: any) => state.auth.companyName);
    const location = useLocation();

    // filter by version
    const navVersion = navData.filter((nav) => {
        if (version === "base" || version === "brokerage_integration" || version === "brokerage_integration_plus" || version === "brokerage_integration_plus_v2" || version === "crypto_monitoring" || version === "crypto_base" || version === "crossover_base") {
            if (nav.exclude_companies) {
                if (nav.exclude_companies.includes(company)) {
                    return false;
                }
            }
            if (nav.companies) {
                return nav.version.includes(version) && nav.companies.includes(company);
            } else {
                return nav.version.includes(version);
            }
        } else {
            return true;
        }
    });

    // Filter by user role
    const toDisplay = navVersion.filter((nav) => {
        if (isProxy) {
            return nav.view === "Proxy" || nav.view === "All"
        }
        else if (asAdmin) {
            return nav.view === "Admin" || nav.view === "All";
        } else {
            return nav.view === "User" || nav.view === "All";
        }
    });

    // protects against bug that displays some nav items twice
    if (version === "") { return null };

    return (
        <nav>
            <StyledListNav>
                <ul>
                    {toDisplay.map((nav, index) => (
                        <li key={`nav-${nav.title}`} style={location.pathname === nav.path ? { backgroundColor: '#D4DBFF', padding: '10px 0' } : { padding: '10px 0' }}>
                            <Link to={nav.path}>
                                <Row noGutters={true} style={{ padding: '0 10px' }}>
                                    <Col sm={1}>
                                        {nav.title === "History"
                                            ? <BiBookContent style={{ color: "#2C71F0" }} />
                                            : <div><img src={nav.icon} alt="" /></div>
                                        }
                                    </Col>
                                    <Col sm={10}>
                                        <p style={{ margin: 0, padding: '0 10px', color: '#15357a' }}>{nav.title}</p>
                                    </Col>
                                    <Col sm={1}>
                                        {location.pathname === nav.path
                                            ? <div style={{ height: '100%', margin: '0 auto' }}>
                                                <img style={{ margin: '0 0 3px' }} src={ellipsis} alt="" />
                                            </div>
                                            : ""
                                        }
                                    </Col>
                                </Row>
                            </Link>
                        </li>
                    ))}
                </ul>
            </StyledListNav>
        </nav>

    );
}

export default Navbar;
