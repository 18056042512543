import { useState } from "react";
import Modal from "./Modal";
import { StyledModalItem } from "./StyledModalItem";
import { StyledActionButton } from "./StyledActionButton";
import { StyledTextArea } from "./StyledTextArea";

function NotesModal({ style, children, handleSubmit, userActionId }: any) {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [note, setNote] = useState<string>("");

    return (
        <>
            <div
                style={{
                    ...style,
                    textAlign: "center",
                    fontSize: "15px",
                    textDecoration: "underline",
                    color: "#2c71f0",
                    cursor: "pointer",
                }}
                onClick={() => {
                    setShowModal(true);
                }}
            >
                View notes
            </div>
            <Modal
                width="40%"
                height="50%"
                marginTop="10%"
                show={showModal}
                onClose={() => {
                    setShowModal(false);
                }}
                title={"Transaction notes"}
            >
                <div
                    style={{
                        marginBottom: "5%",
                    }}
                >
                    {children}
                </div>
                <StyledModalItem>
                    <StyledTextArea
                        style={{ height: "50px", marginBottom: "10px" }}
                        placeholder="Please enter your note here..."
                        value={note}
                        name="reason"
                        onChange={(event) => {
                            setNote(event.target.value);
                        }}
                    />
                </StyledModalItem>
                <StyledModalItem>
                    <StyledActionButton
                        onClick={(event) => {
                            handleSubmit(note, userActionId);
                            setNote("");
                            setShowModal(false);
                        }}
                        style={{ margin: "10px 0 40px" }}
                    >
                        Add note
                    </StyledActionButton>
                </StyledModalItem>
            </Modal>
        </>
    );
}

export default NotesModal;
