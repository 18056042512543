import React from "react";

interface IModalLabelProps {
    style?: { width: string };
    children?: React.ReactNode
}

function ModalLabel({ style, children }: IModalLabelProps): JSX.Element {

    return (
        <label
            style={{
                paddingBottom: "0%",
                fontWeight: "bold",
                lineHeight: "1.6",
                width: style ? style.width : "auto"
            }}
        >
            {children}
        </label>
    );
}

export default ModalLabel;