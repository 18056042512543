import WatchlistList from "./WatchlistList";
import { StyledSearchInput } from "../components/StyledFilterInput";
import { StyledSectionHeader } from "../components/StyledSectionHeader";
import TradeReqModal from "./TradeReqModal";
import { StyledActionButtonSecondary } from "../components/StyledActionButtonSecondary";
import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import useInterval from "../util/useInterval";
import { StyledSpacing } from "../components/StyledSpacing";
import TablePageFooter, { DefaultPagination } from "../components/TablePageFooter";
import { SecurityAutoComplete, SecuritySuggestion } from "../components/SecurityAutoComplete";
import { ISorting } from "../dashboard/DashboardSlice";
import {
    deleteWatchedSecurity,
    fetchWatchedSecurities,
    saveNewWatchedSecurity,
    selectWatched,
} from "./WatchlistSlice";
import { IPagination } from "../util/pagination";

const initInfo = {
    securityId: "",
    ticker: "",
    name: "",
    description: "",
    sector: "",
    exchange: "",
};

function WatchlistSecurity(): JSX.Element {
    /* Local state for Watchlist*/
    const [filter, setFilter] = useState({ name: "" });

    /* State for Modal */
    const [securityInfo, setSecurityInfo] = useState(initInfo);
    const [showModal, setShowModal] = useState(false);
    const [pagination, setPagination] = useState<IPagination>(DefaultPagination());
    const nextPage = useSelector((state: any) => state.watchlist.nextPage);

    /* Hooks for selectors, store */
    const watched = useSelector(selectWatched);
    const userId = useSelector((state: any) => state.auth.userId);
    const dispatch = useDispatch();
    const watchedLen = watched.length;

    const [sorting, setSorting] = useState<ISorting>(
        { field: 'ticker', direction: 'asc' }
    )
    const sortingByTicker: ISorting[] = useMemo(() => {
        if (sorting.field === 'ticker') return [sorting]
        return [
            sorting,
            { field: 'ticker', direction: 'asc' },
        ]

    }, [sorting])

    useEffect(() => {
        if (userId !== -1) {
            dispatch(
                fetchWatchedSecurities({
                    pagination,
                    filter: filter,
                    sorting: sortingByTicker
                })
            );
        }
    }, [dispatch, userId, watchedLen, pagination, filter, sortingByTicker]);

    useInterval(() => {
        if (userId !== -1) {
            dispatch(
                fetchWatchedSecurities({
                    pagination,
                    filter: filter,
                    sorting: sortingByTicker
                })
            );
            // dispatch(fetchWatchedPrices(watched));
        }
    }, 15000);

    const handleDelete = (ticker: string) => {
        /* Only try and remove if we find a matching security ticker. */
        let found = false;
        let id = 0;
        watched.forEach((entity: any) => {
            if (entity.ticker.toLowerCase() === ticker.toLowerCase()) {
                id = entity.id;
                found = true;
                dispatch(deleteWatchedSecurity(id));
            }
        });
        return found;
    };

    const handleAdd = async (suggestedSecurity: SecuritySuggestion) => {
        dispatch(
            saveNewWatchedSecurity({
                securityId: suggestedSecurity.id,
            })
        );
    };

    const handleSearch = (e: any) => {
        setFilter({ name: e.target.value });
        console.log(filter, e.target.value)
        setPagination({
            ...pagination,
            pageNumber: 1
        })
    };

    const onClose = () => {
        setShowModal(false);
        setSecurityInfo(initInfo);
    };

    return (
        <>
            <TradeReqModal show={showModal} onClose={onClose} securityInfo={securityInfo} cryptoInfo={{ ticker: "", name: "", cryptoId: "" }} requestType="SECURITY" />

            <div style={{ display: "flex", flexWrap: "nowrap" }}>
                <StyledSpacing>
                    <StyledSectionHeader>
                        Add a security to your watchlist
                    </StyledSectionHeader>
                    <div style={{ position: 'relative' }}>
                        <SecurityAutoComplete
                            placeholder="Name or ticker..."
                            onEnter={handleAdd}
                            style={{ width: '50%' }}
                        />
                    </div>
                </StyledSpacing>

                <StyledActionButtonSecondary
                    style={{ marginTop: "1em" }}
                    onClick={(e) => {
                        setShowModal(true);
                    }}
                >
                    Request Approval
                </StyledActionButtonSecondary>
            </div>

            <StyledSearchInput
                placeholder="Search Watchlist"
                type="text"
                onChange={handleSearch}
            />

            <WatchlistList
                watched={watched}
                handleDelete={handleDelete}
                sorting={sorting}
                setSorting={setSorting}
                setShowModal={(show, ticker, name, securityId) => {
                    setSecurityInfo({
                        ticker: ticker,
                        name: name,
                        securityId,
                        description: "",
                        sector: "",
                        exchange: "",
                    });
                    setShowModal(show);
                }}
            />

            <TablePageFooter
                nextPage={nextPage}
                pagination={pagination}
                setPageNumber={(n) =>
                    setPagination({ ...pagination, pageNumber: n })
                }
                setPageSize={(s) =>
                    setPagination({ ...pagination, pageSize: s })
                }
            />

        </>
    );
}

export default WatchlistSecurity;
