import { toast } from "react-toastify";
import { fileUploadService } from "../services/fileUploadService";

const downloadFromUrl = (url: string, name?: string) => {
    const link = document.createElement("a");
    if (name) {
        link.download = name;
    }
    link.href = url;
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const downloadEvent = async (e: MessageEvent<any>) => {
    const fileObj = JSON.parse(e.data);

    try {
        const resp = await fileUploadService.getFileById({
            id: fileObj.id,
        });
        downloadFromUrl(resp.signedUrl);
    } catch {
        toast.error("Unable to Download the file, please try again later", {
            position: "top-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
};
export { downloadFromUrl };
