import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

//import slices here
import ruleReducer from "./restrictions/RestrictionSlice";
import insiderManagementSliceReducer from "./restrictions/InsiderManagement/InsiderManagementSlice";
import userPanelReducer from "./users/UserPanelSlice";
import watchlistReducer from "./watchlist/WatchlistSlice";
import authReducer from "./auth/AuthSlice";
import accountsReducer from "./accounts/AccountsSlice";
import dashboardReducer from "./dashboard/DashboardSlice";
import historicTradesReducer from "./transactions/HistoricTradesSlice";
import historicHoldingsReducer from "./admin/HistoricHoldings/HistoricHoldingsSlice";
import notificationReducer from "./notifications/NotificationSlice";
import walletsReducer from "./wallets/WalletSlice";
import userProfileReducer from "./user/UserProfile/UserProfileSlice";
import analyticsReducer from "./analytics/AnalyticsSlice";
import attestationsReducer from "./attestations/AttestationsSlice";
import globalRestrictionsReducer from "./restrictions/GlobalRestrictionSlice";

// import migrations from "./redux/numberOfChange"

const reducers = combineReducers({
    restriction: ruleReducer,
    globalrestriction: globalRestrictionsReducer,
    userPanel: userPanelReducer,
    watchlist: watchlistReducer,
    dashboard: dashboardReducer,
    auth: authReducer,
    accounts: accountsReducer,
    historicTrades: historicTradesReducer,
    historicHoldings: historicHoldingsReducer,
    notification: notificationReducer,
    wallets: walletsReducer,
    userProfile: userProfileReducer,
    analytics1: analyticsReducer,
    insiderManagement: insiderManagementSliceReducer,
    attestations: attestationsReducer,
});

const persistConfig = {
    key: "root",
    // version: 0,
    // migrate: createMigrate(migrations, {debug: MIGRATION_DEBUG}),
    storage,
};

export const persistedReducer = persistReducer(persistConfig, reducers);

//compose slices in the store here
const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware({
        serializableCheck: false,
    }),
});

export default store;
