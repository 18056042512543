export const TransactionTypes = {
    BUY: "BUY",
    SELL: "SELL",
    BUY_MARGIN: "BUY_MARGIN",
    SELL_MARGIN: "SELL_MARGIN",
    DIVIDEND_PAYMENT: "DIVIDEND_PAYMENT",
    BUY_OPTION: "BUY_OPTION",
    SELL_OPTION: "SELL_OPTION",
    DIVIDEND_REINVESTMENT: "DIVIDEND_REINVESTMENT",
    FEDERAL_TAX_WITHHELD: "FEDERAL_TAX_WITHHELD",
    UNRECOGNIZED: "UNRECOGNIZED",
    DEPOSIT: "DEPOSIT",
    WITHDRAWAL: "WITHDRAWAL",
    ALL: "ALL",
    GAS: "GAS",
    REWARD: "REWARD",
    STAKE: "STAKE",
    UNSTAKE: "UNSTAKE",
    STAKE_REWARD: "STAKE_REWARD",
    SUPPLY_LIQUIDITY: "SUPPLY_LIQUIDITY",
    REMOVE_LIQUIDITY: "REMOVE_LIQUIDITY",
    MINT_LP_TOKEN: "MINT_LP_TOKEN",
    BURN_LP_TOKEN: "BURN_LP_TOKEN"
}