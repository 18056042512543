import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { authService } from "../services/authService";
import { ISorting } from "../dashboard/DashboardSlice";

export interface IUser {
    id: number;
    email: string;
    name: string;
    role: string;
    group: string;
    status: string;
    account: Array<{
        account: string;
        lastUpdated: string;
        accountHolder: string;
        id: string;
        isConnected: boolean;
        isLinked: boolean;
        isExchange?: boolean;
        walletAddress?: string;
    }>;
}

export interface IUserPanel {
    users: Array<IUser>;
    nextPage: boolean;
    status: string;
}

const initialState: IUserPanel = {
    users: [],
    nextPage: false,
    status: "idle",
};

export interface IFilter {
    name: string;
    userType: "PRIMARY" | "SECONDARY";
}

export const fetchUsers = createAsyncThunk(
    "userPanel/fetchUsers",
    async (payload: {
        pageNumber: number;
        filter: IFilter;
        sorting: ISorting[];
        showDeleted?: boolean;
        isLinked?: boolean;
        pageSize?: number;
    }) => {
        const {
            pageNumber,
            filter,
            sorting,
            pageSize,
            showDeleted = false,
            isLinked = true,
        } = payload;
        if (pageSize !== undefined) {
            const users = await authService.getUsersPanel(
                pageNumber,
                filter,
                sorting,
                showDeleted,
                isLinked,
                pageSize
            );
            /* Handle errors */
            return users;
        }
        const users = await authService.getUsersPanel(
            pageNumber,
            filter,
            sorting,
            showDeleted,
            isLinked
        );
        /* Handle errors */
        return users;
    }
);

export const fetchAllUsers = createAsyncThunk("userPanel/fetchAllUsers", async () => {
    const users = await authService.getUsers();
    /* Handle errors */
    return users;
});

export const saveNewUser = createAsyncThunk(
    "userPanel/saveNewUser",
    async (payload: {
        email: string;
        name: string;
        companyId: number;
        outsideEmail: boolean;
    }) => {
        const { email, name, companyId, outsideEmail } =
            payload; /* Unpack parameters */
        const data = await authService.createUser(email, name, companyId, outsideEmail);

        /* Handle errors */

        return data;
    }
);

export const saveUploadedUsers = createAsyncThunk(
    "userPanel/saveUploadedUsers",
    async (formData: FormData) => {
        const data = await authService.uploadUsers(formData);

        /* Handle errors */
        return data;
    }
);

export const deleteUser = createAsyncThunk(
    "userPanel/deleteUser",
    async (id: number) => {
        await authService.deleteUser(id);

        /* Handle errors */

        return id;
    }
);

const userPanelSlice = createSlice({
    name: "userPanel",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder

            .addCase(fetchUsers.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                state.users = action.payload.data;
                state.nextPage = action.payload.nextPage;
                state.status = "idle";
            })
            .addCase(fetchAllUsers.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(fetchAllUsers.fulfilled, (state, action) => {
                state.status = "idle";
            })
            .addCase(saveNewUser.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(saveNewUser.fulfilled, (state, action) => {
                state.users.push(action.payload);
            })
            .addCase(saveUploadedUsers.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(saveUploadedUsers.fulfilled, (state, action) => {
                state.status = "idle";
                state.users = action.payload.data;
            })
            .addCase(deleteUser.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(deleteUser.fulfilled, (state, action) => {
                state.status = "idle";
                state.users = state.users.filter((user) => user.id !== action.payload);
            });
    },
});

/* Selector for state.watched array*/
export const selectUsers = (state: any) => state.userPanel.users;

export default userPanelSlice.reducer;
