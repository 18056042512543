
export function path_in_version(route: string, version: string, navData: Array<any>): boolean {
    let found = false
    navData.forEach((nav) => {
        if (nav.path === route && nav.version.includes(version)) {
            found = true
        }
    })

    return found;
}