import { formatDate } from "../util/formatDate";
import { midEllipsis, smartTruncate } from "../util/formatValues";
import { toast } from "react-toastify";

interface IListItemProps {
    receiver: string;
    sender: string;
    asset: string;
    transactionType: string;
    transactionDate: string;
    value: number;
}

function RelatedWalletTxListItem(props: IListItemProps): JSX.Element {
    const copyHandler = (
        element: HTMLTableCellElement,
        value: string
    ) => {
        const selection = window.getSelection();

        if (selection) {
            const range = document.createRange();

            range.selectNodeContents(element);
            selection.removeAllRanges();
            selection.addRange(range);
        }
        navigator.clipboard.writeText(value);
        toast.info(`Wallet address ${element.innerText} copied to your clipboard`, {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
        });
    };

    return (
        <>
            <tr>
                <td
                    title={props.receiver}
                    onCopy={(e) => copyHandler(e.currentTarget, props.receiver)}
                    onDoubleClick={(e) => copyHandler(e.currentTarget, props.receiver)}
                >
                    {midEllipsis(props.receiver)}
                </td>
                <td
                    title={props.sender}
                    onCopy={(e) => copyHandler(e.currentTarget, props.sender)}
                    onDoubleClick={(e) => copyHandler(e.currentTarget, props.sender)}
                >
                    {midEllipsis(props.sender)}
                </td>
                <td>{props.asset}</td>
                <td>{smartTruncate(props.value)}</td>
                <td>{formatDate(props.transactionDate)}</td>
            </tr>
        </>
    );
}

export default RelatedWalletTxListItem;
