//The jsxRuntime pragma is necessary to get Emotion and React to work w/ latest Create-React-App version
/** @jsxRuntime classic */
/** @jsx jsx */
import styled from "@emotion/styled";

export const TextStylingContainer = styled.div`
    font-family: Lato, sans-serif;
    color: #15357a;
    font-size: 14px;
    line-height: 17px;
    font-style: normal;
    font-weight: normal;

    li {
        margin-bottom: 1%;
    }
`;
