import { StyledPageTitle } from "../components/StyledPageTitle";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { accountsService } from "../services/accountsService";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function FlanksRedirect(): JSX.Element {
    /* Hooks for libraries, selectors */
    const query = useQuery();
    const userId = useSelector((state: any) => state.auth.userId);

    useEffect(() => {
        var code = query.get("code");

        /* Error if no code recieved */
        if (code === null) code = "";

        accountsService.sendCode(code, userId);
    });

    return (
        <StyledPageTitle>
            Succesfully connected to bank account via Flanks. Please close this page.
        </StyledPageTitle>
    );
}

export default FlanksRedirect;
