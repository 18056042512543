/* Wrapper for calls to the restrictions route */
import { ISorting } from "../dashboard/DashboardSlice";
import { IPagination, paginationQueryParam } from "../util/pagination";
import { authService } from "./authService";
/* Handles login, logout, and user CRUD operations for admins */

export interface IRestrictionGroupMember { name: string, email: string, id: string }

export interface IRestrictionGroup { name: string, id: string, companyId: string, lastJoin: string, lastUpdated: string }

export const restrictionService = {
    getRestrictions,
    saveRestriction,
    deleteRestriction,
    saveStockRestrictionUpload,
    getRestrictionGroups,
    getRestrictionGroup,
    getDefaultRestrictionGroup,
    saveRestrictionGroup,
    getRestrictionsDownload,
    updateRestriction,
    getRestrictionGroupMembers,
    saveNewRestrictionGroupMember,
    removeRestrictionGroupMember,
};

const baseUrl = process.env.REACT_APP_API_ENDPOINT;
const baseUrlRestrictions = `${baseUrl}/restrictions`;

export interface IFilter {
    name: string;
}

/* Gets all stocks on restriction list */
function getRestrictions(pagination: IPagination, filter: IFilter, sorting: ISorting[]): any {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ filter, sorting }),
    };

    return fetch(paginationQueryParam(`${baseUrlRestrictions}/get`, pagination), requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);

}

function getRestrictionsDownload(filter: {}, sorting: ISorting[]): any {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
            "Content-type": "application/json",
        },
        body: JSON.stringify({ filter, sorting }),
    };

    return fetch(`${baseUrlRestrictions}/download`, requestParams);
}


/* Save stock to restriction list */
function saveRestriction(
    restrictionType: string,
    restrictionGroupId: string,
    transactionType: string,
    visibilityType: string,
    restrictedFrom: string,
    restrictedTo?: string,
    securityCompanyId?: string,
    cryptoId?: string,
    privateAssetId?: string
): any {
    let base: any = {
        restrictionType,
        restrictionGroupId,
        transactionType,
        visibilityType,
        restrictedFrom,
    };

    if (restrictedTo) {
        base["restrictedTo"] = restrictedTo;
    }
    if (securityCompanyId) {
        base["securityCompanyId"] = securityCompanyId;
    }
    if (cryptoId) {
        base["cryptoId"] = cryptoId;
    }
    if (privateAssetId) {
        base["privateAssetId"] = privateAssetId;
    }

    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
            "Content-Type": "application/json",
        },
        body: JSON.stringify(base),
    };

    return fetch(`${baseUrlRestrictions}`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);

}

function updateRestriction(
    restrictedTo: string,
    restrictionId: string,
    restrictedFrom?: string
): any {
    if (restrictedFrom) {
        const requestParams = {
            method: "PUT",
            headers: {
                ...authService.authHeader(),
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                restrictedFrom: restrictedFrom,
                restrictionId: restrictionId,
            }),
        };

        return fetch(`${baseUrlRestrictions}/update`, requestParams)
            .then(authService.handleErrors)
            .then(handleResponse);
    } else {
        const requestParams = {
            method: "PUT",
            headers: {
                ...authService.authHeader(),
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                restrictedTo: restrictedTo,
                restrictionId: restrictionId,
            }),
        };

        return fetch(`${baseUrlRestrictions}/update`, requestParams)
            .then(authService.handleErrors)
            .then(handleResponse);
    }

}

/* Delete stock on restriction list by id */
function deleteRestriction(id: string): any {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
        },
    };

    return fetch(`${baseUrlRestrictions}/` + id, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

/* Save stock to restriction list by upload*/
function saveStockRestrictionUpload(formData: FormData): any {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
            // "Content-Type": "multipart/form-data",
        },
        body: formData,
    };
    return fetch(`${baseUrlRestrictions}/upload`, requestParams);
}

/* Gets all restriction groups for company */
function getRestrictionGroups(page?: number, pageSize?: number, filter?: string, filterType?: string, editable?: boolean) {
    const requestParams = {
        method: "GET",
        headers: {
            ...authService.authHeader(),
        },
    };
    let params = [];
    if (filter) params.push(`filter=${encodeURIComponent(filter)}`);
    if (filterType) params.push(`filterType=${encodeURIComponent(filterType)}`);
    if (page != undefined) params.push(`pageNumber=${encodeURIComponent(page)}`);
    if (pageSize != undefined) params.push(`pageSize=${encodeURIComponent(pageSize)}`);
    if (editable != undefined) params.push(`editable=${encodeURIComponent(editable)}`);
    const url = `${baseUrlRestrictions}/group?${params.join("&")}`;

    return fetch(url, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse)
        .then((res) => {
            const data: IRestrictionGroup[] = res.data;
            const paging = {
                hasNext: res.nextPage as boolean,
            };

            return {
                restrictionGroup: data,
                paging: paging,
            };
        });
}

/* Gets restriction groups for company by group id*/
function getRestrictionGroup(groupId: string) {
    const requestParams = {
        method: "GET",
        headers: {
            ...authService.authHeader(),
        },
    };
    const url = `${baseUrlRestrictions}/group/${groupId}`;

    return fetch(url, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse)
        .then((res) => {
            return res.data as IRestrictionGroup;
        });
}

/* Gets default restriction group for company*/
function getDefaultRestrictionGroup() {
    const requestParams = {
        method: "GET",
        headers: {
            ...authService.authHeader(),
        },
    };
    const url = `${baseUrlRestrictions}/group/default`;

    return fetch(url, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse)
        .then((res) => {
            return res.data as IRestrictionGroup;
        });
}

/* Save company unique restriction group */
function saveRestrictionGroup(name: string) {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ name: name }),
    };

    return fetch(`${baseUrlRestrictions}/group`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse)
        .then((res) => {
            return {
                restrictionGroup: res.data as IRestrictionGroup,
            };
        });
}

/* Gets all members for restriction group */
function getRestrictionGroupMembers(groupId: string, page: number, pageSize: number, filter?: string) {
    const requestParams = {
        method: "GET",
        headers: {
            ...authService.authHeader(),
        },
    };

    let params = []
    if (filter) params.push(`filter=${encodeURIComponent(filter)}`)
    if (page) params.push(`pageNumber=${encodeURIComponent(page)}`)
    if (pageSize) params.push(`pageSize=${encodeURIComponent(pageSize)}`)
    const url = `${baseUrlRestrictions}/group/members/aggregated/${groupId}?${params.join('&')}`

    return fetch(url, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse)
        .then((res) => {
            return {
                data: res.data as IRestrictionGroupMember[],
                paging: {
                    hasNext: res.nextPage as boolean,
                },
            };
        });
}

/* Add member to restriction group */
function saveNewRestrictionGroupMember(groupId: string, userId: string): Promise<IRestrictionGroupMember> {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_id: userId }),
    };

    return fetch(`${baseUrlRestrictions}/group/members/${groupId}`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse)
        .then((res) => res.data);
}

/* Remove member from restriction group */
function removeRestrictionGroupMember(groupId: string, userId: string): Promise<string> {
    const requestParams = {
        method: "DELETE",
        headers: {
            ...authService.authHeader(),
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_id: userId }),
    };

    return fetch(`${baseUrlRestrictions}/group/members/${groupId}`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}


function handleResponse(response: any) {
    return response.text().then((text: any) => {
        const data = text && JSON.parse(text);
        return data;
    });
}

export default handleResponse;
