import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { StyledInputField } from "../../components/StyledInputField";
import { StyledActionButton } from "../../components/StyledActionButton";
import { StyledSectionHeader } from "../../components/StyledSectionHeader";
import {
    fetchSecondaryUsers,
    selectSecondaryUsers,
    createSecondaryUser,
    deleteSecondaryUser,
} from "./UserProfileSlice";
import SecondaryUsersList from "./SecondaryUsersList";
import { ISorting } from "../../dashboard/DashboardSlice";
import useInterval from "../../util/useInterval";

function UserProfile(): JSX.Element {
    const dispatch = useDispatch();
    const [designatedUserName, setDesignatedUserName] = useState<string>("");
    const secondaryUsers = useSelector(selectSecondaryUsers);

    const [sorting, setSorting] = useState<ISorting>({
        field: "name",
        direction: "asc",
    });

    useEffect(() => {
        dispatch(fetchSecondaryUsers());
    }, [dispatch, designatedUserName]);

    useInterval(() => {
        dispatch(fetchSecondaryUsers());
    }, 15000);

    const handleDesignatedUserCreate = () => {
        if (designatedUserName !== "") {
            dispatch(createSecondaryUser(designatedUserName));
            dispatch(fetchSecondaryUsers());
        }
        setDesignatedUserName("");
    };

    const handleDelete = (secondaryUserId: string) => {
        dispatch(deleteSecondaryUser(secondaryUserId));
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            <StyledSectionHeader>Add a Designated User</StyledSectionHeader>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                }}
            >
                <StyledInputField
                    style={{
                        marginRight: "2%",
                    }}
                    type="text"
                    placeholder="Add designated user name"
                    value={designatedUserName}
                    onChange={(event) => setDesignatedUserName(event.target.value)}
                />

                <StyledActionButton onClick={(event) => handleDesignatedUserCreate()}>
                    Add user
                </StyledActionButton>
            </div>

            <StyledSectionHeader>Designated users</StyledSectionHeader>
            <SecondaryUsersList
                secondaryUsers={secondaryUsers}
                handleDelete={handleDelete}
                setSorting={setSorting}
                sorting={sorting}
            />
        </div>
    );
}

export default UserProfile;
