import { StyledBrokerageDiv } from "../components/StyledBrokerageDiv";
// import { StyledSpacing } from "../components/StyledSpacing";
import { StyledAccoridanWrapper } from "../components/StyledAccordianWrapper";
import { StyledAccordian } from "../components/StyledAccordian";
import { StyledAccordianItem } from "../components/StyledAccordianItem";
import { StyledAccordianTitle } from "../components/StyledAccordianTitle";
import { StyledAccordianDropdownShow } from "../components/StyledAccordianDropdownShow";
import { StyledInputField } from "../components/StyledInputField";
import { StyledActionButton } from "../components/StyledActionButton";
import { authService } from "../services/authService";
import downCaret from "../assets/downCaret.svg";
import upCaret from "../assets/upCaret.svg";

import { useState } from "react";

function Profile(): JSX.Element {
    const [selectedPass, setSelectedPass] = useState(null);
    // const [selectedAuth, setSelectedAuth] = useState(null);
    // const [selectedPref, setSelectedPref] = useState(null);

    const [oldPass, setOldPass] = useState("");
    const [newPass, setNewPass] = useState("");
    const [confirmNewPass, setConfirmNewPass] = useState("");

    const toggle = (i: any, type: any) => {
        if (selectedPass === i && type === "pass") {
            return setSelectedPass(null);
        }

        if (type === "pass") setSelectedPass(i);
    };

    const handlePasswordChange = async (event: any) => {
        event.preventDefault();
        console.log("password change triggered.");

        if (confirmNewPass !== newPass) return;

        await authService.changePassword(oldPass, newPass);
        console.log("password changed.");

        setOldPass("");
        setNewPass("");
        setConfirmNewPass("");
    };

    return (
        <StyledBrokerageDiv>
            <StyledAccoridanWrapper>
                <StyledAccordian>
                    {data.map((item, i) => (
                        <StyledAccordianItem key={i}>
                            <StyledAccordianTitle onClick={() => toggle(i, item.type)}>
                                <div></div>
                                {item.setting}
                                <span>
                                    {selectedPass === i ? (
                                        <img src={upCaret} alt="" />
                                    ) : (
                                        <img src={downCaret} alt="" />
                                    )}
                                </span>
                            </StyledAccordianTitle>
                            {selectedPass === i ? (
                                <StyledAccordianDropdownShow>
                                    <form
                                        onSubmit={handlePasswordChange}
                                        id="passwordForm"
                                    >
                                        <StyledInputField
                                            style={{
                                                display: "block",
                                                margin: "auto",
                                                marginBottom: "7px",
                                                marginTop: "16px",
                                            }}
                                            placeholder="Current Password"
                                            type="password"
                                            value={oldPass}
                                            onChange={(event) => {
                                                setOldPass(event.target.value);
                                            }}
                                        />
                                        <StyledInputField
                                            style={{
                                                display: "block",
                                                margin: "auto",
                                                marginBottom: "7px",
                                            }}
                                            placeholder="New Password"
                                            type="password"
                                            value={newPass}
                                            onChange={(event) => {
                                                setNewPass(event.target.value);
                                            }}
                                        />
                                        <StyledInputField
                                            style={{
                                                display: "block",
                                                margin: "auto",
                                                marginBottom: "16px",
                                            }}
                                            placeholder="Confirm new Password"
                                            type="password"
                                            value={confirmNewPass}
                                            onChange={(event) => {
                                                setConfirmNewPass(event.target.value);
                                            }}
                                        />
                                        <StyledActionButton
                                            style={{
                                                display: "block",
                                                margin: "auto",
                                                marginBottom: "16px",
                                                width: "25%",
                                                backgroundColor: "#15357A",
                                            }}
                                        >
                                            {" "}
                                            Update{" "}
                                        </StyledActionButton>
                                    </form>
                                </StyledAccordianDropdownShow>
                            ) : null}
                        </StyledAccordianItem>
                    ))}
                </StyledAccordian>
            </StyledAccoridanWrapper>
        </StyledBrokerageDiv>
    );
}

const data = [
    {
        setting: "Change Password",
        content: "password changer component",
        type: "pass",
    },
    {
        setting: "Turn On Two/Factor Authentication",
        content: "turned on",
        type: "auth",
    },
    {
        setting: "Other Preferences",
        content: "preferences here",
        type: "pref",
    },
];

export default Profile;
