import { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StyledSearchInput } from "../components/StyledFilterInput";
import { StyledSpacing } from "../components/StyledSpacing";
import UserPanelList from "./UserPanelList";
import {
    deleteUser,
    // fetchAllUsers,
    fetchUsers,
    selectUsers,
} from "./UserPanelSlice";
import { authService } from "../services/authService";
import handleDownload from "../util/downloadCsv";
import TablePageFooter, { DefaultPagination } from "../components/TablePageFooter";
import { ISorting } from "../dashboard/DashboardSlice";
import AddUser from "./AddUser";
import { IPagination } from "../util/pagination";
import { StyledLabel } from "../components/StyledLabel";
import UserAutocomplete, { UserSuggestion } from "../components/UserAutoComplete";

function UserPanel(): JSX.Element {
    /* Local state for filters and views*/
    //const [activeFilter, setActiveFilter] = useState("");

    const [filter, setFilter] = useState({ name: "" });
    const [pagination, setPagination] = useState<IPagination>(DefaultPagination());
    const nextPage = useSelector((state: any) => state.userPanel.nextPage);
    // const allUsers = useSelector((state: any) => state.userPanel.allUsers);

    /* Hooks for libraries, selectors*/
    const dispatch = useDispatch();
    const users = useSelector(selectUsers);
    const [showDeleted, setShowDeleted] = useState(false);

    const hiddenFileInput = useRef<HTMLInputElement>(null);

    const [sorting, setSorting] = useState<ISorting>({
        field: "email",
        direction: "asc",
    });
    const sortingByDate: ISorting[] = useMemo(() => {
        if (sorting.field === "email") return [sorting];
        return [sorting, { field: "email", direction: "asc" }];
    }, [sorting]);

    useEffect(() => {
        dispatch(
            fetchUsers({
                ...pagination,
                filter: {
                    ...filter,
                    userType: "PRIMARY",
                },
                sorting: sortingByDate,
                isLinked: true,
                showDeleted,
            })
        );
    }, [dispatch, pagination, filter, sortingByDate, showDeleted]);

    const handleDelete = (email: string) => {
        /* Only try and remove if we find a matching stock ticker. */
        let found = false;
        let id = 0;
        users.forEach((user: any) => {
            if (user.email.toLowerCase() === email.toLowerCase()) {
                id = user.id;
                found = true;
                dispatch(deleteUser(id));
            }
        });
        return found;
    };

    const handleEdit = (id: number, name: string) => {
        if (name.length > 0) {
            authService.editUserName(id, name).then((r) => {
                console.log(r);
                if (r.status === 200) {
                    dispatch(
                        fetchUsers({
                            ...pagination,
                            filter: {
                                ...filter,
                                userType: "PRIMARY",
                            },
                            sorting: sortingByDate,
                            isLinked: true,
                            showDeleted,
                        })
                    );
                }
            });
        }
    };

    const handleClick = (event: any) => {
        if (hiddenFileInput) {
            if (hiddenFileInput.current) {
                hiddenFileInput.current.click();
            }
        }
    };

    const handleSearch = (user: UserSuggestion | undefined) => {
        setFilter({ name: user?.name || "" });
        setPagination({ ...pagination, pageNumber: 1 });
    };

    return (
        <div>
            {/* <StyledSpacing style={{ display: "flex", alignItems: "center" }}>
                <StyledActionButton onClick={handleClick}>
                    <img src={uploadIcon} alt="" /> Upload a new user list
                </StyledActionButton>
                <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={(event: any) => {
                        handleFileSelection(event);
                    }}
                    style={{ display: "none" }}
                />
                <InfoModal
                    style={{
                        height: "25px",
                        width: "25px",
                        alignSelf: "center",
                        marginLeft: "5px",
                    }}
                >
                    <p>
                        Format: Email, Name <br />
                        Supported file types: .xslx, .csv, .xsl
                    </p>
                </InfoModal>
            </StyledSpacing> */}
            <StyledSpacing>
                <AddUser />
            </StyledSpacing>
            <UserAutocomplete
                value={""}
                onSelect={handleSearch}
                onEnter={handleSearch}
                style={{ width: "100%" }}
                placeholder={"Filter by name or email"}
            />
            <br /> <br />
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: "1%",
                }}
            >
                <input
                    onChange={() => setShowDeleted(!showDeleted)}
                    type="checkbox"
                    id="deleted-users"
                />
                <StyledLabel>Show deleted users</StyledLabel>
            </div>
            <div
                onClick={() =>
                    handleDownload(
                        authService.getUsersDownload(filter, sortingByDate),
                        "users_list"
                    )
                }
                style={{
                    textAlign: "right",
                    fontSize: "15px",
                    textDecoration: "underline",
                    color: "#2c71f0",
                    cursor: "pointer",
                }}
            >
                Download users list
            </div>
            <UserPanelList
                users={users}
                //activeFilter={activeFilter}
                handleDelete={handleDelete}
                handleEdit={handleEdit}
                sorting={sorting}
                setSorting={setSorting}
            />
            <TablePageFooter
                nextPage={nextPage}
                pagination={pagination}
                setPageNumber={(n) => setPagination({ ...pagination, pageNumber: n })}
                setPageSize={(s) => setPagination({ ...pagination, pageSize: s })}
            />
        </div>
    );
}

export default UserPanel;
