import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../components/Modal";
import { StyledActionButton } from "../components/StyledActionButton";
import { StyledPageTitle } from "../components/StyledPageTitle";
import { StyledSectionHeader } from "../components/StyledSectionHeader";
import { StyledTableContainer } from "../components/StyledTable";
import {
    fetchSixMonths,
    saveLastUpdated,
    selectAccountStatements,
    uploadAccountStatement,
} from "./AccountsSlice";
import useInterval from "../util/useInterval";

interface PDFUploadModalProps {
    show: boolean;
    onClose(): void;
    accountId: string;
    userId: string;
}

interface PDFUploadStatementProps {
    date: string;
    status: string;
    onChange: (event: any, index: number) => void;
    index: number;
}

const PDFUploadStatement: React.FC<PDFUploadStatementProps> = ({
    date,
    status,
    onChange,
    index,
}) => {
    const ref = useRef<HTMLInputElement>(null);
    return (
        <tr>
            <td>{date}</td>
            <td>{status}</td>
            <td>
                <StyledActionButton
                    style={{ width: "60%" }}
                    onClick={(event) => {
                        if (ref && ref.current) ref.current.click();
                    }}
                >
                    Upload
                </StyledActionButton>
                <input
                    type="file"
                    style={{ display: "none" }}
                    ref={ref}
                    onChange={(event: any) => {
                        onChange(event, index);
                    }}
                />
            </td>
        </tr>
    );
};

const PDFUploadModal: React.FC<PDFUploadModalProps> = ({
    show,
    onClose,
    accountId,
    userId,
}) => {
    const d = useRef(new Date()).current;
    const currentMonthIndex = d.getMonth();
    const currentYear = d.getFullYear();

    const monthNames = useMemo(() => {
        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];
        return monthNames.concat(monthNames);
    }, []);
    const months = useMemo(() => {
        const startingMonthIndex = currentMonthIndex + 12 - 6;
        const endingMonthIndex = currentMonthIndex + 12;
        return monthNames.slice(startingMonthIndex, endingMonthIndex).reverse();
    }, [monthNames, currentMonthIndex]);
    const years = useMemo(() => {
        return months.map((_, index) =>
            currentMonthIndex - index < 1 ? currentYear - 1 : currentYear
        );
    }, [months, currentYear, currentMonthIndex]);

    const accountStatements = useSelector(selectAccountStatements);

    const statements = useMemo(() => {
        return months
            .map((month, index) => {
                return accountStatements.find(
                    (statement: any) => {
                        return statement.month === (currentMonthIndex + 12 - 6 + index) % 12
                    }
                );
            })
            .reverse();
    }, [accountStatements, currentMonthIndex, months]);

    const [errors, setErrors] = useState<string[]>(monthNames.map(() => "Not Updated"));

    const statuses = useMemo(() => {
        return statements.map((statement, index) =>
            statement
                ? "Updated: " + new Date(statement.lastUpdated).toLocaleDateString()
                : errors[index]
        );
    }, [statements, errors]);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchSixMonths(accountId));
    }, [accountId, dispatch]);

    // useInterval(() => {
    //     dispatch(fetchSixMonths(accountId));
    // }, 15000);

    const uploadStatement = useMemo(() => {
        return (event: any, index: number) => {
            const file = event.target.files[0]
            const reader = new FileReader();
            reader.onload = async function () {
                if (reader.result !== null && typeof reader.result === 'string') {
                    let fileName = event.target.files[0].name;

                    if (fileName.substring(fileName.length - 4) !== ".pdf") {
                        setErrors(
                            errors.map((error, errorIndex) =>
                                index === errorIndex ? "Invalid file format" : error
                            )
                        );
                        return;
                    }

                    setErrors(
                        errors.map((error, errorIndex) =>
                            index === errorIndex ? "Uploading..." : error
                        )
                    );

                    dispatch(
                        uploadAccountStatement({
                            accountId: accountId,
                            userId: userId,
                            month: monthNames.indexOf(months[index]),
                            year: years[index],
                            file: reader.result,
                        })
                    );
                }
            }
            reader.readAsDataURL(file);
        };
    }, [dispatch, accountId, errors, monthNames, years, months, userId]);

    return (
        <Modal height="95%" show={show} onClose={onClose} title={"Account Statements"}>
            <div
                style={{
                    width: "100%",
                    height: "90%",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <StyledPageTitle style={{ fontSize: "25px", textAlign: "center" }}>
                    Upload Account Statements
                </StyledPageTitle>
                <StyledSectionHeader
                    style={{
                        textAlign: "left",
                        marginLeft: "8%",
                        fontSize: "16px",
                        marginBottom: "15px",
                    }}
                >
                    Last 6 months:
                </StyledSectionHeader>
                <StyledTableContainer
                    style={{ marginLeft: "auto", marginRight: "auto" }}
                >
                    <table>
                        <thead>
                            <tr>
                                <th style={{ width: "200px" }}>Date</th>
                                <th style={{ width: "200px" }}>Status</th>
                                <th style={{ width: "200px" }}></th>
                            </tr>
                        </thead>
                        <tbody style={{ textAlign: "center" }}>
                            {statuses.map((status: any, index: number) => {
                                const date = `${months[index]} ${years[index]}`;
                                return (
                                    <PDFUploadStatement
                                        status={status}
                                        date={date}
                                        index={index}
                                        onChange={uploadStatement}
                                    />
                                );
                            })}
                        </tbody>
                    </table>
                </StyledTableContainer>
            </div>
        </Modal>
    );
};

export default PDFUploadModal;
