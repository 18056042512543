import greencheck from "../assets/greencheck.svg";
import clipboard from "../assets/clipboard.svg";
import flag from "../assets/flag.svg";
import { formatDate } from "../util/formatDate";
import { smartTruncate } from "../util/formatValues";
import { useSelector } from "react-redux";
import capitalizeFirstLetter from "../util/capitalizeFirstLetter";
import IssueNotesModal from "../components/IssueNotesModal";
import "./css/button.css";
interface IListItemProps {
    id: string;
    employee: string;
    ticker: string;
    investmentAccount: string;
    userExchangeAccount: string;
    accountHolder: string;
    status: string;
    volume: string;
    price?: number;
    securityName: string;
    dateCreated: string;
    transactionDate?: string;
    transactionType?: string;
    key: number;
    notes: JSON;
    reviewerName: string;
    handleDelete(id: string): void;
    handleResolve(id: string): void;
    handleMarked(id: string): void;
    handleSubmitNote(note: string, issueId: string): void;
    handleUpdateReviewStatus(id: string): void;
}

/**
 * On dashboard, we have only: pending, under review and reviewed
 *
 */
const generateStatus = (status: string): string => {
    if (status.includes("PENDING")) {
        return "Open";
    }
    if (status.includes("IN_REVIEW")) {
        return "In review";
    }
    return "";
};

const customDate = (date: string): string => {
    const dateObj = new Date(date);
    return `${dateObj.getMonth() + 1}/${dateObj.getDate()}/${dateObj.getFullYear()}`;
};
/* Makes up the DashboardList
 * Set up as component to more gracefully support styling
 * and eventually add buttons/functionality to them.
 */
function IssuesListItem(props: IListItemProps): JSX.Element {
    const version = useSelector((state: any) => state.auth.version);

    return (
        <tr>
            <td>{props.employee}</td>
            {(version === "crypto_base" || version === "crossover_base") && (
                <td>{props.accountHolder}</td>
            )}
            <td>
                {props.investmentAccount
                    ? props.investmentAccount
                    : props.userExchangeAccount}
            </td>
            <td>
                {capitalizeFirstLetter(
                    props.transactionType ? props.transactionType : ""
                )}
            </td>
            <td>{props.ticker}</td>
            <td>{props.securityName}</td>
            <td>
                {props.volume && props.price
                    ? smartTruncate(parseFloat(props.volume) * props.price)
                    : "--"}
            </td>
            <td>{smartTruncate(parseFloat(props.volume))}</td>
            {/*<td>{formatDate(props.dateCreated)}</td>*/}
            <td>{props.transactionDate ? formatDate(props.transactionDate) : "--"}</td>
            <td>{generateStatus(props.status)}</td>
            <td>{props.reviewerName}</td>
            <td>
                {/* <img
                    style={{ marginRight: "20px", cursor: "pointer" }}
                    src={redcross}
                    alt=""
                    onClick={(e) => props.handleDelete(props.id)}
                /> */}
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <button
                        className="tooltip"
                        style={{
                            background: "transparent",
                            border: "none",
                            cursor: "pointer",
                        }}
                        onClick={(e) => props.handleUpdateReviewStatus(props.id)}
                    >
                        <img
                            style={{
                                cursor: "pointer",
                                width: 20,
                                height: 20,
                            }}
                            src={clipboard}
                            alt=""
                        />
                        <span className="tooltiptext">Set yourself as reviewer</span>
                    </button>
                    <button
                        className="tooltip"
                        style={{
                            background: "transparent",
                            border: "none",
                            cursor: "pointer",
                        }}
                        onClick={(e) => props.handleResolve(props.id)}
                    >
                        <img
                            style={{ cursor: "pointer", marginBottom: "2px" }}
                            src={greencheck}
                            alt=""
                        />
                        <span className="tooltiptext">Mark Allowed</span>
                    </button>
                    <button
                        className="tooltip"
                        style={{
                            background: "transparent",
                            border: "none",
                            cursor: "pointer",
                        }}
                        onClick={(e) => props.handleMarked(props.id)}
                    >
                        <img
                            style={{
                                cursor: "pointer",
                                width: 20,
                                height: 20,
                            }}
                            src={flag}
                            alt=""
                        />
                        <span className="tooltiptext">Mark Infraction</span>
                    </button>
                </div>
            </td>
            <td>
                <IssueNotesModal
                    handleSubmit={props.handleSubmitNote}
                    issueId={props.id}
                >
                    {props.notes &&
                        Object.entries(props.notes).map((noteEntry) => {
                            return (
                                <p key={noteEntry[0]}>
                                    {noteEntry[1]?.user} (
                                    {customDate(noteEntry[1]?.timestamp)}):{" "}
                                    {noteEntry[1]?.note}
                                </p>
                            );
                        })}
                </IssueNotesModal>
            </td>
        </tr>
    );
}
export default IssuesListItem;
