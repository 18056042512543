//The jsxRuntime pragma is necessary to get Emotion and React to work w/ latest Create-React-App version
/** @jsxRuntime classic */
/** @jsx jsx */
import styled from "@emotion/styled";

export const StyledInputField = styled.input`
    width: 50%;
    background: #f0f2fd;
    font-family: Lato, sans-serif;
    color: #15357a;
    border: 2px solid #ffffff;
    box-sizing: border-box;
    border-radius: 5px;
    height: 40px;
    padding-left: 8px;
    ::placeholder {
        color: #2c71f0;
        opacity: 1;
    }
    :hover:not(:disabled) {
        border: 2px solid #6499fc;
    }
    :disabled {
        color: #5f5f5f;
        background: #eeeeee;
    }
`;
