
export function get_okta_url(company_name: string, logintype: string): string {

    if (company_name == "public") {
        if (logintype == "admin") {
            return "https://public.okta.com/oauth2/v1/authorize?client_id=0oaay2rhlmiO6LVxx696&redirect_uri=https%3A%2F%2Fpublic.tryargus.com%2Fredirect%3Fprovider%3Dokta%26logintype%3Dadmin&scope=openid+email+profile&state=TEST&response_type=id_token&response_mode=fragment"
        } else {
            return "https://public.okta.com/oauth2/v1/authorize?client_id=0oaay2rhlmiO6LVxx696&redirect_uri=https%3A%2F%2Fpublic.tryargus.com%2Fredirect%3Fprovider%3Dokta&scope=openid+email+profile&state=TEST&response_type=id_token&response_mode=fragment"
        }
    } else if (company_name == "nydig") {
        if (logintype == "admin") {
            return "https://nydig.okta.com/oauth2/v1/authorize?client_id=0oaf4u0kbcur8gpCP2p7&redirect_uri=https%3A%2F%2Fnydig.tryargus.com%2Fredirect%3Fprovider%3Dokta%26logintype%3Dadmin&scope=openid+email+profile&state=TEST&response_type=id_token&response_mode=fragment"
        } else {
            return "https://nydig.okta.com/oauth2/v1/authorize?client_id=0oaf4u0kbcur8gpCP2p7&redirect_uri=https%3A%2F%2Fnydig.tryargus.com%2Fredirect%3Fprovider%3Dokta&scope=openid+email+profile&state=TEST&response_type=id_token&response_mode=fragment"
        }
    }
    else if (company_name == "stoneridge") {
        if (logintype == "admin") {
            return "https://stoneridgeam.okta.com/oauth2/v1/authorize?client_id=0oait6pki9umfm2O02p7&redirect_uri=https%3A%2F%2Fnydig.tryargus.com%2Fredirect%3Fprovider%3Dokta%26logintype%3Dadmin&scope=openid+email+profile&state=TEST&response_type=id_token&response_mode=fragment"
        } else {
            return "https://stoneridgeam.okta.com/oauth2/v1/authorize?client_id=0oait6pki9umfm2O02p7&redirect_uri=https%3A%2F%2Fnydig.tryargus.com%2Fredirect%3Fprovider%3Dokta&scope=openid+email+profile&state=TEST&response_type=id_token&response_mode=fragment"
        }
    } else if (company_name == "paradigm") {
        if (logintype == "admin") {
            return "https://paradigmxyz.okta.com/oauth2/v1/authorize?client_id=0oa1p5tloaxfOOTuH0h8&redirect_uri=https%3A%2F%2Fparadigm.app.tryargus.com%2Fredirect%3Fprovider%3Dokta%26logintype%3Dadmin&scope=openid+email+profile&state=TEST&response_type=id_token&response_mode=fragment"
        } else {
            return "https://paradigmxyz.okta.com/oauth2/v1/authorize?client_id=0oa1p5tloaxfOOTuH0h8&redirect_uri=https%3A%2F%2Fparadigm.app.tryargus.com%2Fredirect%3Fprovider%3Dokta&scope=openid+email+profile&state=TEST&response_type=id_token&response_mode=fragment"
        }
    } else if (company_name == "tower") {
        if (logintype == "admin") {
            return "https://tower.okta.com/oauth2/v1/authorize?client_id=0oa16g4gotuu5PbON697&redirect_uri=https%3A%2F%2Ftower.app.tryargus.com%2Fredirect%3Fprovider%3Dokta%26logintype%3Dadmin&scope=openid+email+profile&state=TEST&response_type=id_token&response_mode=fragment"
        } else {
            return "https://tower.okta.com/oauth2/v1/authorize?client_id=0oa16g4gotuu5PbON697&redirect_uri=https%3A%2F%2Ftower.app.tryargus.com%2Fredirect%3Fprovider%3Dokta&scope=openid+email+profile&state=TEST&response_type=id_token&response_mode=fragment"
        }
    } else if (company_name == "localhost") {
        if (logintype == "admin") {
            return "https://dev-62138065.okta.com/oauth2/default/v1/authorize?client_id=0oa3d2ws7iA7SgKFJ5d7&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fredirect%3Fprovider%3Dokta%26logintype%3Dadmin&scope=openid+email+profile&state=TEST&response_type=id_token&response_mode=fragment"
        } else {
            return "https://dev-62138065.okta.com/oauth2/default/v1/authorize?client_id=0oa3d2ws7iA7SgKFJ5d7&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fredirect%3Fprovider%3Dokta&scope=openid+email+profile&state=TEST&response_type=id_token&response_mode=fragment"
        }
    } else if (company_name == "dev") {
        if (logintype == "admin") {
            return "https://dev-62138065.okta.com/oauth2/v1/authorize?client_id=0oa3d2ws7iA7SgKFJ5d7&redirect_uri=https%3A%2F%2Fdev.app.tryargus.com%2Fredirect%3Fprovider%3Dokta%26logintype%3Dadmin&scope=openid+email+profile&state=TEST&response_type=id_token&response_mode=fragment"
        } else {
            return "https://dev-62138065.okta.com/oauth2/v1/authorize?client_id=0oa3d2ws7iA7SgKFJ5d7&redirect_uri=https%3A%2F%2Fdev.app.tryargus.com%2Fredirect%3Fprovider%3Dokta&scope=openid+email+profile&state=TEST&response_type=id_token&response_mode=fragment"
        }
    }

    return "";
}

const stagingDomain = "dev-74hcmx65.us.auth0.com";
const prodDomain = "tryargus.us.auth0.com";

const stagingClientId = "P7lQzFusdI8wKy88hRNS2guEnUrp5sMH";
const prodClientId = "te7wSHEnXGw8DU0hgFOoCExKYPdrmVey";

export function get_auth0_url(company_name: string, logintype: string): string {

    if (company_name == "argus") {
        if (logintype == "admin") {
            return `https://${prodDomain}/authorize?response_type=id_token&client_id=${prodClientId}&redirect_uri=https%3A%2F%2Fargus.app.tryargus.com%2Fredirect%3Fsource=auth0%26logintype=admin&scope=openid%20email&state=STATE`
        } else {
            return `https://${prodDomain}/authorize?response_type=id_token&client_id=${prodClientId}&redirect_uri=https%3A%2F%2Fargus.app.tryargus.com%2Fredirect%3Fsource=auth0&scope=openid%20email&state=STATE`
        }
    } else if (company_name == "paradigm") {
        if (logintype == "admin") {
            return `https://${prodDomain}/authorize?response_type=id_token&client_id=${prodClientId}&redirect_uri=https%3A%2F%2Fparadigm.app.tryargus.com%2Fredirect%3Fsource=auth0%26logintype=admin&scope=openid%20email&state=STATE`
        } else {
            return `https://${prodDomain}/authorize?response_type=id_token&client_id=${prodClientId}&redirect_uri=https%3A%2F%2Fparadigm.app.tryargus.com%2Fredirect%3Fsource=auth0&scope=openid%20email&state=STATE`
        }
    } else if (company_name == "dev") {
        if (logintype == "admin") {
            return `https://${stagingDomain}/authorize?response_type=id_token&client_id=${stagingClientId}&redirect_uri=https%3A%2F%2Fdev.app.tryargus.com%2Fredirect%3Fsource=auth0%26logintype=admin&scope=openid%20email&state=STATE`
        } else {
            return `https://${stagingDomain}/authorize?response_type=id_token&client_id=${stagingClientId}&redirect_uri=https%3A%2F%2Fdev.app.tryargus.com%2Fredirect%3Fsource=auth0&scope=openid%20email&state=STATE`
        }
    } else if (company_name == "localhost") {
        if (logintype == "admin") {
            return `https://${stagingDomain}/authorize?response_type=id_token&client_id=${stagingClientId}&redirect_uri=http%3A%2F%2Flocalhost:3000%2Fredirect%3Fsource=auth0%26logintype=admin&scope=openid%20email&state=STATE`
        } else {
            return `https://${stagingDomain}/authorize?response_type=id_token&client_id=${stagingClientId}&redirect_uri=http%3A%2F%2Flocalhost:3000%2Fredirect%3Fsource=auth0&scope=openid%20email&state=STATE`
        }
    }

    return "";
}


export const auth0_user_url = "https://dev-74hcmx65.us.auth0.com/authorize?response_type=id_token&client_id=P7lQzFusdI8wKy88hRNS2guEnUrp5sMH&redirect_uri=https%3A%2F%2Fdev.app.tryargus.com%2Fredirect%3Fsource=auth0&scope=openid%20email&state=ST";
export const auth0_admin_url = "https://dev-74hcmx65.us.auth0.com/authorize?response_type=id_token&client_id=P7lQzFusdI8wKy88hRNS2guEnUrp5sMH&redirect_uri=https%3A%2F%2Fdev.app.tryargus.com%2Fredirect%3Fsource=auth0%26logintype=admin&scope=openid%20email&state=ST";


