import { useState, useMemo, useEffect } from "react";
import { StyledDropdown } from "./StyledDropdown";
import { StyledInputField } from "./StyledInputField";
import debounce from "../util/debounce";
import escapeRegExp from "../util/regexHelpers";

export interface IDynamicAutoComplete<T> {
    placeholder?: string;
    style?: any;
    dropdownStyle?: any;
    selected?: T;
    onEnter?(selectedSuggestion: T): void;
    onSelect?(selectedSuggestion: T | undefined): void;
    onSelectText?(selectedSuggestion: string): void;
    value?: string;
    onChange?: (input: string) => void;
    noMatchSuggestions?: string[];
    noMatchAction?(suggestion: string): void;
    populatedSuggestions?: string[];
    filteredSuggestions?: boolean;
    clearActionRegister?(func: () => void): void;
}

interface IFullDynamicAutoComplete<T> extends IDynamicAutoComplete<T> {
    autoComplete: (input: string) => Promise<T[]>;
    formatAutoComplete: (element: T) => string;
}

function DynamicAutoComplete<T>(props: IFullDynamicAutoComplete<T>): JSX.Element {
    const {
        formatAutoComplete,
        autoComplete,
        placeholder,
        style = {},
        dropdownStyle = style,
        value,
        onEnter,
        onSelect,
        onSelectText,
        noMatchSuggestions,
        noMatchAction,
        populatedSuggestions = [],
        clearActionRegister,
        selected,
    } = props;

    const [search, setSearch] = useState("");
    const [hidden, setHidden] = useState(false);
    const [cursor, setCursor] = useState(0);
    const [suggestions, setSuggestions] = useState<T[]>([]);
    const [selectedSuggestion, setSelectedSuggestion] = useState<T | undefined>(
        selected
    );

    useEffect(() => {
        if (value !== undefined) setSearch(value);
    }, [value]);

    useEffect(() => {
        const suggestionValue = selectedSuggestion
            ? formatAutoComplete(selectedSuggestion)
            : value
            ? value
            : "";
        if (suggestionValue != search) {
            setSearch(`${suggestionValue}`);
        }
    }, [selectedSuggestion]);

    const clear = useMemo(() => {
        return () => {
            setHidden(true);
            setSuggestions([]);
        };
    }, [setSuggestions]);

    useEffect(() => {
        if (clearActionRegister) {
            clearActionRegister(() => setSearch(""));
        }
    }, [clearActionRegister]);

    const choices: string[] = useMemo(() => {
        if (hidden) return [];
        let givenChoices: any[] = [];
        if (search !== "") {
            const regex = new RegExp(`^.* ?${escapeRegExp(search)}`, "i");
            givenChoices = givenChoices.concat(
                populatedSuggestions.filter((suggestion) => regex.test(suggestion))
            );
        }
        if (
            search !== "" &&
            suggestions.length === 0 &&
            noMatchSuggestions !== undefined
        ) {
            if (givenChoices.length === 0) return noMatchSuggestions;
            return givenChoices;
        }
        givenChoices = givenChoices.concat(suggestions.map(formatAutoComplete));
        return givenChoices.filter(
            (choice, index) =>
                index ===
                givenChoices.map((c) => c.toLowerCase()).indexOf(choice.toLowerCase())
        ) as string[];
    }, [
        suggestions,
        formatAutoComplete,
        search,
        noMatchSuggestions,
        hidden,
        populatedSuggestions,
    ]);

    const debouncedAutocompletStock = useMemo(() => {
        return debounce((input: string) => {
            autoComplete(input).then(setSuggestions);
        }, 100);
    }, [autoComplete]);
    const propsOnChange = props.onChange;

    const onChange = useMemo(() => {
        return async (event: any) => {
            if (event.target) {
                setHidden(false);
                const input = event.target.value;
                if (propsOnChange) {
                    propsOnChange(input);
                }
                if (input !== "") {
                    debouncedAutocompletStock(input);
                } else {
                    setTimeout(clear, 200);
                }
                setSearch(input);
            }
        };
    }, [debouncedAutocompletStock, clear, propsOnChange]);

    function renderSuggestions() {
        if (choices.length === 0) {
            return null;
        }

        return (
            <ul>
                {choices.map((item: string, index: number) => (
                    <li
                        style={{
                            backgroundColor:
                                cursor - 1 === index ? "rgba(249, 249, 249, 1)" : "",
                        }}
                        key={index}
                        /* Must stop the onBlur event from propagating with the */
                        /* onMouseDown event. It should occur after the onClick event. */
                        /* Otherwise, suggestions list is emptied before the onClick fires. */
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={(e) => {

                            if (!(noMatchSuggestions && noMatchSuggestions.includes(item))) {
                                suggestionSelected(index);
                                clear();
                            } else if (noMatchAction) {
                                setHidden(true);
                                noMatchAction(item);
                            }
                        }}
                    >
                        {item}
                    </li>
                ))}
            </ul>
        );
    }

    const suggestionSelected = useMemo(() => {
        return (index: number) => {
            setSearch(choices[index]);
            setSelectedSuggestion(suggestions[index]);
            if (onSelect) onSelect(suggestions[index]);
            if (onSelectText) onSelectText(choices[index]);
        };
    }, [
        setSearch,
        setSelectedSuggestion,
        choices,
        suggestions,
        onSelect,
        onSelectText,
    ]);

    const onBlur = useMemo(() => {
        return async (event: any) => {
            setSuggestions([]);
            setSearch(selectedSuggestion ? formatAutoComplete(selectedSuggestion) : "");
        };
    }, [selectedSuggestion, setSearch, setSuggestions, formatAutoComplete]);

    const handleKeyPress = (event: any) => {
        if (event.key === "Enter") {
            event.preventDefault();
            if (suggestions.length === 0) {
                if (onEnter && selectedSuggestion) {
                    onEnter(selectedSuggestion);
                    setSearch("");
                    setSelectedSuggestion(undefined);
                }
            } else {
                clear();
            }
        } else if (event.key === "ArrowUp") {
            if (cursor > 1) {
                suggestionSelected(cursor - 2);
                setCursor((prevCount) => prevCount - 1);
            } else if (cursor === 1) {
                suggestionSelected(0);
                setCursor((prevCount) => prevCount - 1);
            }
        } else if (event.key === "ArrowDown") {
            if (cursor < choices.length) {
                console.log(cursor);
                suggestionSelected(cursor);
                setCursor((prevCount) => prevCount + 1);
            }
        } else if (event.key === "ArrowUp" && cursor === 0) {
            /*
            onChange("");
            onSelect("");
            */
        }

        // handleKeyDown(event);
    };

    return (
        <>
            <StyledInputField
                onKeyDown={handleKeyPress}
                style={style}
                placeholder={placeholder}
                value={search}
                onChange={onChange}
                onBlur={onBlur}
                type={"search"}
                ref={(element) =>
                    //@ts-ignore
                    ((element || {}).onsearch = () => {
                        if (onSelect) onSelect(undefined);
                        setSearch("");
                        setSelectedSuggestion(undefined);
                    })
                }
            />

            <StyledDropdown
                style={{
                    visibility: choices.length > 0 ? "visible" : "hidden",
                    whiteSpace: "pre-wrap",
                    overflowWrap: "break-word",
                    ...dropdownStyle,
                }}
            >
                {renderSuggestions()}
            </StyledDropdown>
        </>
    );
}

export default DynamicAutoComplete;