import SmartList from "../../components/SmartList";
import { ISecondaryUser } from "./UserProfileSlice";
import { ISorting } from "../../dashboard/DashboardSlice";
import SecondaryUsersListItem from "./SecondaryUsersListItem";

export interface IListProps {
    secondaryUsers: Array<ISecondaryUser>;
    handleDelete: (secondaryUserId: string) => void;
    setSorting: (sorting: ISorting) => void;
    sorting: ISorting;
}

function SecondaryUsersList(props: IListProps): JSX.Element {
    /* Provide default values and replace them with the prop values if there are any. */
    const { secondaryUsers, handleDelete, setSorting, sorting } = props;
    const headerData = [
        { fieldText: "Name", fieldValue: "user.name" },
        // {
        //     fieldText: "Number of associated accounts",
        //     fieldValue: "user.accounts.length",
        // },
    ];

    const generateItem = (user: ISecondaryUser, index: number) => {
        return (
            <SecondaryUsersListItem
                secondaryUserId={user.id}
                name={user.name}
                // associatedAccounts={user.account.length}
                handleDelete={handleDelete}
            />
        );
    };

    return (
        <SmartList
            headerData={headerData}
            // bumper={"Delete user"}
            arr={secondaryUsers}
            generateItem={generateItem}
            setSorting={setSorting}
            sorting={sorting}
        />
    );
}
export default SecondaryUsersList;
