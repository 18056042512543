//The jsxRuntime pragma is necessary to get Emotion and React to work w/ latest Create-React-App version
/** @jsxRuntime classic */
/** @jsx jsx */
import styled from "@emotion/styled";

export const StyledLoginPage = styled.div`
    width: 100%;
    height: 100vh;

    background: #2c71f0;
`;
