import { useEffect, useMemo, useState, } from "react";
import { infoService } from "../services/infoService";
import DynamicAutoComplete, { IDynamicAutoComplete } from "./DynamicAutoComplete";

export type CryptoSuggestion = {
    id: string;
    exchangeId: string;
    exchangeName: string;
    unsupported: boolean;
};

function CryptoExchangeAutoComplete(props: IDynamicAutoComplete<CryptoSuggestion>): JSX.Element {

    const formatAutoComplete = useMemo(() => {
        return (suggestion: CryptoSuggestion) =>
            suggestion.exchangeName
    }, [])

    const autoComplete = useMemo(() => {
        return async (input: string) => {
            const response = await infoService.autocompleteCryptoExchange(input)
            const suggestions = response.data as Array<CryptoSuggestion>
            return suggestions.map<CryptoSuggestion>(
                (suggestion) => {
                    return {
                        id: suggestion.id,
                        exchangeId: suggestion.exchangeId,
                        exchangeName: suggestion.exchangeName.replace(/\b(\w)/g, s => s.toUpperCase()),
                        unsupported: suggestion.unsupported
                    }
                }
            )
        }
    }, [])

    return (
        <DynamicAutoComplete<CryptoSuggestion> {...props} formatAutoComplete={formatAutoComplete} autoComplete={autoComplete} />
    );
}

export default CryptoExchangeAutoComplete;
