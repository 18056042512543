import React, { useState, useEffect, useContext } from "react";
import debounce from "../util/debounce";

type Dimensions = {
    width: number,
    height: number
};

const DimensionsContext = React.createContext<Dimensions | undefined>(undefined);

export const useDimensions = () => {
  const context = useContext(DimensionsContext);

  if (context === undefined) {
    throw new Error("useDimensions must be used within a DimensionsProvider");
  }

  return context;
};

export const DimensionsProvider = ({ children }: { children: React.ReactNode }) => {
    const [dimensions, setDimensions] = useState<Dimensions>({
        width: window.innerWidth,
        height: window.innerHeight
    })


  useEffect(() => {
    const debouncedHandleResize = debounce(() => {
        setDimensions({width: window.innerWidth, height: window.innerHeight})
    }, 100)
    
    window.addEventListener('resize', debouncedHandleResize)
    return () => window.removeEventListener('resize', debouncedHandleResize)
  }, []);

  return (
    <DimensionsContext.Provider value={dimensions}>{children}</DimensionsContext.Provider>
  );
};