import { accountsService } from "../../services/accountsService";
import { getDateDiffInDay } from "../../util/getDateDiffInDay";
import { kebabToTitleCase } from "../../util/regexHelpers";

interface IListItemProps {
    name: string;
    status: string;
    account: {
        account: string;
        lastUpdated: string;
        accountHolder: string;
        id: string;
        isConnected?: boolean;
        isLinked?: boolean;
        isExchange?: boolean;
        walletAddress?: string;
    };
    statements: any;
    key: number;
}

/**
 * Same parsing ussed in AccountListItem
 */
const parseAccountDate = (date: string) => {
    if (date === null || date === "--" || date == "") return "";
    return new Date(date).toLocaleDateString("en-US", {
        timeZone: "UTC",
    });
};

const formatFilename = (filename: string) => {
    return filename
        .replace(/[^a-z0-9.@ \-]/gi, "_")
        .replace(/[.@ \-]/gi, "")
        .toLowerCase();
};

function AdminAccountsListItem(props: IListItemProps): JSX.Element {
    let status = null;
    if (
        !props.account.walletAddress &&
        !props.account.isExchange &&
        props.account.lastUpdated !== '--'
      ) {
        const lastPull = new Date(props.account.lastUpdated);
        const now = new Date();
        if (getDateDiffInDay(lastPull, now) < 8) {
          status = <span>Feeding</span>;
        } else {
            status = <span>Reauthorize Required</span>;
        }
      }
    else if (props.account.isLinked === false) {
        status = <span>Manual Upload</span>
    }
    else if (props.account.isLinked && props.account.isConnected) {
        status = <span>Feeding</span>
    }
    else {
        status = <span>Requires Connection</span>
    }
    return (
        <>
            <tr>
                <td>{props.name}</td>
                <td>{kebabToTitleCase(props.status)}</td>
                <td>{props.account.account}</td>
                <td>{props.account.accountHolder}</td>
                <td>{status}</td>
                <td>{parseAccountDate(props.account.lastUpdated)}</td>
                <td>
                    <ul>
                        {props.statements
                            ? props.statements.map((statement: any) => {
                                  const filename =
                                      formatFilename(
                                          props.name +
                                              "_" +
                                              props.account.account +
                                              "_" +
                                              props.account.accountHolder +
                                              "_" +
                                              (
                                                  parseInt(statement.month) + 1
                                              ).toString() +
                                              "_" +
                                              statement.year
                                      ) + statement.filetype;
                                  return (
                                      <li
                                          key={statement.id}
                                          style={{ cursor: "pointer" }}
                                          onClick={(e) => {
                                              accountsService.downloadStatement(
                                                  statement.id,
                                                  filename
                                              );
                                          }}
                                      >
                                          <u>{filename}</u>
                                      </li>
                                  );
                              })
                            : null}
                    </ul>
                </td>
            </tr>
        </>
    );
}

export default AdminAccountsListItem;
