import { IRelatedTransaction } from "./AnalyticsSlice";
import SmartList from "../components/SmartList";
import { ISorting } from "../dashboard/DashboardSlice";
import { useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import RelatedTxListItem from "./RelatedTxListItem";
import Modal from "../components/Modal";
import { StyledActionButtonSecondary } from "../components/StyledActionButtonSecondary";
import { StyledModalItem } from "../components/StyledModalItem";
import { StyledTextArea } from "../components/StyledTextArea";
import ModalLabel from "../watchlist/ModalLabel";
import { StyledActionButton } from "../components/StyledActionButton";

interface IProps<T> {
    record: T | undefined;
    title: string;
    onClose: () => void;
    onCompliance: (record: T, note: string) => void;
}

function ComplianceModal<T>(props: IProps<T>): JSX.Element {
    const { record, title, onClose, onCompliance } = props;
    const [note, setNote] = useState("");

    useEffect(() => setNote(""), [record]);

    return (
        <Modal
            show={record}
            onClose={onClose}
            title={title}
            backgroundStyle={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
            }}
            modalStyle={{ height: "auto", maxHeight: "100%" }}
        >
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "row",
                    padding: "3rem 0",
                    overflow: "auto",
                    gap: "1rem",
                }}
            >
                <StyledModalItem>
                    <ModalLabel>Compliance Notes:</ModalLabel>
                    <StyledTextArea
                        placeholder="Please enter your note here..."
                        value={note}
                        name="reason"
                        onChange={(event) => {
                            setNote(event.target.value);
                        }}
                    />
                </StyledModalItem>

                <StyledModalItem>
                    <StyledActionButton
                        onClick={() => {
                            if (record) {
                                onCompliance(record, note);
                                onClose();
                            }
                        }}
                    >
                        Enter
                    </StyledActionButton>
                </StyledModalItem>
            </div>
        </Modal>
    );
}

export default ComplianceModal;
