import { useMemo, useEffect } from "react";
import { StyledActionButtonSecondary } from "../components/StyledActionButtonSecondary";
import { StyledBrokerageDiv } from "../components/StyledBrokerageDiv";
import AccountsStock from "./AccountsStock";
import AccountsCrypto from "./AccountsCrypto";
import { selectVersion } from "../auth/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchUserDeclare,
    selectUserDeclare,
    selectShowUserDeclare,
    updateUserDeclare,
} from "./AccountsSlice";

function Accounts(): JSX.Element {
    const dispatch = useDispatch();

    /* User declare status */
    const userId = useSelector((state: any) => state.auth.userId);
    const userDeclare = useSelector(selectUserDeclare);
    const showUserDeclare = useSelector(selectShowUserDeclare);

    const version = useSelector(selectVersion);
    const company = useSelector((state: any) => state.auth.companyName);

    /* display crypto and stock views */
    const showCryptoAccounts = useMemo(
        () =>
            version === "crypto_monitoring" ||
            version === "crossover_base" ||
            version === "crypto_base",
        [version]
    );
    const showStockAccounts = useMemo(() => version !== "crypto_base", [version]);

    useEffect(() => {
        dispatch(fetchUserDeclare());
    }, [dispatch, userId]);

    const handleDeclare = (declared: boolean) => {
        dispatch(updateUserDeclare(declared));
    };

    return (
        <StyledBrokerageDiv>
            {showUserDeclare && (
                <div
                    style={{
                        lineHeight: "1.5rem",
                        padding: "0 0 .5rem",
                    }}
                >
                    <label
                        htmlFor="user-declare"
                        style={{
                            userSelect: "none",
                            cursor: "pointer",
                        }}
                    >
                        <input
                            checked={userDeclare}
                            onChange={(event) => handleDeclare(event.target.checked)}
                            type="checkbox"
                            id="user-declare"
                        />
                        I do not have any accounts
                    </label>
                </div>
            )}
            {showCryptoAccounts && <AccountsCrypto />}
            {showCryptoAccounts && showStockAccounts ? (
                <div
                    style={{
                        borderBottom: "dashed 1px #75a4fa",
                        margin: 10,
                        marginBottom: 20,
                    }}
                ></div>
            ) : null}
            {(showStockAccounts ||
                (version === "crypto_base" && (company === "Fargus" || company === "ArgusTest" || company === "Paradigm" || company === "Nydig" || company === "Celsius" || company === "OneRiver"))) && (
                    <AccountsStock />
                )}
        </StyledBrokerageDiv>
    );
}

export default Accounts;
