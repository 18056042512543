import capitalizeFirstLetter from "../util/capitalizeFirstLetter";
import { formatDate } from "../util/formatDate";
import { smartTruncate } from "../util/formatValues";

interface IListItemProps {
    account: string;
    asset: string;
    transactionType: string;
    transactionDate: string;
    value: number;
}

function RelatedTxListItem(props: IListItemProps): JSX.Element {
    return (
        <>
            <tr>
                <td>{props.account}</td>
                <td>{props.asset}</td>
                <td>
                    {capitalizeFirstLetter(
                        props.transactionType
                            ? props.transactionType === "UNRECOGNIZED"
                                ? "TRANSACTION"
                                : props.transactionType
                            : ""
                    )}
                </td>
                <td>{smartTruncate(props.value)}</td>
                <td>{formatDate(props.transactionDate)}</td>
            </tr>
        </>
    );
}

export default RelatedTxListItem;
