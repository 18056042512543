//The jsxRuntime pragma is necessary to get Emotion and React to work w/ latest Create-React-App version
/** @jsxRuntime classic */
/** @jsx jsx */
import styled from "@emotion/styled";

export const StyledTableActionButton = styled.button`
    width: 100%;
    background: #8e8e8e;
    font-family: Lato, sans-serif;
    color: #ffffff;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 5px;
    height: 100%;
    padding: 5px;
    font-weight: bold;
    font-style: normal;
    line-height: 17px;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
`;
