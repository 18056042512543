
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useGoogleLoaded } from '../contexts/GoogleAuthContext';

export interface GoogleCredentialResponse {
    credential: string;
}

interface GoogleButtonParams {
    width: number;
    onCredentialResponse: (response: GoogleCredentialResponse) => void;
}

const GoogleButton: FunctionComponent<GoogleButtonParams> = ({
    // loginAbsolutePath,
    width,
    onCredentialResponse,

}) => {

    const google = useGoogleLoaded();
    const divRef = React.createRef<HTMLDivElement>();

    useEffect(() => {
        if (google.isLoaded && divRef.current) {
            (window as any).google.accounts.id.initialize({
                client_id: '731881917509-74285duqpufgdja8ler9g4t979rfoiin.apps.googleusercontent.com',
                // ux_mode: 'redirect',
                // login_uri: loginAbsolutePath,
                callback: onCredentialResponse,
            });
            (window as any).google.accounts.id.renderButton(divRef.current, {
                theme: 'outline',
                size: 'large',
                // width: divRef.current.clientWidth,
                width: width
            });
        }
        // }, [scriptLoaded, divRef, loginAbsolutePath]);
    }, [google.isLoaded]);

    return (
        <>
            <div ref={divRef} />
        </>
    );
};

export default GoogleButton;