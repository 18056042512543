import { IWallet } from "./WalletSlice";
import trashcan from "../assets/trashcan.svg";
import { useMemo, useState } from "react";
import { StyledActionButton } from "../components/StyledActionButton";
import ManualTransactionUploadModal from "../accounts/ManualTransactionModal";
import { StyledSpanRedBubble } from "../components/StyledSpanRedBubble";
import WalletPDFUploadModal from "./WalletsPDFsUpload";
import { useSelector } from "react-redux";
import { selectUserId } from "../auth/AuthSlice";
import { midEllipsis } from "../util/formatValues";

interface IWalletTableProps {
    walletType: string;
    accountHolder: string;
    aggregatedWallets: { [walletType: string]: { [accountHolder: string]: IWallet[] } };
    handleDelete(walletId: string): void;
}

function WalletAddressesTable(props: IWalletTableProps): JSX.Element {
    const { aggregatedWallets, walletType, accountHolder, handleDelete } = props;
    const [showProofUploadModal, setShowProofUploadModal] = useState(false);
    const userId = useSelector(selectUserId);

    const [selectedWalletId, setSelectedWalletId] = useState("");
    const [selectedWalletName, setSelectedWalletName] = useState("");

    const wallets = useMemo(() => {
        if (
            walletType in aggregatedWallets &&
            accountHolder in aggregatedWallets[walletType]
        ) {
            return aggregatedWallets[walletType][accountHolder];
        }
        return [];
    }, [aggregatedWallets, accountHolder, walletType]);
    console.log(wallets);

    const company = useSelector((state: any) => state.auth.companyName);

    return (
        <>
            <table>
                <thead></thead>
                <tbody>
                    {wallets.map((wallet: IWallet, index: number) => {
                        return (
                            <tr key={index}>
                                <td>{midEllipsis(wallet.address)}</td>
                                <td>{wallet.blockchain.displayName}</td>
                                <td>
                                    <span>
                                        {wallet.lastPull === null ? (
                                            <StyledSpanRedBubble>
                                                {" "}
                                                Last update: <b>Never</b>{" "}
                                            </StyledSpanRedBubble>
                                        ) : (
                                            <>
                                                {" "}
                                                Last Update:{" "}
                                                <b>
                                                    {" "}
                                                    {new Date().toLocaleDateString()}{" "}
                                                </b>
                                            </>
                                        )}
                                    </span>
                                </td>
                                <td>
                                    {wallet.blockchain.supported && (
                                        <StyledActionButton
                                            style={{ background: "#22B43A" }}
                                        >
                                            Feeding
                                        </StyledActionButton>
                                    )}

                                    {!wallet.blockchain.supported &&
                                        company !== "Nydig" && (
                                            <StyledActionButton
                                                style={{ background: "grey" }}
                                                onClick={(e) => {
                                                    setSelectedWalletId(wallet.id);
                                                    setSelectedWalletName(
                                                        wallet.blockchain.displayName
                                                    );
                                                }}
                                            >
                                                Input data
                                            </StyledActionButton>
                                        )}

                                    {!wallet.blockchain.supported && (
                                        <StyledActionButton
                                            style={{ background: "grey" }}
                                            onClick={(e) => {
                                                setSelectedWalletId(wallet.id);
                                                setShowProofUploadModal(true);
                                            }}
                                        >
                                            Documents
                                        </StyledActionButton>
                                    )}
                                </td>

                                <td>
                                    <button
                                        style={{
                                            background: "transparent",
                                            border: "none",
                                            cursor: "pointer",
                                        }}
                                        onClick={(e) => {
                                            handleDelete(wallet.id);
                                        }}
                                    >
                                        <img src={trashcan} alt="" />
                                    </button>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            <WalletPDFUploadModal
                show={showProofUploadModal}
                onClose={() => {
                    setSelectedWalletId("");
                    setShowProofUploadModal(false);
                }}
                walletId={selectedWalletId}
                userId={userId}
            />

            {selectedWalletId !== "" && selectedWalletName !== "" && (
                <ManualTransactionUploadModal
                    userWalletId={selectedWalletId}
                    userWalletName={selectedWalletName}
                    onHide={() => {
                        setSelectedWalletId("");
                        setSelectedWalletName("");
                    }}
                />
            )}
        </>
    );
}

export default WalletAddressesTable;
