//The jsxRuntime pragma is necessary to get Emotion and React to work w/ latest Create-React-App version
/** @jsxRuntime classic */
/** @jsx jsx */
import styled from "@emotion/styled";
type ToolsHeaderProps = {
    showPopup: boolean;
};

export const StyledTooltip = styled.div`
    background-color: black;
    color: #fff;
    font-size: 15px;
    width: fit-content;
    border-radius: 8px;
    padding: 4px 10px;
    margin-top: 24px;
    max-width: 300px;
    position: absolute;
    z-index: 1;
    opacity: 1;
    animation: fade 0.75s ease-in-out;
    @keyframes fade {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 0.5;
        }
        100% {
            opacity: 1;
        }
    }
    ::after {
        content: "";
        position: absolute;
        bottom: 98%;
        right: 50%;
        margin-right: -6px;
        border-width: 6px;
        border-style: solid;
        border-color: transparent transparent black transparent;
    }
`;
