import { createRef, useEffect, useRef, useMemo } from "react";
import useInterval from "../util/useInterval";
import {
    fetchCryptoAccounts,
    ICryptoAccount,
    saveNewCryptoAccount,
    selectCryptoAccounts,
} from "./AccountsSlice";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StyledSectionHeader } from "../components/StyledSectionHeader";
import Wallets from "../wallets/Wallets";
import { StyledInputField } from "../components/StyledInputField";
import { StyledActionButton } from "../components/StyledActionButton";
import CryptoExchangeAutoComplete from "../components/CryptoExchangeAutoComplete";
import ManualTransactionUploadModal from "./ManualTransactionModal";
import {
    fetchSecondaryUsers,
    selectSecondaryUsers,
} from "../user/UserProfile/UserProfileSlice";
import DesignatedUserSelect from "../components/DesignatedUserSelect";
import TablePageFooter, { DefaultPagination } from "../components/TablePageFooter";
import { IPagination } from "../util/pagination";
import SmartList from "../components/SmartList";
import AccountCryptoListItem from "./AccountCryptoListItem";
import { ISorting } from "../dashboard/DashboardSlice";

function AccountsCrypto(): JSX.Element {
    /* Local state for inputs, files */
    const designatedUsers = useSelector(selectSecondaryUsers);

    const [activeAccount, setActiveAccount] = useState<string>();
    const [accountHolder, setAccountHolder] = useState("");
    const [description, setDescription] = useState("");

    /* Selector for UserId (add to watchlist for demo) */
    const userId = useSelector((state: any) => state.auth.userId);

    const [searchCrypto, setSearchCrypto] = useState<string>("");

    const [selectUnsupportedAccountId, setSelectUnsupportedAccountId] =
        useState<string>("");
    const [selectUnsupportedAccountName, setSelectUnsupportedAccountName] =
        useState<string>("");

    /* Hooks for pagination. */
    const nextPage = useSelector(
        (state: any) => state.accounts.cryptoAccounts.nextPage
    );
    const [pagination, setPagination] = useState<IPagination>(DefaultPagination());

    /* Hooks for selectors, store*/
    const cryptoAccounts = useSelector(selectCryptoAccounts);
    const dispatch = useDispatch();
    const hiddenFileInputs = useRef<Array<HTMLInputElement>>([]);
    // const [cursor, setCursor] = useState(0);

    /* Bad practice to put here and not in useEffect, but more optimized. */
    /* Essentially, creates a new ref when brokerages.length changes */
    /* The refs are used to connect the visible button to the hidden file input html elements */
    const arrLength = cryptoAccounts ? cryptoAccounts.length : 0;
    if (hiddenFileInputs.current.length !== arrLength) {
        // add or remove refs
        hiddenFileInputs.current = Array(arrLength)
            .fill(0)
            .map((_, i) => hiddenFileInputs.current[i] || createRef());
    }
    const [sorting, setSorting] = useState<ISorting>({
        field: "lastUpdated",
        direction: "desc",
    });

    const sortingByDate: ISorting[] = useMemo(() => {
        if (sorting.field === "lastUpdated") return [sorting];
        return [sorting];
    }, [sorting]);

    useEffect(() => {
        if (userId !== -1) {
            dispatch(
                fetchCryptoAccounts({
                    ...pagination,
                    sorting: sortingByDate,
                })
            );
            dispatch(fetchSecondaryUsers());
        }
    }, [dispatch, userId, pagination, sortingByDate]);

    useEffect(() => {
        if (!accountHolder) {
            setDefaultAccountHolder();
        }
    }, [accountHolder]);

    useEffect(() => {
        setDefaultAccountHolder();
    }, [designatedUsers, designatedUsers.length]);

    useInterval(() => {
        if (userId !== -1) {
            dispatch(
                fetchCryptoAccounts({
                    ...pagination,
                    sorting: sortingByDate,
                })
            );
        }
    }, 15000);

    // const capitalizeExchange = (exchange: string) => {
    //     return exchange[0].toUpperCase() + exchange.substring(1);
    // };

    const handleClickAddAccount = (event: any) => {
        console.log(event.key);
        if (activeAccount && activeAccount !== "") {
            dispatch(
                saveNewCryptoAccount({
                    exchangeId: activeAccount,
                    accountHolderId: accountHolder,
                    description: description,
                })
            );
            setAccountHolder("");
            setActiveAccount(undefined);
            setSearchCrypto("");
            setDescription("");
        }
    };

    const handleClick = (event: any, inputIndex: number) => {
        if (hiddenFileInputs.current[inputIndex]) {
            if (hiddenFileInputs.current[inputIndex]) {
                hiddenFileInputs.current[inputIndex].click();
            }
        }
    };

    const company = useSelector((state: any) => state.auth.companyName);

    const setDefaultAccountHolder = () => {
        if (designatedUsers.length > 0) {
            setAccountHolder(designatedUsers[0].id);
        } else {
            setAccountHolder(userId);
        }
    };

    const generateItem = (account: ICryptoAccount, index: number) => (
        <AccountCryptoListItem
            id={account.id}
            accountHolder={account.accountHolder}
            description={account.description}
            exchangeName={account.exchangeName}
            exchangeId={account.cryptoExchange.exchangeId}
            supported={account.supported}
            isConnected={account.isConnected}
            lastUpdated={account.lastUpdated}
            handleClick={handleClick}
            hiddenFileInputs={hiddenFileInputs}
            index={index}
            setSelectUnsupportedAccountId={setSelectUnsupportedAccountId}
            setSelectUnsupportedAccountName={setSelectUnsupportedAccountName}
            userId={userId}
        />
    );

    const headerData = [
        { fieldText: "Exchange", fieldValue: "exchange" },
        { fieldText: "Account Holder", fieldValue: "accountHolder" },
        { fieldText: "Last Updated", fieldValue: "lastUpdated" },
        { fieldText: "Status", fieldValue: "" },
        { fieldText: "", fieldValue: "" },
    ];

    return (
        <>
            <br />

            <StyledSectionHeader>Add an Exchange Account</StyledSectionHeader>

            <div style={{ display: "flex" }}>
                <div style={{ width: "67%", display: "flex" }}>
                    <div style={{ width: "50%" }}>
                        <CryptoExchangeAutoComplete
                            style={{ width: "100%" }}
                            dropdownStyle={{ width: "20%" }}
                            placeholder="Add another account"
                            onChange={setSearchCrypto}
                            value={searchCrypto}
                            onSelect={(value) => {
                                if (value) {
                                    setActiveAccount(value.id);
                                }
                            }}
                        />
                    </div>

                    <DesignatedUserSelect
                        accountHolderId={accountHolder}
                        setAccountHolderId={setAccountHolder}
                    />
                    {(company === "Nydig" ||
                        company === "Argus" ||
                        company === "Fargus" ||
                        company === "ArgusTest") && (
                        <StyledInputField
                            style={{ width: "50%" }}
                            placeholder="Description"
                            value={description}
                            onChange={(event) => {
                                setDescription(event.target.value);
                            }}
                        />
                    )}
                </div>
                <StyledActionButton
                    style={{ width: "33%" }}
                    onClick={handleClickAddAccount}
                >
                    Add Exchange Account
                </StyledActionButton>
            </div>

            {/* <table>
                <thead>
                    <tr>
                        <th>Exchange</th>
                        <th>Account Holder</th>
                        <th>Last Updated</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {cryptoAccounts
                        ? cryptoAccounts.map(
                              (account: ICryptoAccount, index: number) => {
                                  return (
                                      <tr
                                          key={index}
                                          onMouseEnter={() => setShowDescription(index)}
                                          onMouseLeave={() => setShowDescription(null)}
                                      >
                                          <td>
                                              <div
                                                  style={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                      position: "relative",
                                                  }}
                                              >
                                                  {showDescription === index &&
                                                  account.description ? (
                                                      <StyledTooltip>
                                                          {account.description}
                                                      </StyledTooltip>
                                                  ) : null}
                                                  {account.exchangeName}
                                              </div>
                                          </td>
                                          <td>{account.accountHolder}</td>
                                          <td>
                                              <span>
                                                  {account.lastUpdated === null &&
                                                  account.isConnected === false ? (
                                                      <StyledSpanRedBubble>
                                                          {" "}
                                                          Last update: <b>Never</b>{" "}
                                                      </StyledSpanRedBubble>
                                                  ) : account.lastUpdated === null ? (
                                                      <StyledSpanYellowBubble>
                                                          {" "}
                                                          Pending{" "}
                                                      </StyledSpanYellowBubble>
                                                  ) : (
                                                      <>
                                                          {" "}
                                                          Last Update:{" "}
                                                          <b>
                                                              {" "}
                                                              {new Date().toLocaleDateString()}{" "}
                                                          </b>
                                                      </>
                                                  )}
                                              </span>
                                          </td>
                                          <td>
                                              {
                                                  <>
                                                      {!account.supported &&
                                                          company !== "Nydig" && (
                                                              <StyledTableActionButton
                                                                  style={{
                                                                      background:
                                                                          "grey",
                                                                  }}
                                                                  onClick={(event) => {
                                                                      setSelectUnsupportedAccountId(
                                                                          account.id
                                                                      );
                                                                      setSelectUnsupportedAccountName(
                                                                          account.exchangeName
                                                                      );
                                                                  }}
                                                              >
                                                                  <span>
                                                                      Input data
                                                                  </span>
                                                              </StyledTableActionButton>
                                                          )}

                                                      {!account.supported && (
                                                          <StyledTableActionButton
                                                              style={{
                                                                  background: "grey",
                                                              }}
                                                              onClick={(event) => {
                                                                  setShowAdditionalProofsModal(
                                                                      account.id
                                                                  );
                                                              }}
                                                          >
                                                              <span>Documents</span>
                                                          </StyledTableActionButton>
                                                      )}
                                                      {showAdditionalProofsModal ===
                                                          account.id && (
                                                          <ExchangesPDFUploadModal
                                                              show={
                                                                  showAdditionalProofsModal ===
                                                                  account.id
                                                              }
                                                              onClose={() =>
                                                                  setShowAdditionalProofsModal(
                                                                      undefined
                                                                  )
                                                              }
                                                              exchangeId={account.id}
                                                              userId={userId}
                                                          />
                                                      )}

                                                      {account.supported && (
                                                          <StyledTableActionButton
                                                              style={{
                                                                  background:
                                                                      !account.isConnected
                                                                          ? "#F02C38"
                                                                          : "#22B43A",
                                                              }}
                                                              onClick={(event) => {
                                                                  handleClick(
                                                                      event,
                                                                      index
                                                                  );
                                                              }}
                                                          >
                                                              {account.isConnected ? (
                                                                  <span>Feeding</span>
                                                              ) : (
                                                                  <span>Connect</span>
                                                              )}
                                                          </StyledTableActionButton>
                                                      )}

                                                      {!account.isConnected ? (
                                                          <ConnectCrypto
                                                              exchangeId={
                                                                  exchangeNameToId.get(
                                                                      account.exchangeName
                                                                  ) as string
                                                              }
                                                              exchangeName={
                                                                  account.exchangeName
                                                              }
                                                              docs={lookupDocs(
                                                                  account.exchangeName
                                                              )}
                                                              onSubmit={(
                                                                  ccxt_exchange_id: string,
                                                                  exchangeId: string,
                                                                  apiKey: string,
                                                                  apiSecret: string,
                                                                  apiPassphrase?: string
                                                              ) =>
                                                                  onSubmit(
                                                                      exchangeId,
                                                                      account.id,
                                                                      apiKey,
                                                                      apiSecret,
                                                                      apiPassphrase
                                                                  )
                                                              }
                                                              title={
                                                                  "Connect your " +
                                                                  lookupDisplayName(
                                                                      account.exchangeName
                                                                  ) +
                                                                  " account."
                                                              }
                                                              key={index}
                                                              forwardedRef={(
                                                                  el: any
                                                              ) => {
                                                                  hiddenFileInputs.current[
                                                                      index
                                                                  ] = el;
                                                              }}
                                                          />
                                                      ) : null}
                                                  </>
                                              }
                                          </td>
                                          <td>
                                              <button
                                                  style={{
                                                      background: "transparent",
                                                      border: "none",
                                                      cursor: "pointer",
                                                  }}
                                                  onClick={(e) => {
                                                      dispatch(
                                                          saveDeleteCryptoAccount(
                                                              account.id
                                                          )
                                                      );
                                                  }}
                                              >
                                                  <img src={trashcan} alt="" />
                                              </button>
                                          </td>
                                      </tr>
                                  );
                              }
                          )
                        : null}
                </tbody>
            </table> */}

            <SmartList
                arr={cryptoAccounts}
                generateItem={generateItem}
                headerData={headerData}
                setSorting={setSorting}
                sorting={sorting}
                disabled={["status", ""]}
            />
            <TablePageFooter
                nextPage={nextPage}
                pagination={pagination}
                setPageNumber={(n) => setPagination({ ...pagination, pageNumber: n })}
                setPageSize={(s) => setPagination({ ...pagination, pageSize: s })}
            />

            <Wallets />

            {selectUnsupportedAccountId !== "" && selectUnsupportedAccountName !== "" && (
                <ManualTransactionUploadModal
                    userExchangeAccountId={selectUnsupportedAccountId}
                    userExchangeAccountName={selectUnsupportedAccountName}
                    onHide={() => {
                        setSelectUnsupportedAccountId("");
                        setSelectUnsupportedAccountName("");
                    }}
                />
            )}
        </>
    );
}

export default AccountsCrypto;
