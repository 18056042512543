export const allowedEtfsJson = {
    etfs: [
        "AAXJ",
        "ACIO",
        "ACSG",
        "ACWF",
        "ACWI",
        "ACWV",
        "ACWX",
        "ADME",
        "ADRE",
        "AFIF",
        "AFK",
        "AFLG",
        "AFMC",
        "AFSM",
        "AFTY",
        "AGG",
        "AGGY",
        "AGNG",
        "AGZ",
        "AGZD",
        "AIA",
        "AIEQ",
        "AIIQ",
        "AIQ",
        "AIRR",
        "ALTL",
        "AMCA",
        "AMER",
        "AMZA",
        "ANEW",
        "ANGL",
        "ARCM",
        "ARKG",
        "ARKK",
        "ARKW",
        "ASET",
        "ASHR",
        "ASHS",
        "ASHX",
        "AUSF",
        "AVDE",
        "AVDV",
        "AVEM",
        "AVUS",
        "AVUV",
        "AWTM",
        "AXJO",
        "BAB",
        "BANK",
        "BATT",
        "BBAX",
        "BBC",
        "BBCA",
        "BBEU",
        "BBIN",
        "BBJP",
        "BBMC",
        "BBP",
        "BBRE",
        "BBSA",
        "BBSC",
        "BBUS",
        "BFIT",
        "BFOR",
        "BFTR",
        "BGRN",
        "BIB",
        "BIBL",
        "BICK",
        "BIV",
        "BKAG",
        "BKEM",
        "BKF",
        "BKHY",
        "BKIE",
        "BKLC",
        "BKLN",
        "BKMC",
        "BKSB",
        "BKSE",
        "BLCN",
        "BLES",
        "BLHY",
        "BLV",
        "BMED",
        "BND",
        "BNDX",
        "BNE",
        "BOND",
        "BOSS",
        "BRF",
        "BSBE",
        "BSCE",
        "BSCL",
        "BSCM",
        "BSCN",
        "BSCO",
        "BSCP",
        "BSCQ",
        "BSCR",
        "BSCS",
        "BSCT",
        "BSCU",
        "BSDE",
        "BSJM",
        "BSJN",
        "BSJO",
        "BSJP",
        "BSJQ",
        "BSJR",
        "BSJS",
        "BSML",
        "BSMM",
        "BSMN",
        "BSMO",
        "BSMP",
        "BSMQ",
        "BSMR",
        "BSMS",
        "BSMT",
        "BSMU",
        "BSV",
        "BTAL",
        "BTEC",
        "BTEK",
        "BTK",
        "BUL",
        "BUYZ",
        "BUZZ",
        "BWX",
        "BWZ",
        "CACG",
        "CALF",
        "CATH",
        "CBON",
        "CDC",
        "CDL",
        "CEFA",
        "CEMB",
        "CEY",
        "CFA",
        "CFO",
        "CGW",
        "CHGX",
        "CHIH",
        "CHII",
        "CHIK",
        "CHIL",
        "CHIM",
        "CHIQ",
        "CHIR",
        "CHIS",
        "CHIX",
        "CHNA",
        "CID",
        "CIL",
        "CIZ",
        "CLDL",
        "CLRG",
        "CLTL",
        "CMBS",
        "CMF",
        "CN",
        "CNXT",
        "CNYA",
        "CORP",
        "COWZ",
        "CQQQ",
        "CRBN",
        "CSA",
        "CSB",
        "CSF",
        "CSM",
        "CSML",
        "CURE",
        "CUT",
        "CVY",
        "CWB",
        "CWI",
        "CXSE",
        "CZA",
        "DBAW",
        "DBEF",
        "DBEM",
        "DBEU",
        "DBEZ",
        "DBGR",
        "DBJP",
        "DBLV",
        "DDIV",
        "DEED",
        "DEEF",
        "DEEP",
        "DEF",
        "DEFN",
        "DEM",
        "DES",
        "DEUS",
        "DEW",
        "DFE",
        "DFJ",
        "DFND",
        "DFNV",
        "DGRE",
        "DGRO",
        "DGRS",
        "DGRW",
        "DGS",
        "DGT",
        "DHS",
        "DIM",
        "DIV",
        "DIVA",
        "DIVB",
        "DJA",
        "DJUSFN",
        "DLN",
        "DLS",
        "DMDV",
        "DMRE",
        "DMRI",
        "DMRL",
        "DMRM",
        "DMRS",
        "DMXF",
        "DNL",
        "DOL",
        "DON",
        "DOO",
        "DPST",
        "DRIV",
        "DRN",
        "DRW",
        "DSI",
        "DSTL",
        "DSTX",
        "DTD",
        "DTEC",
        "DTH",
        "DTN",
        "DURA",
        "DUSL",
        "DVLU",
        "DVOL",
        "DVY",
        "DVYA",
        "DVYE",
        "DWAS",
        "DWCR",
        "DWM",
        "DWMC",
        "DWPP",
        "DWSH",
        "DWX",
        "DYNF",
        "EAGG",
        "EASG",
        "EBND",
        "ECNS",
        "ECOW",
        "EDEN",
        "EDIV",
        "EDOG",
        "EDV",
        "EELV",
        "EEM",
        "EEMA",
        "EEMD",
        "EEMO",
        "EEMS",
        "EEMV",
        "EEMX",
        "EES",
        "EFA",
        "EFAD",
        "EFAS",
        "EFAV",
        "EFAX",
        "EFG",
        "EFIV",
        "EFIX",
        "EFV",
        "EIDO",
        "EIS",
        "EKAR",
        "EMAG",
        "EMB",
        "EMBD",
        "EMCB",
        "EMDV",
        "EMFM",
        "EMGF",
        "EMHC",
        "EMHY",
        "EMLC",
        "EMLP",
        "EMNT",
        "EMQQ",
        "EMSG",
        "EMTL",
        "EMXC",
        "EMXF",
        "ENOR",
        "EOPS",
        "EPI",
        "EPP",
        "EPRF",
        "EPS",
        "EQAL",
        "EQOP",
        "EQRR",
        "EQWL",
        "ERTH",
        "ESCR",
        "ESEB",
        "ESG",
        "ESGD",
        "ESGE",
        "ESGG",
        "ESGU",
        "ESGV",
        "ESHY",
        "ESML",
        "ESNG",
        "ETHO",
        "EUDG",
        "EUFN",
        "EURZ",
        "EUSA",
        "EUSB",
        "EWA",
        "EWC",
        "EWD",
        "EWEB",
        "EWG",
        "EWGS",
        "EWJ",
        "EWJE",
        "EWJV",
        "EWK",
        "EWMC",
        "EWN",
        "EWQ",
        "EWSC",
        "EWT",
        "EWU",
        "EWUS",
        "EWW",
        "EWX",
        "EWY",
        "EWZ",
        "EWZS",
        "EXI",
        "EYLD",
        "EZM",
        "EZU",
        "FAB",
        "FAD",
        "FALN",
        "FAN",
        "FAS",
        "FAUS",
        "FBCG",
        "FBCV",
        "FBND",
        "FBZ",
        "FCAL",
        "FCAN",
        "FCEF",
        "FCOM",
        "FCOR",
        "FCPI",
        "FCTR",
        "FCVT",
        "FDHY",
        "FDL",
        "FDLO",
        "FDM",
        "FDRR",
        "FDT",
        "FDTS",
        "FDVV",
        "FEM",
        "FEMB",
        "FEMS",
        "FEP",
        "FEUZ",
        "FEX",
        "FEZ",
        "FFIU",
        "FFR",
        "FFTY",
        "FGD",
        "FGRO",
        "FHK",
        "FHLC",
        "FIBR",
        "FICS",
        "FID",
        "FIDI",
        "FIDU",
        "FIGB",
        "FILL",
        "FITE",
        "FIVA",
        "FIVG",
        "FIXD",
        "FJP",
        "FKO",
        "FKU",
        "FLAU",
        "FLAX",
        "FLBL",
        "FLBR",
        "FLCA",
        "FLCB",
        "FLCH",
        "FLCO",
        "FLDR",
        "FLEE",
        "FLEH",
        "FLFR",
        "FLGB",
        "FLGR",
        "FLHK",
        "FLHY",
        "FLIA",
        "FLIN",
        "FLJH",
        "FLJP",
        "FLKR",
        "FLLA",
        "FLLV",
        "FLM",
        "FLMB",
        "FLMI",
        "FLN",
        "FLOT",
        "FLQD",
        "FLQE",
        "FLQG",
        "FLQH",
        "FLQL",
        "FLQM",
        "FLQS",
        "FLRG",
        "FLRN",
        "FLSA",
        "FLSP",
        "FLSW",
        "FLTR",
        "FLTW",
        "FLZA",
        "FM",
        "FMAG",
        "FMAT",
        "FMB",
        "FMHI",
        "FMIL",
        "FNCL",
        "FNDA",
        "FNDB",
        "FNDC",
        "FNDE",
        "FNDF",
        "FNDX",
        "FNI",
        "FNK",
        "FNX",
        "FNY",
        "FPA",
        "FPE",
        "FPEI",
        "FPX",
        "FPXE",
        "FPXI",
        "FQAL",
        "FRDM",
        "FREL",
        "FRI",
        "FSMB",
        "FSMO",
        "FSTA",
        "FTA",
        "FTAG",
        "FTAS",
        "FTASX2790",
        "FTASX8980",
        "FTC",
        "FTCS",
        "FTEC",
        "FTHI",
        "FTLB",
        "FTLC",
        "FTLS",
        "FTRI",
        "FTSC",
        "FTSD",
        "FTSE",
        "FTSL",
        "FTSM",
        "FTXD",
        "FTXN",
        "FUMB",
        "FUTY",
        "FVAL",
        "FVD",
        "FVL",
        "FXD",
        "FXH",
        "FXI",
        "FXL",
        "FXO",
        "FXR",
        "FYC",
        "FYLD",
        "FYT",
        "FYX",
        "GAMR",
        "GBF",
        "GBLD",
        "GCOR",
        "GCOW",
        "GDMA",
        "GDX",
        "GDXJ",
        "GEM",
        "GENY",
        "GERM",
        "GHYB",
        "GHYG",
        "GIGB",
        "GII",
        "GINN",
        "GLCN",
        "GLIF",
        "GLIN",
        "GNMA",
        "GNR",
        "GOAT",
        "GOEX",
        "GOVT",
        "GQRE",
        "GRES",
        "GRID",
        "GRNB",
        "GSEE",
        "GSEU",
        "GSEW",
        "GSID",
        "GSIE",
        "GSIG",
        "GSJY",
        "GSLC",
        "GSSC",
        "GSST",
        "GSUS",
        "GSY",
        "GTO",
        "GUNR",
        "GURU",
        "GVAL",
        "GVI",
        "GVIP",
        "GWX",
        "GXC",
        "GYLD",
        "HACK",
        "HAIL",
        "HAP",
        "HART",
        "HAUZ",
        "HCRB",
        "HDAW",
        "HDEF",
        "HDG",
        "HDMV",
        "HDV",
        "HELX",
        "HEX",
        "HFXI",
        "HIBL",
        "HIPR",
        "HLGE",
        "HMOP",
        "HOLD",
        "HOMZ",
        "HSCE",
        "HSI",
        "HSMV",
        "HSPX",
        "HSRT",
        "HTAB",
        "HTEC",
        "HTRB",
        "HUSV",
        "HYBB",
        "HYDB",
        "HYDW",
        "HYEM",
        "HYG",
        "HYGV",
        "HYHG",
        "HYLB",
        "HYLD",
        "HYLS",
        "HYLV",
        "HYMB",
        "HYMU",
        "HYS",
        "HYUP",
        "HYXE",
        "HYXF",
        "HYXU",
        "HYZD",
        "IAGG",
        "IAK",
        "IAT",
        "IBB",
        "IBBJ",
        "IBCE",
        "IBDD",
        "IBDM",
        "IBDN",
        "IBDO",
        "IBDP",
        "IBDQ",
        "IBDR",
        "IBDS",
        "IBDT",
        "IBDU",
        "IBDV",
        "IBHA",
        "IBHB",
        "IBHC",
        "IBHD",
        "IBHE",
        "IBHF",
        "IBMJ",
        "IBMK",
        "IBML",
        "IBMM",
        "IBMN",
        "IBMO",
        "IBMP",
        "IBMQ",
        "IBND",
        "IBUY",
        "ICLN",
        "ICOW",
        "ICSH",
        "ICVT",
        "IDEV",
        "IDHD",
        "IDHQ",
        "IDLB",
        "IDLV",
        "IDMO",
        "IDNA",
        "IDOG",
        "IDRV",
        "IDU",
        "IDV",
        "IECS",
        "IEDI",
        "IEFA",
        "IEFN",
        "IEHS",
        "IEI",
        "IEIH",
        "IEME",
        "IEMG",
        "IETC",
        "IEUR",
        "IEUS",
        "IEV",
        "IFGL",
        "IFRA",
        "IG",
        "IGE",
        "IGEB",
        "IGF",
        "IGHG",
        "IGIB",
        "IGLB",
        "IGM",
        "IGOV",
        "IGRO",
        "IGSB",
        "IGV",
        "IHAK",
        "IHE",
        "IHF",
        "IHI",
        "IHY",
        "IHYF",
        "IIGD",
        "IIGV",
        "IJH",
        "IJJ",
        "IJK",
        "IJR",
        "IJS",
        "IJT",
        "ILCB",
        "ILCG",
        "ILCV",
        "ILDR",
        "ILF",
        "ILTB",
        "IMCB",
        "IMCG",
        "IMCV",
        "IMFL",
        "IMOM",
        "IMTB",
        "IMTM",
        "INDA",
        "INDY",
        "INFR",
        "INMU",
        "INTF",
        "IOO",
        "IPAC",
        "IPFF",
        "IPKW",
        "IPO",
        "IPOS",
        "IQDE",
        "IQDF",
        "IQDG",
        "IQDY",
        "IQIN",
        "IQLT",
        "IQM",
        "IQSI",
        "IQSU",
        "IRBO",
        "ISCB",
        "ISCF",
        "ISCG",
        "ISCV",
        "ISDX",
        "ISEM",
        "ISHG",
        "ISMD",
        "ISRA",
        "ISTB",
        "ISVL",
        "ISZE",
        "ITEQ",
        "ITOT",
        "IUS",
        "IUSB",
        "IUSG",
        "IUSS",
        "IUSV",
        "IVAL",
        "IVE",
        "IVES",
        "IVLC",
        "IVLU",
        "IVOG",
        "IVOO",
        "IVOV",
        "IVRA",
        "IVV",
        "IVW",
        "IWB",
        "IWC",
        "IWD",
        "IWF",
        "IWFH",
        "IWL",
        "IWM",
        "IWN",
        "IWO",
        "IWP",
        "IWR",
        "IWS",
        "IWV",
        "IWX",
        "IWY",
        "IXBK",
        "IXC",
        "IXF",
        "IXG",
        "IXIC",
        "IXJ",
        "IXN",
        "IXP",
        "IXSE",
        "IXTR",
        "IXUS",
        "IXUT",
        "IYC",
        "IYF",
        "IYG",
        "IYH",
        "IYJ",
        "IYK",
        "IYR",
        "IYW",
        "IYY",
        "IYZ",
        "IZRL",
        "JAGG",
        "JCPB",
        "JCTR",
        "JDIV",
        "JEMA",
        "JEPI",
        "JGLD",
        "JHCB",
        "JHEM",
        "JHMC",
        "JHMD",
        "JHMF",
        "JHMH",
        "JHMI",
        "JHML",
        "JHMM",
        "JHMS",
        "JHMT",
        "JHSC",
        "JIG",
        "JIGB",
        "JKD",
        "JKE",
        "JKF",
        "JKG",
        "JKH",
        "JKI",
        "JKJ",
        "JKK",
        "JKL",
        "JMBS",
        "JMIN",
        "JMOM",
        "JMST",
        "JMUB",
        "JNK",
        "JOET",
        "JPEM",
        "JPGB",
        "JPIN",
        "JPMB",
        "JPME",
        "JPN",
        "JPSE",
        "JPST",
        "JPUS",
        "JPXN",
        "JQUA",
        "JSCP",
        "JSMD",
        "JSML",
        "JUST",
        "JVAL",
        "JXI",
        "KALL",
        "KARS",
        "KBA",
        "KBE",
        "KBWR",
        "KCE",
        "KEMQ",
        "KEMX",
        "KESG",
        "KFYP",
        "KIE",
        "KMED",
        "KNG",
        "KOKU",
        "KOMP",
        "KRE",
        "KRMA",
        "KS100",
        "KS200",
        "KSA",
        "KSTR",
        "KURE",
        "KWEB",
        "KXI",
        "LABU",
        "LCTD",
        "LCTU",
        "LDEM",
        "LDUR",
        "LEAD",
        "LEGR",
        "LEMB",
        "LEND",
        "LGLV",
        "LGOV",
        "LKOR",
        "LMBS",
        "LNGR",
        "LOWC",
        "LQD",
        "LQDB",
        "LRGF",
        "LSAF",
        "LSLT",
        "LSST",
        "LTL",
        "LVHD",
        "LVHI",
        "MBB",
        "MBBB",
        "MBND",
        "MBSD",
        "MCHI",
        "MCRO",
        "MDAXI",
        "MDIV",
        "MDY",
        "MDYG",
        "MDYV",
        "MEAR",
        "MFDX",
        "MFEM",
        "MFUS",
        "MGC",
        "MGK",
        "MGV",
        "MID",
        "MIDE",
        "MIDF",
        "MIG",
        "MILN",
        "MINC",
        "MINT",
        "MMIN",
        "MMIT",
        "MMLG",
        "MMTM",
        "MNA",
        "MOAT",
        "MOM",
        "MOO",
        "MOON",
        "MOTI",
        "MTGP",
        "MTUM",
        "MUB",
        "MUNI",
        "MVV",
        "MXDU",
        "MXI",
        "N100",
        "N225",
        "N300",
        "NACP",
        "NAIL",
        "NBI",
        "NDX",
        "NEAR",
        "NFLT",
        "NFRA",
        "NFTY",
        "NIFE",
        "NOBL",
        "NORW",
        "NSEI",
        "NTSI",
        "NTSX",
        "NUAG",
        "NUBD",
        "NUDM",
        "NUEM",
        "NULG",
        "NULV",
        "NUMG",
        "NUMV",
        "NUSA",
        "NUSC",
        "NXTG",
        "NYA",
        "NYF",
        "OBOR",
        "OEF",
        "OEUR",
        "OEX",
        "OGIG",
        "OMFL",
        "OMFS",
        "ONEO",
        "ONEQ",
        "ONEV",
        "ONEY",
        "ONOF",
        "OSCV",
        "OUSA",
        "OUSM",
        "PAB",
        "PALC",
        "PAMC",
        "PAVE",
        "PBD",
        "PBDM",
        "PBEE",
        "PBND",
        "PBP",
        "PBSM",
        "PBUS",
        "PBW",
        "PCEF",
        "PCY",
        "PDEV",
        "PDN",
        "PDP",
        "PEXL",
        "PEY",
        "PEZ",
        "PFF",
        "PFFA",
        "PFFD",
        "PFFR",
        "PFFV",
        "PFI",
        "PFIG",
        "PFLD",
        "PFM",
        "PFXF",
        "PGF",
        "PGHY",
        "PGJ",
        "PGX",
        "PHB",
        "PHDG",
        "PHYL",
        "PICB",
        "PICK",
        "PID",
        "PIE",
        "PIFI",
        "PILL",
        "PIN",
        "PIO",
        "PIZ",
        "PKW",
        "PLAT",
        "PLRG",
        "PLTL",
        "PNQI",
        "PPA",
        "PQDI",
        "PQIN",
        "PQLC",
        "PQSG",
        "PQSV",
        "PREF",
        "PRF",
        "PRFZ",
        "PRME",
        "PRNT",
        "PSC",
        "PSCD",
        "PSCF",
        "PSCH",
        "PSCI",
        "PSCT",
        "PSET",
        "PSK",
        "PSP",
        "PSR",
        "PTBD",
        "PTEU",
        "PTH",
        "PTIN",
        "PTLC",
        "PTMC",
        "PTNQ",
        "PULS",
        "PWB",
        "PWC",
        "PWS",
        "PWV",
        "PWZ",
        "PXF",
        "PXH",
        "PXUS",
        "PY",
        "PZA",
        "PZT",
        "QABA",
        "QAI",
        "QARP",
        "QCLN",
        "QDEF",
        "QDF",
        "QDIV",
        "QDYN",
        "QEFA",
        "QEMM",
        "QLC",
        "QLD",
        "QLS",
        "QLTA",
        "QLV",
        "QLVD",
        "QLVE",
        "QMJ",
        "QMN",
        "QMOM",
        "QQEW",
        "QQQ",
        "QQQE",
        "QQQJ",
        "QQQM",
        "QQQN",
        "QQXT",
        "QSY",
        "QTUM",
        "QUAL",
        "QUS",
        "QVAL",
        "QWLD",
        "QYLD",
        "QYLG",
        "RAFE",
        "RALS",
        "RAVI",
        "RBIN",
        "RBND",
        "RBUS",
        "RCD",
        "RDIV",
        "RDVY",
        "REC",
        "REET",
        "REGL",
        "RESE",
        "RETL",
        "REZ",
        "RFAP",
        "RFCI",
        "RFDA",
        "RFDI",
        "RFEM",
        "RFEU",
        "RFFC",
        "RFG",
        "RFUN",
        "RFV",
        "RGI",
        "RIGS",
        "RING",
        "RISN",
        "RNDM",
        "RNDV",
        "RNEM",
        "RNLC",
        "RNMC",
        "RNSC",
        "ROAM",
        "ROBO",
        "ROBT",
        "RODE",
        "RODM",
        "ROM",
        "ROOF",
        "ROSC",
        "ROUS",
        "RPG",
        "RPV",
        "RSP",
        "RUI",
        "RUT",
        "RVNU",
        "RWJ",
        "RWK",
        "RWL",
        "RWO",
        "RWR",
        "RWX",
        "RXI",
        "RXL",
        "RYF",
        "RYH",
        "RYJ",
        "RYT",
        "RZG",
        "RZV",
        "SAA",
        "SBIO",
        "SCHA",
        "SCHB",
        "SCHC",
        "SCHD",
        "SCHE",
        "SCHF",
        "SCHG",
        "SCHH",
        "SCHI",
        "SCHJ",
        "SCHK",
        "SCHM",
        "SCHO",
        "SCHQ",
        "SCHR",
        "SCHV",
        "SCHX",
        "SCHY",
        "SCHZ",
        "SCJ",
        "SCZ",
        "SDAXI",
        "SDEM",
        "SDG",
        "SDGA",
        "SDIV",
        "SDOG",
        "SDVY",
        "SDY",
        "SEIX",
        "SENT",
        "SFHY",
        "SFIG",
        "SHAG",
        "SHE",
        "SHLD",
        "SHM",
        "SHV",
        "SHY",
        "SHYG",
        "SHYL",
        "SILJ",
        "SIZE",
        "SJNK",
        "SKOR",
        "SKYY",
        "SLQD",
        "SLT",
        "SLY",
        "SLYG",
        "SLYV",
        "SMDV",
        "SMDY",
        "SMIN",
        "SMLE",
        "SMLF",
        "SMLV",
        "SMMU",
        "SMMV",
        "SMOG",
        "SNPE",
        "SPAB",
        "SPAK",
        "SPBO",
        "SPCY",
        "SPDV",
        "SPDW",
        "SPEM",
        "SPEU",
        "SPGM",
        "SPGP",
        "SPHB",
        "SPHD",
        "SPHQ",
        "SPHY",
        "SPIB",
        "SPLB",
        "SPLG",
        "SPLV",
        "SPMB",
        "SPMD",
        "SPMO",
        "SPMV",
        "SPQQ",
        "SPRE",
        "SPSB",
        "SPSK",
        "SPSM",
        "SPSUPX",
        "SPTI",
        "SPTL",
        "SPTM",
        "SPTS",
        "SPUS",
        "SPVM",
        "SPVU",
        "SPX",
        "SPXB",
        "SPXE",
        "SPXN",
        "SPXT",
        "SPXV",
        "SPY",
        "SPYD",
        "SPYG",
        "SPYV",
        "SPYX",
        "SQLV",
        "SRLN",
        "SSHI",
        "SSLY",
        "SSO",
        "SSPY",
        "STLC",
        "STLG",
        "STLV",
        "STMB",
        "STOT",
        "STOXXIEX.DE",
        "STSB",
        "SUB",
        "SUSA",
        "SUSB",
        "SUSC",
        "SUSL",
        "SVAL",
        "SXDPEX.DE",
        "SXNPEX.DE",
        "SYLD",
        "SZNE",
        "TAN",
        "TDIV",
        "TECB",
        "TECL",
        "TERM",
        "TFI",
        "THD",
        "THNQ",
        "TILT",
        "TIP",
        "TLTD",
        "TLTE",
        "TMDV",
        "TMFC",
        "TOK",
        "TOLZ",
        "TOPX",
        "TOPX100",
        "TOPX500",
        "TOPXL",
        "TOPXM",
        "TOPXS",
        "TOTL",
        "TPAY",
        "TPYP",
        "TQQQ",
        "TSI2",
        "TSX-TC",
        "TTAC",
        "TTAI",
        "TUR",
        "TUSA",
        "TXCC-TC",
        "TXMC-TC",
        "TXSC-TC",
        "UCC",
        "UCON",
        "UEVM",
        "UGE",
        "UITB",
        "UIVM",
        "ULST",
        "ULTR",
        "ULVM",
        "UMDD",
        "UPRO",
        "UPW",
        "URE",
        "URTH",
        "URTY",
        "USEQ",
        "USHY",
        "USI",
        "USIG",
        "USLB",
        "USMC",
        "USMF",
        "USMV",
        "USRT",
        "USSG",
        "USTB",
        "USVM",
        "USXF",
        "UTRN",
        "UWM",
        "UXI",
        "UYG",
        "VABS",
        "VALT",
        "VAW",
        "VB",
        "VBK",
        "VBR",
        "VCEB",
        "VCIT",
        "VCLT",
        "VCR",
        "VCSH",
        "VDC",
        "VDE",
        "VEA",
        "VEGI",
        "VETS",
        "VEU",
        "VFH",
        "VFLQ",
        "VFMF",
        "VFMO",
        "VFMV",
        "VFQY",
        "VFVA",
        "VGIT",
        "VGK",
        "VGLT",
        "VGSH",
        "VGT",
        "VHT",
        "VIG",
        "VIGI",
        "VIOG",
        "VIOO",
        "VIOV",
        "VIRS",
        "VIS",
        "VLU",
        "VLUE",
        "VMBS",
        "VNLA",
        "VNMC",
        "VNQ",
        "VNQI",
        "VNSE",
        "VO",
        "VOE",
        "VONE",
        "VONG",
        "VONV",
        "VOO",
        "VOOG",
        "VOOV",
        "VOT",
        "VOX",
        "VPC",
        "VPL",
        "VPU",
        "VRAI",
        "VRIG",
        "VRP",
        "VSDA",
        "VSGX",
        "VSL",
        "VSMV",
        "VSS",
        "VT",
        "VTEB",
        "VTHR",
        "VTI",
        "VTRN",
        "VTV",
        "VTWG",
        "VTWO",
        "VTWV",
        "VUG",
        "VUSB",
        "VV",
        "VWID",
        "VWO",
        "VWOB",
        "VXF",
        "VXUS",
        "VYM",
        "VYMI",
        "WANT",
        "WBIE",
        "WBIF",
        "WBIG",
        "WBII",
        "WBIL",
        "WBIN",
        "WBIT",
        "WBIY",
        "WBND",
        "WCLD",
        "WDIV",
        "WEBL",
        "WFHY",
        "WFIG",
        "WINC",
        "WIP",
        "WLDR",
        "WOMN",
        "WPS",
        "WWJD",
        "WWOW",
        "XAX",
        "XBI",
        "XBUY",
        "XDAT",
        "XHE",
        "XHS",
        "XITK",
        "XJH",
        "XJR",
        "XLF",
        "XLG",
        "XLI",
        "XLK",
        "XLV",
        "XLY",
        "XMHQ",
        "XMLV",
        "XMMO",
        "XMPT",
        "XMVM",
        "XOP",
        "XPH",
        "XRLV",
        "XRT",
        "XSHD",
        "XSHQ",
        "XSLV",
        "XSMO",
        "XSOE",
        "XSVM",
        "XSW",
        "XT",
        "XVV",
        "XWEB",
        "XYLD",
        "XYLG",
        "YLD",
        "YLDE",
        "ZIG",
    ],
};
