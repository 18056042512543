import { ISorting } from "../dashboard/DashboardSlice";
import { IPagination, paginationQueryParam } from "../util/pagination";
import { authService } from "./authService";

export const transactionsService = {
    getTransactions,
    getHistoricTradesDownload,
    uploadManualTransaction,
    getUserTransactions,
    getUserHistoricTradesDownload,
    getManualTransaction,
    deleteManualTransaction,
    uploadCSVExchangeStatement,
    uploadCSVWalletStatement,
    createTransactionNote,
};

export interface IFilter {
    user: string;
    action: string;
    ticker: string;
    asset: string;
    account: string;
    volume: string;
    permissionStatus: string;
    date: string;
}

const base = process.env.REACT_APP_API_ENDPOINT;
const baseUrlTransactions = `${base}/transactions`;

/* Create transaction note */
function createTransactionNote(note: string, userActionId: string) {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
            "Content-type": "application/json",
        },
        body: JSON.stringify({
            note: note,
            userActionId: userActionId,
        }),
    };

    return fetch(`${baseUrlTransactions}/note`, requestParams);
}

/* Get all transactions */
function getTransactions(
    pagination: IPagination,
    filter: IFilter,
    sorting: ISorting[],
    category: string
): any {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
            "Content-type": "application/json",
        },
        body: JSON.stringify({
            filter,
            sorting,
            category: category,
        }),
    };

    return fetch(
        paginationQueryParam(`${baseUrlTransactions}/get`, pagination),
        requestParams
    )
        .then(authService.handleErrors)
        .then(handleResponse);
}

/* Get user transactions */
function getUserTransactions(
    pagination: IPagination,
    filter: IFilter,
    sorting: ISorting[],
    category: string
): any {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
            "Content-type": "application/json",
            "Cache-Control": "no-cache",
            pragma: "no-cache",
        },
        body: JSON.stringify({ filter, sorting, category: category }),
    };

    return fetch(
        paginationQueryParam(`${baseUrlTransactions}/user/get`, pagination),
        requestParams
    )
        .then(authService.handleErrors)
        .then(handleResponse);
}

function uploadManualTransaction(
    category: string,
    crypto_id: string,
    volume: number,
    price: number,
    transaction_date: string,
    transaction_type: string,
    userExchangeAccountId?: string,
    userWalletId?: string
): any {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
            "Content-type": "application/json",
        },
        body: JSON.stringify({
            category,
            crypto_id,
            volume,
            price,
            transaction_date,
            transaction_type,
            user_exchange_id: userExchangeAccountId ? userExchangeAccountId : "",
            user_wallet_id: userWalletId ? userWalletId : "",
        }),
    };

    return fetch(`${baseUrlTransactions}/manual/transaction`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

function deleteManualTransaction(transactionId: string): any {
    const requestParams = {
        method: "DELETE",
        headers: {
            ...authService.authHeader(),
        },
    };

    return fetch(`${baseUrlTransactions}/manual/${transactionId}`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

function getManualTransaction(
    userExchangeAccountId?: string,
    userWalletId?: string
): any {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
            "Content-type": "application/json",
        },
        body: JSON.stringify({
            user_exchange_id: userExchangeAccountId ? userExchangeAccountId : "",
            user_wallet_id: userWalletId ? userWalletId : "",
        }),
    };

    return fetch(`${baseUrlTransactions}/manual/get`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

/* Get filtered trade requests download */
function getHistoricTradesDownload(
    filter: IFilter,
    sorting: ISorting[],
    fields: string[],
    category: string
): any {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
            "Content-type": "application/json",
        },
        body: JSON.stringify({
            filter,
            sorting,
            fields,
            category: category,
        }),
    };

    return fetch(`${baseUrlTransactions}/download`, requestParams);
    // .then(authService.handleErrors)
    // .then(handleResponse);
}

/* Get filtered trade requests download */
function getUserHistoricTradesDownload(
    filter: IFilter,
    sorting: ISorting[],
    fields: string[],
    category: string
): any {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
            "Content-type": "application/json",
        },
        body: JSON.stringify({ filter, sorting, fields, category: category }),
    };

    return fetch(`${baseUrlTransactions}/user/download`, requestParams);
    // .then(authService.handleErrors)
    // .then(handleResponse);
}

/* Upload an Account Statement */
function uploadCSVExchangeStatement(
    userExchangeAccountId: string,
    formData: FormData
): any {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
        },
        body: formData,
    };

    return fetch(
        `${baseUrlTransactions}/manual/${userExchangeAccountId}/upload`,
        requestParams
    )
        .then(authService.handleErrors)
        .then(handleResponse);
}

/* Upload a Wallet Account Statement */
function uploadCSVWalletStatement(userWalletId: string, formData: FormData): any {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
        },
        body: formData,
    };

    return fetch(
        `${baseUrlTransactions}/wallets/manual/${userWalletId}/upload`,
        requestParams
    )
        .then(authService.handleErrors)
        .then(handleResponse);
}

function handleResponse(response: any) {
    return response.text().then((text: any) => {
        const data = text && JSON.parse(text);
        return data;
    });
}
