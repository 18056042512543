//The jsxRuntime pragma is necessary to get Emotion and React to work w/ latest Create-React-App version
/** @jsxRuntime classic */
/** @jsx jsx */
import styled from "@emotion/styled";

export const StyledAccoridanWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
