import React, { useEffect, FunctionComponent } from "react";
import {
  usePlaidLink,
  PlaidLinkOptions,
  PlaidLinkOnSuccess,
  PlaidLinkOnLoad,
  PlaidLinkOnExit,
} from "react-plaid-link";

interface Props {
  token: string;
  onSuccess:PlaidLinkOnSuccess
  onExit: PlaidLinkOnExit
}

const PlaidLink: FunctionComponent<Props> = ({ token, onSuccess, onExit }) => {

  const config: PlaidLinkOptions = {
    // When re-initializing Link after OAuth redirection, the same
    // Link token from the first initialization must be used
    token,
    onSuccess,
    onExit,
    // receivedRedirectUri: document.location.href, // required for OAuth
    // onExit
    // onEvent
  };

  const { open, ready } = usePlaidLink(config);

  // this opens link as soon as it's ready
  useEffect(() => {
    if (!ready) {
      return;
    }
    open();
  }, [ready, open]);

  // don't render anything, just open Link
  return null;
};

export default React.memo(PlaidLink)