import RequiredTextWarning from "./RequiredTextWarning";

interface RequiredFieldProps {
    children: any;
    submitAttempted: boolean;
    value: string;
    wrapperStyles? : React.CSSProperties;
    textStyles? : React.CSSProperties;
}

function RequiredField({
    children,
    submitAttempted,
    value,
    wrapperStyles,
    textStyles
}: RequiredFieldProps): JSX.Element {
    return (
        <>
            {children}

            {submitAttempted && !value ? <RequiredTextWarning wrapperStyles={wrapperStyles} textStyles={textStyles}/> : null}
        </>
    );
}

export default RequiredField;
