import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { analyticsService } from "../services/analyticsService";

export enum InsiderType {
    AbnormalGain = "AbnormalGain",
    TMinus = "TMinus",
}

export enum AmlType {
    HighValueOutgoing = "HighValueOutgoing",
    StructuringIn = "StructuringIn",
    StructuringOut = "StructuringOut",
    LowValueTrading = "LowValueTrading",
    LossMakingTrading = "LossMakingTrading",
    HighRiskCurrency = "HighRiskCurrency",
}

export type SortingField = "threshold" | "inspectDate" | "gain" | "numTrades";
export type SortingDirection = "asc" | "desc";
export interface ISorting {
    field: SortingField;
    direction: SortingDirection;
}

export type RelativeSortingField = "date" | "value";
export interface IRelativeSorting {
    field: RelativeSortingField;
    direction: SortingDirection;
}

export type WindowType = "DAILY" | "WEEK" | "MONTH";
export interface IFilter {
    userId?: string;
    startDate?: string;
    endDate?: string;
    asset?: {id: string, type: "CRYPTO" | "SECURITY"};
    windowType?: WindowType;
    flagged?: boolean;
    type?: string;
}

// export interface IFrequentTrading {
//     id: string;
//     anchorDate: string;
//     numTrades: number;
//     ticker: string;
//     userName: string;
//     windowType: WindowType;
//     userId: string;
//     flagged: boolean;
//     comment: string;
// }

export interface IInsiderTrading {
    insiderTradeType: InsiderType;
    id: string;
    flagUserId: string;
    anchorDate: string;
    windowType: WindowType;
    reviewing: string;
    username: string;
    ticker: string;
}

export interface IAml {
    amlTradeType: AmlType;
    id: string;
    flagUserId: string;
    anchorDate: string;
    windowType: WindowType;
    reviewing: string;
    username: string;
    ticker: string;
}

export interface ISuspiciousWallet {
    id: string;
    username: string;
    address: string;
    partyAddress: string;
    asset: string;
    cryptoId: string;
    reviewing: string;
    flagUserId: string;
}

export interface IRelatedTransaction {
    accountHolder: string;
    asset: string;
    price: number;
    volume: number;
    transactionDate: string;
    transactionType: string;
}

export interface IRelatedWalletTransaction {
    receiver: string;
    sender: string;
    asset: string;
    price: number;
    volume: number;
    transactionDate: string;
    transactionType: string;
}
export interface IRecords<T> {
    records: {
        data: Array<T>;
        nextPage: boolean;
    };
    related: {
        data: Array<IRelatedTransaction>;
        nextPage: boolean;
    };
    status: string;
}
export interface ISuspiciousWalletRecords {
    records: {
        data: Array<ISuspiciousWallet>;
        nextPage: boolean;
    };
    related: {
        data: Array<IRelatedWalletTransaction>;
        nextPage: boolean;
    };
    status: string;
}

export interface IAnalytics {
    insiderTradings: IRecords<IInsiderTrading>;
    amlRecords: IRecords<IAml>;
    suspiciousWallets: ISuspiciousWalletRecords;
    status: string;
}

const initialState: IAnalytics = {
    insiderTradings: {
        records: {
            data: [],
            nextPage: false,
        },
        related: {
            data: [],
            nextPage: false,
        },
        status: "idle",
    },
    amlRecords: {
        records: {
            data: [],
            nextPage: false,
        },
        related: {
            data: [],
            nextPage: false,
        },
        status: "idle",
    },
    suspiciousWallets: {
        records: {
            data: [],
            nextPage: false,
        },
        related: {
            data: [],
            nextPage: false,
        },
        status: "idle",
    },
    status: "idle",
};

// insider trading
export const fetchInsiderTradings = createAsyncThunk(
    "analytics/fetchInsiderTradings",
    async (payload: {
        filter: IFilter;
        sorting: ISorting[];
        pageNumber: number;
        pageSize?: number;
    }) => {
        const { pageNumber, pageSize = 10, filter, sorting } = payload;

        return await analyticsService.getInsiderTradings(
            filter,
            sorting,
            pageNumber,
            pageSize
        );
    }
);

export const fetchInsiderTradingRelated = createAsyncThunk(
    "analytics/fetchInsiderTradingRelated",
    async (payload: {
        record: IInsiderTrading;
        sorting: IRelativeSorting[];
        pageNumber: number;
        pageSize?: number;
    }) => {
        const { record, pageNumber, pageSize = 10, sorting } = payload;

        return await analyticsService.getInsiderTradingRelated(
            record.insiderTradeType,
            record.id,
            sorting,
            pageNumber,
            pageSize
        );
    }
);

export const flagInsiderTrading = createAsyncThunk(
    "analytics/flagInsiderTrading",
    async (payload: { record: IInsiderTrading }) => {
        const { record } = payload;

        return await analyticsService.flagInsiderTrading(
            record.id,
            record.insiderTradeType
        );
    }
);

export const reviewInsiderTrading = createAsyncThunk(
    "analytics/reviewInsiderTrading",
    async (payload: { record: IInsiderTrading }) => {
        const { record } = payload;

        return await analyticsService.reviewInsiderTrading(
            record.id,
            record.insiderTradeType
        );
    }
);

export const complianceInsiderTrading = createAsyncThunk(
    "analytics/complianceInsiderTrading",
    async (payload: { record: IInsiderTrading; comment: string }) => {
        const { record, comment } = payload;

        return await analyticsService.complianceInsiderTrading(
            record.id,
            record.insiderTradeType,
            comment
        );
    }
);

// aml
export const fetchAmlRecords = createAsyncThunk(
    "analytics/fetchAmlRecords",
    async (payload: {
        filter: IFilter;
        sorting: ISorting[];
        pageNumber: number;
        pageSize?: number;
    }) => {
        const { pageNumber, pageSize = 10, filter, sorting } = payload;

        return await analyticsService.getAmlRecords(
            filter,
            sorting,
            pageNumber,
            pageSize
        );
    }
);

export const fetchAmlRelated = createAsyncThunk(
    "analytics/fetchAmlRelated",
    async (payload: {
        record: IAml;
        sorting: IRelativeSorting[];
        pageNumber: number;
        pageSize?: number;
    }) => {
        const { record, pageNumber, pageSize = 10, sorting } = payload;

        return await analyticsService.getAmlRelated(
            record.amlTradeType,
            record.id,
            sorting,
            pageNumber,
            pageSize
        );
    }
);

export const flagAmlRecord = createAsyncThunk(
    "analytics/flagAmlRecord",
    async (payload: { record: IAml }) => {
        const { record } = payload;

        return await analyticsService.flagAmlRecord(record.id, record.amlTradeType);
    }
);

export const reviewAmlRecord = createAsyncThunk(
    "analytics/reviewAmlRecord",
    async (payload: { record: IAml }) => {
        const { record } = payload;

        return await analyticsService.reviewAmlRecord(record.id, record.amlTradeType);
    }
);

export const complianceAmlRecord = createAsyncThunk(
    "analytics/complianceAmlRecord",
    async (payload: { record: IAml; comment: string }) => {
        const { record, comment } = payload;

        return await analyticsService.complianceAmlRecord(
            record.id,
            record.amlTradeType,
            comment
        );
    }
);

// suspicious wallets
export const fetchSuspiciousWallets = createAsyncThunk(
    "analytics/fetchSuspiciousWallet",
    async (payload: {
        filter: IFilter;
        sorting: ISorting[];
        pageNumber: number;
        pageSize?: number;
    }) => {
        const { pageNumber, pageSize = 10, filter, sorting } = payload;

        return await analyticsService.getSuspiciousWallets(
            filter,
            sorting,
            pageNumber,
            pageSize
        );
    }
);

export const fetchSuspiciousWalletRelated = createAsyncThunk(
    "analytics/fetchSuspiciousWalletRelated",
    async (payload: {
        address: string;
        cryptoId: string;
        sorting: IRelativeSorting[];
        pageNumber: number;
        pageSize?: number;
    }) => {
        const { address, cryptoId, pageNumber, pageSize = 10, sorting } = payload;

        return await analyticsService.getSuspiciousWalletRelated(
            address,
            cryptoId,
            sorting,
            pageNumber,
            pageSize
        );
    }
);

export const flagSuspiciousWallet = createAsyncThunk(
    "analytics/flagSuspiciousWallet",
    async (payload: { record: ISuspiciousWallet }) => {
        const { record } = payload;

        return await analyticsService.flagSuspiciousWallet(
            record.partyAddress,
            record.cryptoId
        );
    }
);

export const reviewSuspiciousWallet = createAsyncThunk(
    "analytics/reviewSuspiciousWallet",
    async (payload: { record: ISuspiciousWallet }) => {
        const { record } = payload;

        return await analyticsService.reviewSuspiciousWallet(
            record.partyAddress,
            record.cryptoId
        );
    }
);

export const whitelistingSuspiciousWallet = createAsyncThunk(
    "analytics/whitelistingSuspiciousWallet",
    async (payload: { record: ISuspiciousWallet }) => {
        const { record } = payload;

        return await analyticsService.whitelistingSuspiciousWallet(
            record.partyAddress,
            record.cryptoId
        );
    }
);

export const complianceSuspiciousWallet = createAsyncThunk(
    "analytics/complianceSuspiciousWallet",
    async (payload: { record: ISuspiciousWallet; comment: string }) => {
        const { record, comment } = payload;

        return await analyticsService.complianceSuspiciousWallet(
            record.partyAddress,
            record.cryptoId,
            comment
        );
    }
);

// createSlice
const analyticsSlice = createSlice({
    name: "analytics1",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // insider tradings
            .addCase(fetchInsiderTradings.pending, (state, action) => {
                state.status = "loading";
                state.insiderTradings.status = "loading";
            })
            .addCase(fetchInsiderTradings.fulfilled, (state, action) => {
                state.insiderTradings.records = {
                    data: action.payload.data,
                    nextPage: action.payload.next_page,
                };
                state.insiderTradings.status = "idle";
                state.status = "idle";
            })
            .addCase(fetchInsiderTradingRelated.pending, (state, action) => {
                state.status = "loading";
                state.insiderTradings.status = "loading";
            })
            .addCase(fetchInsiderTradingRelated.fulfilled, (state, action) => {
                state.insiderTradings.related = {
                    data: action.payload.data,
                    nextPage: action.payload.next_page,
                };
                state.insiderTradings.status = "idle";
                state.status = "idle";
            })
            .addCase(flagInsiderTrading.pending, (state, action) => {
                state.status = "loading";
                state.insiderTradings.status = "loading";
            })
            .addCase(flagInsiderTrading.fulfilled, (state, action) => {
                state.insiderTradings.status = "idle";
                state.status = "idle";
            })
            .addCase(reviewInsiderTrading.pending, (state, action) => {
                state.status = "loading";
                state.insiderTradings.status = "loading";
            })
            .addCase(reviewInsiderTrading.fulfilled, (state, action) => {
                state.insiderTradings.status = "idle";
                state.status = "idle";
            })
            .addCase(complianceInsiderTrading.pending, (state, action) => {
                state.status = "loading";
                state.insiderTradings.status = "loading";
            })
            .addCase(complianceInsiderTrading.fulfilled, (state, action) => {
                state.insiderTradings.status = "idle";
                state.status = "idle";
            })
            // aml
            .addCase(fetchAmlRecords.pending, (state, action) => {
                state.status = "loading";
                state.amlRecords.status = "loading";
            })
            .addCase(fetchAmlRecords.fulfilled, (state, action) => {
                state.amlRecords.records = {
                    data: action.payload.data,
                    nextPage: action.payload.next_page,
                };
                state.amlRecords.status = "idle";
                state.status = "idle";
            })
            .addCase(fetchAmlRelated.pending, (state, action) => {
                state.status = "loading";
                state.amlRecords.status = "loading";
            })
            .addCase(fetchAmlRelated.fulfilled, (state, action) => {
                state.amlRecords.related = {
                    data: action.payload.data,
                    nextPage: action.payload.next_page,
                };
                state.amlRecords.status = "idle";
                state.status = "idle";
            })
            .addCase(flagAmlRecord.pending, (state, action) => {
                state.status = "loading";
                state.amlRecords.status = "loading";
            })
            .addCase(flagAmlRecord.fulfilled, (state, action) => {
                state.amlRecords.status = "idle";
                state.status = "idle";
            })
            .addCase(reviewAmlRecord.pending, (state, action) => {
                state.status = "loading";
                state.amlRecords.status = "loading";
            })
            .addCase(reviewAmlRecord.fulfilled, (state, action) => {
                state.amlRecords.status = "idle";
                state.status = "idle";
            })
            .addCase(complianceAmlRecord.pending, (state, action) => {
                state.status = "loading";
                state.amlRecords.status = "loading";
            })
            .addCase(complianceAmlRecord.fulfilled, (state, action) => {
                state.amlRecords.status = "idle";
                state.status = "idle";
            })
            // suspicious wallet
            .addCase(fetchSuspiciousWallets.pending, (state, action) => {
                state.status = "loading";
                state.suspiciousWallets.status = "loading";
            })
            .addCase(fetchSuspiciousWallets.fulfilled, (state, action) => {
                state.suspiciousWallets.records = {
                    data: action.payload.data,
                    nextPage: action.payload.next_page,
                };
                state.suspiciousWallets.status = "idle";
                state.status = "idle";
            })
            .addCase(fetchSuspiciousWalletRelated.pending, (state, action) => {
                state.status = "loading";
                state.suspiciousWallets.status = "loading";
            })
            .addCase(fetchSuspiciousWalletRelated.fulfilled, (state, action) => {
                state.suspiciousWallets.related = {
                    data: action.payload.data,
                    nextPage: action.payload.next_page,
                };
                state.suspiciousWallets.status = "idle";
                state.status = "idle";
            })
            .addCase(flagSuspiciousWallet.pending, (state, action) => {
                state.status = "loading";
                state.suspiciousWallets.status = "loading";
            })
            .addCase(flagSuspiciousWallet.fulfilled, (state, action) => {
                state.suspiciousWallets.status = "idle";
                state.status = "idle";
            })
            .addCase(reviewSuspiciousWallet.pending, (state, action) => {
                state.status = "loading";
                state.suspiciousWallets.status = "loading";
            })
            .addCase(reviewSuspiciousWallet.fulfilled, (state, action) => {
                state.suspiciousWallets.status = "idle";
                state.status = "idle";
            })
            .addCase(whitelistingSuspiciousWallet.pending, (state, action) => {
                state.status = "loading";
                state.suspiciousWallets.status = "loading";
            })
            .addCase(whitelistingSuspiciousWallet.fulfilled, (state, action) => {
                state.suspiciousWallets.status = "idle";
                state.status = "idle";
            })
            .addCase(complianceSuspiciousWallet.pending, (state, action) => {
                state.status = "loading";
                state.suspiciousWallets.status = "loading";
            })
            .addCase(complianceSuspiciousWallet.fulfilled, (state, action) => {
                state.suspiciousWallets.status = "idle";
                state.status = "idle";
            });
    },
});

/**
 * t- trading
 */
export const selectInsiderTradings = (state: { analytics1: IAnalytics }) =>
    state.analytics1.insiderTradings.records;
export const selectInsiderTradingRelated = (state: { analytics1: IAnalytics }) =>
    state.analytics1.insiderTradings.related;

/**
 * abnormal gain
 */
export const selectAmlRecords = (state: { analytics1: IAnalytics }) =>
    state.analytics1.amlRecords.records;
export const selectAmlRecordRelated = (state: { analytics1: IAnalytics }) =>
    state.analytics1.amlRecords.related;

/**
 * suspicious wallet
 */
export const selectSuspiciousWallet = (state: { analytics1: IAnalytics }) => {
    return state.analytics1.suspiciousWallets.records;
};
export const selectSuspiciousWalletRelated = (state: { analytics1: IAnalytics }) =>
    state.analytics1.suspiciousWallets.related;

export default analyticsSlice.reducer;
