export const formatDatetime = (initDate: string) => {
    const utc = new Date(initDate);
    const raw = new Date(
        Date.UTC(
            utc.getFullYear(),
            utc.getMonth(),
            utc.getDate(),
            utc.getHours(),
            utc.getMinutes(),
            utc.getSeconds()
        )
    );
    const localeDate = raw.toLocaleDateString();
    const localeTime = raw.toLocaleTimeString();
    const date = localeDate.split("T")[0].split("/");
    return `${date[0]}/${date[1]}/${date[2]} ${localeTime}`;
};
