import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import capitalizeFirstLetter from "../util/capitalizeFirstLetter";
import { formatDate } from "../util/formatDate";
import { Version } from "./AdminList";
import { ISecurity, ISecurityCompany } from "./RestrictionSlice";

interface IListItemProps {
    id: string;
    class: string;
    security?: ISecurity;
    securityType: string;
    securityCompany?: ISecurityCompany;
    restrictionType: string;
    transactionType: string;
    restrictedFrom: string;
    restrictedTo?: string;
    restrictionGroupName: string;
    visibilityType: string;
    type: Version;
    dateCreated: string;
    handleDelete(ticker: string): void;
}

/*
* A simplified version for admin list item
* */

function UserListItem(props: IListItemProps): JSX.Element {
    const company = useSelector((state: any) => state.auth.companyName);
    const [showRestrictionType, setShowRestrictionType] = useState(true);
    const [showVisibilityType, setShowVisibilityType] = useState(false);

    useEffect(() => {
        if (
            company === "Fargus" ||
            company === "Multicoin" ||
            company === "Framework" ||
            company === "Paradigm" ||
            company === "Celsius"
        ) {
            setShowRestrictionType(false);
        }
        if (company === "Jnk" || company === "Cinven" || company === "proSapient") {
            setShowVisibilityType(true);
        }
    }, [company]);

    return (
        <tr>
            <td>{props.class}</td>
            {
                <td>{props.security && props.security.ticker}</td>
            }
            {
            props.security && 
                (<td>{props.security.name}</td>)
            }
            {
            props.securityCompany && 
                (<td>{props.securityCompany.name}</td>)
            }
            {showRestrictionType && (
                <td>{capitalizeFirstLetter(props.restrictionType)}</td>
            )}
            {(company === "Paradigm" || company === "ArgusTest" || company === "Celsius") && (
                <td>
                    {company === "Paradigm" && props.restrictionGroupName === "default"
                        ? "Business Conflict"
                        : props.restrictionGroupName}
                </td>
            )}
            <td>{capitalizeFirstLetter(props.transactionType)}</td>
            {props.type !== Version.CRYPTO && showVisibilityType ? (
                <td>{capitalizeFirstLetter(props.visibilityType)}</td>
            ) : null}
            <td>{formatDate(props.restrictedFrom)}</td>
            <td>
                {props.restrictedTo ? formatDate(props.restrictedTo) : "Active"}
            </td>
        </tr>
    );
}

export default UserListItem;
