import {ErrorBoundary} from 'react-error-boundary'
import { StyledPageTitle } from '../components/StyledPageTitle'
import warning from "../assets/warning.svg";
import { StyledActionButtonSecondary } from '../components/StyledActionButtonSecondary';
import { authService } from '../services/authService';

type Error = {
    message: string
}

export function ErrorFallback({resetErrorBoundary = () => {}}) {
    return (
        <div style={{flex:1, display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', width:'100vw', height:'100vh', backgroundColor:'#2c71f0'}}>
            <div style={{
                backgroundColor:'white',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                flexDirection:'column',
                padding:'50px',
                borderRadius:10,
                width:'50%'
            }}>
                <StyledPageTitle style={{ fontSize: "15px", textAlign:'center'}}>
                    <img
                        src={warning}
                        alt="" 
                        height="35px"
                        style={{ verticalAlign: "middle", marginBottom: "10px", marginRight:"10px" }}
                    />
                    <span style={{fontWeight:'bolder', fontSize:'18px'}}>Something went wrong. </span><br />
                    <p style={{textAlign:'center'}}>
                    <span style={{fontWeight:'normal'}}>We are looking into it. Please try again later.</span>
                    </p>
                </StyledPageTitle>
                <StyledActionButtonSecondary onClick={resetErrorBoundary}>Try again</StyledActionButtonSecondary>
            </div>
        </div>
    )
}

const myErrorHandler = (error: Error, info: {componentStack: string}) => {
    // Do something with the error
    // E.g. log to an error logging client here
    authService.sendFrontendError({error: error.message, ...info})
  }

const AppErrorBoundary: React.FC = ({children}) =>  (
  <ErrorBoundary
    FallbackComponent={ErrorFallback}
    onError={myErrorHandler}
  >
      {children}
  </ErrorBoundary>
)
export default AppErrorBoundary