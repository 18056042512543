import priority_accounts from "./brokerage_priority";

export function get_priority(account: string): number {

    let found = false;

    priority_accounts.forEach((priority_account) => {
        if (account === priority_account) {
            found = true
        }
    })

    if (found) {
        return 1
    }

    return 2
}


export function compare_priority(a: string, b: string): number {

    return get_priority(a) - get_priority(b);

}

