export interface IPagination {
    pageNumber: number;
    pageSize: number;
}

export const paginationQueryParam = (url: string, pagination: IPagination) => {
    let param = ''
    if (url.includes('?')) {
        param = `&pageNumber=${pagination.pageNumber}&pageSize=${pagination.pageSize}`
    }
    else {
        param = `?pageNumber=${pagination.pageNumber}&pageSize=${pagination.pageSize}`
    }

    return `${url}${param}`
}