import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Attestation from "../components/Attestation";
import Representation from "./Representation";

type RepresentationSchema = Array<{
    key: string;
    type: string;
    label: string;
    reviewLabel?: string;
}>;

const frameworkMulticoinSchema: RepresentationSchema = [
    {
        key: "rep1",
        label: "1. Are you proposing to purchase securities or digital assets that are being issued and sold as part of a primary market offering (e.g., an initial public offering, initial coin offering, or similar distribution mechanism whereby the assets purchased are being sold to the public for the first time)?",
        reviewLabel: "Primary market offering: ",
        type: "YESNO",
    },
    {
        key: "rep2",
        label: "2. Are you proposing to purchase securities or digital assets in a limited, non-public offering pursuant to an exemption from the registration requirements of the Securities Act of 1933 (e.g., pursuant to Regulation D, Regulation A or Regulation S)?",
        reviewLabel: "Limited, non-public offering: ",
        type: "YESNO",
    },
    {
        key: "rep3",
        label: "3. Are you in possession of any material nonpublic information relating to the security or digital asset you propose to purchase, sell or trade, or the issuer thereof (including its affiliates and subsidiaries)? \n \t a. For the purposes of this question, “material nonpublic information” means information regarding the security or other asset that is the subject of your proposed trade, or the issuer thereof (including its affiliates and subsidiaries), which is not generally available to the public and which (i) a reasonable investor would likely consider important in deciding whether to buy, sell or trade such asset or (ii) if made public, would reasonably be expected to impact the price of the asset.",
        reviewLabel: "Possession of any material nonpublic information: ",
        type: "YESNO",
    },
    {
        key: "rep4",
        label: "4. Do you have any other knowledge relating to the security or digital asset you propose to sell, purchase or trade, or the issuer thereof (including its affiliates and subsidiaries), which, if used as a basis for your trade, would, to the best of your knowledge, violate Firm policies or applicable law?",
        reviewLabel: "Other knowledge relating to the asset or issuer: ",
        type: "YESNO",
    },
    {
        key: "rep5",
        label: "5. To the best of your knowledge, is the Firm considering any trades (or has it initiated any open orders to trade) in the security or digital asset you propose to purchase, sell or trade?",
        reviewLabel: "Firm trading: ",
        type: "YESNO",
    },
    {
        key: "rep6",
        label: "6. Have you had any communications with the issuer of the security or the issuer or development team (if any) for the digital asset you propose to purchase, sell or trade in the last 90 days?",
        reviewLabel: "Communications with the issuer or development team: ",
        type: "YESNO",
    },
    {
        key: "rep7",
        label: "Acknowledge: Your approval for the requested transaction will be valid for the period indicated in the approval notice; provided, however, that you may not rely on such approval if the facts or circumstances have changed such that the above representations are no longer true or accuraterepresent and warrant that your responses to the questions above are true, accurate and complete in all respects; and affirm that, to the best of your knowledge, you are not legally prohibited from executing the proposed transaction for which you seek preclearance.",
        type: "BOOL",
    },
];

const paradigmSchema: RepresentationSchema = [
    {
        key: "rep1",
        label: "1. Are you proposing to purchase securities or digital assets that are being issued and sold as part of a primary market offering (e.g., an initial public offering, initial coin offering, or similar distribution mechanism whereby the assets purchased are being sold to the public for the first time)?",
        reviewLabel: "Primary market offering: ",
        type: "YESNO",
    },
    {
        key: "rep2",
        label: "2. Are you in possession of any material nonpublic information relating to the security or digital asset you propose to purchase, sell or trade, or the issuer thereof (including its affiliates and subsidiaries)? a. For the purposes of this question, “material nonpublic information” means information regarding the security or other asset that is the subject of your proposed trade, or the issuer thereof (including its affiliates and subsidiaries), which is not generally available to the public and which (i) a reasonable investor would likely consider important in deciding whether to buy, sell or trade such asset or (ii) if made public, would reasonably be expected to impact the price of the asset.",
        reviewLabel: "Possession of any material nonpublic information: ",
        type: "YESNO",
    },
    {
        key: "rep3",
        label: "3. Do you have any other knowledge relating to the security or digital asset you propose to sell, purchase or trade, or the issuer thereof (including its affiliates and subsidiaries), which, if used as a basis for your trade, would, to the best of your knowledge, violate Firm policies, confidentiality agreements or securities laws?",
        reviewLabel: "Other knowledge relating to the asset or issuer: ",
        type: "YESNO",
    },
    {
        key: "rep4",
        label: "4. To the best of your knowledge, is the Firm considering any trades (or has it initiated any open orders to trade) in the security or digital asset you propose to purchase, sell or trade?",
        reviewLabel: "Firm trading: ",
        type: "YESNO",
    },
    {
        key: "rep5",
        label: "Acknowledge: Your approval for the requested transaction will be valid for the period indicated in the approval notice; provided, however, that you may not rely on such approval if the facts or circumstances have changed such that the above representations are no longer true or accurate.  You represent and warrant that your responses to the questions above are true, accurate and complete in all respects; and affirm that, to the best of your knowledge, you are not legally prohibited from executing the proposed transaction for which you seek preclearance.",
        type: "BOOL",
    },
];

const publicSchema: RepresentationSchema = [
    {
        key: "rep1",
        label: "1. Are you on the Public Alts investment committee?",
        reviewLabel: "Public Alts investment committee: ",
        type: "YESNO",
    },
    {
        key: "rep2",
        label: "2. Are you a FINRA registered or associated person?",
        reviewLabel: "FINRA registered person: ",
        type: "YESNO",
    },
    {
        key: "rep3",
        label: "3. Have you reviewed and acknowledged the Alts Insider Trading & Trade Preclearance Policy?",
        reviewLabel: "Policy on Trading of Alts: ",
        type: "YESNO",
    },
    {
        key: "rep4",
        label: '4. I understand the concept of “material nonpublic information” as it relates to Alts. For purposes of this attestation, "material nonpublic information" means information regarding an alternative asset which is not generally available to the public and which (i) a reasonable investor would likely consider important in deciding whether to buy, sell or trade such asset or (ii) if made public, would reasonably be expected to impact the price of the asset.',
        reviewLabel: "Understand MNPI: ",
        type: "YESNO",
    },
    {
        key: "rep5",
        label: "5. In requesting preclearance for this trade, I confirm that I am not in possession of any material nonpublic information relating to the alternative asset I propose to purchase or sell. If you are unsure whether information is material nonpublic, then assume it is material nonpublic information and refrain from trading. Reach out to Compliance with any questions.",
        reviewLabel: "Confirm no MNPI: ",
        type: "YESNO",
    },
];

const nydigSchema: RepresentationSchema = [
    {
        key: "rep1",
        label: "In requesting preclearance for this trade, I confirm that I am not in possession of any material nonpublic information relating to the digital asset I propose to purchase or sell. For the purposes of this attestation, “material nonpublic information” means information regarding a digital asset which is not generally available to the public and which (i) a reasonable investor would likely consider important in deciding whether to buy, sell or trade such asset or (ii) if made public, would reasonably be expected to impact the price of the asset.",
        reviewLabel: "Attestation: ",
        type: "YESNO",
    },
    {
        key: "rep2",
        label: "I have added the name of the account where I plan to execute this trade in the Notes field above.",
        reviewLabel: "Account name in Notes: ",
        type: "BOOL",
    },
];

const prosapientSchema: RepresentationSchema = [
    {
        key: "rep1",
        label: "I acknowledge the above statements",
        type: "BOOL",
    },
];

export const representation: { [company: string]: RepresentationSchema } = {
    FRAMEWORK: frameworkMulticoinSchema,
    MULTICOIN: frameworkMulticoinSchema,
    VARIANT: frameworkMulticoinSchema,
    PARADIGM: paradigmSchema,
    CELSIUS: paradigmSchema,
    KHELP: paradigmSchema,
    DRAGONFLY: paradigmSchema,
    ONERIVER: paradigmSchema,
    NYDIG: nydigSchema,
    ARGUS: nydigSchema,
    FARGUS: prosapientSchema,
    ARGUSTEST: paradigmSchema,
    PUBLIC: publicSchema,
    PROSAPIENT: prosapientSchema,
};

export function getReprensetation(company: string) {
    if (company && representation.hasOwnProperty(company.toUpperCase())) {
        return representation[company.toUpperCase()];
    }
    return frameworkMulticoinSchema;
}

function complete(reps: any, repsSchema: RepresentationSchema) {
    let counter = 0;
    for (let i = 0; i < reps.length; i++) {
        if (reps[i] === "YES" || reps[i] === "NO" || reps[i] === true) {
            counter++;
        } else {
        }
    }

    if (counter === repsSchema.length) {
        return true;
    } else {
        return false;
    }
}

function formRepresentativeJson(
    reps: Array<"YES" | "NO" | boolean | undefined>,
    repsSchema: RepresentationSchema
) {
    let result: { [key: string]: boolean } = {};
    reps.forEach((rep, index) => {
        let repValue = false;
        if (rep === "YES") {
            repValue = true;
        }

        result[repsSchema[index].key] =
            rep === "YES" || rep === "NO" ? repValue : (rep as boolean);
    });

    return result;
}

export interface IRepresentationsProps {
    setComplete(complete: boolean): void;
    showErrors: boolean;
    setRepresentationJson(repJson: any): void;
}

function Representations({
    setComplete,
    showErrors,
    setRepresentationJson,
}: IRepresentationsProps): JSX.Element {
    const company = useSelector((state: any) => state.auth.companyName);

    const [reps, setReps] = useState<Array<"YES" | "NO" | boolean | undefined>>([]);

    const [completeLocal, setCompleteLocal] = useState(false);

    const repsSchema = getReprensetation(company);

    useEffect(() => {
        setReps(
            repsSchema.map((repSchema) => {
                return repSchema.type === "YESNO" ? undefined : false;
            })
        );
    }, [repsSchema]);

    useEffect(() => {
        setComplete(complete(reps, repsSchema));
        setCompleteLocal(complete(reps, repsSchema));

        setRepresentationJson(formRepresentativeJson(reps, repsSchema));
        console.log(formRepresentativeJson(reps, repsSchema));
    }, [reps, setComplete, setCompleteLocal, setRepresentationJson, repsSchema]);

    return (
        <>
            {(company === "proSapient" || company === "Fargus") && (
                <div style={{ width: "50%" }}>
                    <label
                        style={{
                            fontSize: "11px",
                            lineHeight: "15px",
                            whiteSpace: "pre-wrap",
                            display: "block",
                        }}
                    >
                        You must take responsibility for ensuring you are aware of the
                        requirements of the proSapient Confidential Information and
                        Insider Trading Policy and adhere to it at all times. At all
                        times the interests of clients take priority over your personal
                        investment interests. You may not engage in fraudulent or
                        manipulative conduct in connection with the trading of
                        securities and/or shares. You may not profit, or cause others to
                        profit, based on your knowledge of completed or contemplated
                        Client transactions. Adhering to the proSapient Confidential
                        Information and Insider Trading policy, all personal trades must
                        be pre-approved through the Argus platform and subsequently
                        execute your trades in accordance with stated timeframes. <br />
                        <br />
                        Dealing on the basis of material non-public information and
                        confidential information is illegal. Confidential Information
                        should only be used for purposes related to proSapient’s
                        business activities. Employees are strictly prohibited from
                        using Confidential Information for any direct or indirect
                        personal gain or the gain of others and must exercise the utmost
                        care when handling Confidential Information to ensure that such
                        information is safeguarded and is not shared with unauthorised
                        third parties or other employees who do not have a “need to
                        know” such information (as determined by the proSapient
                        Compliance Team)
                    </label>
                </div>
            )}

            <div
                style={{
                    display: "flex",
                    width: 35,
                    justifyContent: "space-between",
                    paddingLeft: 3,
                    fontSize: 11,
                    marginBottom: 0,
                    paddingBottom: 0,
                    lineHeight: 2,
                }}
            >
                <b>Y</b>
                <b>N</b>
            </div>
            {repsSchema.map((repSchema, index) => {
                if (repSchema.type === "YESNO") {
                    return (
                        <Representation
                            name=""
                            style={{ attestation: {}, label: {} }}
                            value={reps[index] as "YES" | "NO" | undefined}
                            onCheck={(value: "YES" | "NO" | undefined) => {
                                console.log(reps[index], value);
                                const copyReps = [...reps];
                                copyReps[index] = value;
                                setReps(copyReps);
                            }}
                        >
                            {repSchema.label}
                        </Representation>
                    );
                } else {
                    return (
                        <Attestation
                            name=""
                            style={{ attestation: {}, label: {} }}
                            onCheck={(value: boolean) => {
                                console.log(reps[index], value);
                                const copyReps = [...reps];
                                copyReps[index] = !copyReps[index];
                                setReps(copyReps);
                            }}
                        >
                            {repSchema.label}
                        </Attestation>
                    );
                }
            })}
            {showErrors && !completeLocal && (
                <p style={{ margin: "0", color: "red" }}>
                    All representations must be complete to make a request.
                </p>
            )}
        </>
    );
}

export default Representations;
