import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ISorting } from "../dashboard/DashboardSlice";
import { IRestrictionGroup, restrictionService } from "../services/restrictionService";
import "react-toastify/dist/ReactToastify.css";
import downloadCsv from "../util/downloadCsv";
import { IPagination } from "../util/pagination";

export interface ISecurity {
    ticker: string;  // SecurityCompany won't have ticker
    name: string;
    id: string;
    platform: string;
    cryptocurrencyType: string;
};


export interface ISecurityCompany {
    name: string;
    platform: string;
    cryptocurrencyType: string;
    id: string;
}

export type RestrictionGroup = {
    name: string;
};

enum SecurityType {
    stock,
    securityCompany,
    crypto,
    privateAsset,
}

type SecurityTypeStrings = keyof typeof SecurityType;

export interface IRestriction {
    securityType: SecurityTypeStrings;
    crypto?: ISecurity;
    privateAsset?: ISecurity;
    securityCompany?: ISecurityCompany;
    restrictionType: string;
    visibilityType: string;
    transactionType: string;
    restrictionGroup: IRestrictionGroup;
    restrictedFrom: string;
    restrictedTo?: string;
    id: string;
    dateCreated: string;
}

const initialState: {
    defaultRestrictionGroup?: IRestrictionGroup;
    rule: {
        loaded: boolean;
        entities: IRestriction[];
        nextPage: boolean;
        status: string;
    };
    restrictionGroups: {
        loaded: boolean;
        status: string;
        entities: Array<IRestrictionGroup>;
    };
} = {
    defaultRestrictionGroup: undefined,
    rule: {
        status: "idle",
        loaded: false,
        entities: [],
        nextPage: false,
    },
    restrictionGroups: {
        status: "idle",
        loaded: false,
        entities: [],
    },
};

export interface IFilter {
    name: string;
    restrictionDate: string;
}

export const fetchRestrictions = createAsyncThunk(
    "restriction/fetchAnyRestrictions",
    async (payload: { pagination: IPagination; filter: IFilter; sorting: ISorting[] }) => {
        const { pagination, filter, sorting } = payload;
        return await restrictionService.getRestrictions(pagination, filter, sorting);
    }
);

export const createRestriction = createAsyncThunk(
    "restriction/createRestriction",
    async (payload: {
        securityCompanyId?: string;
        cryptoId?: string;
        privateAssetId?: string;
        restrictionType: string;
        restrictionGroups: IRestrictionGroup[];
        transactionType: string;
        visibilityType: string;
        restrictedFrom: string;
        restrictedTo?: string;
    }) => {
        const {
            securityCompanyId,
            cryptoId,
            privateAssetId,
            restrictionType,
            restrictionGroups,
            transactionType,
            visibilityType,
            restrictedFrom,
            restrictedTo,
        } = payload; /* Unpack parameters */
        const final = new Promise<IRestriction[]>((resolve) => {
            const promises: Promise<IRestriction>[] = [];
            restrictionGroups.forEach((g) => {
                const p: Promise<IRestriction> = restrictionService.saveRestriction(
                    restrictionType,
                    g.id,
                    transactionType,
                    visibilityType,
                    restrictedFrom,
                    restrictedTo,
                    securityCompanyId,
                    cryptoId,
                    privateAssetId
                );
                p.catch((reason) => console.log(reason));
                promises.push(p);
            });

            Promise.allSettled(promises).then((data) => {
                const successRes: IRestriction[] = [];
                data.forEach((res) => {
                    if (res.status === "fulfilled") {
                        successRes.push(res.value);
                    }
                });

                resolve(successRes ? successRes : []);
            });
        });

        return await final;
    }
);

export const deleteRestriction = createAsyncThunk(
    "restriction/deleteAnyRestriction",
    async (id: string) => {
        await restrictionService.deleteRestriction(id);
        return id;
    }
);

export const fetchRestrictionGroups = createAsyncThunk(
    "restriction/fetchRestrictionGroups",
    async () => {
        return await restrictionService.getRestrictionGroups();
    }
);

export const saveRestrictionGroup = createAsyncThunk(
    "restriction/saveRestrictionGroup",
    async (name: string) => {
        return await restrictionService.saveRestrictionGroup(name);
    }
);

export const updateRestrictionEnd = createAsyncThunk(
    "restriction/updateRestrictionEnd",
    async (payload: { restrictedTo: string; restrictionId: string }) => {
        const { restrictedTo, restrictionId } = payload;
        return await restrictionService.updateRestriction(
            restrictedTo,
            restrictionId,
            ""
        );
    }
);

export const fetchDefaultRestrictionGroup = createAsyncThunk(
    "restriction/fetchDefaultRestrictionGroup",
    async () => {
        return await restrictionService.getDefaultRestrictionGroup();
    }
);

export const updateRestrictionStart = createAsyncThunk(
    "restriction/updateRestrictionStart",
    async (payload: { restrictedFrom: string; restrictionId: string }) => {
        const { restrictedFrom, restrictionId } = payload;
        return await restrictionService.updateRestriction(
            "",
            restrictionId,
            restrictedFrom
        );
    }
);

export const saveUploadedRestrictions = createAsyncThunk(
    "stock/saveUploadedRestrictions",
    async (formData: FormData) => {
        let response = await restrictionService.saveStockRestrictionUpload(formData);
        let responseText = await response.text();
        let responseJson = JSON.parse(responseText);
        if (responseJson?.csv) {
            await downloadCsv("", "restricted_list_fix.csv", responseJson?.csv);
        }
        return responseJson;
    }
);

const ruleSlice = createSlice({
    name: "rules",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchRestrictions.pending, (state, action) => {
                state.rule.status = "loading";
            })
            .addCase(fetchRestrictions.fulfilled, (state, action) => {
                state.rule.entities = action.payload.data;
                state.rule.nextPage = action.payload.nextPage;
                state.rule.status = "idle";
                state.rule.loaded = true;
            })
            .addCase(fetchRestrictionGroups.pending, (state, action) => {
                state.restrictionGroups.status = "loading";
            })
            .addCase(fetchRestrictionGroups.fulfilled, (state, action) => {
                state.restrictionGroups.entities = action.payload.restrictionGroup;
                state.restrictionGroups.status = "idle";
            })
            .addCase(fetchDefaultRestrictionGroup.fulfilled, (state, action) => {
                state.defaultRestrictionGroup = action.payload;
            })
            .addCase(saveRestrictionGroup.fulfilled, (state, action) => {
                state.restrictionGroups.entities.push(action.payload.restrictionGroup);
            })
            .addCase(createRestriction.fulfilled, (state, action) => {
                state.rule.entities.push(...action.payload);
            })
            .addCase(deleteRestriction.fulfilled, (state, action) => {
                state.rule.entities = state.rule.entities.filter((entity) => {
                    return entity.id !== action.payload;
                });
                state.rule.status = "idle";
            })
            .addCase(updateRestrictionEnd.fulfilled, (state, action) => {
                for (var i = 0; i < state.rule.entities.length; i++) {
                    if (state.rule.entities[i].id === action.payload.id) {
                        state.rule.entities[i] = action.payload;
                    }
                }
                state.rule.status = "idle";
            })
            .addCase(updateRestrictionStart.fulfilled, (state, action) => {
                for (var i = 0; i < state.rule.entities.length; i++) {
                    if (state.rule.entities[i].id === action.payload.id) {
                        state.rule.entities[i] = action.payload;
                    }
                }
                state.rule.status = "idle";
            });
    },
});

/* Selector for state.entities array */
export const selectRules = (state: any) =>
    state.restriction.rule.entities as IRestriction[];

export const selectRestrictionGroups = (state: any) =>
    state.restriction.restrictionGroups.entities as Array<IRestrictionGroup>;

export const selectDefaultestrictionGroup = (state: any) =>
    state.restriction.defaultRestrictionGroup as IRestrictionGroup;

export default ruleSlice.reducer;
