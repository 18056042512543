import capitalizeFirstLetter from "../util/capitalizeFirstLetter";
import { authService } from "./authService";

export const attestationsService = {
    getAttestations,
    getAdminAttestations,
    createAttestation,
    downloadAttestation,
};

const base = process.env.REACT_APP_API_ENDPOINT;
const baseUrl = `${base}/attestations`;

function getAttestations(): any {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
        },
    };

    return fetch(`${baseUrl}/get`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

function getAdminAttestations(id?: string): any {
    const requestParams = {
        method: "POST",
        headers: {
            ...authService.authHeader(),
        },
    };

    let uri = "";
    if (id) {
        uri = `${baseUrl}/admin/${id}/get`;
    } else {
        uri = `${baseUrl}/admin/get`;
    }

    return fetch(uri, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

function createAttestation(quarter: string, year: number, signature: string): any {
    const requestParams = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            ...authService.authHeader(),
        },
        body: JSON.stringify({ quarter: quarter, year: year, signature: signature }),
    };

    return fetch(`${baseUrl}`, requestParams)
        .then(authService.handleErrors)
        .then(handleResponse);
}

function downloadAttestation(
    quarter: string,
    year: number,
    userId: string,
    type: string
): any {
    const requestParams = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            ...authService.authHeader(),
        },
        body: JSON.stringify({}),
    };

    return fetch(
        `${baseUrl}/download/${userId}/${year}/${quarter}/${type}`,
        requestParams
    )
        .then(authService.handleErrors)
        .then((response) => response.blob())
        .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
                "download",
                `${capitalizeFirstLetter(type)}-${quarter}-${year}.pdf`
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            if (link.parentNode) {
                link.parentNode.removeChild(link);
            }
        });
}

function handleResponse(response: any) {
    return response.text().then((text: any) => {
        const data = text && JSON.parse(text);
        return data;
    });
}
