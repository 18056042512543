import { useSelector } from "react-redux";
import { StyledSpacing } from "../components/StyledSpacing";
import GlobalRestrictions from "./GlobalRestrictions/GlobalRestrictions";
import Accounts from "../accounts/Accounts";
import UserRule from "./UserRestrictions";

function User(): JSX.Element {
    const company = useSelector((state: any) => state.auth.companyName);

    return (
        <>
            <StyledSpacing>
                <GlobalRestrictions />
            </StyledSpacing>

            <UserRule />

            {(company === "Paradigm" || company === "OneRiver") && <Accounts />}
        </>
    );
}

export default User;
