import { StyledTableContainer } from "../components/StyledTable";
import { IWatch } from "./WatchlistSlice";
import WatchlistListItem from "./WatchlistListItem";
import SelectableTableHeader from "../components/SelectableTableHeader";
import { ISorting, SortingField } from "../dashboard/DashboardSlice";

/* Type for the WatchlistList props */
interface IListProps {
    watched: Array<IWatch>;
    handleDelete(ticker: string): void;
    setShowModal(show: boolean, ticker: string, name: string, securityId: string): void;
    setSorting: (sorting: ISorting) => void
    sorting: ISorting
}

const headerData = [
    { fieldText: "Ticker", fieldValue: "ticker" },
    { fieldText: "Name", fieldValue: "name" },
    // { fieldText: "Price", fieldValue: "price" },
    { fieldText: "Status", fieldValue: "status" },
];

function WatchlistList(props: IListProps): JSX.Element {
    /* Provide default values and replace them with the prop values if there are any. */
    const { watched, sorting, setSorting } = props;

    return (
        <StyledTableContainer>
            <table>
                <thead>
                    <tr>
                        {headerData.map((header, index) => {
                            return (
                                <SelectableTableHeader
                                    key={index}
                                    targetCompare={header.fieldValue}
                                    activeCompare={sorting.field}
                                    setActiveCompare={(field: SortingField) => setSorting({ ...sorting, field })}
                                    asc={sorting.direction === 'asc'}
                                    setAsc={(asc: boolean) => setSorting({ ...sorting, direction: asc ? 'asc' : 'desc' })}
                                    disabled={['status']}
                                >
                                    {header.fieldText}
                                </SelectableTableHeader>
                            );
                        })}
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {/* Generate the AdminListItems and add them to the ul */}
                    {/* 
                        1. Filter by the activeFilter, show everything if empty, matching names and tickers if not.
                        2. Slice to show only show pageSize number of results (defaults to 100)
                        3. Map the entities array to AdminListItem components
                        */}
                    {watched
                        .map((watch: IWatch, index: number) => (
                            <WatchlistListItem
                                securityId={watch.securityId}
                                ticker={watch.ticker}
                                name={watch.name}
                                price={watch.price}
                                movement={watch.movement}
                                status={watch.status}
                                handleDelete={props.handleDelete}
                                setShowModal={props.setShowModal}
                                key={index}
                            />
                        ))}
                </tbody>
            </table>
        </StyledTableContainer>
    );
}
export default WatchlistList;
